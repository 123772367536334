import { useEffect } from 'react';
import { useNewAppointmentContentV2Context } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/contexts/NewAppointmentContentV2ContextProvider';
import { useAuth } from '@rs-core/context/UserAuthContext';

const usePageChangeWatcher = () => {
	const { patientMapping } = useAuth();

	const {
		isUpdateForm,
		page,
		appointment,
		accounts,
		setSelectedProfile,
		form,
		rescheduleScanType,
		setFacilityList,
		rescheduleFacility,
		setIsGenderAvailable,
	} = useNewAppointmentContentV2Context();

	useEffect(() => {
		const callback = async () => {
			// set page one details if isUpdateForm and user clicked back
			if (isUpdateForm && page === 'first') {
				const appointmentPatientMapping = patientMapping.filter(
					item =>
						item.patientId ==
						appointment?.resource?.participant
							?.find(eachAppointment =>
								eachAppointment?.actor?.reference?.toLowerCase().includes('patient')
							)
							?.actor.reference.split('/')[1]
				)[0];

				// find the profile based on fname and lname of mapping
				const appointmentFamilyMember = accounts.filter(
					item =>
						item?.firstName?.toLowerCase() === appointmentPatientMapping?.firstName.toLowerCase() &&
						item?.lastName?.toLowerCase() === appointmentPatientMapping?.lastName.toLowerCase()
				);

				const initialProfile = appointmentFamilyMember[0];
				setSelectedProfile(initialProfile?.profileId);

				form.setValue('patient', initialProfile);
				form.setValue('gender', initialProfile?.gender?.toUpperCase());
				form.setValue('birthDate', initialProfile?.birthDate);

				setIsGenderAvailable(Boolean(initialProfile?.gender));

				form.setValue('scanType', rescheduleScanType);
				setFacilityList([
					{
						organizationName: appointment?.organization,
						internalOrganizationID: appointment?.internalOrganizationID,
					},
				]);
				form.setValue('facility', rescheduleFacility);
			}
		};

		callback();
	}, [isUpdateForm, page]);
};

export default usePageChangeWatcher;
