import { useEffect } from 'react';
import useOnUserChange from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/hooks/useOnUserChange';
import { useNewAppointmentContentV2Context } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/contexts/NewAppointmentContentV2ContextProvider';
import { useAuth } from '@rs-core/context/UserAuthContext';

const useGenderBirthDateWatcher = () => {
	const { profiles } = useAuth();

	const { form } = useNewAppointmentContentV2Context();

	const { onUserChange } = useOnUserChange();

	const patient = form.watch('patient');
	const gender = form.watch('gender');
	const birthDate = form.watch('birthDate');

	useEffect(() => {
		if (gender) {
			const currentGender = profiles[patient?.profileId]?.gender;
			if (currentGender != gender) {
				onUserChange('gender', gender);
			}
		}
		if (birthDate) {
			const currentBirthDate = profiles[patient?.profileId]?.birthDate;
			if (currentBirthDate != birthDate) {
				onUserChange('birthDate', birthDate);
			}
		}
	}, [gender, birthDate]);
};

export default useGenderBirthDateWatcher;
