// core
import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import commonMiddlewares from '@rs-core/store/middlewares';

// api
import getUserInfo from '@worklist-2/patientPortal/src/api/getUserInfo';
import patchUser from '@worklist-2/patientPortal/src/api/patchUser';

// utils
import { extractEmergencyContactsFromProfile } from '@worklist-2/patientPortal/src/utils/extractEmergencyContactsFromProfile';
import { extractPhysiciansFromProfile } from '@worklist-2/patientPortal/src/utils/extractPhysiciansFromProfile';
import { getAllergyDataFromProfile } from '@worklist-2/patientPortal/src/utils/getAllergyDataFromProfile';
import { extractAddressFromProfile } from '@worklist-2/patientPortal/src/utils/extractAddressFromProfile';
import { updateEmergencyContacts, updatePhysicians, updateLanguage } from '../utils/updateUser';
import { LANGUAGE_CHANGE } from '@worklist-2/patientPortal/src/analytics/eventTypes';
import sendAnalyticsEvent from '../analytics';
import getChangedFieldsOnEditProfile from '../utils/getChangedFieldsOnEditProfile';
import divideObject from '../utils/divideObject';

const DEFAULT_LANGUAGE = 'en';

export const useUserStore = create(
	commonMiddlewares((set, get) => ({
		user: null, // selected user
		primaryUser: null,
		emergencyContacts: [],
		physicians: [],
		selectedLanguage: DEFAULT_LANGUAGE,
		userFeedback: null,
		profiles: null,
		isSynced: false,

		// setters
		setUser: user => set(() => ({ user })),
		setPhysicians: physicians => set(() => ({ physicians })),
		setEmergencyContacts: emergencyContacts => set(() => ({ emergencyContacts })),
		setLanguage: selectedLanguage => set(() => ({ selectedLanguage })),
		setUserFeedback: userFeedback => set(() => ({ userFeedback })),

		// actions
		fetchUser: async ({ __config }) => {
			try {
				const userInfo = await getUserInfo({ __config });

				let primaryUserProfileId;
				let primaryUser;

				for (const key in userInfo.aggregatedetails) {
					if (userInfo.aggregatedetails[key].isPrimary) {
						primaryUserProfileId = key;
						primaryUser = userInfo.aggregatedetails[key];
						break;
					}
				}

				const extractedEmergencyContacts = extractEmergencyContactsFromProfile(primaryUser);
				const extractedPhysicians = extractPhysiciansFromProfile(primaryUser);
				const user = {
					...extractAddressFromProfile(primaryUser?.address),
					img: primaryUser?.profilePictureUrl,
					firstName: primaryUser?.firstName || '',
					middleName: primaryUser?.middleName || '',
					lastName: primaryUser?.lastName || '',
					birthDate: primaryUser?.birthDate || '',
					gender: primaryUser?.gender || '',
					phone: userInfo.phone || '',
					email: userInfo.email || '',
					race: primaryUser?.race || '',
					ethnicity: primaryUser?.ethnicity || '',
					birthSex: primaryUser?.birthSex || '',
					language: primaryUser?.language || '',
					ssn: primaryUser?.ssn || '',
					maritalStatus: primaryUser?.maritalStatus || '',
					smokingStatus: primaryUser?.smokingStatus || '',
					alcoholUse: primaryUser?.alcoholUse || '',
					motherMaidenName: primaryUser?.motherMaidenName || '',
					height: primaryUser?.height || '',
					weight: primaryUser?.weight || '',
					abha: primaryUser?.abha || {},
					profileId: primaryUserProfileId,
					isPrimary: true,
					myPhysician: extractedPhysicians,
					emergencyContacts: extractedEmergencyContacts,
					allergyMapping: getAllergyDataFromProfile(primaryUser?.allergyMapping),
				};

				set(() => ({
					user,
					profiles: userInfo.aggregatedetails,
					primaryUser: { profileId: primaryUserProfileId, ...primaryUser },
					emergencyContacts: extractedEmergencyContacts,
					physicians: extractedPhysicians,
					selectedLanguage: userInfo.userSettings?.language || DEFAULT_LANGUAGE,
					userFeedback: userInfo.userFeedback || null,
					isSynced: Boolean(userInfo.isSynced),
				}));

				patchUser({ __config, data: [{ operation: 'ADD', path: '/isOnline', value: true }] });

				return userInfo.aggregatedetails;
			} catch (error) {
				console.error(`Error while getting current loggedIn user: ${error.message}`);
			}
		},
		updateUser: async ({ __config, payload }) => {
			const { user, setUser } = get();
			const updatedUser = { ...user, ...payload };
			const profileId = updatedUser.profileId;
			const changedFields = getChangedFieldsOnEditProfile(payload, user);

			// there is a limitation in backend. We can't patch with more that 10 values, so we have to divide requests
			const { firstObject, secondObject } = divideObject(changedFields);
			const firstUpdateList = [];
			const secondUpdateList = [];
			for (const key in firstObject) {
				firstUpdateList.push({
					operation: 'ADD',
					path: `/Aggregatedetails/${profileId}/${key}`,
					value: firstObject[key],
				});
			}

			for (const key in secondObject) {
				secondUpdateList.push({
					operation: 'ADD',
					path: `/Aggregatedetails/${profileId}/${key}`,
					value: secondObject[key],
				});
			}
			let response = await patchUser({ __config, data: firstUpdateList });

			if (secondUpdateList.length) {
				response = await patchUser({ __config, data: secondUpdateList });
			}

			setUser(updatedUser);

			// updated profile hash
			return response.key;
		},
		createReferringPhysician: async ({ __config, data }) => {
			const uniqueId = uuidv4();
			data.id = uniqueId;
			const { physicians, user, setPhysicians } = get();
			const updatedPhysicians = [...physicians, data];
			updatePhysicians({ __config, profileId: user.profileId, payload: updatedPhysicians });
			setPhysicians(updatedPhysicians);
		},
		updateReferringPhysician: async ({ __config, data }) => {
			const { physicians, user, setPhysicians } = get();
			const updatedPhysicians = physicians.map(physician => (physician.id === data.id ? data : physician));
			updatePhysicians({ __config, profileId: user.profileId, payload: updatedPhysicians });
			setPhysicians(updatedPhysicians);
		},
		deleteReferringPhysician: async ({ __config, data }) => {
			const { physicians, user, setPhysicians } = get();
			const updatedPhysicians = physicians.filter(physician => physician.id !== data.id);
			updatePhysicians({ __config, profileId: user.profileId, payload: updatedPhysicians });
			setPhysicians(updatedPhysicians);
		},
		createEmergencyContact: async ({ __config, data }) => {
			const uniqueId = uuidv4();
			data.id = uniqueId;
			data.emergencyContactId = uniqueId;
			const { user, setEmergencyContacts, emergencyContacts } = get();
			const updatedEmergencyContacts = [...emergencyContacts, data];

			updateEmergencyContacts({ __config, profileId: user.profileId, payload: updatedEmergencyContacts });
			setEmergencyContacts(updatedEmergencyContacts);
		},
		updateEmergencyContacts: async ({ __config, data }) => {
			const { emergencyContacts, setEmergencyContacts, user } = get();
			const updatedEmergencyContacts = emergencyContacts.map(emergencyContact => {
				if (emergencyContact.id) {
					return emergencyContact.id === data.id ? data : emergencyContact;
				}

				return emergencyContact.emergencyContactId === data.emergencyContactId ? data : emergencyContact;
			});
			updateEmergencyContacts({ __config, profileId: user.profileId, payload: updatedEmergencyContacts });
			setEmergencyContacts(updatedEmergencyContacts);
		},
		deleteEmergencyContact: async ({ __config, data }) => {
			const { setEmergencyContacts, emergencyContacts, user } = get();
			const updatedEmergencyContacts = emergencyContacts.filter(emergencyContact => {
				if (emergencyContact.id) {
					return emergencyContact.id !== data.id;
				}
				return emergencyContact.emergencyContactId !== data.emergencyContactId;
			});
			updateEmergencyContacts({ __config, profileId: user.profileId, payload: updatedEmergencyContacts });
			setEmergencyContacts(updatedEmergencyContacts);
		},
		updateLanguage: ({ __config, language }) => {
			const { setLanguage } = get();
			updateLanguage({ __config, Language: language });
			setLanguage(language);
			sendAnalyticsEvent(LANGUAGE_CHANGE, { language });
		},
	}))
);
