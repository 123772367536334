export default function changeLanguage(selectedLanguage) {
	const observer = new MutationObserver(() => {
		const optionNodes = document.querySelector('.goog-te-combo')?.querySelectorAll('option');
		if (optionNodes?.length > 0) {
			const frame = document.querySelector('.goog-te-combo');
			if (selectedLanguage === 'en' || !selectedLanguage) {
				frame.value = 'en';
				frame.dispatchEvent(new Event('change'));
			} else {
				const languageLinks = frame?.querySelectorAll('option');
				if (languageLinks) {
					languageLinks.forEach(link => {
						if (link.value === selectedLanguage) {
							frame.value = selectedLanguage;
							const event = new Event('change');
							frame.dispatchEvent(event);
						}
					});
				}
			}
			observer.disconnect();
		}
	});
	observer.observe(document.body, {
		childList: true,
		subtree: true,
	});
}
