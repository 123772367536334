import * as yup from 'yup';
import { mediaMobile, mediaTablet } from '@rs-core/utils/responsiveUtils';

const CARD_HEIGHT = 150;
const CARD_SPACING = 16;

const notificationType = {
	OAI_PATIENT_SYNC: 'OAI_PATIENT_SYNC',
	OAI_PATIENT_DATA_SYNC: 'OAI_PATIENT_DATA_SYNC',
};

const publicBookingSchema = yup
	.object()
	.shape({
		patient: yup.object().required('Required'),
		facility: yup.object().required('Required').nullable(),
		scanType: yup.object().required('Required'),
	})
	.required();

const newAppointmentSchema = yup
	.object()
	.shape({
		patient: yup.object().required('Required'),
		facility: yup.object().required('Required').nullable(),
		scanType: yup.object().required('Required'),
	})
	.required();

const menuProps = {
	PaperProps: {
		sx: {
			minWidth: '428px !important',
			maxHeight: '340px !important',
			marginTop: '4px',
			border: '1px solid rgba(255, 255, 255, 0.1)',
			boxShadow: '2px 2px 8px 2px rgba(18, 18, 18, 0.08)',
			borderRadius: '6px',
			[mediaTablet]: {
				minWidth: '324px !important',
			},
			[mediaMobile]: {
				minWidth: 'calc(100% - 36px) !important',
			},
		},
	},
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'right',
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'right',
	},
};

export { CARD_HEIGHT, CARD_SPACING, notificationType, publicBookingSchema, newAppointmentSchema, menuProps };
