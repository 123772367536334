import React, { createContext, useContext, useRef, useState } from 'react';
import { getSeriesModality } from '../utils/utils';
import { BROADCAST_EVENTS } from './consts/consts';
import { useImageViewerMultiMonitorContext } from './ImageViewerMultiMonitorContext';
import useStateRef from '../hooks/useStateRef';
import getViewportWindowLevel from '../cornerstone/getViewportWindowLevel';
import { useImageViewerLayoutStoreSelector } from '../features/CustomToolbar/context/zustand/ImageViewerLayoutZustandProvider';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const ImageViewerLayoutContext = createContext({});

const ImageViewerLayoutContextProvider = ({ children }) => {
	const wonIvCustomtoolbar = useBooleanFlagValue('WON-IV-CUSTOMTOOLBAR');
	const setActiveViewportZustand = useImageViewerLayoutStoreSelector(state => state.setActiveViewport);
	const setActiveViewportIdZustand = useImageViewerLayoutStoreSelector(state => state.setActiveViewportId);
	const activeViewportIdZustand = useImageViewerLayoutStoreSelector(state => state.activeViewportId);

	const { isEnabledMultiMonitor, screenNumber } = useImageViewerMultiMonitorContext();

	const [layoutSize, setLayoutSize] = useStateRef({
		rows: 1,
		columns: 1,
	});

	const [layoutItems, setLayoutItems, layoutItemsRef] = useStateRef([]);
	const [activeViewportId, setActiveViewportId] = useState();
	const [isMPRView, setIsMPRView] = useState(false);
	const [MPRSeries, setMPRSeries] = useState();
	const [isFusionView, setIsFusionView] = useState(false);
	const [FusionCTSeries, setFusionCTSeries] = useState();
	const [FusionPTSeries, setFusionPTSeries] = useState();
	const [draggingElementData, setDraggingElementData] = useState(null);
	const [highlightedViewport, setHighlightedViewport, highlightedViewportRef] = useStateRef(null);
	const [blendMode, setBlendMode] = useState(null);
	const [activeViewportModality, setActiveViewportModality] = useState(null);
	const [multiViewportState, setMultiViewportState] = useState([]);

	const isStackViewportInitializedRef = useRef(false);
	const previousLayout = useRef(null);
	const keyImageIndex = useRef(0);
	const fusionViewportsIds = useRef({});
	const activeViewportIdRef = useRef(activeViewportId);
	const isMPRViewRef = useRef(isMPRView);
	const isFusionViewRef = useRef(isFusionView);
	activeViewportIdRef.current = activeViewportId;
	isMPRViewRef.current = isMPRView;
	isFusionViewRef.current = isFusionView;

	const MPROptions = useRef({});

	const setMPR = async ({ layoutItem }) => {
		const viewport = layoutItem.viewports[0];

		MPROptions.current = getViewportWindowLevel(viewport.id);

		setMPRSeries(layoutItem.series);
		setIsMPRView(true);
	};

	const disableMPR = () => {
		setMPRSeries(undefined);
		setIsMPRView(false);
		setBlendMode(null);
	};

	const fusionOptions = useRef({
		CT: {},
		PT: {},
	});

	const setFusion = async () => {
		let _layoutItems = layoutItems;

		if (isEnabledMultiMonitor) {
			_layoutItems = layoutItems.filter(layoutItem => layoutItem.screenNumber === screenNumber);
		}

		const CTLayoutItem = _layoutItems?.find(item => getSeriesModality(item.series) === 'CT');
		const PTLayoutItem = _layoutItems?.find(item => getSeriesModality(item.series) === 'PT');

		const CTViewport = CTLayoutItem.viewports[0];
		const PTViewport = PTLayoutItem.viewports[0];

		const CTSeries = CTLayoutItem.series;
		const PTSeries = PTLayoutItem.series;

		fusionOptions.current = {
			CT: getViewportWindowLevel(CTViewport.id),
			PT: getViewportWindowLevel(PTViewport.id),
		};

		setFusionCTSeries(CTSeries);
		setFusionPTSeries(PTSeries);
		setIsFusionView(true);
	};

	const disableFusion = () => {
		setFusionCTSeries(undefined);
		setFusionPTSeries(undefined);
		setIsFusionView(false);
	};

	return (
		<ImageViewerLayoutContext.Provider
			value={{
				layoutSize,
				setLayoutSize,
				layoutItems,
				setLayoutItems,
				activeViewportId: wonIvCustomtoolbar ? activeViewportIdZustand : activeViewportId,
				setActiveViewportId: wonIvCustomtoolbar ? setActiveViewportIdZustand : setActiveViewportId,
				setActiveViewport: wonIvCustomtoolbar ? setActiveViewportZustand : () => {},
				setMPR,
				disableMPR,
				isMPRView,
				setFusion,
				isFusionView,
				disableFusion,
				fusionViewportsIds,
				keyImageIndex,
				activeViewportIdRef: wonIvCustomtoolbar ? { current: activeViewportIdZustand } : activeViewportIdRef,
				isMPRViewRef,
				isFusionViewRef,
				setIsMPRView,
				setIsFusionView,
				layoutItemsRef,
				MPRSeries,
				setMPRSeries,
				MPROptions,
				FusionCTSeries,
				setFusionCTSeries,
				FusionPTSeries,
				setFusionPTSeries,
				fusionOptions,
				isStackViewportInitializedRef,
				previousLayout,
				draggingElementData,
				setDraggingElementData,
				highlightedViewport,
				highlightedViewportRef,
				setHighlightedViewport,
				blendMode,
				setBlendMode,
				activeViewportModality,
				setActiveViewportModality,
				multiViewportState,
				setMultiViewportState,
			}}
		>
			{children}
		</ImageViewerLayoutContext.Provider>
	);
};

const useImageViewerLayoutContext = () => useContext(ImageViewerLayoutContext);

export { useImageViewerLayoutContext, ImageViewerLayoutContextProvider, ImageViewerLayoutContext };
