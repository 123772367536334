// @flow

// core
import React, { type Node, useCallback, useEffect, useState } from 'react';

// libs
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { fhirExtensionUrls } from '@worklist-2/core/src';

// utils
import PractitionerMapping from '@rs-core/fhir/resource/columnMapping/PractitionerMapping';
import useLoading from '@rs-core/hooks/useLoading';
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';
import useDebounce from '@rs-core/hooks/useDebounce';
import { searchScopes } from '@rs-core/context/consts/searchScopes';

import getUserFullName from '@rs-core/fhir/resource/columnMapping/utils/getUserFullName';
import { Popper } from '@mui/material';

const getNameInitials = name => {
	if (name && name.length > 0) {
		const nameSlices = getUserFullName(name).split(' ');

		return nameSlices.length > 1 ? `${nameSlices[0]?.[0] || ''}${nameSlices[1]?.[0] || ''}` : name[0];
	}

	return '...';
};

interface IUserSelectorProps {
	/**
	 * User data. Must include full name. May include profile picture.
	 */
	data: any[];

	/**
	 * Component size. Can be `small` or `medium`
	 * */
	size: string;

	/**
	 * Determines if the component should take up the full width of the container.
	 */
	fullWidth: boolean;

	/**
	 * Controls the width of the component. If `fullWidth` is true, this value does not take effect.
	 */
	width: number;
	/**
	 * Form validation error for UserSelector.
	 */
	error: boolean;

	/**
	 * Form valiadation error message for UserSelector.
	 */
	helperText: string;

	/**
	 * Default value of UserSelector
	 */
	defaultValue: object;
	/**
	 *  label of UserSelector
	 */
	mainLabel: string;
	/**
	 *  Search parameters to filter Users by
	 */
	searchParams: object;
	/**
	 *  Flag for showing 'Unassigned' option
	 */
	showUnassignedOption: boolean;
	/**
	 *  Styling
	 */
	sx: object;

	/**
	 *  Flag for whether options should be pre-loaded
	 */
	preloadOptions: boolean;
	/**
	 *  Props for input label
	 */
	InputLabelProps: object;
	/**
	 *  Props for input field
	 */
	InputProps: object;
	/**
	 *  Flag to identify if it's needed to highlight folder icon
	 */
	shouldHighlightIcon?: boolean;
	/**
	 * Handler for when an option (user) is selected.
	 * */
	onChange: () => void;

	/**
	 *  Callback function to show additional info as subtitle
	 */
	subtitleFunc: () => void;
	/**
	 * Referring column size width
	 * */
	columnSize?: number;
	/**
	 * disable the worklist settings user name
	 * */
	disabled?: boolean;
}

const UserSelector = ({
	data,
	size,
	width,
	fullWidth,
	onChange,
	testId,
	error,
	helperText,
	defaultValue,
	mainLabel,
	searchParams,
	showUnassignedOption,
	sx,
	subtitleFunc,
	preloadOptions,
	customSearchIcon,
	defaultOpen,
	InputLabelProps,
	InputProps,
	shouldHighlightIcon,
	columnSize,
	disabled = true,
}: IUserSelectorProps): Node => {
	const [optionsData, setOptionsData] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [suggestionValue, setSuggestionValue] = useState('');
	const [selected, setSelected] = useState('');
	const debouncedSearchValue = useDebounce(suggestionValue, 500);

	const fhirDataLoader = useFhirDataLoader({
		scope: searchScopes.practitioner,
	});

	const { loading, setLoading } = useLoading();
	const [searchLoading, setSearchLoading] = useState(false);
	const { t } = useTranslation('workList');
	const minColWidth = 300; // Minimum column width which user can reduce for column in the dataGridHeader
	const mavenStudyPlayerAssignGUI = useBooleanFlagValue('maven-study-player-assign-gui');
	const mavenReferringOrganizationNotUpdating = useBooleanFlagValue('mav-2543-referring-organization-not-updating');
	const mavenWorklistSelectorUACTestRefinement = useBooleanFlagValue('maven-2493-worklist-selector-uac');
	const fetchData = useCallback(() => {
		//Get all the user data.
		try {
			if (!loading) {
				let usersList = [];
				if (showUnassignedOption) {
					usersList.push({
						id: '',
						fullName: mavenStudyPlayerAssignGUI ? t('UNASSIGN') : t('UNASSIGNED'),
					});
				}

				setLoading(true);
				if (!!(onChange && debouncedSearchValue) || preloadOptions) {
					if (_.trim(suggestionValue) === '' && !preloadOptions) {
						setOptionsData(usersList);
					} else if (data === undefined || preloadOptions) {
						fhirDataLoader
							.load(
								{
									summary: false,
									name: debouncedSearchValue,
									ispractitionerroleactive: true,
									...searchParams,
								},
								true
							)
							.then(result => {
								const sortedUserList = [];

								if (
									result.entry &&
									result.entry.length > 0 &&
									result.resourceType &&
									result.resourceType === 'Bundle'
								) {
									_.filter(result.entry, elem => {
										if (!!elem.resource.name && elem.resource.name[0]) {
											const fullName = getUserFullName(elem.resource.name[0]);
											sortedUserList.push({
												id: elem.resource.id,
												reference: `Practitioner/${elem.resource.id}`,
												display: elem.resource.name[0].text ?? fullName,
												fullName,
												organization: PractitionerMapping.organization?.getDisplay(
													elem.resource
												),
												physicianTitle: PractitionerMapping.physicianTitle?.getDisplay(
													elem.resource
												),
												organizationExtension: mavenReferringOrganizationNotUpdating
													? elem.resource.extension.find(
															org =>
																org.url.indexOf(fhirExtensionUrls.common.organization) >
																-1
													  )
													: null,
											});
										}
									});
									// Sort alphabetically by name
									// "Unassigned" option is excluded from this sort, and placed at the top
									// TO-DO: Get sorted bundle from API
									sortedUserList.sort((a, b) => a.fullName.localeCompare(b.fullName));

									usersList = usersList.concat(sortedUserList);
								}

								setOptionsData(usersList);
								setLoading(false);
							})
							.catch(console.error);
					} else {
						setOptionsData(data);
					}
				}
			}
		} finally {
			setLoading(false);
		}
	}, [suggestionValue, loading, setLoading]);

	useEffect(() => {
		if (mavenStudyPlayerAssignGUI) {
			document.getElementById('search-as-you-type-user-selector')?.focus();
		}
	}, []);

	useEffect(() => {
		if (!loading) {
			fetchData();
		}
	}, [debouncedSearchValue]);
	/**
	 * Remove loading circle after loading usernames (api call & usernames update in state hook)
	 */
	useEffect(() => {
		setSearchLoading(false);
	}, [optionsData]);

	useEffect(() => {
		if (onChange && searchValue) {
			setSelected(searchValue);

			const usersList = [];
			if (showUnassignedOption) {
				usersList.push({
					id: '',
					fullName: 'Unassigned',
				});
			}

			setOptionsData(usersList);
		}
	}, [searchValue]);

	const renderSelectedUserAvatar = selectedUser => {
		if (selectedUser && selectedUser.profilePicPath) {
			return (
				<Box sx={{ px: 0.25 }}>
					<Avatar
						alt={selectedUser.fullName}
						src={selectedUser.profilePicPath}
						sx={{ width: 20, height: 20 }}
					/>
				</Box>
			);
		}
		return customSearchIcon ?? <AccountCircleIcon />;
	};

	const renderListItem = (props, option) => {
		let userImage;
		if (option.profilePicPath) {
			userImage = (
				<Box sx={{ pl: 0.25, pr: 2 }}>
					<Avatar alt={option.fullName} src={option.profilePicPath} sx={{ width: 20, height: 20 }} />
				</Box>
			);
		} else if (option.display) {
			userImage = (
				<Box
					sx={{
						width: '25px',
						height: '25px',
						minWidth: '25px',
						borderRadius: '50%',
						background: '#6482CE',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginRight: '10px',
					}}
				>
					<Typography sx={{ fontSize: '10px' }}>{getNameInitials(option.display)}</Typography>
				</Box>
			);
		} else if (option.fullName === 'Unassigned') {
			userImage = (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						pl: 0.25,
						pr: 2,
						width: 20,
						height: 20,
					}}
				>
					<FolderSharedIcon sx={{ color: shouldHighlightIcon ? '#FFFFFF99' : '#F5BF0E' }} />
				</Box>
			);
		} else {
			userImage = <AccountCircleIcon sx={{ pr: 1.25 }} />;
		}

		return (
			<li {...props} style={{ display: 'flex', flexDirection: 'column' }}>
				<div
					data-info="option"
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						width: '100%',
					}}
				>
					{userImage}
					{option.fullName}
				</div>
				<Typography
					sx={{
						pl: '38px',
						fontSize: '12px',
						color: 'text.faint',
						width: 'calc(100% - 38px)',
					}}
				>
					{subtitleFunc ? subtitleFunc(option) : null}
				</Typography>
			</li>
		);
	};
	const practitionerColumns = [
		'performingPhysician',
		'performingTechnologist',
		'readingPhysician',
		'referringPhysician',
		'transcriptionist',
	];

	const CustomPopper = React.useCallback(
		props => (
			<Popper
				{...props}
				data-testid="custom-popper"
				placement="bottom-start"
				style={{
					width: columnSize > minColWidth ? `${columnSize}px` : '300px',
				}}
			/>
		),
		[columnSize]
	);

	return (
		<Autocomplete
			autoHighlight
			disableClearable
			freeSolo
			openOnFocus
			PopperComponent={mavenStudyPlayerAssignGUI && practitionerColumns.includes(mainLabel) && CustomPopper}
			defaultOpen={defaultOpen}
			defaultValue={defaultValue}
			disabled={mavenWorklistSelectorUACTestRefinement && !disabled}
			getOptionLabel={option => option.fullName}
			id={mavenStudyPlayerAssignGUI ? 'search-as-you-type-user-selector' : 'search-as-you-type'}
			loading={loading}
			options={selected ? [] : optionsData}
			renderInput={params => (
				<TextField
					onChange={event => {
						setSelected('');
						setSuggestionValue(event.target.value);
						setSearchLoading(true);
						if (onChange && event.target.value == '') {
							setSearchLoading(false);
							onChange('');
						}
					}}
					{...params}
					InputLabelProps={{
						...params.InputLabelProps,
						...InputLabelProps,
					}}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position="start" sx={{ pl: 1 }}>
								{renderSelectedUserAvatar(selected)}
							</InputAdornment>
						),
						endAdornment: searchLoading ? <CircularProgress color="inherit" size={20} /> : null,
						placeholder: t('search'),
						...InputProps,
					}}
					data-cy={testId}
					data-testid={testId}
					error={error}
					helperText={helperText}
					label={
						mavenStudyPlayerAssignGUI && practitionerColumns.includes(mainLabel)
							? ''
							: mainLabel === 'workListDrawer.userName'
							? t(mainLabel)
							: t(`imagingWorkListColumns.${mainLabel}`)
					}
					variant="outlined"
				/>
			)}
			renderOption={renderListItem}
			size={size || 'medium'}
			sx={{
				...sx,
				...(fullWidth ? '' : { width: columnSize || width }),
			}}
			onChange={(event, value) => {
				setSearchValue(value);
				if (onChange) {
					onChange(value);
				}
			}}
		/>
	);
};

UserSelector.defaultProps = {
	size: 'medium',
	width: 200,
	fullWidth: false,
	mainLabel: 'workListDrawer.userName',
	InputLabelProps: {},
	InputProps: {},
};

export default UserSelector;
