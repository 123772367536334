// Custom

import { mediaTablet } from '@worklist-2/core/src';

// App global styles

const BORDER_RADIUS_BASE = 10;
const BORDER_RADIUS_SMALL = 8;
const BORDER_RADIUS_LARGE = 20;

const appGlobalStyles = {
	root: {
		'--app-header-height': 'unset',

		'--border-radius-base': `${BORDER_RADIUS_BASE}px`,
		'--border-radius-small': `${BORDER_RADIUS_SMALL}px`,
		'--border-radius-large': `${BORDER_RADIUS_LARGE}px`,

		'--color-primary': '#42a5f5',

		'--field-color': '#2f2f2f',

		'--chat-menu-background-color': '#fcfcfc',
		'--chat-menu-foreground-color': '#414141',

		[mediaTablet]: {
			'--app-header-height': '58px',
		},
	},

	translate: {
		'.google_translate_element': {
			display: 'none !important',
		},

		'body > .goog-tooltip': { display: 'none !important', top: '0px !important', visibility: 'hidden !important' },
		'body > .VIpgJd-ZVi9od-aZ2wEe-wOHMyf': {
			visibility: 'hidden !important',
		},
		'body> .skiptranslate': { display: 'none !important', top: '0px !important' },
		font: {
			backgroundColor: 'transparent !important',
			boxShadow: 'none !important',
		},
	},

	chat: {
		'.chat-message--incoming > .chat-message-content': {
			'--element-background-color': '#f6f6f6',

			'& .chat-message-title': {
				'--element-foreground-color': '#191919',
			},

			'& .chat-message-datetime': {
				'--element-foreground-color': '#666666',
			},
		},

		'.chat-message--outgoing > .chat-message-content': {
			'--element-background-color': 'var(--color-primary)',
			'--element-foreground-color': '#ffffff',
		},

		'.chat-header-text': {
			color: 'currentColor',
		},

		'.chat-header-files-container': {
			'--element-background-color': '#fcfcfc',
		},

		'.chat-list-item--current': {
			'--element-background-color': '#e8f4fe',
		},

		'.chat-option': {
			'--element-foreground-color': 'var(--color-primary)',
		},

		'.chat-option-icon': {
			'--element-background-color': '#f3f3f3',
		},

		'.chat-conversation-form-button': {
			'--element-background-color': '#ededed',
			'--element-foreground-color': '#6a6a6a',
		},

		'.chat-layout--expanded .chat-conversation': {
			boxShadow: '0px 10px 20px var(--element-content-box-shadow-color)',
		},
	},
	userProfile: {
		'.userProfile': {
			'--user-profile-common-padding-top': '12px',
			'--user-profile-common-padding-left': '181px',
			'--user-profile-common-padding-right': '145px',

			'--user-profile-page-padding-top': '22px',
			'--user-profile-page-left-section-margin': '24px',

			'--user-profile-info-page-my-physician-left-margin': '40px',
			'--user-profile-info-page-my-physician-top-margin': '31px',

			'--user-profile-info-page-my-documents-left-margin': '40px',
			'--user-profile-info-page-my-documents-top-margin': '32.5px',
		},
		'.userProfileLeftBarOpen': {
			'--user-profile-common-padding-left': '111px',
			'--user-profile-common-padding-right': '109px',
		},
		'.userProfileRightBarOpen': {
			'--user-profile-common-padding-left': '111px',
			'--user-profile-common-padding-right': '109px',
		},
		'.userProfileLeftRightBarOpen': {
			'--user-profile-common-padding-left': '111px',
			'--user-profile-common-padding-right': '51px',
		},
	},
};

export default appGlobalStyles;
