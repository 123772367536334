import { ArrowOutward } from '@mui/icons-material';
import { Divider, Menu, MenuItem, Stack, SvgIcon, Typography } from '@mui/material';
import user from '@worklist-2/ui/src/assets/img/homepage/user.svg';
import logout from '@worklist-2/ui/src/assets/img/homepage/logout.svg';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProfileMenu = ({ anchor, onClose, name, email, handleProfileRedirect, handleLogout, navigateTermsPrivacy }) => {
	const { t } = useTranslation('homepage');
	return (
		<Menu
			PaperProps={{
				sx: {
					borderRadius: '10px',
					background: '#ffffff',
					'& ul': { padding: '0px 0px' },
					boxShadow: '1px 3px 11px #dadada',
				},
				onMouseLeave: onClose,
			}}
			anchorEl={anchor}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			open={Boolean(anchor)}
			sx={{ mt: 1, py: 0 }}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			onClose={onClose}
		>
			<Box sx={{ px: 2, mt: 2, mb: 1 }}>
				<Typography color="#364152" fontWeight={600}>
					{name}
				</Typography>
				<Typography color="#4B5565" fontSize={14}>
					{email}
				</Typography>
			</Box>
			<Divider />
			<Box sx={{ color: '#364152' }}>
				<MenuItem sx={{ mt: 1, py: 1 }} onClick={handleProfileRedirect}>
					<SvgIcon
						component={user}
						sx={{ color: '#ffffff', height: '26px', mr: 1, ml: 0.3 }}
						viewBox="0 0 28 28"
					/>
					{t('Profile Details')}
				</MenuItem>
				<MenuItem sx={{ mb: 1, py: 1 }} onClick={handleLogout}>
					<SvgIcon
						component={logout}
						sx={{ color: '#ffffff', height: '26px', ml: 0.6, mr: 1, mt: 0.1 }}
						viewBox="0 0 17 16"
					/>
					{t('Logout')}
				</MenuItem>
			</Box>
			<Divider />
			<Stack alignItems="center" columnGap={2} direction="row" pl={3} py={1.5} sx={{ color: '#697586' }}>
				<Stack
					alignItems="center"
					direction="row"
					sx={{ fontSize: 15, cursor: 'pointer' }}
					onClick={() => navigateTermsPrivacy(true)}
				>
					{t('Privacy')} <ArrowOutward sx={{ ml: '3px', fontSize: 15 }} />
				</Stack>
				<Stack
					alignItems="center"
					direction="row"
					sx={{ fontSize: 15, cursor: 'pointer' }}
					onClick={() => navigateTermsPrivacy()}
				>
					{t('Terms')} <ArrowOutward sx={{ ml: '3px', fontSize: 15 }} />
				</Stack>
			</Stack>
		</Menu>
	);
};

export default ProfileMenu;
