// @flow

// libs
import { create } from 'zustand';
// utils
import Patient from '../models/Patient';
import Observations from '../models/Observations';
import { type IDocument, type IImagingStudy, type IPatientAlert } from '@rs-ui/views/PatientInfoView/utils';
import CoverageModel from '@rs-ui/views/PatientInformationView/models/Coverage';

export type TPatientStore = {
	patient: Patient | null,
	setPatient: (patient: Patient) => void,

	guarantor: Object,
	setGuarantor: (guarantor: Object) => void,

	coverages: CoverageModel[],
	setCoverages: (coverages: CoverageModel[]) => void,

	documents: IDocument[],
	setDocuments: (documents: IDocument[]) => void,

	observations: Observations | null,
	setPatientObservations: (observations: Observations) => void,

	linkedPatients: IPatientLink[],
	setLinkedPatients: (linkedPatients: IPatientLink[]) => void,

	alerts: IPatientAlert[],
	setAlerts: (alerts: IPatientAlert[]) => void,

	/** Whether the patient data is currently being fetched */
	isLoading: boolean, // #TODO: implemented with react-query
	setIsLoadingPatient: (isLoading: boolean) => void,

	isLoaded: boolean, // #TODO: implemented with react-query
	setIsLoadedPatient: (isLoading: boolean) => void,

	permissions: any | null,
	setPermissions: (permissions: any) => void,

	resetPatientStore: () => void,

	studyShareQRLink: string | null,
	setStudyShareQRLink: (observations: Observations) => void,

	updateCoverage: (res: CoverageModel) => void,
	deleteCoverage: (coverageId: string) => void,
};

export const usePatientStore: () => TPatientStore = create((set, get) => ({
	// Store variables w/ default values
	alerts: [],
	documents: [],
	coverages: [],
	isLoading: false,
	isLoaded: false,
	// order: null,		// #TODO
	patient: null,
	permissions: null,
	guarantor: null,
	observations: null,
	studyShareQRLink: null,

	// ==================== Re-setters ====================
	resetPatientStore: () =>
		set({
			// #NOTE: Be sure to include all the store's variables !
			alerts: [],
			documents: [],
			coverages: [],
			patient: null,
			isLoading: false,
			isLoaded: false,
			permissions: null,
			linkedPatients: [],
			guarantor: null,
			observations: null,
			studyShareQRLink: null,
		}),

	// ==================== Setters ====================
	// #NOTE: use setters only with actual value, to reset store variables, use re-setters down below

	setPatient: (patientData: Patient) =>
		set(prevState => {
			// Compare IDs
			if (prevState.patient?.id !== patientData?.id) {
				const store = get();
				// Reset store if IDs don't match
				store.resetPatientStore();
			}

			// Update patient data
			return {
				patient: {
					...prevState.patient,
					...patientData,
				},
			};
		}),

	setPatientWithClass: (patientData: any) =>
		set(prevState => {
			const convertedPatient = new Patient(patientData);
			if (prevState.patient?.id !== convertedPatient?.patient?.id) {
				const store = get();
				store.resetPatientStore();
			}
			return {
				patient: convertedPatient,
			};
		}),

	setGuarantor: (guarantor: Object) => set({ guarantor }),

	setPatientObservations: (observations: Observations) =>
		set(prevState => ({
			observations: {
				...prevState.observations,
				...observations,
			},
		})),

	setCoverages: (coverages: CoverageModel[]) => set({ coverages }),

	updateCoverage: res => {
		set(state => {
			const newCoverages = [...state.coverages];

			const newUpdatedCoverageIndex = newCoverages.findIndex(item => item.data.id === res?.id);

			if (newUpdatedCoverageIndex !== -1) {
				const coverageModel = new CoverageModel(res);
				coverageModel.init();
				newCoverages[newUpdatedCoverageIndex] = { ...coverageModel };

				return { coverages: newCoverages };
			}

			return state;
		});
	},

	setIsLoadingPatient: (isLoading: boolean) => set({ isLoading }),

	setAlerts: (alerts: IPatientAlert[]) => set({ alerts }),

	setDocuments: (documents: IDocument[]) => {
		set(state => {
			// Update `setDocuments` to accept a functional update or a direct value
			const newDocuments = typeof documents === 'function' ? documents(state.documents) : documents;
			return { documents: newDocuments };
		});
	},

	setIsLoadedPatient: (isLoaded: boolean) => set({ isLoaded }),

	setLinkedPatients: (linkedPatients: IPatientLink[]) => set({ linkedPatients }),

	setPermissions: (permissions: any) => set({ permissions }),

	/** Resets the patient's `alerts` back to `[]` */
	resetAlerts: () => set({ alerts: [] }),

	/** Resets the patient's `documents` back to `[]` */
	resetDocuments: () => set({ documents: [] }),

	/** Resets the patient's `insuranceCoverage` back to `[]` */
	resetInsuranceCoverage: () => set({ coverages: [] }),

	/** Resets the patient back to `null` */
	resetPatient: () => set({ patient: null }),

	/** Resets the patient's `links` back to `[]` */
	resetLinkedPatients: () => set({ linkedPatients: [] }),

	setStudyShareQRLink: (studyShareQRLink: String) => set({ studyShareQRLink }),

	deleteCoverage: (coverageId: string) => {
		set(state => {
			const updatedCoverages = state.coverages.filter(coverage => coverage.data.id !== coverageId);
			return { coverages: updatedCoverages };
		});
	},

	// ! DON'T USE !
	// ==================== Danger zone  ====================
	// __deleteStore: () => set({}, true), // clears the entire store, actions included
}));
