// core
import React, { useEffect, useRef, useState } from 'react';

// components
import DemographyIcon from '@worklist-2/ui/src/assets/icons/demography-gray.svg';
import FinanceMode from '@worklist-2/ui/src/assets/icons/finance_mode.svg';
// libs
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
// mui
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const OverflowMenu = ({ patientId }) => {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);

	const navigate = useNavigate();
	const { t } = useTranslation('patientInfo');

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	return (
		<Stack data-testid="overflow-menu" direction="row" spacing={2}>
			<Button
				ref={anchorRef}
				aria-controls={open ? 'composition-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				data-testid="more-button"
				id="composition-button"
				sx={{ padding: 0, minWidth: 0 }}
				onClick={handleToggle}
			>
				<MoreVertIcon sx={{ color: '#FFFFFF99' }} />
			</Button>

			<Popper
				disablePortal
				transition
				anchorEl={anchorRef.current}
				open={open}
				placement="bottom-start"
				role={undefined}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
						}}
					>
						<Paper data-testid="menu">
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									aria-labelledby="composition-button"
									autoFocusItem={open}
									id="composition-menu"
								>
									<MenuItemComponent
										icon={DemographyIcon}
										text={t('Patient Ledger')}
										onClick={() => navigate(`/patient/${patientId}/patient-ledger`)}
									/>

									<MenuItemComponent
										additionalSX={{ display: 'none' }}
										icon={FinanceMode}
										text={t('paymentReport')}
										onClick={() => {} /* Will be implemented later */}
									/>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Stack>
	);
};

const MenuItemComponent = ({ icon: Icon, text, onClick, additionalSX = {} }) => (
	<MenuItem sx={{ ...SX.menuItem, ...additionalSX }} onClick={onClick}>
		<Icon />
		<Typography sx={{ color: '#FFFFFFDE' }}>{text}</Typography>
	</MenuItem>
);

const SX = {
	menuItem: {
		display: 'flex',
		columnGap: '8px',
		alignItems: 'center',
		paddingLeft: '19.1px',
	},
};
