import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useIsTablet } from '@rs-core/utils/responsiveUtils';
import { useTranslation } from 'react-i18next';
import { useBlumeAssistantContext } from '@worklist-2/patientPortal/src/context/BlumeAssistantContext';
import { useDrawersStore } from '@worklist-2/patientPortal/src/stores/drawers';
import Lottie from 'lottie-web';
import InitialAnimation from '@worklist-2/ui/src/assets/animation/Blume/BlumeAssistant/Initial/data.json';
import ThinkingAnimation from '@worklist-2/ui/src/assets/animation/Blume/BlumeAssistant/Thinking/data.json';
import AIIcon from '@rs-ui/assets/icons/GlobalSearch/AIIcon';
import Link from '@mui/material/Link';
import aiStar from '@worklist-2/ui/src/assets/img/homepage/ai_star.svg';
import Icon from '@mui/material/Icon';

export const NoResultsAssistant = () => {
	const { t } = useTranslation('Search');
	const { searchText, mobileSearchText } = useDrawersStore(state => ({
		searchText: state.searchText,
		mobileSearchText: state.mobileSearchText,
	}));

	const { askAQuestion, completions, isLoading } = useBlumeAssistantContext();
	const [question, setQuestion] = useState(null);
	const [answer, setAnswer] = useState(null);
	const isTablet = useIsTablet();

	useEffect(() => {
		if (isTablet) {
			if (mobileSearchText.length) {
				askAQuestion(mobileSearchText);
			}
		} else if (searchText.length) {
			askAQuestion(searchText);
		}
	}, [searchText, mobileSearchText, isTablet]);

	useEffect(() => {
		if (!isLoading && completions.length && completions.at(-1)?.role === 'Assistant') {
			isTablet ? setQuestion(mobileSearchText) : setQuestion(searchText);
			setAnswer(completions.at(-1).content);
		}
	}, [completions, isLoading, isTablet]);

	useEffect(() => {
		const animationRegion = document.querySelector('#AnimationInDrawer');
		if (animationRegion) {
			animationRegion.innerHTML = '';
			animationRegion.style.display = isLoading ? 'block' : 'none';
		}

		if (isLoading && Lottie.loadAnimation) {
			Lottie.loadAnimation({
				container: animationRegion,
				animationData: ThinkingAnimation,
				autoplay: true,
				loop: true,
			});
		}
	}, [isLoading]);

	return (
		<Box
			data-testid="no-results-assistant"
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				boxSizing: 'border-box',
			}}
		>
			{isLoading && <Box data-testid="AnimationInDrawer" id="AnimationInDrawer" />}

			{!isLoading && (
				<Box
					sx={{
						position: 'absolute',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{question && !isTablet ? (
						<Typography
							data-testid="NoResultsFound"
							sx={{
								fontWeight: '500',
								fontSize: '16px',
								marginBottom: '8px',
								color: 'text.primary',
								display: 'flex',
								alignItems: 'center',
								ml: 3.5,
							}}
						>
							<Icon component={aiStar} sx={{ height: '23px', mr: 0.5 }} /> {t('AI Results')}
						</Typography>
					) : (
						<></>
					)}
					{answer && (
						<Box
							sx={{
								display: 'grid',
								gridTemplateColumns: isTablet ? '100%' : '24px auto',
								gap: '8px',
								padding: isTablet ? '0' : '24px',
							}}
						>
							<Box sx={{ display: 'flex', gap: '8px', alignItems: isTablet ? 'center' : 'normal' }}>
								<AIIcon />
								{isTablet && (
									<Typography sx={{ color: '#475467', fontWeight: '500', fontSize: '14px' }}>
										{t('AI Results')}
									</Typography>
								)}
							</Box>

							<Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
								<Box
									sx={{
										fontSize: '14px',
										color: 'rgba(52, 64, 84, 1)',
										fontWeight: '500',
										backgroundColor: 'rgba(249, 250, 251, 1)',
										padding: '16px',
										borderRadius: '0 16px 16px 16px',
										border: '1.5px solid #bc67ff',
										position: 'relative',
									}}
								>
									{answer}
								</Box>
								<Box sx={{ fontSize: '12px', color: 'rgba(105, 117, 134, 1)', fontWeight: '400' }}>
									{t('AI-search-disclaimer-text')}{' '}
									<Link
										href="https://www.ramsoft.com/AI-disclaimer"
										sx={{ color: '#42A5F5' }}
										target="_blank"
									>
										{t('detailed disclaimer')}
									</Link>
									.
								</Box>
							</Box>
						</Box>
					)}
				</Box>
			)}
		</Box>
	);
};
