// core
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
// mui
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
// components
import Svg from '@worklist-2/ui/src/components/Svg/Svg';
import SingleSelectV2 from '../../../../SingleSelectV2';
import FormAPIAutocompleteVariant from '../../../../FormNew/formPartials/FormFieldVariants/variants/FormAPIAutocompleteVariant';
import FormTextFieldVariant from '../../../../FormNew/formPartials/FormFieldVariants/variants/FormTextFieldVariant';
import { avatarIconAdornment } from '../../../../FormNew/formUtils/formMappingUtils';
import SecondaryButton from '../../../../SecondaryButton';
import PrimaryButton from '../../../../PrimaryButton';
import { useSelection } from '@rs-core/context/SelectionContext';
import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
import { searchScopes } from '@rs-core/context/consts/searchScopes';
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';
import getUserFullName from '@worklist-2/core/src/fhir/resource/columnMapping/utils/getUserFullName';
import useToast from '@worklist-2/core/src/hooks/useToast';
import { useLicenseStore } from '@worklist-2/patientPortal/src/stores/license';
import { ASSET_NAMES } from '@worklist-2/patientPortal/src/consts';

import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const findTelecomIndex = (telecomArray, system, use) =>
	telecomArray?.findIndex(item => item.system === system && (!use || item.use === use));

const SendReportTab = ({ study, onCancel, sendPermissions, isMultiReport }) => {
	const checkAssetAccess = useLicenseStore(state => state.hasAccess);

	// ======= hooks =======
	const crossShowSendingReportsThroughEmailSms = useBooleanFlagValue('cross-show-sending-reports-through-email-sms');
	const featEnableHl7ReportRedistribution = useBooleanFlagValue('feat-enable-hl7-report-redistribution');
	const proactEnableUacHomeSend = useBooleanFlagValue('proact-enable-uac-home-send');
	const phoenixBlumeIsAssetInBreeze = useBooleanFlagValue('phoenix-blume-is-asset-in-breeze');
	const { showToast } = useToast();
	const { t } = useTranslation('sendReports');

	const requiredText = t('Required');
	const sendToBlumeLabel = t('SEND TO BLUME');

	const sendMethodOptions = [{ label: t('Fax'), value: 'fax' }];

	// Note: Hiding the Email and SMS option until we have a clear requirement
	if (crossShowSendingReportsThroughEmailSms) {
		sendMethodOptions.push({ label: t('Email'), value: 'email' });
		sendMethodOptions.push({ label: t('SMS'), value: 'sms' });
	}

	if (featEnableHl7ReportRedistribution) {
		sendMethodOptions.push({ label: t('HL7'), value: 'hl7' });
	}

	const validationSchema = yup.object().shape({
		singleStudy: yup.bool(),
		referringPhysician: yup.object().nullable(),
		sendMethodValue: yup.string(),
		fax: yup.string().when(['singleStudy', 'sendMethodValue'], {
			is: (singleStudy, sendMethodValue) => singleStudy && sendMethodValue === 'fax',
			then: yup
				.string()
				.test(val => matchIsValidTel(val))
				.required(t('Please enter a valid Phone Number')),
		}),
		email: yup.string().when(['singleStudy', 'sendMethodValue'], {
			is: (singleStudy, sendMethodValue) => singleStudy && sendMethodValue === 'email',
			then: yup.string().email().required(t('Please enter a valid Email')),
		}),
		phone: yup.string().when(['singleStudy', 'sendMethodValue'], {
			is: (singleStudy, sendMethodValue) => singleStudy && sendMethodValue === 'sms',
			then: yup
				.string()
				.test(val => matchIsValidTel(val))
				.required(t('Please enter a valid Phone Number')),
		}),
	});

	const form = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			singleStudy: false,
			referringPhysician: null,
			sendMethodValue: 'fax',
			fax: '',
			email: '',
			phone: '',
			referringPhysicianText: t('Referring Physician'),
		},
	});

	const watchSendMethodValue = form.watch('sendMethodValue');
	const watchReferringPhysician = form.watch('referringPhysician');

	const [parentOrganizations, setParentOrganizations] = useState([]);
	const [hasBlumeSendAccess, setHasBlumeSendAccess] = useState(false);

	const { selectedStudies } = useSelection();

	const dataLoader = useFhirDataLoader({
		scope: searchScopes.practitioner,
	});

	const diagnosticLoader = useFhirDataLoader({
		scope: searchScopes.studyRegular,
	});

	const organizationLoader = useFhirDataLoader({
		scope: searchScopes.organization,
	});

	const diagnosticReportLoader = useFhirDataLoader({
		scope: searchScopes.diagnosticReport,
	});

	const metaSearchReferringPhysicians = async query => {
		if (query) {
			return dataLoader
				.load({
					name: query,
					associatedorganization: parentOrganizations.toString(),
					referringPhysician: true,
				})
				.then(res => {
					if (res) {
						return res;
					}
				});
		}
		return Promise.resolve();
	};

	const getPhysician = physicianId => dataLoader.load({ id: physicianId }, true);

	const getPhysicianFax = async physicianId => {
		const physician = await getPhysician(physicianId);
		if (physician) {
			return physician?.telecom?.find(tel => tel.system === 'fax')?.value;
		}
		return null;
	};

	const getPhysicianEmail = async physicianId => {
		const physician = await getPhysician(physicianId);
		if (physician) {
			return physician?.telecom?.find(tel => tel.system === 'email')?.value;
		}
		return null;
	};

	const getPhysicianSms = async physicianId => {
		const physician = await getPhysician(physicianId);
		if (physician) {
			return physician?.telecom?.find(tel => tel.system === 'phone')?.value;
		}
		return null;
	};

	const sendFax = async (studyId, faxNum, physicianId) => {
		let faxNumber;
		if (!faxNum) {
			faxNumber = await getPhysicianFax(physicianId);
		} else {
			faxNumber = faxNum;
		}

		faxNumber = faxNumber.replace(/\s+/g, ''); // remove whitespace
		if (faxNumber) {
			return diagnosticLoader
				.load({ endpoint: `${studyId}/Fax`, fax: faxNumber })
				.then(res => `${t('Successfully sent fax to')} ${faxNumber}`);
		}
		return Promise.resolve(
			`${t('Study')} ${studyId} ${t(
				'with Referring Physician ID'
			)} ${physicianId} ${'does not have a fax number'}`
		);
	};

	const sendEmail = async (
		studyId,
		email,
		patientId,
		orderId,
		managingOrgId,
		referringPhysicianFirstName,
		physicianId,
		issuerOfPatientID,
		studyInstanceUIDs,
		managingOrganization
	) => {
		let emailAddress;
		if (!email) {
			emailAddress = await getPhysicianEmail(physicianId);
		} else {
			emailAddress = email;
		}

		if (emailAddress) {
			const payload = {
				userName: referringPhysicianFirstName,
				patientId,
				orderId,
				managingOrgId,
				email: emailAddress,
				isEmail: true,
				studyId,
				issuerOfPatientID,
				studyInstanceUIDs,
				managingOrganization,
			};
			return diagnosticLoader
				.save(
					{
						endpoint: `${studyId}/SendReport`,
					},
					payload
				)
				.then(res => {
					if (res?.status == 200) {
						return `${t('Successfully sent Report to')} ${emailAddress}`;
					}
					return `Failed to send Report to ${emailAddress}`;
				});
		}
		return Promise.resolve(
			`${t('Study')} ${studyId} ${t('with Referring Physician ID')} ${physicianId} ${t(
				'does not have an email address'
			)}`
		);
	};

	const sendSms = async (
		studyId,
		phoneNum,
		patientId,
		orderId,
		managingOrgId,
		referringPhysicianFirstName,
		physicianId,
		issuerOfPatientID,
		studyInstanceUIDs
	) => {
		let phoneNumber;
		if (!phoneNum) {
			phoneNumber = await getPhysicianSms(physicianId);
		} else {
			phoneNumber = phoneNum;
		}

		phoneNumber = phoneNumber.replace(/\s+/g, ''); // remove whitespace
		if (phoneNumber) {
			const payload = {
				userName: referringPhysicianFirstName,
				patientId,
				orderId,
				managingOrgId,
				SMS: phoneNumber,
				isEmail: false,
				studyId,
				issuerOfPatientID,
				studyInstanceUIDs,
			};
			return diagnosticLoader
				.save(
					{
						endpoint: `${studyId}/SendReport`,
					},
					payload
				)
				.then(res => {
					if (res?.status == 200) {
						return `${t('Successfully sent Report to')} ${phoneNumber}`;
					}
					return `${t('Failed to send Report to')} ${phoneNumber}`;
				});
		}
		return Promise.resolve(
			`${t('Study')} ${studyId} ${t('with Referring Physician ID')} ${physicianId} ${t(
				'does not have a phone number'
			)}`
		);
	};

	const sendHL7Report = async internalStudyIds =>
		diagnosticReportLoader
			.load(
				{
					endpoint: 'DistributeReports',
					ids: internalStudyIds?.join(','),
				},
				true
			)
			.then(res => `${t(res)}`);

	const onSubmit = form.handleSubmit(async () => {
		if (study && !isMultiReport) {
			let studyInstanceUid = study?.identifier?.find(recordItem => recordItem.system === 'urn:dicom:uid')?.value;
			studyInstanceUid = studyInstanceUid?.replace('urn:oid:', '');
			switch (form.getValues('sendMethodValue')) {
				case 'fax':
					sendFax(study.id, form.getValues('fax')).then(message => {
						showToast(message);
						onCancel();
					});
					break;
				case 'email':
					sendEmail(
						study.id,
						form.getValues('email'),
						study?.subject?.id,
						study.basedOn?.[0]?.id,
						study.internalManagingOrganizationID,
						getUserFullName(study.referringPhysician),
						'',
						study?.extension?.find(ext => ext.url === fhirExtensionUrls.organization.issuer)?.valueReference
							?.display,
						studyInstanceUid,
						study?.rawData?.managingOrganization
					).then(message => {
						showToast(message);
						onCancel();
					});
					break;
				case 'sms':
					sendSms(
						study.id,
						form.getValues('phone'),
						study?.subject?.id,
						study.basedOn?.[0]?.id,
						study.internalManagingOrganizationID,
						getUserFullName(study.referringPhysician),
						'',
						study?.extension?.find(ext => ext.url === fhirExtensionUrls.organization.issuer)?.valueReference
							?.display,
						studyInstanceUid
					).then(message => {
						showToast(message);
						onCancel();
					});
					break;
				case 'hl7':
					sendHL7Report([study.id]).then(message => {
						showToast(message);
						onCancel();
					});
					break;
				default:
					// cannot do anything?
					break;
			}
		} else if (selectedStudies?.length > 0) {
			const allMessages = [];

			selectedStudies.forEach(async selectedStudy => {
				const studyData = selectedStudy?.rawData; // Multi selected study data present in rawData property
				let studyInstanceUid = studyData?.identifier?.find(
					recordItem => recordItem.system === 'urn:dicom:uid'
				)?.value;
				studyInstanceUid = studyInstanceUid?.replace('urn:oid:', '');

				switch (form.getValues('sendMethodValue')) {
					case 'fax':
						await sendFax(studyData.id, form.getValues('fax'), studyData.referringPhysicianId).then(
							message => {
								allMessages.push(message);
							}
						);
						break;
					case 'email':
						await sendEmail(
							studyData.id,
							form.getValues('email'),
							studyData?.subject?.id,
							studyData.basedOn?.[0]?.id,
							studyData.internalManagingOrganizationID,
							getUserFullName(studyData.referringPhysician),
							studyData.referringPhysicianId,
							studyData?.extension?.find(ext => ext.url === fhirExtensionUrls.organization.issuer)
								?.valueReference?.display,
							studyInstanceUid,
							studyData?.managingOrganization
						).then(message => {
							allMessages.push(message);
						});
						break;
					case 'sms':
						await sendSms(
							studyData.id,
							form.getValues('phone'),
							studyData?.subject?.id,
							studyData.basedOn?.[0]?.id,
							studyData.internalManagingOrganizationID,
							getUserFullName(studyData.referringPhysician),
							studyData.referringPhysicianId,
							studyData?.extension?.find(ext => ext.url === fhirExtensionUrls.organization.issuer)
								?.valueReference?.display,
							studyInstanceUid
						).then(message => {
							allMessages.push(message);
						});
						break;
					case 'hl7':
						// check code below to call DistributeReport endpoint in one shot
						break;
					default:
						// cannot do anything?
						break;
				}
			});

			if (form.getValues('sendMethodValue') === 'hl7') {
				const internalStudyIds = selectedStudies.map(selectedStudy => selectedStudy.id);

				await sendHL7Report(internalStudyIds).then(message => {
					allMessages.push(message);
				});
			}

			// todo: I want to add a new line but \r\n is not respected
			showToast(allMessages.join(' - '));
			onCancel();
		}
	});

	const onSendMethodSelect = value => {
		form.setValue('sendMethodValue', value);
	};

	const sendToBlume = async () => {
		if (study) {
			if (!study?.patientEmail) {
				showToast(
					`${t('Failed to send as emailId is missing for')} ${getUserFullName(study.subject?.display)}`
				);
				return;
			}
			if (!study?.numReports && !study?.numImages) {
				showToast(
					`${t('Failed to send as no reports or images are available for')} ${getUserFullName(
						study.subject?.display
					)}`
				);
				return;
			}
			const patientName = getUserFullName(study.subject?.display) || '';
			diagnosticLoader
				.save({
					endpoint: `${study.id}/Blume`,
					email: study.patientEmail,
					patientName,
					managingOrganization: study?.rawData?.managingOrganization,
				})
				.then(res => {
					if (res.status == 200) {
						showToast(`${t('Successfully sent Report to Blume for patient')} ${patientName}`);
					}
				});
		} else if (selectedStudies?.length > 0) {
			const patientWithEmail = selectedStudies.filter(
				study => study.patientEmail && (study.numReports || study.numImages)
			);

			if (patientWithEmail.length === 0) {
				showToast(`${t('Email address or study report/image is required to sent email.')}`);
				return;
			}
			const allMessages = [];

			Promise.all(
				patientWithEmail.map(selectedStudy =>
					diagnosticLoader.save({
						endpoint: `${selectedStudy.id}/Blume`,
						email: selectedStudy.patientEmail,
						patientName: selectedStudy.subject?.display,
					})
				)
			)
				.then(resArr => {
					resArr.forEach((res, index) => {
						if (res.status == 200) {
							allMessages.push(
								`${t('Successfully sent Report to Blume for patient')} ${
									patientWithEmail[index].subject?.display
								}`
							);
						} else {
							allMessages.push(
								`${t('Failed to send Report to Blume for patient')} ${
									patientWithEmail[index].subject?.display
								}`
							);
						}
					});
				})
				.then(() => {
					showToast(allMessages.join(' - '));
				});
		}
	};

	useEffect(() => {
		if (study) {
			form.setValue('singleStudy', true);
		}

		if (study?.referringPhysicianId) {
			dataLoader.load({ id: study.referringPhysicianId }, true).then(res => {
				if (res) {
					form.setValue('referringPhysician', res);
				}
			});
		}
		if (study?.internalManagingOrganizationID) {
			organizationLoader
				.load(
					{
						id: study.internalManagingOrganizationID,
						descendant: true,
					},
					true
				)
				.then(res => {
					if (res.total > 0) {
						const ids = res.entry.map(e => e.resource.id);
						setParentOrganizations(ids);
					}
				});
		}
	}, [study]);

	useEffect(() => {
		// Do we need to consider 'use' here?
		if (watchReferringPhysician) {
			const faxIndex = findTelecomIndex(watchReferringPhysician.telecom, 'fax');
			const emailIndex = findTelecomIndex(watchReferringPhysician.telecom, 'email');
			const phoneIndex = findTelecomIndex(watchReferringPhysician.telecom, 'phone');
			if (faxIndex >= 0) {
				form.setValue('fax', watchReferringPhysician.telecom[faxIndex].value);
			}
			if (emailIndex >= 0) {
				form.setValue('email', watchReferringPhysician.telecom[emailIndex].value);
			}
			if (phoneIndex >= 0) {
				form.setValue('phone', watchReferringPhysician.telecom[phoneIndex].value);
			}
		} else {
			form.setValue('fax', '');
			form.setValue('email', '');
			form.setValue('phone', '');
		}
	}, [watchReferringPhysician]);

	useEffect(() => {
		/* Blume */
		const managingOrgId = study?.internalManagingOrganizationID || study?.internalManagingOrganizationId;

		(async () => {
			if (!managingOrgId || !phoenixBlumeIsAssetInBreeze) {
				setHasBlumeSendAccess(true);
				return;
			}

			const access = await checkAssetAccess({
				orgId: managingOrgId,
				assetNames: [ASSET_NAMES.BLUME_AUTOMATED_FRONT_DESK, ASSET_NAMES.BLUME_PATIENT_PORTAL],
			});

			setHasBlumeSendAccess(access);
		})();
	}, [checkAssetAccess, phoenixBlumeIsAssetInBreeze, study]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				maxHeight: '100%',
				justifyContent: 'space-between',
			}}
		>
			<Stack
				flexGrow={1}
				spacing={4}
				sx={{
					pt: hasBlumeSendAccess ? 0 : 1,
					pb: 4,
					px: '40px',
					overflowY: 'auto',
					overflowX: 'hidden',
				}}
			>
				{hasBlumeSendAccess && (
					<Box sx={{ display: 'flex', alignItems: 'center', height: '40px' }}>
						<Button color="rsPrimary" startIcon={<Svg name="blumelogo" />} onClick={sendToBlume}>
							<Typography
								color="rsPrimary.button"
								fontSize="14px"
								fontStyle="medium"
								letterSpacing="1.25px"
							>
								{sendToBlumeLabel}
							</Typography>
						</Button>
					</Box>
				)}

				{!proactEnableUacHomeSend || sendPermissions?.['send reports']?.['send method']?.read ? (
					<SingleSelectV2
						fullWidth
						itemList={sendMethodOptions}
						label={t('Send Method')}
						onSelect={onSendMethodSelect}
					/>
				) : null}

				{!study && (!proactEnableUacHomeSend || sendPermissions?.['send reports']?.['send method']?.read) && (
					<FormTextFieldVariant
						formHook={form}
						name="referringPhysicianText"
						props={{
							sx: {
								width: '100%',
							},
							label: t('Send To'),
							InputProps: {
								readOnly: true,
							},
						}}
					/>
				)}

				{study &&
					form.getValues('sendMethodValue') != 'hl7' &&
					(!proactEnableUacHomeSend || sendPermissions?.['send reports']?.['referring physician']?.read) && (
						<FormAPIAutocompleteVariant
							TextFieldProps={{
								label: t('Referring Physician'),
								InputProps: {
									...avatarIconAdornment,
								},
							}}
							formHook={form}
							name="referringPhysician"
							props={{
								isOptionEqualToValue: (option, value) => !value || option?.id === value?.id,
								getOptionLabel: option => (option?.name?.[0] ? getUserFullName(option?.name?.[0]) : ''),
								renderOption: (props, option) => (
									<li {...props} key={option?.id}>
										<Typography
											sx={{
												fontSize: '16px',
												font: 'Roboto',
												color: '#FCFCFC',
											}}
										>
											{getUserFullName(option?.name?.[0]) || ''}
										</Typography>
									</li>
								),
							}}
							onSearch={metaSearchReferringPhysicians}
						/>
					)}

				{study && (
					<>
						{watchSendMethodValue === 'fax' && (
							<>
								<Divider />
								<Controller
									control={form.control}
									name="fax"
									render={({ field, fieldState }) => (
										<MuiTelInput
											{...field}
											MenuProps={{
												sx: {
													maxHeight: '400px',
												},
											}}
											error={fieldState.invalid}
											helperText={
												fieldState.invalid ? t('Please enter a valid Fax Number') : requiredText
											}
											inputProps={{ maxLength: 30 }}
											label={t('Fax Number')}
										/>
									)}
								/>
							</>
						)}

						{watchSendMethodValue === 'email' && (
							<>
								<Divider />
								<FormTextFieldVariant
									formHook={form}
									name="email"
									props={{
										sx: {
											width: '100%',
										},
										label: t('Email'),
										helperText: `${
											form.formState.errors?.email
												? t('Please enter a valid Email')
												: requiredText
										}`,
									}}
								/>
							</>
						)}

						{watchSendMethodValue === 'sms' && (
							<>
								<Divider />
								<Controller
									control={form.control}
									name="phone"
									render={({ field, fieldState }) => (
										<MuiTelInput
											{...field}
											MenuProps={{
												sx: {
													maxHeight: '400px',
												},
											}}
											error={fieldState.invalid}
											helperText={
												fieldState.invalid
													? t('Please enter a valid Phone Number')
													: requiredText
											}
											inputProps={{ maxLength: 30 }}
											label={t('Phone Number')}
										/>
									)}
								/>
							</>
						)}
					</>
				)}
			</Stack>
			<Box
				data-testid="send-report"
				sx={{
					flexDirection: 'row',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: 391,
					mb: '20px',
					mx: '48px',
				}}
			>
				<SecondaryButton label={t('CANCEL')} onClick={onCancel} />
				<PrimaryButton label={t('SEND REPORT')} onClick={onSubmit} />
			</Box>
		</Box>
	);
};

export default SendReportTab;
