import React, { useCallback, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import HealingIcon from '@mui/icons-material/Healing';
import SportsScore from '@mui/icons-material/SportsScore';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import MonetizationOnOutlined from '@mui/icons-material/MonetizationOnOutlined';
import DomainIcon from '@mui/icons-material/Domain';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import TuneIcon from '@mui/icons-material/Tune';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CardTravel from '@mui/icons-material/CardTravel';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import PlayIcon from '@mui/icons-material/PlayArrowOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import ListIcon from '@mui/icons-material/List';
import BadgeIcon from '@mui/icons-material/Badge';
import AddIcon from '@mui/icons-material/Add';
import SubtitlesOutlinedIcon from '@mui/icons-material/SubtitlesOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import CodeIcon from '@mui/icons-material/Code';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useTranslation } from 'react-i18next';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import GroupIcon from '@mui/icons-material/Group';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';
import LocalLibraryOutlined from '@mui/icons-material/LocalLibraryOutlined';
import StudySetsIcon from '../../../assets/icons/study-sets.svg';
import ActiveSwitch from '../../ActiveSwitch';
import StatusBadge from './StatusBadge';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { Svg } from '../../Svg';

const PopoverWrapper = ({ children, content }) => {
	const anchorRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	const onToggleIsOpen = useCallback(() => {
		setIsOpen(was => !was);
	}, []);

	return (
		<>
			{children(anchorRef, onToggleIsOpen)}

			<Popover
				PaperProps={{
					sx: { borderRadius: 0 },
				}}
				anchorEl={anchorRef?.current}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				open={isOpen}
				transformOrigin={{
					horizontal: 'right',
					vertical: 'top',
				}}
				onClose={onToggleIsOpen}
			>
				{content(onToggleIsOpen)}
			</Popover>
		</>
	);
};

const FormHeader = ({
	onAddMore,
	className,
	buttons,
	formHeaderText,
	headerIconType,
	iconColor,
	subHeaderText,
	getModel,
	showActiveButton,
	disableActiveButton,
	showStatus,
	statusType,
	sx,
	onActiveSwitchClick = '',
	addTooltip = '',
}) => {
	const { t } = useTranslation(['organization', 'healthcareServices']);

	const defaultClassName = 'form';
	const defaultIconColor = 'secondary';
	const iconStyle = {
		ml: 2.5,
		fontSize: 48,
	};

	const renderHeaderIcon = (iconType, iconColor) => {
		if (iconType) {
			switch (iconType) {
				case 'account':
					return <AccountCircleIcon key="account" color={iconColor} sx={iconStyle} />;
				case 'device':
					return (
						<DeviceHubIcon
							key="device"
							color="#000"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#282C9C99',
								width: '40px',
								height: '40px',
								padding: '10px',
								fontWeight: '500',
							}}
							sx={iconStyle}
						/>
					);
				case 'study':
					return (
						<DescriptionIcon
							key="study"
							color="#000"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#A66BA3',
								width: '40px',
								height: '40px',
								padding: '10px',
							}}
							sx={iconStyle}
						/>
					);
				case 'workflowConfig':
					return (
						<TuneIcon
							key="workflowConfig"
							color={iconColor}
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#282C9C99',
								width: '40px',
								height: '40px',
								padding: '10px',
							}}
							sx={iconStyle}
						/>
					);
				case 'importScript':
					return (
						<CodeIcon
							key="importScript"
							color={iconColor}
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#282C9C99',
								width: '40px',
								height: '40px',
								padding: '10px',
							}}
							sx={iconStyle}
						/>
					);
				case 'organization':
					return (
						<DomainIcon
							key="organization"
							color="#000"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#282C9C99',
								width: '40px',
								height: '40px',
								padding: '10px',
							}}
							sx={iconStyle}
						/>
					);
				case 'workflowSteps':
					return (
						<ListAltIcon
							key="workflowSteps"
							color="#000"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#282C9C99',
								width: '40px',
								height: '40px',
								padding: '10px',
							}}
							sx={iconStyle}
						/>
					);
				case 'healthcareService':
					return (
						<HealingIcon
							key="healthcareService"
							color="#000"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#282C9C99',
								width: '40px',
								height: '40px',
								padding: '10px',
							}}
							sx={iconStyle}
						/>
					);
				case 'studyPriority':
					return (
						<Box
							sx={{
								...iconStyle,
								boxSizing: 'border-box',
								borderRadius: '50%',
								width: '40px',
								height: '40px',
								padding: '10px',
								background: 'rgba(40, 44, 156, 0.6)',
								fontSize: 'unset',
							}}
						>
							<SportsScore sx={{ width: 24, height: 24, color: '#FFFFFF' }} />
						</Box>
					);
				case 'studyStatusV2':
					return (
						<Box
							sx={{
								...iconStyle,
								boxSizing: 'border-box',
								borderRadius: '50%',
								width: '40px',
								height: '40px',
								padding: '10px',
								background: 'rgba(40, 44, 156, 0.6)',
								fontSize: 'unset',
							}}
						>
							<HealingIcon sx={{ width: 24, height: 24, color: '#FFFFFF' }} />
						</Box>
					);
				case 'studyStatus':
					return (
						<DescriptionIcon
							key="studyStatus"
							color="#000"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#282C9C99',
								width: '40px',
								height: '40px',
								padding: '10px',
							}}
							sx={iconStyle}
						/>
					);
				case 'IDP':
					return (
						<BadgeIcon
							key="IDP"
							color="#000"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#282C9C99',
								width: '40px',
								height: '40px',
								padding: '10px',
							}}
							sx={iconStyle}
						/>
					);
				case 'patient':
					return (
						<AssignmentIndIcon
							key="organization"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#B96877',
								width: '40px',
								height: '40px',
								padding: '10px',
							}}
							sx={iconStyle}
						/>
					);
				case 'visit':
					return (
						<CardTravel
							key="visit"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#568AF3',
								width: '40px',
								height: '40px',
								padding: '10px',
							}}
							sx={iconStyle}
						/>
					);
				case 'user':
					return (
						<PersonIcon
							key="visit"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#C480DC',
								width: '40px',
								height: '40px',
								padding: '10px',
							}}
							sx={iconStyle}
						/>
					);
				case 'order':
					return (
						<ListIcon
							key="order"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#46a2b1',
								width: '40px',
								height: '40px',
								padding: '10px',
							}}
							sx={iconStyle}
						/>
					);
				case 'insuranceInfo':
					return (
						<HealthAndSafetyOutlinedIcon
							key="insuranceInfo"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#06ADA3',
								width: '40px',
								height: '40px',
								padding: '10px',
							}}
							sx={iconStyle}
						/>
					);
				case 'financialType':
					return (
						<MonetizationOnOutlined
							key="financialType"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#282C9C',
								width: '40px',
								height: '40px',
								padding: '10px',
								marginLeft: '24px',
							}}
							sx={iconStyle}
						/>
					);
				case 'procedureCode':
					return (
						<SubtitlesOutlinedIcon
							key="procedureCode"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#282C9C',
								width: '40px',
								height: '40px',
								padding: '10px',
								marginLeft: '24px',
							}}
							sx={iconStyle}
						/>
					);
				case 'feeSchedule':
					return (
						<RequestQuoteOutlinedIcon
							key="feeSchedule"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#282C9C',
								width: '40px',
								height: '40px',
								padding: '10px 9px 10px 12px',
								marginLeft: '24px',
							}}
							sx={iconStyle}
						/>
					);
				case 'preference':
					return (
						<SettingsOutlinedIcon
							key="preference"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#282C9C',
								width: '40px',
								height: '40px',
								padding: '10px',
								marginLeft: '24px',
							}}
							sx={iconStyle}
						/>
					);
				case 'self-scheduling':
					return (
						<LocalLibraryOutlined
							key="self-scheduling"
							style={{
								boxSizing: 'border-box',
								borderRadius: '50%',
								background: '#282C9C',
								width: '40px',
								height: '40px',
								padding: '10px',
								marginLeft: '24px',
							}}
							sx={iconStyle}
						/>
					);

				case 'studySets':
					return (
						<Box
							sx={{
								width: '40px',
								height: '40px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '50%',
								backgroundColor: '#282C9C99',
								marginLeft: '24px',
							}}
						>
							<GroupWorkOutlinedIcon />
						</Box>
					);
				case 'users':
					return (
						<Box
							sx={{
								width: '40px',
								height: '40px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '50%',
								backgroundColor: '#282C9C99',
								marginLeft: '24px',
							}}
						>
							<GroupIcon />
						</Box>
					);
				case 'roles':
					return (
						<Box
							sx={{
								width: '40px',
								height: '40px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '50%',
								backgroundColor: '#282C9C99',
								marginLeft: '24px',
							}}
						>
							<Groups3OutlinedIcon />
						</Box>
					);
				case 'affiliation':
					return (
						<Box
							sx={{
								width: '40px',
								height: '40px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '50%',
								backgroundColor: '#282C9C99',
								marginLeft: '24px',
							}}
						>
							<Svg name="affiliationIcon" />
						</Box>
					);
				case 'refresh':
					return (
						<Box
							sx={{
								width: '40px',
								height: '40px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '50%',
								backgroundColor: '#282C9C99',
								marginLeft: '24px',
							}}
						>
							<AutorenewOutlinedIcon />
						</Box>
					);
				default:
					break;
			}
		}
	};

	const getIconTooltipTitle = iconType => {
		if (iconType) {
			switch (iconType) {
				case 'procedureCode':
					return t('icons.addProcedure');
				case 'feeSchedule':
					return t('icons.addNew');
				case 'users':
					return t('icons.addUser');
				case 'roles':
					return t('icons.addRole');
				case 'healthcareService':
					return t('healthcareServices:newHealthcareService');
				case 'studyStatus':
					return t('Add_New_Study_Status');
				case 'refresh':
					return t('Refresh the page to view the latest status');
				default:
					return addTooltip ?? '';
			}
		}
	};

	const getButtonIcon = iconType => {
		switch (iconType) {
			case 'save':
				return <SaveOutlinedIcon key="save" sx={{ color: 'icon.primary', pl: 1.25 }} />;
			case 'image':
				return <ImageOutlinedIcon key="image" sx={{ color: 'icon.primary', pl: 1.25 }} />;
			case 'edit':
				return <EditOutlinedIcon key="edit" sx={{ color: 'icon.primary', pl: 1.25 }} />;
			case 'document':
				return <ArticleOutlinedIcon key="document" sx={{ color: 'icon.primary', pl: 1.25 }} />;
			case 'cancel':
			case 'close':
				return <CloseIcon key="cancel" sx={{ color: 'icon.primary', pl: 1.25 }} />;
			case 'deploy':
				return <PlayIcon key="play" sx={{ color: 'icon.primary', pl: 1.25 }} />;
			case 'check':
				return <AccountBalanceOutlinedIcon key="check" sx={{ color: 'icon.primary', pl: 1.25 }} />;
			case 'scan':
				return <QrCodeScannerOutlinedIcon key="check" sx={{ color: 'icon.primary', pl: 1.25 }} />;
			case 'script':
				return <CodeIcon key="script" sx={{ color: 'icon.primary', pl: 1.25 }} />;
			case 'studySets':
				return <StudySetsIcon key="studySets" />;
			case 'refresh':
				return <AutorenewOutlinedIcon key="refresh" sx={{ color: 'icon.primary', pl: 1.25 }} />;
			//added this as no default case was mentioned and eslint was giving it as error
			default:
				return null;
		}
	};

	const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

	const model = getModel();

	return (
		<Grid item data-testid="form-header" sx={sx} xs={12}>
			<Box
				className={classnames(className, defaultClassName, 'main-header')}
				component="div"
				data-cy="main-header"
				data-testid="main-header"
				sx={{
					backgroundColor: 'dark-4',
					color: 'primary.contrastText',
					fontFamily: 'fontFamily',
					borderRadius: 2,
					pb: '20px',
					py: '20px',
					display: 'flex',
					alignItems: 'center',
					flexWrap: 'wrap',
					fontSize: 20,
					fontWeight: 500,
					minWidth: '310px',
				}}
			>
				{renderHeaderIcon(headerIconType, iconColor || defaultIconColor)}
				<Box
					component="div"
					sx={{
						color: 'primary.contrastText',
						ml: headerIconType ? 2.5 : 11,
						flex: showStatus ? 0 : 1,
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						flexDirection: 'column',
						display: 'flex',
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						{formHeaderText}

						{onAddMore && (
							<Tooltip title={getIconTooltipTitle(headerIconType)}>
								<IconButton sx={{ marginLeft: '8px' }} onClick={onAddMore}>
									<AddIcon data-cy="add-icon" sx={{ color: '#FFFFFF99' }} />
								</IconButton>
							</Tooltip>
						)}
					</Box>

					<Box
						component="div"
						sx={{
							fontSize: 14,
							lineHeight: 1.5,
							fontWeight: 400,
							color: 'icon.primary',
						}}
					>
						{subHeaderText}
					</Box>
				</Box>

				{showStatus && (
					<Box
						component="div"
						sx={{
							color: 'primary.contrastText',
							flexGrow: 2.3,
							marginLeft: '15px',
						}}
					>
						<StatusBadge type={statusType} />
					</Box>
				)}

				{showActiveButton && (
					<Box
						component="div"
						sx={{
							color: 'primary.contrastText',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<ActiveSwitch
							isChecked={model?.active}
							isDisabled={disableActiveButton}
							onChange={(event, newValue, customModelUpdate) => {
								onActiveSwitchClick(null, newValue, 'active', null, customModelUpdate);
							}}
						/>
					</Box>
				)}

				<Box
					className="formHeaderActionsButtonsWrapper"
					component="div"
					data-testid="form-buttons-section"
					sx={{
						pr: 2,
						display: 'flex',
						flexDirection: 'row',
						mr: 1.625,
					}}
				>
					{buttons
						? buttons.map(button => {
								if (!button.hidden) {
									if (button.customRenderer) {
										return button.customRenderer();
									}

									const renderButton = (ref, onClick) => (
										<Tooltip title={button?.toolTip || ''}>
											<Button
												key={`${button.type}---button`}
												ref={ref}
												className={classnames(
													className,
													defaultClassName,
													`${button.type}-button`
												)}
												data-testid="form-button"
												startIcon={
													button.loading ? (
														<CircularProgress
															size={20}
															sx={{
																color: 'icon.primary',
															}}
														/>
													) : (
														getButtonIcon(button.type)
													)
												}
												sx={{
													flexDirection: 'column',
													textTransform: 'none',
													fontSize: 12,
													color: 'icon.primary',

													'.MuiButton-startIcon': button.loading ? { mx: 'auto' } : undefined,
												}}
												variant="text"
												onClick={() => {
													if (!button.loading) {
														if (onClick) {
															onClick();
														} else {
															button.onClickHandler(getModel());
														}
													}
												}}
											>
												{button.type !== 'studySets' && t(capitalizeFirstLetter(button.type))}
											</Button>
										</Tooltip>
									);

									if (button.display) {
										return button.display;
									}

									return (
										<div key={`${button.type}-button`}>
											{button.popoverContent ? (
												<PopoverWrapper content={button.popoverContent}>
													{(anchorRef, onToggleIsOpen) =>
														renderButton(anchorRef, onToggleIsOpen)
													}
												</PopoverWrapper>
											) : (
												renderButton()
											)}
											{button.dividerRight ? (
												<Box
													key={`${button.type}--button-key`}
													component="span"
													sx={{
														alignItems: 'inline-text',
														color: 'primary.light',
														fontWeight: 'normal',
														mr: button.disableMrOnDivider ? 0.375 : 1.625,
													}}
												>
													|
												</Box>
											) : null}
										</div>
									);
								}
						  })
						: null}
				</Box>
			</Box>
		</Grid>
	);
};

FormHeader.propTypes = {
	/**
	 * Array of buttons to add to header. Each button is a JS object with three properties: "type", "onClickHandler", "dividerRight"
	 */
	buttons: PropTypes.array,

	/**
	 * Type of icon to be used in the header: "account", "device", "study", "organisation", "patient", "visit", "workflowConfig", "order", 'workflowSteps, 'IDP, 'studyStatus
	 */
	headerIconType: PropTypes.oneOf([
		'account',
		'device',
		'study',
		'workflowConfig',
		'importScript',
		'organization',
		'workflowSteps',
		'healthcareService',
		'studyStatusV2',
		'studyStatus',
		'IDP',
		'patient',
		'visit',
		'user',
		'order',
		'insuranceInfo',
		'financialType',
		'procedureCode',
		'feeSchedule',
		'preference',
		'studySets',
		'users',
		'roles',
		'refresh',
		'self-scheduling',
	]),

	/**
	 * MUI color to apply to header icon: "primary", "secondary", "success", "action", "disabled"
	 */
	iconColor: PropTypes.oneOf(['primary', 'secondary', 'success', 'action', 'disabled', 'white']),

	/**
	 * Main header text
	 */
	formHeaderText: PropTypes.string.isRequired,

	/**
	 * Sub-header text
	 */
	subHeaderText: PropTypes.string,

	/**
	 * Function to retrieve model data that the form is based on
	 */
	getModel: PropTypes.func.isRequired,

	/**
	 * Condition to display status badge
	 */
	showStatus: PropTypes.bool,

	/**
	 * Status badge value
	 */
	statusType: PropTypes.string,

	/**
	 * Custom stylings for the wrapper
	 */
	sx: PropTypes.object,

	/**
	 * Add button tooltip fallback
	 */
	addTooltip: PropTypes.string,
};

export default FormHeader;
