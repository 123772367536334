import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import lightTheme from '@rs-ui/themes/light-theme';
import { mediaDesktopL, mediaTablet } from '@rs-core/utils/responsiveUtils';
import FormToolBody from '@rs-ui/components/FormTool/FormToolBody';

import { useFormStore } from '@worklist-2/patientPortal/src/stores/forms';
import FormToolHeader from '@worklist-2/patientPortal/src/components/FormDrawer/components/FormToolHeader';
import FormToolSubheader from '@worklist-2/patientPortal/src/components/FormDrawer/components/FormToolSubheader';
import FormToolFieldset from '@worklist-2/patientPortal/src/components/FormDrawer/components/FormToolFieldset';
import FormQuestionType from '@worklist-2/patientPortal/src/components/FormDrawer/components/FormQuestionType';

const FormTool = ({
	handleFormData,
	onSave,
	showBackBtn = false,
	isReadOnly = false,
	progressPercentage = '0%',
	pageRef,
	isValidForm,
	isCompact,
	setPdfInstance,
	pdfUrl,
	isExternal,
}) => {
	const selectedForm = useFormStore(state => state.selectedForm);
	const setSelectedForm = useFormStore(state => state.setSelectedForm);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { t } = useTranslation('appointments');

	const isPdfForm = !!selectedForm?.pdfFileName;

	const onCloseClick = () => {
		setSelectedForm(null);
	};

	return (
		<ThemeProvider theme={lightTheme}>
			<Box>
				{!isExternal && (
					<Stack
						alignItems="center"
						direction="row"
						sx={{
							'--element-progress-height': '4px',

							background: '#ffffff',
							boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
							marginBottom: 'var(--element-progress-height)',
							padding: '15px 20px',
							position: 'relative',
						}}
					>
						<Typography
							sx={{
								color: '#838383',
								flex: 'auto',
								fontSize: '20px',
								fontWeight: 500,
							}}
							variant="h2"
						>
							{selectedForm.title}
						</Typography>

						<IconButton onClick={onCloseClick}>
							<Icon component={CloseIcon} />
						</IconButton>

						{!isPdfForm && (
							<Box
								sx={{
									background: 'var(--color-primary)',
									height: 'var(--element-progress-height)',
									left: '0',
									position: 'absolute',
									top: '100%',
									width: progressPercentage, // progress
								}}
							/>
						)}
					</Stack>
				)}

				<Stack
					sx={{
						alignItems: 'center',
						maxHeight: '93vh',
						'& ::-webkit-scrollbar': {
							width: '8px',
							height: '8px',
						},
						'& ::-webkit-scrollbar-thumb': {
							backgroundColor: '#C4C4C4',
							borderRadius: '10px',
						},
						'& ::-webkit-scrollbar-track': {
							borderRadius: '10px',
							backgroundColor: '#c4c4c433',
						},
					}}
				>
					<FormToolBody
						sx={{
							minWidth: isPdfForm ? '100%' : '600px',
							[mediaDesktopL]: {
								padding: isPdfForm ? 0 : '24px 30px',
								maxWidth: isPdfForm ? '100%' : 'calc(100% - 60px)',
							},
							[mediaTablet]: {
								padding: isPdfForm ? 0 : '24px 0px',
								minWidth: '100%',
								maxWidth: '100%',
							},
							overflow: 'auto',
							paddingRight: '5px',
						}}
					>
						{selectedForm.questionData?.map((page, pageIndex) => (
							<Stack
								key={pageIndex}
								ref={el => (pageRef && pageRef.current ? (pageRef.current[pageIndex] = el) : null)} // used for screenshot and pdf
							>
								{pageIndex > 0 && (
									<Box>
										<Box
											sx={{
												position: 'relative',
												boxSizing: 'border-box',
												height: '50px',
												left: '0',
												top: '10px',
												zIndex: 1,
											}}
										>
											<Typography
												sx={{
													background: '#42A5F5',
													textTransform: 'uppercase',
													color: '#FFFFFF',
													fontSize: '16px',
													letterSpacing: '0.4px',
													borderRadius: '10px',
													whiteSpace: 'nowrap',
													padding: '10px 20px',
													width: 'fit-content',
													height: 'inherit',
												}}
											>
												{`Page ${pageIndex + 1}`}
											</Typography>
										</Box>
										<FormToolFieldset
											sx={{
												position: 'relative',
												left: '0',
												zIndex: 100,
												width: '100%',
												display: 'flex',
											}}
										>
											<FormToolSubheader pageIndex={pageIndex} titleId={`page-id-${pageIndex}`} />
										</FormToolFieldset>
									</Box>
								)}
								{!isPdfForm && pageIndex === 0 && <FormToolHeader />}
								{page?.map((question, index) => (
									<FormQuestionType
										key={`Question-${pageIndex}-${index}`}
										formId={
											isPdfForm
												? selectedForm.id
												: selectedForm.referenceTemplate
												? selectedForm.referenceTemplate
												: selectedForm.id
										}
										handleInput={handleFormData}
										isReadOnly={isReadOnly}
										pdfUrl={pdfUrl}
										question={question}
										setPdfInstance={setPdfInstance}
									/>
								))}
							</Stack>
						))}
						{onSave && !isReadOnly && (
							<Box sx={{ display: 'flex', justifyContent: 'end' }}>
								<Button
									color="secondary"
									data-testid="next"
									disabled={isSubmitting || !isValidForm}
									sx={{
										width: '110px',
										height: '42px',
										borderRadius: '8px',
										color: '#FFF',
										background: 'rgb(66, 165, 245)',
										'&:hover': {
											background: 'rgb(144, 202, 249)',
										},
									}}
									variant="contained"
									onClick={async () => {
										try {
											setIsSubmitting(true);

											if (isSubmitting) return;

											await onSave();

											setIsSubmitting(false);
										} catch (err) {
											setIsSubmitting(false);
											console.error(err);
										}
									}}
								>
									<Typography
										style={{
											fontSize: '16px',
											fontWeight: '500',
											lineHeight: '19px',
											letterSpacing: '0.15em',
											textTransform: 'uppercase',
										}}
									>
										{isSubmitting ? <CircularProgress size={20} /> : t('SUBMIT')}
									</Typography>
								</Button>
							</Box>
						)}
					</FormToolBody>
				</Stack>
			</Box>
		</ThemeProvider>
	);
};

export default FormTool;
