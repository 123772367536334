import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';

import PictureAsPdfOutlinedIcon from '@worklist-2/ui/src/assets/icons/uploadProgress/pdf.svg';
import ImageOutlinedIcon from '@worklist-2/ui/src/assets/icons/uploadProgress/jpeg.svg';
import VideocamIcon from '@worklist-2/ui/src/assets/icons/uploadProgress/mov.svg';
import DicomIcon from '@worklist-2/ui/src/assets/icons/uploadProgress/dicom.svg';
import ImageViewerExternal from '@rs-ui/views/ImageViewerView3D/ImageViewerExternal/ImageViewerExternal';
import PdfViewer from '@rs-ui/components/PdfViewer/PdfViewer';
import { useConfig } from '@rs-core/context/ConfigContext';
import { useIsTablet, mediaTablet } from '@rs-core/utils/responsiveUtils';
import { useAuth } from '@rs-core/context/UserAuthContext';
import { useUserStore } from '@worklist-2/patientPortal/src/stores';
import SectionMyDocumentVideoPlayer from '../../views/SettingsView/views/sections/SectionMyDocument/components/SectionMyDocumentVideoPlayer';
import MobileBottomDrawer from '../MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import MobileList from '../MobileBottomDrawer/MobileList/MobileList';

const DocumentViewDrawer = ({ drawerOpen, setIsOpenDocument, documentData, source }) => {
	const __config = useConfig();
	const { accessToken, sessionId } = useAuth();
	const { t } = useTranslation('profile');
	const isTablet = useIsTablet();

	const [imageUrl, setImageUrl] = useState();
	const [pdfFileUrl, setPdfFileUrl] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [saasUrl, setSaasUrl] = useState();
	const { user } = useUserStore(state => ({
		user: state.user,
	}));

	const headers = useMemo(
		() => ({
			Accept: '*/*',
			Authorization: accessToken,
			SessionID: sessionId,
		}),
		[accessToken, sessionId]
	);

	const getFileType = () => {
		if (!documentData) return 'N/A';
		if (documentData?.fileType) return documentData?.fileType;
		if (documentData?.fileName?.split('.')?.reverse()?.[0])
			return documentData?.fileName?.split('.')?.reverse()?.[0];
		return 'N/A';
	};

	const fileType = useMemo(() => getFileType(), [documentData]);
	const fileId = source === 'REPORT' ? documentData?.reportId : documentData?.documentId;
	const internalManagingOrgId = fileType === 'DICOM' ? documentData?.internalManagingOrgId : '';

	const primaryData =
		source === 'REPORT'
			? documentData.reportName
			: fileType === 'DICOM'
			? documentData?.fileName
			: documentData?.fileName
			? `${t('Document name')} : ${documentData?.fileName}`
			: `${t('Document name')} :  ${documentData?.valueString?.split('/')?.reverse()?.[0]}`;

	const secondaryData =
		source === 'REPORT'
			? documentData.doctorName
			: fileType === 'DICOM'
			? fileType
			: `${t('Document type')} : ${fileType}`;

	let fileTypeIcon = '';
	let drawerContent = null;
	switch (fileType?.toLowerCase()) {
		case 'pdf':
		case 'docx':
		case 'doc':
			fileTypeIcon = PictureAsPdfOutlinedIcon;
			drawerContent = (
				<Grid
					item
					sx={{
						width: '100%',
						border: '1px solid #C4C4C480',
					}}
				>
					<PdfViewer fileUrl={pdfFileUrl} headers={headers} isLoading={false} showFullScreenIcon={false} />
				</Grid>
			);

			break;
		case 'video':
			fileTypeIcon = VideocamIcon;
			drawerContent = (
				<Box
					sx={{
						maxHeight: '100%',
						maxWidth: '100%',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<SectionMyDocumentVideoPlayer saasUrl={saasUrl} />
				</Box>
			);

			break;
		case 'dicom':
			fileTypeIcon = DicomIcon;
			drawerContent = (
				<ImageViewerExternal
					disabledKeyImages
					creatorUserId={documentData?.creatorUserId || null}
					internalManagingOrganizationID={internalManagingOrgId}
					isUploaded={documentData?.isUploaded || false}
					studyInstanceUID={fileId}
				/>
			);

			break;
		default:
			fileTypeIcon = ImageOutlinedIcon;
			drawerContent = (
				<Box
					alt=""
					component="img"
					src={imageUrl}
					sx={{
						maxHeight: '100%',
						maxWidth: '100%',
						display: 'flex',
						alignSelf: 'center',
						margin: '0 auto',
					}}
				/>
			);

			break;
	}

	const onDrawerClose = () => {
		setImageUrl();
		setIsOpenDocument(false);
		setIsLoading(true);
	};

	useEffect(() => {
		if (fileId) {
			if (fileType === 'pdf' || fileType === 'docx' || fileType === 'doc') {
				if (source === 'REPORT') {
					setPdfFileUrl(documentData.reportPath);
				} else {
					documentData.notesURL
						? setPdfFileUrl(documentData.notesURL)
						: setPdfFileUrl(
								documentData?.valueString
									? `${__config.data_sources.blume}Appointment/attachment?appointmentId=${fileId}&fileName=${documentData?.valueString}`
									: `${__config.data_sources.blume}Document/${fileId}/?isThumbnail=false&hash=${user?.profileId}`
						  );
				}
				setIsLoading(false);
			} else if (fileType === 'video') {
				axios
					.get(`${__config.data_sources.blume}Document/${fileId}/?isSaasURI=true&hash=${user?.profileId}`)
					.then(result => {
						if (result && result.status === 200 && result.data) {
							setSaasUrl(result.data);
							setIsLoading(false);
						}
					})
					.catch(e => {
						console.error(`Error: ${e.message}`);
					});
			} else if (fileType === 'DICOM') {
				setIsLoading(false);
			} else {
				axios
					.get(
						documentData.notesURL
							? documentData.notesURL
							: documentData.valueString
							? `${__config.data_sources.blume}Appointment/attachment?appointmentId=${fileId}&fileName=${documentData?.valueString}`
							: `${__config.data_sources.blume}Document/${fileId}/?isThumbnail=false&hash=${user?.profileId}`,
						{
							responseType: 'arraybuffer',
						}
					)
					.then(result => {
						if (result && result.status === 200 && result.data) {
							const { Buffer } = require('buffer');
							setImageUrl(`data:image/jpeg;base64,${Buffer.from(result.data).toString('base64')}`);
							setIsLoading(false);
						}
					})
					.catch(e => {
						console.error(`Error: ${e.message}`);
					});
			}
		}
	}, [documentData]);

	const getDocumentContent = () => (
		<>
			<Box
				sx={{
					padding: '16px 24px',
					boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
					[mediaTablet]: {
						padding: '0px 0px 15px 0px',
					},
				}}
			>
				<MobileList
					background="transparent"
					border="0px"
					items={[
						{
							primary: primaryData,
							secondary: secondaryData,
							icon: fileTypeIcon,
							secondaryAction: (
								<IconButton onClick={() => onDrawerClose()}>
									<CloseIcon />
								</IconButton>
							),

							sx: {
								padding: '0px',
								marginBottom: '0px',
								'& .MuiListItemButton-root': {
									minWidth: '42px',
									padding: '0px',
									'& .MuiIcon-root': { width: '42px', height: '51px' },
									'&:hover': {
										backgroundColor: 'transparent',
									},
								},
								'.ExpandableSectionButton': {
									display: 'none',
								},
								'& .MuiListItemText-root': {
									margin: 0,
									paddingLeft: '15px',
								},
								'& .MuiListItemText-primary': {
									'& .MuiTypography-root': {
										fontWeight: '400',
										fontSize: '16px',
										width: '75%',
									},
								},
								'& .MuiListItemSecondaryAction-root': {
									right: '0',
								},
								'& .MuiListItemText-secondary': {
									'& .MuiTypography-root': {
										fontWeight: '400',
										fontSize: '14px',
									},
								},
								[mediaTablet]: {
									'& .MuiListItemSecondaryAction-root': {
										display: 'none',
									},
								},
							},
						},
					]}
					sx={{
						borderRadius: '20px 0px 0px 0px',
						padding: '0px',
					}}
				/>
			</Box>
			<Box
				sx={{
					marginTop: '0px',
					padding: '0px',
					borderRadius: `10px 10px 0px 0px`,
					height: 'calc(100% - 82px)',
					display: 'flex',
					[mediaTablet]: {
						height: 'calc(100% - 62px)',
					},
				}}
			>
				<Box
					sx={{
						margin: '31px 24px',
						width: '100%',
						backgroundColor: isLoading || fileType === 'pdf' ? 'rsSecondary.medium' : '#000000',
						display: 'flex',
						[mediaTablet]: {
							margin: '0px',
						},
					}}
				>
					{isLoading ? (
						<Skeleton
							sx={{
								width: '100%',
								height: '100%',
							}}
							variant="rectangular"
						/>
					) : (
						drawerContent
					)}
				</Box>
			</Box>
		</>
	);

	return (
		<>
			{isTablet ? (
				<MobileBottomDrawer fullHeight background="#ffffff" open={drawerOpen} onClose={() => onDrawerClose()}>
					<Box
						className="translate"
						sx={{
							backgroundColor: 'rsSecondary.medium',
							height: '100%',
						}}
					>
						{getDocumentContent()}
					</Box>
				</MobileBottomDrawer>
			) : (
				<React.Fragment key="right">
					<Drawer
						PaperProps={{
							sx: {
								overflowY: 'hidden',
								backgroundColor: 'transparent',
								borderRadius: `10px 0px 0px 10px`,
							},
						}}
						anchor="right"
						open={drawerOpen}
					>
						<Box
							className="translate"
							height="100vh"
							sx={{ backgroundColor: 'rsSecondary.medium' }}
							width={646}
						>
							{getDocumentContent()}
						</Box>
					</Drawer>
				</React.Fragment>
			)}
		</>
	);
};

export default DocumentViewDrawer;
