import { v4 as uuid } from 'uuid';
import MammographyHangingProtocols from '../mammography/mammographyHangingProtocols';

export const MODALITIES = Object.freeze({
	XR: 'XR',
	US: 'US',
	CT: 'CT',
	MR: 'MR',
	MG: 'MG',
});

const DefaultXRHps = [
	{
		'Hanging Protocol Name': 'XR Chest',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['XR'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Chest',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'PA',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'LAT',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 0,
				},
			},
		],
		ProtocolID: 'XR Chest',
	},
	{
		'Hanging Protocol Name': 'XR Chest + Prior',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['XR'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Chest',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'PA',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'PA',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
						prior: 1,
					},
				],
				layout: {
					x: 1,
					y: 0,
				},
			},
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'LAT',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'LAT',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
						prior: 1,
					},
				],
				layout: {
					x: 1,
					y: 0,
				},
			},
		],
		ProtocolID: 'XR Chest + Prior',
	},
	{
		'Hanging Protocol Name': 'XR Abdomen',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['XR'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Abdomen',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'AP',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'LAT',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 0,
				},
			},
		],
		ProtocolID: 'XR Abdomen',
	},
	{
		'Hanging Protocol Name': 'XR Abdomen + Prior',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['XR'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Abdomen',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'PA',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'PA',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
						prior: 1,
					},
					{
						id: uuid(),
						viewCode: 'LAT',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'LAT',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
						prior: 1,
					},
				],
				layout: {
					x: 1,
					y: 1,
				},
			},
		],
		ProtocolID: 'XR Abdomen + Prior',
	},
	{
		'Hanging Protocol Name': 'XR Single Extremities',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['XR'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Extremity',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'AP',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'LAT',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 0,
				},
			},
		],
		ProtocolID: 'XR Single Extremities',
	},
	{
		'Hanging Protocol Name': 'XR Extremities 2x2',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['XR'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Extremity',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'AP',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'LAT',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'AP',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'LAT',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 1,
				},
			},
		],
		ProtocolID: 'XR Extremities 2x2',
	},
	{
		'Hanging Protocol Name': 'XR Extremities',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['XR'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Extremity',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'AP',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'AP',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
						prior: 1,
					},
					{
						id: uuid(),
						viewCode: 'LAT',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'LAT',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
						prior: 1,
					},
				],
				layout: {
					x: 1,
					y: 1,
				},
			},
		],
		ProtocolID: 'XR Extremities',
	},
];

const DefaultMGHps = [
	{
		'Hanging Protocol Name': 'MG 2x2',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MG'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Breast',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'MG: RCC',
						modifier: '',
						parentId: null,
						rules: [],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MG: LCC',
						modifier: '',
						parentId: null,
						rules: [],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MG: RMLO',
						modifier: '',
						parentId: null,
						rules: [],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MG: LMLO',
						modifier: '',
						parentId: null,
						rules: [],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 1,
				},
			},
		],
		ProtocolID: 'MG 2x2',
	},
	{
		'Hanging Protocol Name': 'MG 4x1',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MG'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Breast',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'MG: RCC',
						modifier: '',
						parentId: null,
						rules: [],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MG: LCC',
						modifier: '',
						parentId: null,
						rules: [],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MG: RMLO',
						modifier: '',
						parentId: null,
						rules: [],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MG: LMLO',
						modifier: '',
						parentId: null,
						rules: [],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 3,
					y: 0,
				},
			},
		],
		ProtocolID: 'MG 4x1',
	},
];

const DefaultCTHps = [
	{
		'Hanging Protocol Name': 'CT Abdo-Pelvis',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['CT'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Abdomen and Pelvis',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'CT: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 1,
				},
			},
		],
		ProtocolID: 'CT Abdo-Pelvis',
	},
	{
		'Hanging Protocol Name': 'CT KUB URO',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['CT'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Abdomen',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'CT: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 1,
				},
			},
		],
		ProtocolID: 'CT KUB URO',
	},
	{
		'Hanging Protocol Name': 'CT Chest',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['CT'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Chest',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'CT: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 1500,
									ww: -600,
									preset: 'lung',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 1500,
									ww: -600,
									preset: 'lung',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 1500,
									ww: -600,
									preset: 'lung',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 80,
									ww: 40,
									preset: 'bone',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 1,
				},
			},
		],
		ProtocolID: 'CT Chest',
	},
	{
		'Hanging Protocol Name': 'CT Chest + Prior',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['CT'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Chest',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'CT: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 1500,
									ww: -600,
									preset: 'lung',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 1500,
									ww: -600,
									preset: 'lung',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
						prior: 1,
					},
					{
						id: uuid(),
						viewCode: 'CT: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 1500,
									ww: -600,
									preset: 'lung',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 1500,
									ww: -600,
									preset: 'lung',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 1,
				},
			},
		],
		ProtocolID: 'CT Chest + Prior',
	},
	{
		'Hanging Protocol Name': 'CT Brain',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['CT'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Brain',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'CT: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 400,
									ww: 40,
									preset: 'softTissue',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 400,
									ww: 40,
									preset: 'softTissue',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 400,
									ww: 40,
									preset: 'softTissue',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Any',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 80,
									ww: 40,
									preset: 'bone',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 1,
				},
			},
		],
		ProtocolID: 'CT Brain',
	},
	{
		'Hanging Protocol Name': 'CT Brain + Priors',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['CT'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Brain',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'CT: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 400,
									ww: 40,
									preset: 'softTissue',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 400,
									ww: 40,
									preset: 'softTissue',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 400,
									ww: 40,
									preset: 'softTissue',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Any',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 80,
									ww: 40,
									preset: 'bone',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 400,
									ww: 40,
									preset: 'softTissue',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
						prior: 1,
					},
					{
						id: uuid(),
						viewCode: 'CT: Any',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 80,
									ww: 40,
									preset: 'bone',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
						prior: 1,
					},
				],
				layout: {
					x: 1,
					y: 2,
				},
			},
		],
		ProtocolID: 'CT Brain + Priors',
	},
	{
		'Hanging Protocol Name': 'CT Ext & Spine',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['CT'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Extremity',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'CT: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'CT: Any',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'windowPresets',
								values: {
									wl: 400,
									ww: 40,
									preset: 'softTissue',
								},
							},
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: true,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 1,
				},
			},
		],
		ProtocolID: 'CT Ext & Spine',
	},
];

const DefaultUSHps = [
	{
		'Hanging Protocol Name': 'USG-Abdo-Pelvis 2x2',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['US'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Abdomen and Pelvis',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'US',
						modifier: '',
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: false,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'US',
						modifier: '',
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: false,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'US',
						modifier: '',
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: false,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'US',
						modifier: '',
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: false,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 1,
				},
			},
		],
		ProtocolID: 'USG-Abdo-Pelvis 2x2',
	},
	{
		'Hanging Protocol Name': 'USG-Abdo-Pelvis 3X2',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['US'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Abdomen and Pelvis',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'US',
						modifier: '',
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: false,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'US',
						modifier: '',
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: false,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'US',
						modifier: '',
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: false,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'US',
						modifier: '',
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: false,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'US',
						modifier: '',
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: false,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'US',
						modifier: '',
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: false,
										scroll: true,
										annotations: false,
										overlay: true,
										referenceLines: false,
										scoutImageOverlay: false,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 2,
				},
			},
		],
		ProtocolID: 'USG-Abdo-Pelvis 3X2',
	},
];

const DefaultMRHps = [
	{
		'Hanging Protocol Name': 'MR Extremities',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MR'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Extremity',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'MR: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 2,
				},
			},
		],
		ProtocolID: 'MR Extremities',
	},
	{
		'Hanging Protocol Name': 'MR Abd + Pelvis',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MR'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Abdomen and Pelvis',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'MR: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 2,
				},
			},
		],
		ProtocolID: 'MR Abd + Pelvis',
	},
	{
		'Hanging Protocol Name': 'MR Spine',
		'Number of Priors Referenced': 1,
		'Compare Mode Exists': 'N',
		'Public Protocol': 'Y',
		'Hanging Protocol Description': '',
		'Hanging Protocol Level': '',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MR'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Abdomen and Pelvis',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'MR: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 2,
				},
			},
		],
		ProtocolID: 'MR Spine',
	},
	{
		'Hanging Protocol Name': 'MR Brain',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MR'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Brain',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'MR: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
				],
				layout: {
					x: 1,
					y: 1,
				},
			},
		],
		ProtocolID: 'MR Brain',
	},
	{
		'Hanging Protocol Name': 'MR Brain + Priors',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MR'],
					'Anatomic Region Sequence': {
						'Item 1': ['T-D3000', 'SRT', 'Chest'],
						Laterality: '',
						BodyPart: 'Brain',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: [
			{
				monitors: [
					{
						id: uuid(),
						viewCode: 'MR: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Sagittal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Coronal',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
					},
					{
						id: uuid(),
						viewCode: 'MR: Axial',
						modifier: null,
						parentId: null,
						rules: [
							{
								type: 'toggle',
								values: {
									options: {
										linking: true,
										scroll: true,
										annotations: true,
										overlay: true,
										referenceLines: true,
										scoutImageOverlay: true,
										cine: false,
									},
								},
							},
						],
						layout: {
							x: 0,
							y: 0,
						},
						prior: 1,
					},
				],
				layout: {
					x: 1,
					y: 1,
				},
			},
		],
		ProtocolID: 'MR Brain + Priors',
	},
];

export const DefaultHangingProtocols = {
	[MODALITIES.XR]: DefaultXRHps,
	[MODALITIES.MG]: [...MammographyHangingProtocols, ...DefaultMGHps],
	[MODALITIES.CT]: DefaultCTHps,
	[MODALITIES.US]: DefaultUSHps,
	[MODALITIES.MR]: DefaultMRHps,
};
