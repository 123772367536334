import React, { useEffect, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ListItemText from '@mui/material/ListItemText';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormFieldVariants from '@rs-ui/components/FormNew/formPartials/FormFieldVariants/FormFieldVariants';
import InformationFormValidationSchema from '@worklist-2/patientPortal/src/views/NewSettingsView/validationSchemas/InformationFormValidationSchema';
import SearchIcon from '@mui/icons-material/Search';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useIsMobile } from '@rs-core/utils/responsiveUtils';
import { CircularProgress } from '@mui/material';
import arraysEqual from '@worklist-2/patientPortal/src/utils/arraysEqual';
import moment from 'moment';
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';
import { useUserStore, useProfileStore } from '@worklist-2/patientPortal/src/stores';
import { useConfig } from '@rs-core/context/ConfigContext';
import { useAuth } from '@rs-core/context/UserAuthContext';

const FormContainer = ({
	formFields,
	col,
	isEdit,
	setIsEdit,
	formType,
	isOaiNotification,
	setIsOpenDetail,
	fieldChangeRequest,
	updateNotification,
	loading = false,
	setLoading = Function.prototype,
}) => {
	const __config = useConfig();
	const { setProfiles } = useAuth();

	const [anchorEl, setAnchorEl] = useState(null);
	const [multiSelectList, setMultiSelectList] = useState([]);
	const open = Boolean(anchorEl);
	const { t } = useTranslation('drawer');
	const { t: tProfile } = useTranslation('profile');
	const isMobile = useIsMobile();
	const { setToastMsg } = useToastMessageContext();

	const { user, fetchUser, updateUser } = useUserStore(state => ({
		fetchUser: state.fetchUser,
		updateUser: state.updateUser,
		user: state.user,
	}));

	const { setCachedProfileId } = useProfileStore(state => ({ setCachedProfileId: state.setCachedProfileId }));

	const personalDefaultValues = {
		firstName: user.firstName,
		middleName: user.middleName,
		lastName: user.lastName,
		birthDate: user.birthDate,
		gender: user.gender,
		race: user.race,
		ethnicity: user.ethnicity,
		birthSex: user.birthSex,
		language: user.language,
		ssn: user.ssn,
		maritalStatus: user.maritalStatus,
		smokingStatus: user.smokingStatus,
		alcoholUse: user.alcoholUse,
		motherMaidenName: user.motherMaidenName,
	};

	const addressDefaultValues = {
		country: user.country,
		state: user.state,
		city: user.city,
		zip: user.zip,
		address: user.address,
		addressLine2: user.addressLine2,
	};

	const contactDefaultValues = {
		phone: user.phone,
		email: user.email,
	};

	const [showFieldChangeRequestWarning, setShowFieldChangeRequestWarning] = useState(false);

	const form = useForm({
		resolver: yupResolver(InformationFormValidationSchema(formType !== 'Personal Information')),
		defaultValues:
			formType === 'Personal Information'
				? personalDefaultValues
				: formType === 'Address'
				? addressDefaultValues
				: contactDefaultValues,
	});

	const handleClick = useCallback((event, itemList) => {
		setMultiSelectList(itemList);
		setAnchorEl(event.currentTarget);
	}, []);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const extractValueFromMultiSelectFields = values => {
		if (values) {
			const valueArray = values?.map(value => (value?.display ? value?.display : value));
			return valueArray;
		}
	};

	const onSubmit = async data => {
		const formData = { ...data };
		setLoading(true);
		if (formType === 'Personal Information') {
			formData.ethnicity = extractValueFromMultiSelectFields(data.ethnicity);
			formData.race = extractValueFromMultiSelectFields(data.race);
			formData.language = extractValueFromMultiSelectFields(data.language);
		}

		const changedValues = {};

		for (const key in formData) {
			if (Array.isArray(formData[key])) {
				if (!arraysEqual(formData[key], user[key])) {
					changedValues[key] = formData[key];
				}
			} else if (formData[key] !== user[key]) {
				changedValues[key] = formData[key];
			}
		}

		const updatedProfileId = await updateUser({ __config, payload: changedValues });
		if (changedValues.firstName || changedValues.lastName) {
			const profiles = await fetchUser({ __config });
			setProfiles(profiles);
			setCachedProfileId(updatedProfileId);
		}
		if (formType === 'Personal Information') {
			const changedKeys = Object.keys(changedValues);
			setToastMsg(
				`${changedKeys.slice(0, 3)?.join(', ')}${changedKeys?.length > 3 ? '...' : ''} updated successfully!`
			);
		} else if (formType === 'Address') {
			setToastMsg('Address details updated successfully!');
		} else {
			setToastMsg('Contact details updated successfully!');
		}
		if (isOaiNotification) updateNotification('updated');
		setIsEdit(false);
		if (isOaiNotification) setIsOpenDetail(false);
		setLoading(false);
	};

	const customRenderMultiSelectValue = (selectedValues, setItemName, fieldOnchange) => {
		const deleteValue = valueId => {
			const finalValues = selectedValues.filter(value => value?.id !== valueId);
			setItemName(finalValues);
			fieldOnchange(finalValues);
		};
		return (
			<Box sx={{ display: 'flex', gap: 0.5, overflowX: 'auto' }}>
				{selectedValues.map(
					(value, index) =>
						value?.display &&
						index < 2 && (
							<Chip
								key={value?.id}
								deleteIcon={<ClearOutlinedIcon sx={{ fill: '#121212DE' }} />}
								label={value?.display}
								sx={{
									'& .MuiChip-label': {
										fontWeight: '400',
									},
								}}
								onDelete={() => deleteValue(value?.id)}
								onMouseDown={event => {
									event.stopPropagation();
								}}
							/>
						)
				)}

				{selectedValues?.length > 2 && <Chip key="plusIcon" label={`+${selectedValues?.length - 2}`} />}
			</Box>
		);
	};

	const customRenderMultiSelectMenuItem = (item, selectedItems) => {
		const isSelected = _.findIndex(selectedItems, elem => elem?.id === item.id) > -1;
		return (
			<MenuItem key={_.camelCase(item.id)} name={_.camelCase(item.display)} sx={{ px: '6px' }} value={item}>
				<ListItemText
					primary={item.display}
					primaryTypographyProps={{
						fontSize: '12px',
						color: isSelected ? '#42A5F5' : '',
					}}
				/>

				{isSelected && <CheckOutlinedIcon sx={{ fill: '#42A5F5' }} />}
			</MenuItem>
		);
	};

	useEffect(() => {
		if (!form || !user) return;
		const values =
			formType === 'Personal Information'
				? personalDefaultValues
				: formType === 'Address'
				? addressDefaultValues
				: contactDefaultValues;
		if (!isEdit) form.reset(values);
	}, [isEdit, form]);

	const fieldValue = useWatch({
		control: form.control,
		name: fieldChangeRequest,
	});

	useEffect(() => {
		if (isOaiNotification) {
			if (
				(typeof fieldValue === 'string' && fieldValue && fieldValue !== '') ||
				(typeof fieldValue === 'object' && fieldValue?.length != 0)
			)
				setShowFieldChangeRequestWarning(false);
			else setShowFieldChangeRequestWarning(true);
		} else setShowFieldChangeRequestWarning(false);
	}, [fieldValue]);

	if (isEdit) {
		return (
			<Box sx={{ flexGrow: 1 }}>
				{formType === 'Address' && (
					<Box sx={{ marginTop: '20px' }}>
						<FormFieldVariants
							field={{
								name: 'Address Search',
								type: 'googleAutocomplete',
								props: {
									label: 'Address Search',
									InputProps: {
										autoComplete: 'off',
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
									},

									sx: {
										marginTop: '20px',
										width: isMobile ? '100%' : '97.5%',
									},
								},
							}}
							formHook={form}
						/>
					</Box>
				)}

				<Grid container columnSpacing={1} rowSpacing={2} sx={{ marginTop: '10px' }}>
					{formFields.map((item, index) => (
						<Grid
							key={index + item.name}
							item
							lg={item.name === 'email' || item.name === 'phone' || item.fullWidth ? 12 : 6}
							sm={item.name === 'email' || item.name === 'phone' || item.fullWidth ? 12 : 6}
							xs={12}
						>
							<FormFieldVariants
								field={{
									name: item.name,
									type: item.type,
									options: item.selectOptions,
									menuPopoverAdjustments: {
										adjustWidth: item.name === 'races' ? '1' : 0,
									},

									props: {
										SelectProps:
											item.type === 'multiSelect'
												? {
														'data-cy': `general-info-multiselect-${item.name}`,
														renderValue: item => item,
														customRenderValue: customRenderMultiSelectValue,
														customRenderMenuItem: customRenderMultiSelectMenuItem,
														parseValueFromOptions: true,
												  }
												: {
														renderValue: item => item,
														'data-cy': `general-info-select-${item.name}`,
												  },

										label: tProfile(item.label),
										type: item.type,
										error:
											(fieldChangeRequest === item.name &&
												(!user[item.name] || user[item.name]?.length === 0) &&
												showFieldChangeRequestWarning) ||
											Object.keys(form?.formState?.errors || {}).includes(item.name),
										helperText:
											fieldChangeRequest === item.name &&
											!user[item.name] &&
											showFieldChangeRequestWarning
												? 'Fill in the missing info'
												: form?.formState?.errors?.[item?.name]?.message || '',
										InputProps: {
											'data-cy': `general-info-input-${item.name}`,
											name: `general-info-input-${item.name}`,
											autoComplete: 'off',
											startAdornment:
												item.type === 'select' ? (
													<InputAdornment position="start">
														<SearchIcon />
													</InputAdornment>
												) : null,
										},

										sx: {
											'& .MuiFormHelperText-root': {
												marginLeft: '0px',
												display: item.type === 'muiPhone' ? 'none' : 'block',
											},

											marginTop: '20px',
											marginRight: '10px',
											minHeight: '50px',
											width: '100%',
										},
									},
								}}
								formHook={form}
							/>
						</Grid>
					))}
				</Grid>
				<Box
					sx={{
						marginTop: '30px',
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<Button
						disabled={loading}
						sx={{
							color: 'var(--color-primary)',
							borderColor: 'var(--color-primary)',
							borderRadius: '8px',
							width: '100px',
							fontWeight: '400',
							letterSpacing: '0.1em',
						}}
						variant="outlined"
						onClick={() => {
							setIsEdit(false);
							if (isOaiNotification) setIsOpenDetail(false);
						}}
					>
						{t('Cancel')}
					</Button>
					<Button
						data-cy="form-container-save-btn"
						disabled={Boolean(Object.entries(form.formState.errors)?.length) || loading}
						sx={{
							background: 'var(--color-primary)',
							borderRadius: '8px',
							fontWeight: '400',
							letterSpacing: '0.1em',
							width: '80px',
						}}
						variant="contained"
						onClick={form.handleSubmit(onSubmit)}
					>
						{loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : t('Save')}
					</Button>
				</Box>
			</Box>
		);
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<Menu
				PaperProps={{
					style: {
						maxHeight: '142px',
						width: '113px',
						borderRadius: '10px',
					},
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{multiSelectList.map(
					value =>
						value && (
							<MenuItem key={value}>
								<Typography
									sx={{
										color: 'text.primary',
										fontSize: '12px',
										lineHeight: '20px',
									}}
								>
									{t(value)}
								</Typography>
							</MenuItem>
						)
				)}
			</Menu>
			<Grid container item>
				{formFields.map((item, index) => (
					<React.Fragment key={item.mapping}>
						<Grid
							item
							sm={item.fullWidth ? 12 : 4}
							sx={{
								display: 'flex',
								flexDirection: 'column',
							}}
							xs={6}
						>
							<Typography
								component="label"
								sx={{
									fontSize: '14px',
									fontWeight: '400',
									marginTop: '20px',
								}}
							>
								{tProfile(item.label)}
							</Typography>
							{item.type === 'multiSelect' ? (
								<Typography
									component="span"
									data-cy={`general-info-${item.name}`}
									sx={{
										fontSize: '16px',
										fontWeight: '400',
										color: '#121212de',

										textOverflow: 'ellipsis',
										overflow: 'hidden',
									}}
								>
									{user[item.name]?.length > 0 ? (
										<Typography
											component="span"
											sx={{
												fontSize: '16px',
												fontWeight: '400',
												color: '#121212de',

												textOverflow: 'ellipsis',
												overflow: 'hidden',
											}}
										>
											{user[item.name][0]}
											{user[item.name]?.length > 1 && (
												<Chip
													key="plusIcon"
													label={`+${user[item.name]?.length - 1}`}
													sx={{
														marginLeft: '5px',
														height: '28px',
														width: '28px',
														backgroundColor: '#42A5F50D',
														'& .MuiChip-label': {
															color: '#42A5F5',
															padding: 0,
														},
													}}
													onClick={e => handleClick(e, user[item.name])}
												/>
											)}
										</Typography>
									) : (
										'N/A'
									)}
								</Typography>
							) : (
								<Typography
									component="span"
									data-cy={`general-info-${item.name}`}
									sx={{
										fontSize: '16px',
										fontWeight: '400',
										color: '#121212de',

										textOverflow: 'ellipsis',
										overflow: 'hidden',
									}}
								>
									{item?.type === 'date'
										? user[item?.name]
											? moment(user[item?.name]).format('MMM DD, YYYY')
											: 'N/A'
										: user[item.name] || 'N/A'}
								</Typography>
							)}
						</Grid>
						{!isMobile && col === 2 && (index + 1) % 2 === 0 ? <Grid item sm={4} xs={6} /> : null}
					</React.Fragment>
				))}
			</Grid>
		</Box>
	);
};

export default FormContainer;
