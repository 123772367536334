import React from 'react';

export const MOBILE_ACTION_ICONS = {
	forms: (
		<svg fill="none" height="18" viewBox="0 0 18 18" width="18">
			<path
				d="M2.16667 15.5C1.70833 15.5 1.31597 15.3368 0.989583 15.0104C0.663194 14.684 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663194 1.31597 0.989583 0.989583C1.31597 0.663194 1.70833 0.5 2.16667 0.5H13.8333C14.2917 0.5 14.684 0.663194 15.0104 0.989583C15.3368 1.31597 15.5 1.70833 15.5 2.16667V7.75C15.2361 7.625 14.9653 7.51736 14.6875 7.42708C14.4097 7.33681 14.125 7.27083 13.8333 7.22917V2.16667H2.16667V13.8333H7.20833C7.25 14.1389 7.31597 14.4306 7.40625 14.7083C7.49653 14.9861 7.60417 15.25 7.72917 15.5H2.16667ZM2.16667 13.8333V2.16667V7.22917V7.16667V13.8333ZM3.83333 12.1667H7.22917C7.27083 11.875 7.33681 11.5903 7.42708 11.3125C7.51736 11.0347 7.61806 10.7639 7.72917 10.5H3.83333V12.1667ZM3.83333 8.83333H8.91667C9.36111 8.41667 9.85764 8.06944 10.4062 7.79167C10.9549 7.51389 11.5417 7.32639 12.1667 7.22917V7.16667H3.83333V8.83333ZM3.83333 5.5H12.1667V3.83333H3.83333V5.5ZM13 17.1667C11.8472 17.1667 10.8646 16.7604 10.0521 15.9479C9.23958 15.1354 8.83333 14.1528 8.83333 13C8.83333 11.8472 9.23958 10.8646 10.0521 10.0521C10.8646 9.23958 11.8472 8.83333 13 8.83333C14.1528 8.83333 15.1354 9.23958 15.9479 10.0521C16.7604 10.8646 17.1667 11.8472 17.1667 13C17.1667 14.1528 16.7604 15.1354 15.9479 15.9479C15.1354 16.7604 14.1528 17.1667 13 17.1667ZM12.5833 15.5H13.4167V13.4167H15.5V12.5833H13.4167V10.5H12.5833V12.5833H10.5V13.4167H12.5833V15.5Z"
				fill="#42A5F5"
			/>
		</svg>
	),
	attachments: (
		<svg fill="none" height="11" viewBox="0 0 18 11" width="18">
			<path
				d="M5.87508 10.4167C4.43064 10.4167 3.20147 9.90974 2.18758 8.89585C1.17369 7.88196 0.666748 6.65279 0.666748 5.20835C0.666748 3.7639 1.17369 2.53474 2.18758 1.52085C3.20147 0.50696 4.43064 1.52588e-05 5.87508 1.52588e-05H13.5834C14.6251 1.52588e-05 15.5105 0.364599 16.2397 1.09377C16.9688 1.82293 17.3334 2.70835 17.3334 3.75002C17.3334 4.79168 16.9688 5.6771 16.2397 6.40627C15.5105 7.13543 14.6251 7.50002 13.5834 7.50002H6.29175C5.65286 7.50002 5.11119 7.27779 4.66675 6.83335C4.2223 6.3889 4.00008 5.84724 4.00008 5.20835C4.00008 4.56946 4.2223 4.02779 4.66675 3.58335C5.11119 3.1389 5.65286 2.91668 6.29175 2.91668H14.0001V4.58335H6.29175C6.11119 4.58335 5.96189 4.64238 5.84383 4.76043C5.72578 4.87849 5.66675 5.02779 5.66675 5.20835C5.66675 5.3889 5.72578 5.53821 5.84383 5.65627C5.96189 5.77432 6.11119 5.83335 6.29175 5.83335H13.5834C14.1667 5.81946 14.6598 5.6146 15.0626 5.21877C15.4654 4.82293 15.6667 4.33335 15.6667 3.75002C15.6667 3.16668 15.4654 2.67363 15.0626 2.27085C14.6598 1.86807 14.1667 1.66668 13.5834 1.66668H5.87508C4.88897 1.65279 4.05216 1.99307 3.36466 2.68752C2.67716 3.38196 2.33341 4.22224 2.33341 5.20835C2.33341 6.18057 2.67716 7.00696 3.36466 7.68752C4.05216 8.36807 4.88897 8.72224 5.87508 8.75002H14.0001V10.4167H5.87508Z"
				fill="#42A5F5"
			/>
		</svg>
	),
	reschedule: (
		<svg fill="none" height="20" viewBox="0 0 19 20" width="19">
			<path
				d="M2.49992 17.5C2.04159 17.5 1.64922 17.3368 1.32284 17.0104C0.996446 16.6841 0.833252 16.2917 0.833252 15.8334V4.1667C0.833252 3.70837 0.996446 3.31601 1.32284 2.98962C1.64922 2.66323 2.04159 2.50003 2.49992 2.50003H3.33325V0.833366H4.99992V2.50003H11.6666V0.833366H13.3333V2.50003H14.1666C14.6249 2.50003 15.0173 2.66323 15.3437 2.98962C15.6701 3.31601 15.8333 3.70837 15.8333 4.1667V9.1667H14.1666V7.50003H2.49992V15.8334H8.33325V17.5H2.49992ZM14.1666 19.1667C13.1527 19.1667 12.2673 18.8507 11.5103 18.2188C10.7534 17.5868 10.2777 16.7917 10.0833 15.8334H11.3749C11.5555 16.4445 11.8992 16.9445 12.4062 17.3334C12.9131 17.7223 13.4999 17.9167 14.1666 17.9167C14.9721 17.9167 15.6596 17.632 16.2291 17.0625C16.7985 16.4931 17.0833 15.8056 17.0833 15C17.0833 14.1945 16.7985 13.507 16.2291 12.9375C15.6596 12.3681 14.9721 12.0834 14.1666 12.0834C13.7638 12.0834 13.3888 12.1563 13.0416 12.3021C12.6944 12.4479 12.3888 12.6528 12.1249 12.9167H13.3333V14.1667H9.99992V10.8334H11.2499V12.0209C11.6249 11.6598 12.0624 11.3716 12.5624 11.1563C13.0624 10.941 13.5971 10.8334 14.1666 10.8334C15.3194 10.8334 16.302 11.2396 17.1145 12.0521C17.927 12.8646 18.3333 13.8473 18.3333 15C18.3333 16.1528 17.927 17.1354 17.1145 17.9479C16.302 18.7604 15.3194 19.1667 14.1666 19.1667ZM2.49992 5.83337H14.1666V4.1667H2.49992V5.83337Z"
				fill="#42A5F5"
			/>
		</svg>
	),
	cancel: (
		<svg fill="none" height="18" viewBox="0 0 16 18" width="16">
			<path
				d="M6.08333 14.5834L4.91667 13.4167L6.83333 11.5L4.91667 9.58338L6.08333 8.41671L8 10.3334L9.91667 8.41671L11.0833 9.58338L9.16667 11.5L11.0833 13.4167L9.91667 14.5834L8 12.6667L6.08333 14.5834ZM2.16667 17.3334C1.70833 17.3334 1.31597 17.1702 0.989583 16.8438C0.663194 16.5174 0.5 16.125 0.5 15.6667V4.00004C0.5 3.54171 0.663194 3.14935 0.989583 2.82296C1.31597 2.49657 1.70833 2.33338 2.16667 2.33338H3V0.66671H4.66667V2.33338H11.3333V0.66671H13V2.33338H13.8333C14.2917 2.33338 14.684 2.49657 15.0104 2.82296C15.3368 3.14935 15.5 3.54171 15.5 4.00004V15.6667C15.5 16.125 15.3368 16.5174 15.0104 16.8438C14.684 17.1702 14.2917 17.3334 13.8333 17.3334H2.16667ZM2.16667 15.6667H13.8333V7.33338H2.16667V15.6667ZM2.16667 5.66671H13.8333V4.00004H2.16667V5.66671Z"
				fill="#D92D20"
			/>
		</svg>
	),
};

export const DESKTOP_ACTION_ICONS = {
	cancel: (
		<svg fill="none" height="28" viewBox="0 0 28 28" width="28">
			<rect height="26.7" rx="13.35" stroke="#42A5F5" strokeWidth="1.3" width="26.7" x="0.65" y="0.65" />
			<path
				d="M12.5093 18.3426L11.6019 17.4352L13.0926 15.9445L11.6019 14.4537L12.5093 13.5463L14 15.0371L15.4908 13.5463L16.3982 14.4537L14.9074 15.9445L16.3982 17.4352L15.4908 18.3426L14 16.8519L12.5093 18.3426ZM9.46298 20.4815C9.1065 20.4815 8.80133 20.3546 8.54747 20.1007C8.29362 19.8469 8.16669 19.5417 8.16669 19.1852V10.1111C8.16669 9.75467 8.29362 9.4495 8.54747 9.19564C8.80133 8.94178 9.1065 8.81485 9.46298 8.81485H10.1111V7.51855H11.4074V8.81485H16.5926V7.51855H17.8889V8.81485H18.5371C18.8935 8.81485 19.1987 8.94178 19.4526 9.19564C19.7064 9.4495 19.8334 9.75467 19.8334 10.1111V19.1852C19.8334 19.5417 19.7064 19.8469 19.4526 20.1007C19.1987 20.3546 18.8935 20.4815 18.5371 20.4815H9.46298ZM9.46298 19.1852H18.5371V12.7037H9.46298V19.1852ZM9.46298 11.4074H18.5371V10.1111H9.46298V11.4074Z"
				fill="#42A5F5"
			/>
		</svg>
	),
	reschedule: (
		<svg fill="none" height="28" viewBox="0 0 28 28" width="28">
			<rect fill="#42A5F5" height="28" rx="14" width="28" />
			<g clipPath="url(#clip0_384_3320)">
				<path
					d="M8.16666 19.8331C7.81018 19.8331 7.50501 19.7062 7.25115 19.4523C6.99729 19.1984 6.87036 18.8933 6.87036 18.5368V9.46271C6.87036 9.10623 6.99729 8.80106 7.25115 8.5472C7.50501 8.29334 7.81018 8.16641 8.16666 8.16641H8.81481V6.87012H10.1111V8.16641H15.2963V6.87012H16.5926V8.16641H17.2407C17.5972 8.16641 17.9024 8.29334 18.1562 8.5472C18.4101 8.80106 18.537 9.10623 18.537 9.46271V13.3516H17.2407V12.0553H8.16666V18.5368H12.7037V19.8331H8.16666ZM17.2407 21.1294C16.4522 21.1294 15.7635 20.8836 15.1748 20.3921C14.586 19.9006 14.216 19.2822 14.0648 18.5368H15.0694C15.2099 19.0121 15.4772 19.401 15.8715 19.7035C16.2658 20.0059 16.7222 20.1572 17.2407 20.1572C17.8673 20.1572 18.402 19.9357 18.8449 19.4928C19.2878 19.0499 19.5093 18.5152 19.5093 17.8886C19.5093 17.2621 19.2878 16.7274 18.8449 16.2845C18.402 15.8416 17.8673 15.6201 17.2407 15.6201C16.9275 15.6201 16.6358 15.6768 16.3657 15.7903C16.0957 15.9037 15.858 16.063 15.6528 16.2683H16.5926V17.2405H14V14.6479H14.9722V15.5715C15.2639 15.2906 15.6042 15.0665 15.993 14.8991C16.3819 14.7316 16.7978 14.6479 17.2407 14.6479C18.1373 14.6479 18.9016 14.9639 19.5336 15.5958C20.1655 16.2278 20.4815 16.992 20.4815 17.8886C20.4815 18.7852 20.1655 19.5495 19.5336 20.1815C18.9016 20.8134 18.1373 21.1294 17.2407 21.1294ZM8.16666 10.759H17.2407V9.46271H8.16666V10.759Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_384_3320">
					<rect fill="white" height="15.5556" transform="translate(6.22223 6.22217)" width="15.5556" />
				</clipPath>
			</defs>
		</svg>
	),
};
