import useCardDeck from '@worklist-2/patientPortal/src/views/NewSettingsView/Components/CardStack/hooks/useCardDeck';
import Box from '@mui/material/Box';
import OaiPatientSyncNotification from '@worklist-2/patientPortal/src/components/Notifications/OaiPatientSyncNotification';
import React from 'react';
import CardContainer from './CardContainer';
import { CARD_HEIGHT, CARD_SPACING } from '../consts/consts';

const OaiSyncNotifications = ({
	oaiSyncPatientNotifications,
	clubNotifications,
	setClubNotifications,
	isNotificationCollapsed,
	setIsNotificationCollapsed,
	appointmentStatusChange,
}) => {
	const { cardsOrder, shown } = useCardDeck(oaiSyncPatientNotifications);

	const cardsTotal = clubNotifications?.length;

	return (
		<Box>
			{clubNotifications?.map((item, index) => {
				const order = cardsOrder[index];
				return (
					<CardContainer
						key={index}
						Card={OaiPatientSyncNotification}
						appointmentStatusChange={appointmentStatusChange}
						cardHeight={CARD_HEIGHT}
						cardSpacing={CARD_SPACING}
						collapsed={isNotificationCollapsed}
						data={item}
						id={index}
						isNotificationCollapsed={isNotificationCollapsed}
						order={order}
						setClubNotifications={setClubNotifications}
						setCollapsed={setIsNotificationCollapsed}
						shown={shown && order === 0}
						total={cardsTotal}
						onClick={event => {
							event.preventDefault();

							if (
								event?.target?.id !== 'accept-btn' &&
								event?.target?.id !== 'reject-btn' &&
								oaiSyncPatientNotifications?.length > 1
							) {
								setIsNotificationCollapsed(prevState => !prevState);
							}
						}}
					/>
				);
			})}
		</Box>
	);
};

export default OaiSyncNotifications;
