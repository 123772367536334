import axios from 'axios';

const getStudyImages = async ({ __config, studyUID, orgId, onDownloadProgress }) => {
	const response = await axios.get(
		`${__config.data_sources.blume}Study/${studyUID}/dicom?managingOrganizationId=${orgId}`,
		{ responseType: 'blob', onDownloadProgress }
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default getStudyImages;
