import { useConfig } from '@rs-core/context/ConfigContext';
import { scanTypes } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentUtils/AppointmentUtils';
import { menuProps } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/consts/consts';
import InputAdornment from '@mui/material/InputAdornment';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import FormSelectVariant from '@rs-ui/components/FormNew/formPartials/FormFieldVariants/variants/FormSelectVariant';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getOrgModality from '@worklist-2/patientPortal/src/api/getOrgModality';
import { useNewAppointmentContentV2Context } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/contexts/NewAppointmentContentV2ContextProvider';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const ScanType = () => {
	const __config = useConfig();
	const phoenixBlumeOaiPatientDemographicSync = useBooleanFlagValue('phoenix-blume-oai-patient-demographic-sync');
	const { t } = useTranslation('appointments');

	const { isUpdateForm, hasPendingCRs, form, formErrors, helperText } = useNewAppointmentContentV2Context();
	const [modality, setModality] = useState(scanTypes);

	useEffect(() => {
		(async () => {
			const organization = sessionStorage.getItem('organization');
			if (organization) {
				const orgModality = [];
				const result = await getOrgModality({ __config, orgId: organization });
				if (result && result.entry) {
					result.entry.forEach(item => {
						item?.resource?.extension?.forEach(ele => {
							if (ele?.url?.includes('StructureDefinition/modality')) {
								ele?.extension?.forEach(m => {
									if (!orgModality.includes(m.valueCode)) {
										orgModality.push(m.valueCode);
									}
								});
							}
						});
					});
				}
				if (orgModality.length) {
					const updatedModality = [];
					orgModality.forEach(item => {
						modality.forEach(ele => {
							const found = ele.DicomModalities.find(m => m === item);
							if (found) {
								updatedModality.push(ele);
							}
						});
					});
					setModality(updatedModality);
				}
			}
		})();
	}, []);

	return (
		<FormSelectVariant
			formHook={form}
			name="scanType"
			options={modality}
			props={{
				disabled: !!(isUpdateForm || (hasPendingCRs && phoenixBlumeOaiPatientDemographicSync)),
				label: t('Order Set'),
				helperText: formErrors?.scanType?.type === 'manual' ? formErrors.scanType?.message : helperText,
				SelectProps: {
					MenuProps: {
						...menuProps,
						onClick: () => form.clearErrors('scanType'),
					},
					renderValue: option => t(option?.Name),
				},
				InputProps: {
					startAdornment: (
						<InputAdornment
							position="start"
							sx={{
								'& .MuiSvgIcon-root': {
									opacity: 1.6,
								},
							}}
						>
							<MedicalServicesOutlinedIcon />
						</InputAdornment>
					),
				},
			}}
		/>
	);
};

export default ScanType;
