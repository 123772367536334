import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNewAppointmentContentV2Context } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/contexts/NewAppointmentContentV2ContextProvider';

const NextButton = ({ onNextClick, disabled }) => {
	const { t } = useTranslation('appointments');
	const { hasLocationPermission } = useNewAppointmentContentV2Context();

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				margin: '15px 0px !important',
				alignItems: 'center',
			}}
		>
			<div style={{ maxWidth: '65%' }}>
				{hasLocationPermission ? null : (
					<Typography style={{ fontSize: '10px', fontWeight: '400' }}>
						{t(
							'Note: You have not provided the location permission. Please add it from the browser settings!'
						)}
					</Typography>
				)}
			</div>
			<Button
				color="rsPrimary"
				data-testid="next"
				disabled={disabled}
				sx={{
					width: '110px',
					height: '42px',
					borderRadius: '8px',
				}}
				variant="contained"
				onClick={onNextClick}
			>
				<Typography
					style={{
						fontSize: '16px',
						fontWeight: '500',
						lineHeight: '19px',
						letterSpacing: '0.15em',
						textTransform: 'uppercase',
					}}
				>
					{t('NEXT')}
				</Typography>
			</Button>
		</Box>
	);
};

export default NextButton;
