import axios from 'axios';
import moment from 'moment-timezone';

import { useConfig } from '@rs-core/context/ConfigContext';
import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
import getExtensionValueString from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueString';

import { useNewAppointmentContentV2Context } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/contexts/NewAppointmentContentV2ContextProvider';
import { scanTypes } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentUtils/AppointmentUtils';
import { getTimeFromMins } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/utils/utils';

const useGetAvailableTimeSlots = () => {
	const __config = useConfig();

	const {
		form,
		isUpdateForm,
		appointment,
		setAvailableTimeSlots,
		setSelectedDay,
		setOrganizationTimeZone,
		setAppointmentSlotsLoading,
		rescheduleScanType,
		SetRescheduleScanType,
		rescheduleFacility,
		setRescheduleFacility,
		setPage,
	} = useNewAppointmentContentV2Context();

	const scanType = form.watch('scanType');
	const facility = form.watch('facility');

	const getAvailableTimeSlots = async date => {
		let selectedScanType = {};
		let selectedFacility = {};
		setAppointmentSlotsLoading(true);
		if (!isUpdateForm && !facility) {
			setPage('first');
			return;
		}
		if (isUpdateForm) {
			if (!rescheduleScanType) {
				selectedScanType = scanTypes.filter(item => item.Name == appointment?.type)[0];
				SetRescheduleScanType(selectedScanType);
			} else {
				selectedScanType = rescheduleScanType;
			}

			if (!rescheduleFacility) {
				// get healthcare service if from appointment
				const appointmentHealthCareServiceId = [
					appointment?.resource?.participant
						?.find(item => item?.actor?.reference?.toLowerCase().includes('healthcareservice'))
						?.actor.reference.split('/')[1],
				];

				if (appointmentHealthCareServiceId) {
					await axios
						.get(
							`${__config.data_sources.blume}Appointment/healthcareService?id=${appointmentHealthCareServiceId}&multipleIds=true`
						)
						.then(response => {
							if (response?.data?.entry) {
								const facilityIANATimeZone = getExtensionValueString(
									response?.data?.entry[0]?.resource?.providedBy,
									fhirExtensionUrls.organization.ianaTimezone
								);
								const facilityTimeZone = getExtensionValueString(
									response?.data?.entry[0]?.resource?.providedBy,
									fhirExtensionUrls.organization.timezone
								);

								selectedFacility = {
									organizationName: appointment?.organization,
									internalOrganizationID: appointment?.internalOrganizationID,
									ianaTimezone: facilityIANATimeZone,
								};

								if (facilityTimeZone !== Intl.DateTimeFormat().resolvedOptions().timeZone) {
									setOrganizationTimeZone(facilityTimeZone);
								}
								setRescheduleFacility(selectedFacility);
							}
						});
				}
			} else {
				selectedFacility = rescheduleFacility;
			}
		} else {
			selectedScanType = scanType;
			selectedFacility = facility;
		}
		setSelectedDay(date);
		await axios
			.get(
				`${
					__config.data_sources.blume
				}Appointment/findavailabletime?modality=${selectedScanType?.DicomModalities?.toString()}&internalOrganizationId=${
					selectedFacility?.internalOrganizationID
				}&date=${moment(date).format('YYYY-MM-DD')}`
			)
			.then(result => {
				const timeSlots = [];
				let currentSlot = 1;
				result?.data.forEach(eachSlot => {
					const timeSlot = {
						...eachSlot,
						id: currentSlot++,
						selected: false,
						time: `${getTimeFromMins(eachSlot.start)} - ${getTimeFromMins(eachSlot.end)}`,
					};
					timeSlots.push(timeSlot);
				});
				setAvailableTimeSlots(timeSlots);
			})
			.finally(() => {
				setAppointmentSlotsLoading(false);
			});
	};

	return {
		getAvailableTimeSlots,
	};
};

export default useGetAvailableTimeSlots;
