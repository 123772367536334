import { Box, Icon } from '@mui/material';
import React from 'react';

const EmptyIllustration = ({
	image,
	title,
	description,
	imageHeight,
	marginTop,
	titleSx = {},
	desSx = {},
	bottomContent,
}) => (
	<Box
		data-testid="empty"
		sx={{
			textAlign: 'center',
			overflowY: 'visible',
			overscrollBehavior: 'none',
			fontFamily: 'roboto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			margin: '10px auto',
			padding: '0px 20px',
			marginTop,
		}}
	>
		<Icon
			component={image}
			sx={{
				boxSizing: 'border-box',
				flex: 'none',
				height: imageHeight || '100%',
				width: 'auto',
				maxWidth: '90vw',
			}}
		/>
		<h3 style={{ color: '#12121280', fontSize: '20px', lineHeight: '100%', marginTop: '20px', ...titleSx }}>
			{title}
		</h3>
		<p style={{ color: '#12121260', lineHeight: '1.25em', marginTop: '-10px', ...desSx }}>{description}</p>
		{bottomContent || null}
	</Box>
);

export default EmptyIllustration;
