/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';

//mui
import TextField from '@mui/material/TextField';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';

import { SecondaryButton } from '@worklist-2/ui/src/components';
import AppointmentCalendar from './AppointmentCalendar';

import { useIsTablet, useIsMobile } from '@worklist-2/core/src/utils/responsiveUtils';
import { useTranslation } from 'react-i18next';
import noSlots from './assets/no_slots.png';
import CircularProgress from '@mui/material/CircularProgress';

import { useAppointmentContext } from '../Appointment/AppointmentContext/AppointmentContext';

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	const isTablet = useIsTablet();
	const isMobile = useIsMobile();

	return (
		<div
			aria-labelledby={`simple-tab-${index}`}
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			role="tabpanel"
			{...other}
		>
			{value === index && (
				<Box
					sx={{
						height: 360,
						width: isMobile || isTablet ? undefined : 420,
					}}
				>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

export const handleValidate = (validate, setAnchorEl, inputDateTime, setTabValue) => {
	if (validate) {
		const select = document.getElementById('datetimepicker');
		if (!select) return;
		setAnchorEl(select);
		if (inputDateTime) setTabValue(1);
	}
};

const AppointmentDateTimePicker = ({
	inputDateTime,
	setInputDateTime,
	validate,
	setValidate,
	getAvailableTimeSlots,
	availableTimeSlots,
	setAvailableTimeSlots,
	disabled,
	organizationTimeZone,
	slotsLoading,
	isValid,
	setInputDateTimeEng,
	isUpdateForm,
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popper' : undefined;
	const [tabValue, setTabValue] = useState(0);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedMonth, setSelectedMonth] = useState(new Date());
	const [selectedDayCurrent, setSelectedDayCurrent] = useState('');
	const [slotView, setSlotView] = useState(0);
	const [today] = useState(new Date());
	const isTablet = useIsTablet();
	const isMobile = useIsMobile();
	const { t, i18n } = useTranslation('appointments');

	const { selectedDay } = useAppointmentContext();

	const defaultValuesUpdated = useRef(false);

	const handleClick = event => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setTabValue(0);
	};

	useEffect(() => {
		handleValidate(validate, setAnchorEl, inputDateTime, setTabValue);
	}, [validate]);

	const handleSelectSlot = _selectedTime => {
		if (setInputDateTimeEng)
			setInputDateTimeEng(`${moment(selectedDate).format('dddd, MMMM D, YYYY')} ${_selectedTime}`);
		setInputDateTime(`${selectedDayCurrent} ${_selectedTime}`);
		setAnchorEl(null);
		setTabValue(0);
	};

	useEffect(() => {
		setSelectedDate(selectedDay < new Date() ? new Date() : selectedDay);
		setSelectedMonth(selectedDay < new Date() ? new Date() : selectedDay);
	}, [selectedDay]);

	const selectDay = day => {
		setTabValue(1);

		if (moment(day).isSame(selectedDate)) {
			return;
		}

		setSelectedDate(day);
	};

	useEffect(() => {
		if (selectedDayCurrent === '') setAvailableTimeSlots([]);

		const startDateTime = formatDate();

		setSelectedDayCurrent(startDateTime);
	}, [selectedDate]);

	useEffect(() => {
		const select = document.getElementById('datetimepicker');
		if (!select) return;
		setAnchorEl(select);
		setTabValue(0);
	}, []);

	useEffect(() => {
		if (selectedDayCurrent != '') {
			setInputDateTime(selectedDayCurrent);
			if (setInputDateTimeEng) setInputDateTimeEng(moment(selectedDate).format('dddd, MMMM D, YYYY'));
			getAvailableTimeSlots(moment(selectedDate).format('YYYY-MM-DD'));
		}
	}, [selectedDayCurrent]);

	useEffect(() => {
		availableTimeSlots.forEach(item => {
			if (item.selected === true) {
				handleSelectSlot(item.time);
			}
		});
	}, [availableTimeSlots]);

	useEffect(() => {
		if (isUpdateForm && inputDateTime && !defaultValuesUpdated.current) {
			const date = inputDateTime?.split(' -')[0];
			setSelectedDate(new Date(date));
			setSelectedMonth(new Date(date));
			defaultValuesUpdated.current = true;
		}
	}, [isUpdateForm, availableTimeSlots, inputDateTime]);

	const formatDate = useCallback(() => {
		const day = moment(selectedDate).format('dddd');
		const month = moment(selectedDate).format('MMMM');
		const date = moment(selectedDate).format('D');
		const year = moment(selectedDate).format('YYYY');

		const formattedDate = i18n?.language === 'fr' ? `le ${date}` : date;

		if (i18n?.language === 'fr') {
			return t('appointmentData', { day: t(day), date, month: t(month), year });
		}

		return `${t(day)}, ${t(month)} ${formattedDate}, ${year}`;
	}, [selectedDate, i18n, t]);

	const renderDay = day => (
		<AppointmentCalendar
			day={day}
			selectedDay={selectedDate}
			selectedMonth={selectedMonth}
			setSelectedDay={selectDay}
			today={today}
		/>
	);

	const selectTimeSlot = useCallback(
		slotid => {
			setValidate(false);
			const timeSlot = availableTimeSlots.map(item => {
				if (item.id === slotid) {
					item.selected = !item.selected;
				} else {
					item.selected = false;
				}
				return item;
			});
			setAvailableTimeSlots(timeSlot);
		},
		[availableTimeSlots]
	);

	const timeSlotSection = slot => (
		<Stack direction="row" spacing={5} sx={{ paddingTop: '10px' }}>
			<Button
				data-testid={`time-${slot.id}`}
				sx={{
					border: '1px solid #42A5F5',
					width: isMobile ? '139px' : isTablet ? '156px' : '178px',
					height: isMobile ? '32px' : undefined,
					padding: isMobile ? '0px' : '5px 14px',

					color: slot.selected ? '#FFFFFF' : '#42A5F5',
					bgcolor: slot.selected ? '#42A5F5' : undefined,
					fontSize: isMobile || isTablet ? '13px' : '14px',
					fontWeight: isMobile || isTablet ? 300 : 400,
					transition: 'unset',
					':hover': {
						bgcolor: '#42A5F5',
						color: '#FFFFFF',
					},
				}}
				variant="outlined"
				onClick={() => {
					selectTimeSlot(slot.id);
				}}
			>
				{slot.time}
			</Button>
		</Stack>
	);

	const displayDate = () => {
		const day = moment(selectedDate).format('ddd');
		const month = moment(selectedDate).format('MMMM');
		const date = moment(selectedDate).format('D');

		return `${t(day)}, ${t(month)} ${date}`;
	};

	const TimePicker = () => (
		<Box>
			<Grid
				container
				sx={{
					marginLeft: isMobile ? '20px' : '10px',
					marginTop: isMobile ? '10px' : '20px',
					marginRight: '10px',
				}}
			>
				<Grid item xs={2}>
					<IconButton>
						<ArrowBackIcon
							data-testid="go-back-btn"
							sx={{ color: '#42A5F5' }}
							onClick={() => {
								setTabValue(0);
								setSlotView(0);
							}}
						/>
					</IconButton>
				</Grid>
				<Grid item sx={{ paddingTop: '10px', paddingLeft: '5px' }} xs={7}>
					<Typography sx={{ color: '#42A5F5' }}>{displayDate()}</Typography>
				</Grid>
				{availableTimeSlots?.length > 12 && (
					<Grid container item xs={2}>
						<Grid xs={6}>
							<IconButton disabled={slotView === 0}>
								<KeyboardArrowLeftIcon
									onClick={() => {
										setSlotView(slotView - 12);
									}}
								/>
							</IconButton>
						</Grid>
						<Grid xs={6}>
							<IconButton disabled={slotView === 12} sx={{ paddingLeft: '10px', marginLeft: '5px' }}>
								<KeyboardArrowRightIcon
									onClick={() => {
										setSlotView(slotView + 12);
									}}
								/>
							</IconButton>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Typography
				sx={{
					paddingTop: '10px',
					paddingLeft: isMobile ? '30px' : '38px',
				}}
			>
				{t('Available Time')}
				{organizationTimeZone && ` (${organizationTimeZone})`}
			</Typography>
			{slotsLoading ? (
				<Box sx={{ display: 'grid', placeItems: 'center', height: 300 }}>
					<CircularProgress
						className="progressBar"
						size={22}
						sx={{
							color: '#03dac5',
						}}
					/>
				</Box>
			) : !availableTimeSlots?.length ? (
				<Box sx={{ display: 'grid', placeItems: 'center', margin: '10px' }}>
					<img alt="" height="180px" src={noSlots} style={{ marginTop: '20px' }} />
					<Box sx={{ textAlign: 'center', marginTop: '20px' }}>
						<p style={{ lineHeight: '50%' }}>
							<b>No available time slots for this day</b>
						</p>
						<p style={{ lineHeight: '50%', fontSize: 14 }}>Please select another date</p>
					</Box>
				</Box>
			) : (
				<Grid
					container
					gap={isMobile || isTablet ? 1 : 3}
					sx={{
						marginTop: '10px',
						paddingBottom: '20px',
						justifyContent: 'center',
					}}
				>
					<Box>{availableTimeSlots.slice(slotView, slotView + 6).map(slot => timeSlotSection(slot))}</Box>
					<Box>
						{availableTimeSlots.slice(slotView + 6, slotView + 12).map(slot => timeSlotSection(slot))}
					</Box>
				</Grid>
			)}
		</Box>
	);

	const ResetDoneButtons = () => (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				paddingLeft: '16px',
				paddingRight: '16px',
				pb: isMobile ? '20px' : '30px',
				pt: tabValue === 0 ? '0px' : isMobile ? '0px' : '32px',
			}}
		>
			<SecondaryButton label={t('Cancel')} placement="bottom-left" testid="resetDateTime" onClick={handleClose} />
			{/* UNCOMMENT THIS IF YOU NEED THE SELECT BUTTON IN FUTURE */}
			{/* <PrimaryButton
				label={t('Select')}
				testid="saveDateTime"
				placement="bottom-right"
				disabled={!isTimeSlotSelected}
				onClick={handleSelectSlot}
			/> */}
		</Box>
	);

	const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const locale = {
		localize: {
			day: n => t(days[n]),
			month: n => t(months[n]),
		},
		formatLong: {
			date: () => 'MMM d, yyyy',
		},
	};

	const width = useMemo(() => {
		const eles = document.querySelectorAll(
			'.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd'
		);
		if (eles.length) {
			const styles = window.getComputedStyle(eles[0]);
			const totalWidth = eles[0].offsetWidth + parseInt(styles.marginLeft) + parseInt(styles.marginRight);
			return totalWidth;
		}
		return null;
	}, []);

	return (
		<>
			<TextField
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<CalendarMonthOutlinedIcon />
						</InputAdornment>
					),
					endAdornment: (
						<IconButton edge="end">
							<ArrowDropDownIcon />
						</IconButton>
					),
				}}
				data-cy="dateTimeLabelField"
				disabled={Boolean(disabled)}
				error={!!validate}
				helperText={isValid ? t('Required') : t('Please select slot')}
				id="datetimepicker"
				label={t('Appointment Date and Time')}
				sx={{
					width: '100%',
				}}
				value={inputDateTime}
				onClick={handleClick}
			/>
			<Popover
				PaperProps={{
					sx: {
						marginLeft: !isMobile && -1.7,
						height: isMobile ? '420px' : undefined,
						width: isMobile ? '100%' : width ? `${width}px` : undefined,
					},
				}}
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				id={id}
				open={open}
				onClose={handleClose}
			>
				<TabPanel index={0} value={tabValue}>
					<Box
						sx={{
							marginLeft: '30px',
							marginRight: '20px',
							'& .MuiCalendarPicker-root': {
								width: '100%',
								'& > div': {
									'&:first-of-type': {
										paddingLeft: '10px',
										paddingRight: '0px',
										'& > div > div': {
											fontSize: '20px',
											fontWeight: '400',
											letterSpacing: '0.15px',
										},
									},
									'&:last-child': {
										'& > div': {
											justifyContent: 'space-between',
										},
									},
								},
							},
							'& .PrivatePickersFadeTransitionGroup-root': {
								fontSize: '20px',
								lineHeight: '23px',
								letterSpacing: '0.15px',
								color: '#121212',
								'& > div > div': {
									justifyContent: 'space-between',
								},
								'&.MuiSvgIcon-root': {
									display: 'none',
								},
							},
							'& .PrivatePickersSlideTransition-root': {
								minHeight: '244px',
								'& > div': {
									paddingBottom: '2px',
									'& > div': {
										justifyContent: 'space-between',
									},
								},
							},
							'& .MuiTypography-root': {
								fontSize: '16px',
								color: '#12121299',
								width: '42px',
							},
						}}
					>
						<LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
							<CalendarPicker
								disablePast
								date={selectedDate}
								renderDay={renderDay}
								views={['year', 'day']}
								onChange={selectDay}
								onMonthChange={setSelectedMonth}
							/>
						</LocalizationProvider>
					</Box>
				</TabPanel>
				<TabPanel index={1} value={tabValue}>
					<TimePicker />
				</TabPanel>

				<Grid>
					<ResetDoneButtons />
				</Grid>
			</Popover>
		</>
	);
};
export default AppointmentDateTimePicker;
