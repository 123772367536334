import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useIsMobile } from '@rs-core/utils/responsiveUtils';
import { useNewAppointmentContentV2Context } from '../../contexts/NewAppointmentContentV2ContextProvider';
import NextButton from '../../components/NextButton';
import Facility from './Facility';
import Patient from './Patient';
import ScanType from './ScanType';
import UserLocation from './UserLocation';
import BirthDate from './BirthDate';
import Gender from './Gender';
import GoogleMap from './GoogleMap';
import GoogleMapBackButton from './GoogleMapBackButton';
import OAISyncNotifications from './OAISyncNotifications';
import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const FirstPage = () => {
	const isMobile = useIsMobile();
	const phoenixBlumeOaiPatientDemographicSync = useBooleanFlagValue('phoenix-blume-oai-patient-demographic-sync');
	const { t } = useTranslation('notifications');

	const {
		mapFullScreen,
		oaiSyncPatientNotifications,
		// isGenderAvailable,
		isDateOfBirthAvailable,
		onNextClick,
		disableNext,
		hasPendingCRs,
	} = useNewAppointmentContentV2Context();

	return (
		<Box>
			<Stack
				flexGrow={1}
				spacing={1.2}
				sx={{
					px: !isMobile ? '32px' : undefined,
					pt: '30px',
					overflow: 'auto',
				}}
			>
				{phoenixBlumeOaiPatientDemographicSync && oaiSyncPatientNotifications?.length ? (
					<>
						<OAISyncNotifications />
						{hasPendingCRs && (
							<p
								data-testid="pending-cr-msg"
								style={{
									marginTop: -3,
									marginBottom: '10px',
									color: '#CF6679',
									fontFamily: 'roboto',
									fontSize: 14,
									textAlign: 'center',
								}}
							>
								{t('Please, accept or reject the changes before continuing the booking')}
							</p>
						)}
					</>
				) : null}

				<Stack flexGrow={1} spacing={1.2} sx={{ width: '100%' }}>
					<Patient />

					{!isDateOfBirthAvailable && <BirthDate />}

					{/* {!isGenderAvailable && <Gender />} */}

					<ScanType />

					<UserLocation />

					<Facility />
				</Stack>

				<GoogleMap />

				{mapFullScreen && <GoogleMapBackButton />}

				<NextButton disabled={hasPendingCRs || disableNext} onNextClick={onNextClick} />
			</Stack>
		</Box>
	);
};

export default FirstPage;
