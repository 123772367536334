import moment from 'moment-timezone';

export const getTimezoneOffset = timeZone => moment.tz(timeZone).utcOffset() / 60;

export const getTimezoneWithOffset = timeZone => {
	const offset = getTimezoneOffset(timeZone);
	const hours = Math.floor(Math.abs(offset));
	const minutes = Math.round((Math.abs(offset) * 60) % 60);

	const formattedHours = hours.toString().padStart(2, '0');
	const formattedMinutes = minutes.toString().padStart(2, '0');

	const sign = offset >= 0 ? '+' : '-';

	return timeZone ? `${timeZone} (UTC${sign}${formattedHours}:${formattedMinutes})` : '';
};

export default getTimezoneWithOffset;
