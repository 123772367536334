import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { searchScopes } from '@rs-core/context/consts/searchScopes';
import { useConfig } from '@rs-core/context/ConfigContext';
import { useAuth } from '@rs-core/context/UserAuthContext';
import { mediaTablet, useIsTablet, useIsMobile } from '@rs-core/utils/responsiveUtils';
import { useAppModeContext } from '@rs-core/context/AppModeContext';
import useHelpCenter from '@rs-core/hooks/useHelpCenter';
import fhirEndpoints from '@rs-core/fhir/fhirEndpoints';
import { useTabContext } from '@rs-core/context/TabContext';
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';

import { useSearchScope } from '@rs-core/context/SearchScopeContext';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { styled } from '@mui/material/styles';

import GenericDrawer from '@worklist-2/ui/src/components/Drawers/GenericDrawer';
import Toast from '@worklist-2/ui/src/components/Toast';
import ShareContactList from '@worklist-2/patientPortal/src/components/ShareContact/ShareContactList';
import Alert from '@mui/material/Alert';
import MobileSnackbar from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileSnackbar';
import MobileBottomDrawer from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import MobileStudyDetail from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetail';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-relative-packages
import searchApps from '../../../../../utils/marketplace/searchApps';
import { useGoToUser } from '../SearchResults/SearchResultItem';
import DicomForm from '../DicomForm';
import AutoSuggestDialog from '../AutoSuggestDialog';
import ClearIcon from '../../assets/icons/clearIcon.svg';
import SearchDialog from '../SearchDialog';
import { useDrawersStore } from '@worklist-2/patientPortal/src/stores';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const marketplaceCategoryOptions = [
	{
		hash: '#all',
		fullCategory: 'All',
	},
	{
		hash: '#visualization',
		fullCategory: 'Advanced Visualization',
	},
	{
		hash: '#reporting',
		fullCategory: 'Reporting',
	},
	{
		hash: '#workflow',
		fullCategory: 'Workflow Optimization',
	},
	{
		hash: '#ai',
		fullCategory: 'Clinical Support (AI)',
	},
];

const SearchBar = ({
	children,
	placeholder,
	setParentData,
	additionalSearchParams,
	initialValue,
	setOpenDrawer,
	setIsOpen,
}) => {
	const { setSearchResults } = useHelpCenter();
	const isMounted = useRef(false);
	const fetchTimeout = useRef(null);
	const [dialog, setDialog] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [dicomSearchText, setDicomSearchText] = useState('');
	const [isDicomDialog, setDicomDialog] = useState(false);
	const [organizations, setOrganizations] = useState([]);
	const [devices, setDevices] = useState([]);
	const [organization, setOrganization] = useState(null);
	const [device, setDevice] = useState(null);
	const ref = useRef(null);
	const lastSearchedData = useRef({ scope: '', value: '' });
	const savedDuringLoad = useRef(0);
	const dicomRef = useRef(null);
	const { loggedInUserId, isLoadingUserId, goToUser } = useGoToUser();
	const [fieldValuesObj, setFieldValuesObj] = useState({
		'Anatomic Focus': [],
		Gender: [],
		Modality: [],
		'Study Priority': [],
		'Study Status': [],
	});
	const [checkedFields, setCheckedFields] = useState([1, 2, 3]);

	const [loading, setLoading] = useState(0);
	const [isRecentSearch, setIsRecentSearch] = useState(false);
	const [showSearchIcon, setShowSearchIcon] = useState(false);
	const [data, setData] = useState(null);
	const { instance, accounts, inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const [showDialogHeader, setShowDialogHeader] = useState(true);
	const { isPatientPortalMode, isWorklistMode, isMarketplaceMode, isHelpCenterMode, getItemCountRecentSearch } =
		useAppModeContext();
	const appModeIsWorklist = isWorklistMode();
	const appModeIsPatientPortal = isPatientPortalMode();
	const appModeIsMarketplace = isMarketplaceMode();
	const appModeIsHelpCenter = isHelpCenterMode();
	const isTablet = useIsTablet();
	const isMobile = useIsMobile();
	const [studySharedId, setStudySharedId] = useState();
	const [toastMsg, setToastMsg] = useState();
	const [snackbar, setSnackbar] = useState();
	const [isSharedType, setIsSharedType] = useState('');
	const [studyInDetailedView, setStudyInDetailedView] = useState(null);
	const proactIncludeMarketplaceInGlobalSearchAll = useBooleanFlagValue(
		'proact-include-marketplace-in-global-search-all'
	);
	const { t } = useTranslation('Search');
	const { selectedLanguage, globalPermissionsForLoggedUser } = useAuth();
	const { searchTextFromStore, mobileSearchDrawerOpen, setMobileSearchText } = useDrawersStore(state => ({
		searchTextFromStore: state.searchText,
		setMobileSearchText: state.setMobileSearchText,
		mobileSearchDrawerOpen: state.mobileSearchDrawerOpen,
	}));
	const isBlumeHomePage = searchTextFromStore?.length > 0 || mobileSearchDrawerOpen;

	useEffect(() => {
		const id = setTimeout(() => {
			if (isTablet) {
				setMobileSearchText(searchText);
			}
		}, 500);

		return () => {
			clearTimeout(id);
		};
	}, [searchText]);

	useEffect(() => {
		if (isBlumeHomePage) {
			setSearchText(searchTextFromStore);
			setDicomSearchText(searchTextFromStore);
			if (searchTextFromStore) {
				setDialog(true);
			}
		}

		if (!isMobile && !isTablet) {
			if (searchTextFromStore?.length === 0 && setIsOpen) {
				setIsOpen(false);
			}
		}
	}, [searchTextFromStore, isMobile, isTablet]);

	const {
		scope,
		fhirLoader,
		setLoading: setScopeLoading,
		loading: scopeLoading,
		handleSaveRecentlyViewed,
	} = useSearchScope();
	const __searchScopeContext = useSearchScope();
	const isDicomScope = __searchScopeContext?.scope === 'searchDicom';

	const __config = useConfig();

	const {
		updateSearchTabs,
		updateTabVisibility,
		setSearchData,
		updatePlaceholder,
		setValidOrganizations,
		setInternalPatients,
	} = useTabContext();
	const fhirDataLoader = useFhirDataLoader({
		scope,
		isGlobal: true,
	});

	const orgDataLoader = useFhirDataLoader({
		endpoint: fhirEndpoints.organization,
	});

	const deviceDataLoader = useFhirDataLoader({
		endpoint: fhirEndpoints.device,
	});

	const [, setDataLoader] = useState(fhirDataLoader);

	const navigate = useNavigate();

	const addResourceTypeToApps = apps =>
		_.map(apps, app => ({
			...app,
			resourceType: 'MarketplaceApp',
		}));

	const permissions = useMemo(
		() => ({
			study: globalPermissionsForLoggedUser?.SearchStudy,
			patient: globalPermissionsForLoggedUser?.SearchPatient,
			order: globalPermissionsForLoggedUser?.SearchOrder,
			visit: false,
			organization: globalPermissionsForLoggedUser?.SearchOrganization,
			user: globalPermissionsForLoggedUser?.SearchUser,
			marketplace: globalPermissionsForLoggedUser?.SearchMarketplace,
			help: globalPermissionsForLoggedUser?.SearchHelp,
		}),
		[globalPermissionsForLoggedUser]
	);

	const fetchData = async (loader, value) => {
		try {
			const marketplaceSearchParams = `?_count=10&name=${searchText}${
				additionalSearchParams?.category ? `&categories=${additionalSearchParams.category}` : ''
			}${additionalSearchParams?.vendor ? `&author=${additionalSearchParams.vendor}` : ''}`;

			if (scope === searchScopes.marketplace) {
				setLoading(p => p + 1);

				searchApps({
					config: __config,
					queryParamString: marketplaceSearchParams,
				})
					.then(res => addResourceTypeToApps(res))
					.then(res => {
						setData(res);
						setSearchData(res);
					})
					.finally(() => {
						setLoading(p => p - 1);
					});
			} else {
				let fnName = 'load';
				let bodyData;

				if (!loader) {
					if (scope === searchScopes.all && !isBlumeHomePage) {
						loader = fhirLoader;
						fnName = '__op__load';
						bodyData = getSearchAllBody(encodeURIComponent(searchText), permissions);
					} else if (scope === searchScopes.patientPortalAll || isBlumeHomePage) {
						loader = fhirLoader;
						fnName = '__op__load';
						bodyData = getPatientPortalSearchAllBody(encodeURIComponent(searchText));
					} else {
						loader = fhirDataLoader;
					}
				}

				if (isAuthenticated) {
					setLoading(p => p + 1);

					try {
						Promise.all([
							loader[fnName](
								value ?? { content: searchText },
								!!bodyData || undefined,
								bodyData,
								appModeIsPatientPortal ? __config.data_sources.blume : undefined
							),
							scope === searchScopes.all &&
							(proactIncludeMarketplaceInGlobalSearchAll || appModeIsMarketplace)
								? searchApps({
										config: __config,
										queryParamString: marketplaceSearchParams,
								  })
								: Promise.resolve(),
						]).then(values => {
							const result = values[0];
							const apps = values[1];

							let newData =
								scope === searchScopes.all || scope === searchScopes.patientPortalAll
									? _.filter(
											_.flatMap(result?.entry ? result?.entry : [], ({ resource }) =>
												_.map(resource?.entry, e => {
													const item = e.resource;
													item.eTag = e.response?.eTag;
													return item;
												})
											),
											itemValue => !!itemValue
									  )
									: result;
							if (!isPatientPortalMode) {
								getValidOrganizations();
								getInternalPatients();
							}

							if (apps?.length > 0) {
								newData = newData.concat(addResourceTypeToApps(apps));
							}

							if (appModeIsWorklist) {
								_.remove(newData, item => item.ResourceType === 'helpcenter');
							}

							if (savedDuringLoad.current) {
								if (
									lastSearchedData.current.scope === scope &&
									lastSearchedData.current.value === searchText
								) {
									setSearchData(newData);
								}

								savedDuringLoad.current = Math.max(0, savedDuringLoad.current - 1);
							}

							setTimeout(() => {
								{
									setData(newData);
								}
								setTimeout(() => {
									setLoading(p => Math.max(p - 1, 0));
								}, 0);
							}, 0);
						});
					} catch (e) {
						savedDuringLoad.current = Math.max(0, savedDuringLoad.current - 1);
						setLoading(p => Math.max(p - 1, 0));
						console.error(e);
					}
				}
			}
		} catch (e) {
			console.error('Error while doing search', e);
		}
	};

	const setLoader = searchContext => {
		fhirDataLoader.updateScope({
			scope: searchContext.scope,
			endpoint: searchContext.endpoint,
		});
		setDataLoader(fhirDataLoader);
		return fhirDataLoader;
	};

	const getValidOrganizations = () => {
		axios
			.get(
				`${__config.data_sources.fhir}/ValidOrgsFromCache/UACCache-${loggedInUserId}-ValidManagingOrganizations-Patient`
			)
			.then(resp => {
				if (resp?.status === 200) {
					setValidOrganizations(String(resp.data).split(','));
				}
			})
			.catch(e => {
				console.error(e);
			});
	};

	const getInternalPatients = () => {
		axios
			.get(`${__config.data_sources.fhir}/patient/attestation`)
			.then(resp => {
				if (resp?.status === 200) {
					setInternalPatients(String(resp.data).split(','));
				}
			})
			.catch(e => {
				console.error(e);
			});
	};

	useEffect(() => {
		const updateLoader = async () => {
			if (inProgress !== InteractionStatus.None && accounts.length > 0) {
				setLoader(__searchScopeContext);
			}
		};
		updateLoader().catch(console.error);
	}, [inProgress, instance, accounts, __searchScopeContext]);

	useEffect(() => {
		if (searchText && searchText !== initialValue && placeholder !== 'Dicom') {
			if (appModeIsPatientPortal) {
				setShowDialogHeader(true);
				updatePlaceholder('All');
				setData([]);
			}
			if (scope === searchScopes.helpElk) {
				fetchData(null, { content: searchText, appname: 'oai', endpoint: selectedLanguage }).catch(
					console.error
				);
			} else {
				fetchData().catch(console.error);
			}
		} else {
			setLoading(p => Math.max(p - 1, 0));
		}
	}, [searchText]);

	useEffect(() => {
		if (isDicomDialog && data) {
			if (organization) {
				setDevices(data);
			} else {
				setOrganizations(data);
			}
		} else if (!isDicomDialog && isRecentSearch && appModeIsMarketplace && !!data) {
			navigate(`/marketplace/appdirectory?name=${searchText}`);
			if (setParentData) {
				setParentData(data);
			}

			setIsRecentSearch(false);
		} else if (isRecentSearch && appModeIsPatientPortal && !!data) {
			setIsRecentSearch(false);
			setShowDialogHeader(false);
		} else if (isRecentSearch && (appModeIsWorklist || appModeIsHelpCenter) && !!data) {
			if (scope === searchScopes.helpElk) {
				openArticlesSearchResult();
			} else {
				setTabsValue();
			}
			setIsRecentSearch(false);
		}
	}, [data]);

	useEffect(() => {
		setDicomDialog(placeholder === t('Dicom'));
		setShowSearchIcon(placeholder !== t('Dicom'));
		setData([]);
		updatePlaceholder(placeholder || 'All');
	}, [placeholder]);

	useEffect(() => {
		if (searchText === '' && appModeIsMarketplace) {
			setSearchText(initialValue);
		}
		document.addEventListener('click', handleClickOutside, true);
		isMounted.current = true;
		return () => {
			isMounted.current = false;
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	const handleClickOutside = event => {
		if (ref.current && !ref.current.contains(event.target)) {
			setShowSearchIcon(true);
			if (!appModeIsPatientPortal) {
				closeSearchDialog();
			}
		}
	};

	useEffect(() => {
		setSearchResults(data);
	}, [data]);

	const openSearchDialog = () => {
		if (placeholder === 'Dicom') {
			setDicomDialog(true);
		} else {
			setDataLoader(fhirDataLoader);
			setDialog(true);
			setShowSearchIcon(true);
		}
	};

	const closeSearchDialog = () => {
		setDialog(false);
		if (setIsOpen) setIsOpen(false);
		if (setOpenDrawer) setOpenDrawer(false);
	};

	const handleChange = event => {
		if (!dialog && event.target.value && !isDicomScope) setDialog(true);
		setSearchText(event.target.value);
	};

	const clearSearchInput = e => {
		if (dicomSearchText && isDicomScope) {
			e?.preventDefault();
			e?.stopPropagation();
			setDevices([]);
			setOrganizations([]);

			setDevice(null);
			setOrganization(null);

			setDicomSearchText('');
			dicomRef.current?.resetFields();
			setDicomDialog(true);
		} else if (!isDicomScope) {
			setSearchText('');

			if (appModeIsMarketplace && _.includes(window.location.pathname, 'appdirectory')) {
				navigate(
					`/marketplace/appdirectory${
						additionalSearchParams?.vendor ? `?author=${additionalSearchParams.vendor}` : ''
					}${
						additionalSearchParams?.category
							? `#${
									_.find(
										marketplaceCategoryOptions,
										option => option.fullCategory === additionalSearchParams.category
									).hash
							  }`
							: ''
					}`
				);
				searchApps({
					config: __config,
					queryParamString: `?_count=10${
						additionalSearchParams?.category ? `&categories=${additionalSearchParams.category}` : ''
					}${additionalSearchParams?.vendor ? `&author=${additionalSearchParams.vendor}` : ''}`,
				})
					.then(res => addResourceTypeToApps(res))
					.then(res => {
						if (setParentData) {
							setParentData(res);
						}
					});
			}
		}
	};

	const showResultList = () => {
		setShowDialogHeader(false);
	};

	const handleInputSaved = event => {
		if (loading) savedDuringLoad.current += 1;

		event.preventDefault();
		if (searchText) {
			const recentSearch = localStorage.getItem('recentSearch');
			const item = {
				type: scope,
				content: searchText,
			};

			if (recentSearch) {
				let arrays = JSON.parse(recentSearch);
				let isContain;
				const arraysByType = _.filter(arrays, elem => elem.type === item.type) || [];

				if (arraysByType.length > 0) {
					isContain = _.find(arrays, elem => elem.content === item.content && elem.type === item.type);
				}

				if (!isContain) {
					if (arraysByType.length > getItemCountRecentSearch() - 1) {
						arraysByType.pop();
					}
					arraysByType.unshift(item);

					_.remove(arrays, elem => elem.type === item.type);

					arrays = _.union(arrays, arraysByType);

					localStorage.setItem('recentSearch', JSON.stringify(arrays));
				}
			} else {
				const arrays = [item];
				localStorage.setItem('recentSearch', JSON.stringify(arrays));
			}

			if (
				lastSearchedData.current.scope &&
				!isDicomScope &&
				(lastSearchedData.current.value !== searchText || lastSearchedData.current.scope !== scope)
			) {
				if (scope === searchScopes.helpElk) {
					fetchData(null, {
						content: searchText,
						appname: 'oai',
						endpoint: selectedLanguage,
					}).catch(console.error);
				} else {
					fetchData().catch(console.error);
				}
				savedDuringLoad.current += 1;
				setScopeLoading(true);
			}

			lastSearchedData.current = {
				scope,
				value: searchText,
			};
			if (appModeIsPatientPortal) {
				setShowDialogHeader(false);
				updatePlaceholder(placeholder);
			} else if (scope === searchScopes.marketplace) {
				navigate(
					`/marketplace/appdirectory?name=${searchText}${
						additionalSearchParams?.vendor ? `&author=${additionalSearchParams.vendor}` : ''
					}`
				);

				if (setParentData) {
					setParentData(data); // Update and stay in App Directory with search results
				}
				closeSearchDialog();
			} else if (scope === searchScopes.helpElk) {
				openArticlesSearchResult();
				closeSearchDialog();
			} else {
				setTabsValue();
				closeSearchDialog();
			}

			const shouldBeLoading = Boolean(loading || !!fetchTimeout.current);
			if (!scopeLoading && shouldBeLoading) {
				setScopeLoading(shouldBeLoading);
			}
		}
	};

	const openArticlesSearchResult = () => {
		navigate('/help/articlesresult');
		setSearchData(data);
	};

	const setTabsValue = () => {
		const tabValue = placeholder || 'All';
		updateSearchTabs(tabValue === 'Practitioner' ? 'User' : tabValue);
		updateTabVisibility(true);
		setSearchData(data);
	};

	const onOrganizationChange = async (e, value) => {
		e?.preventDefault();

		setDevices([]);
		setOrganization(null);

		if (value) {
			const loader = orgDataLoader;
			await fetchData(loader, {
				summary: 'Text',
				name: value,
				privileges: 'DICOM Search',
				page: 1,
				isManaging: true,
			});
		}
	};

	const fetchDevices = async value => {
		if (value.id) {
			setDevices([]);
			setOrganization(value);
			const loader = deviceDataLoader;
			await fetchData(loader, {
				summary: true,
				deviceType: 'dicom-entity,dicom-web',
				page: 1,
				managingorganization: value.id,
				features: 'QueryRetrieve',
				status: 'active',
				'associateddevice:missing': 'false',
			});
		}
	};

	const onDeviceChange = async value => {
		if (organization) {
			setDevices([]);
			const loader = deviceDataLoader;
			await fetchData(loader, {
				deviceName: value,
				summary: true,
				deviceType: 'dicom-entity,dicom-web',
				page: 1,
				managingorganization: organization.id,
				features: 'QueryRetrieve',
				status: 'active',
			});
		}
	};

	const setDicomTexts = value => {
		setDicomSearchText(value);
	};

	const onDicomSearchFinished = results => {
		lastSearchedData.current = {
			scope,
			value: searchText,
		};
		setSearchData(results);
		setScopeLoading(false);
	};

	const onDicomSearchStarted = () => {
		updateSearchTabs('Study');
		updateTabVisibility(true);
		closeDicomDialog();
		setScopeLoading(true);
	};

	const closeDicomDialog = () => {
		setDicomDialog(false);
	};

	useEffect(() => {
		setTimeout(() => {
			if (scopeLoading && !loading) setScopeLoading(false);
		}, 0);
	}, [!!loading]);

	const getToastChildren = message => (
		<Alert
			color="info"
			icon={<CheckIcon fontSize="inherit" />}
			severity="success"
			sx={{
				borderRadius: '20px',
				background: '#FFFFFF',
				boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
			}}
		>
			{message}
		</Alert>
	);
	const getShareContactComponent = useCallback(
		() => (
			<ShareContactList
				setIsOpenShare={setStudySharedId}
				setSnackbar={setSnackbar}
				setToastMsg={setToastMsg}
				studySharedId={studySharedId}
			/>
		),
		[studySharedId]
	);

	return (
		<>
			{isTablet && appModeIsPatientPortal && (
				<>
					<GenericDrawer
						children={getShareContactComponent()}
						headerFlag
						drawerColor="rsSecondary.medium"
						drawerOpen={!!studySharedId}
						setDrawerOpen={setStudySharedId}
						title="SHARE"
						width={422}
					/>
					<Toast
						children={getToastChildren(toastMsg)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						customStyle={{ left: 'auto', marginLeft: '14px' }}
						duration={5000}
						open={!!toastMsg}
						onClose={() => setToastMsg(false)}
					/>
				</>
			)}
			{(isMobile || isTablet) && appModeIsPatientPortal && (
				<>
					<MobileBottomDrawer
						background="#ffffff"
						open={Boolean(studyInDetailedView)}
						onClose={() => setStudyInDetailedView(null)}
					>
						<MobileStudyDetail
							data={studyInDetailedView}
							isShared={isSharedType}
							onShareClick={() => setStudySharedId(studyInDetailedView.study.id)}
						/>
					</MobileBottomDrawer>
					<MobileSnackbar message={snackbar} open={!!snackbar} onClose={() => setSnackbar(false)} />
				</>
			)}
			{isDicomDialog && (
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100vw',
						height: '100vh',
						zIndex: '998',
					}}
					onClick={closeDicomDialog}
				/>
			)}
			<SearchBarDiv
				ref={ref}
				appModeIsPatientPortal={appModeIsPatientPortal}
				isBlumeHomePage={isBlumeHomePage && !isTablet}
				searchText={searchText}
			>
				{isDicomDialog && (
					<div className="dicom-dialog" style={{ zIndex: '999' }}>
						<DicomForm
							ref={dicomRef}
							checkedFields={checkedFields}
							closeDicomDialog={closeDicomDialog}
							device={device}
							devices={devices}
							fetchDevices={fetchDevices}
							fieldValuesObj={fieldValuesObj}
							handleChange={onOrganizationChange}
							handleDeviceChange={onDeviceChange}
							handleDicomSearch={onDicomSearchFinished}
							isLoading={!!loading}
							organization={organization}
							organizations={organizations}
							setCheckedFields={setCheckedFields}
							setDevice={setDevice}
							setDicomTexts={setDicomTexts}
							setFieldValuesObj={setFieldValuesObj}
							onSearchStarted={onDicomSearchStarted}
						/>
					</div>
				)}

				{dialog && !searchText && !isDicomDialog && (
					<SearchDialog
						closeSearchDialog={closeSearchDialog}
						isLoadingUserId={isLoadingUserId}
						loggedInUserId={loggedInUserId}
						scope={scope}
						setIsRecentSearch={setIsRecentSearch}
						setIsSharedType={setIsSharedType}
						setSearchText={setSearchText}
						setStudyInDetailedView={setStudyInDetailedView}
						onGoToUser={goToUser}
					/>
				)}
				{searchText &&
					dialog &&
					!isDicomDialog &&
					(appModeIsWorklist || appModeIsMarketplace || appModeIsHelpCenter) && (
						<AutoSuggestDialog
							additionalSearchParams={additionalSearchParams}
							closeSearchDialog={closeSearchDialog}
							data={data}
							handleSaveRecentlyViewed={handleSaveRecentlyViewed}
							isLoadingUserId={isLoadingUserId}
							loggedInUserId={loggedInUserId}
							openArticlesSearchResult={openArticlesSearchResult}
							scope={scope}
							setParentData={setParentData}
							setSearchText={setSearchText}
							setTabsValue={setTabsValue}
							showDialogHeader={showDialogHeader}
							value={searchText}
							onGoToUser={goToUser}
						/>
					)}
				{searchText && dialog && appModeIsPatientPortal && (
					<AutoSuggestDialog
						additionalSearchParams={additionalSearchParams}
						closeSearchDialog={closeSearchDialog}
						data={data}
						handleSaveRecentlyViewed={handleSaveRecentlyViewed}
						isBlumeHomePage={isBlumeHomePage}
						isLoadingUserId={isLoadingUserId}
						loggedInUserId={loggedInUserId}
						openArticlesSearchResult={openArticlesSearchResult}
						scope={scope}
						setIsSharedType={setIsSharedType}
						setParentData={setParentData}
						setSearchText={setSearchText}
						setShowDialogHeader={setShowDialogHeader}
						setStudyInDetailedView={setStudyInDetailedView}
						setTabsValue={setTabsValue}
						showDialogHeader={showDialogHeader}
						showResultList={showResultList}
						value={searchText}
						onGoToUser={goToUser}
					/>
				)}

				{showSearchIcon && placeholder !== 'Dicom' && !(isBlumeHomePage && !isTablet) && (
					<IconButton
						aria-label="menu"
						className="searchiconcustom"
						sx={{
							[mediaTablet]: {
								padding: '6px 6px 6px 14px',
							},
						}}
					>
						<SearchIcon />
					</IconButton>
				)}
				<InputBase
					autoComplete="off"
					className="textfield search-input"
					data-testid="top-search"
					disabled={isDicomScope}
					id="top-search"
					placeholder={
						!isDicomDialog
							? placeholder === 'All'
								? t('Search')
								: t('Search ') + t(placeholder)
							: undefined
					}
					sx={{
						display: isBlumeHomePage && !isTablet ? 'none' : 'block',
					}}
					value={isDicomScope ? dicomSearchText : searchText}
					onChange={handleChange}
					onClick={openSearchDialog}
					onKeyPress={e => e.key === 'Enter' && handleInputSaved(e)}
				/>
				{children}
				{!!loading && !isDicomDialog && <CircularProgress className="progressBar" size={20} />}
				{((searchText && !isDicomScope) || (dicomSearchText && isDicomScope)) && (
					<div
						className="font-icon-wrapper"
						style={{
							cursor: 'pointer',
							position: 'relative',
							zIndex: 500,
							display: isBlumeHomePage && !isTablet ? 'none' : 'block',
						}}
						onClick={clearSearchInput}
					>
						<IconButton aria-label="menu" className="searchicon" data-testid="clear-btn">
							<ClearIcon />
						</IconButton>
					</div>
				)}
			</SearchBarDiv>
			{isTablet && appModeIsPatientPortal && (
				<>
					<GenericDrawer
						children={getShareContactComponent()}
						headerFlag
						drawerColor="rsSecondary.medium"
						drawerOpen={!!studySharedId}
						setDrawerOpen={setStudySharedId}
						title="SHARE"
						width={422}
					/>
					<Toast
						children={getToastChildren(toastMsg)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						customStyle={{ left: 'auto', marginLeft: '14px' }}
						duration={5000}
						open={!!toastMsg}
						onClose={() => setToastMsg(false)}
					/>
				</>
			)}
			{(isMobile || isTablet) && appModeIsPatientPortal && (
				<>
					<MobileBottomDrawer
						background="#ffffff"
						open={Boolean(studyInDetailedView)}
						onClose={() => setStudyInDetailedView(null)}
					>
						<MobileStudyDetail
							data={studyInDetailedView}
							isShared={isSharedType}
							onShareClick={() => setStudySharedId(studyInDetailedView.study.id)}
						/>
					</MobileBottomDrawer>
					<MobileSnackbar message={snackbar} open={!!snackbar} onClose={() => setSnackbar(false)} />
				</>
			)}
		</>
	);
};

SearchBar.propTypes = {
	placeholder: PropTypes.any,
	/*
	 * Setter for tabs in Search Results page
	 */
	setTabs: PropTypes.any,
	/*
	 * Function for updating parent component state, if necessary
	 */
	setParentData: PropTypes.func,
	/*
	 * Additional Search Parameters
	 */
	additionalSearchParams: PropTypes.object,
	/*
	 * If page needs to be loaded with a value already present in the Search bar
	 */
	initialValue: PropTypes.string,
	/*
	 * Function to close the drawer if Search bar is inside
	 */
	setOpenDrawer: PropTypes.func,
};

export default SearchBar;

const SearchBarDiv = styled(
	React.forwardRef(({ appModeIsPatientPortal, showCategorySelector, isBlumeHomePage, searchText, ...props }, ref) => (
		<div {...props} ref={ref} />
	))
)(({ appModeIsPatientPortal, showCategorySelector = true, searchText, theme, isBlumeHomePage }) => ({
	position: 'relative',
	display: 'inline-flex',
	backgroundColor: theme.palette.rsSecondary.medium,
	color: theme.palette.text.main,
	width: appModeIsPatientPortal ? '89%' : '72%',
	border: isBlumeHomePage ? 'none' : '1px',
	borderRadius: appModeIsPatientPortal ? '8px' : '0px',
	outline: 'none',
	margin: appModeIsPatientPortal ? '0px 16px 0px 26px' : '3px 0px 3px 0px',
	boxShadow:
		appModeIsPatientPortal && !isBlumeHomePage
			? `0 0 0 1px ${searchText != '' ? theme.palette.rsPrimary.main : '#70707026'}`
			: '',
	...(showCategorySelector && {
		'&:before': {
			content: '""',
			top: '25%',
			height: '50%',
			opacity: '0.6',
			position: 'relative',
			borderLeft: isBlumeHomePage ? 'none' : '1px solid #ffffff99',
			fontWeight: 'bold',
		},
	}),
	'& .progressBar': {
		color: '#03dac5',
		margin: 'auto',
	},
	[mediaTablet]: {
		margin: appModeIsPatientPortal ? '0px 16px 0px 16px' : '0',
		width: appModeIsPatientPortal ? '93%' : '100%',
	},
}));

export const getSearchAllBody = (searchText, permissions, select) => {
	const bundle = {
		resourceType: 'Bundle',
		type: 'batch',
		entry: [],
	};

	if (permissions?.study && (select ? select.find(item => item.label === 'Study') || select.length === 0 : true)) {
		bundle.entry.push({
			fullUrl: 'urn:uuid:61ebe359-bfdc-4613-8bf2-c5e300945f0a',
			request: {
				method: 'GET',
				url: `ElkImagingStudyWorklist?_content=${searchText}&_count=50`,
			},
		});
	}
	if (
		permissions?.patient &&
		(select ? select.find(item => item.label === 'Patient') || select.length === 0 : true)
	) {
		bundle.entry.push({
			fullUrl: 'urn:uuid:61ebe359-bfdc-4613-8bf2-c5e300945f0b',
			request: {
				method: 'GET',
				url: `ElkPatient?_content=${searchText}&_count=50`,
			},
		});
	}
	if (
		permissions?.organization &&
		(select ? select.find(item => item.label === 'Organization') || select.length === 0 : true)
	) {
		bundle.entry.push({
			fullUrl: 'urn:uuid:61ebe359-bfdc-4613-8bf2-c5e300945f0e',
			request: {
				method: 'GET',
				url: `ElkOrganization?_content=${searchText}&_count=50`,
			},
		});
	}
	if (permissions?.user && (select ? select.find(item => item.label === 'User') || select.length === 0 : true)) {
		bundle.entry.push({
			fullUrl: 'urn:uuid:61ebe359-bfdc-4613-8bf2-c5e300945f0f',
			request: {
				method: 'GET',
				url: `ElkPractitioner?_content=${searchText}&_count=50`,
			},
		});
	}
	if (permissions?.help && (select ? select.find(item => item.label === 'Help') : true)) {
		bundle.entry.push({
			fullUrl: 'urn:uuid:61ebe359-bfdc-4613-8bf2-c5e300945f0g',
			request: {
				method: 'GET',
				url: `ElkHelpCenter?_content=${searchText}&_count=15&appname=oai`,
			},
		});
	}

	if (bundle.entry.length > 0) {
		return bundle;
	}
	return null;
};

export const getPatientPortalSearchAllBody = searchText => ({
	resourceType: 'Bundle',
	type: 'batch',
	entry: [
		{
			request: {
				method: 'GET',
				url: `ElkStudy?Content=${searchText}`,
			},
		},
		{
			request: {
				method: 'GET',
				url: `ElkStudy?IsShareWithMe=true&Content=${searchText}`,
			},
		},
		{
			request: {
				method: 'GET',
				url: `ElkHelpCenter?Content=${searchText}`,
			},
		},
	],
});
