// Core

import React from 'react';
import moment from 'moment';

import { mediaTablet, mediaDesktopXL } from '@rs-core/utils/responsiveUtils';

// MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import RegFilledIcon from '@mui/icons-material/HowToRegOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Tooltip from '@mui/material/Tooltip';

// Custom
import ClinicFilledIcon from '@worklist-2/ui/src/assets/icons/clinical_filled.svg';
import RegUnfilledIcon from '@worklist-2/ui/src/assets/icons/registration_unfilled.svg';
import ClinicUnfilledIcon from '@worklist-2/ui/src/assets/icons/assignment_outlined_add.svg';
import StudyCardFormRegistrationForm from '@worklist-2/ui/src/assets/icons/StudyCardFormRegistrationForm.svg';
import StudyCardFormClinicalForm from '@worklist-2/ui/src/assets/icons/StudyCardFormClinicalForm.svg';
import StudyCardFormClinicalFormFilled from '@worklist-2/ui/src/assets/icons/StudyCardFormClinicalFormFilled.svg';
import StudyCardFormRegistrationFormFilled from '@worklist-2/ui/src/assets/icons/StudyCardFormRegistrationFormFilled.svg';
import { useTranslation } from 'react-i18next';

const FormCard = ({ data, type, onClick }) => {
	const finished = data.completed;
	const { t } = useTranslation('appointments');

	const _buttonClickHandler = React.useCallback(() => {
		if (onClick) {
			onClick();
		}
	}, []);

	return (
		<>
			{type === 'appointmentCard' ? (
				<Stack>
					<Tooltip describeChild title={data?.title?.length > 15 ? data.title : ''}>
						<Button
							data-testid="form-appointment-card-button"
							endIcon={<ArrowForwardIcon sx={{ fill: '#FFFFFF' }} />}
							sx={{
								height: '47px',
								width: '258px',
								backgroundColor: '#42A5F5',
								alignSelf: 'center',
								justifyContent: 'space-between',
								marginBottom: '6px',
								'&:hover': { backgroundColor: '#42A5F5' },
							}}
							variant="raised"
							onClick={_buttonClickHandler}
						>
							<Box
								sx={{
									width: '1',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-start',
									textTransform: 'none',
									alignItems: 'flex-start',
								}}
							>
								{finished ? (
									<Icon
										component={
											data.type === 'registration'
												? StudyCardFormRegistrationFormFilled
												: StudyCardFormClinicalFormFilled
										}
									/>
								) : (
									<Icon
										component={
											data.type === 'registration'
												? StudyCardFormRegistrationForm
												: StudyCardFormClinicalForm
										}
									/>
								)}
								<Typography
									sx={{
										color: '#FFFFFF',
										textTransform: 'capitalize',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										maxWidth: '15ch',
										textOverflow: 'ellipsis',
										textAlign: 'left',
										paddingLeft: '5px',
									}}
								>
									{t(data.title)}
								</Typography>
							</Box>
							<Box>
								{!finished && (
									<Typography
										sx={{
											color: '#FFFFFF',
										}}
									>
										{t('Fill')}
									</Typography>
								)}
							</Box>
						</Button>
					</Tooltip>
				</Stack>
			) : (
				<Button
					data-testid="form-card-button"
					sx={{
						'--element-background-color': '#ffffff',

						background: 'var(--element-background-color)',
						borderRadius: 'var(--border-radius--base, var(--border-radius-base))',
						boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
						boxSizing: 'border-box',
						flex: 'none',
						height: 'var(--element-card-height)',
						letterSpacing: 'var(--letter-spacing--none, 0px)',
						paddingLeft: '24px',
						paddingRight: '24px',
						textTransform: 'none',
						width: 'var(--element-card-width)',

						'&:hover': {
							background: 'var(--element-background-color)',
						},
						[mediaTablet]: {
							'--element-background-color': '#f3f3f3',
						},
					}}
					onClick={_buttonClickHandler}
				>
					<Stack alignItems="center" direction="row" flex="auto">
						<Stack
							sx={{
								'--element-background-color': finished ? '#e3f0e4' : '#f5e0e4',

								alignItems: 'center',
								background: 'var(--element-background-color)',
								borderRadius: '50%',
								flex: 'none',
								justifyContent: 'center',
								height: '36px',
								width: '36px',
							}}
						>
							{finished ? (
								<Icon
									component={data.type === 'registration' ? RegFilledIcon : ClinicFilledIcon}
									sx={{
										color: '#75b678',
										width: data.type === 'registration' ? undefined : 'unset',
									}}
								/>
							) : (
								<Icon
									component={data.type === 'registration' ? RegUnfilledIcon : ClinicUnfilledIcon}
									sx={
										data.type === 'registration'
											? { marginBottom: '2px' }
											: {
													height: '22px',
													marginLeft: '2px',
													width: '20px',
											  }
									}
								/>
							)}
						</Stack>

						<Stack
							sx={{
								marginLeft: '10px',
								textAlign: 'start',
								rowGap: '4px',
								[mediaDesktopXL]: { marginLeft: '20px' },
							}}
						>
							<Typography
								sx={{
									color: '#717171',
									fontSize: '14px',
									lineHeight: '16px',
								}}
							>
								{t(data.title)}
							</Typography>
							<Typography
								sx={{
									color: '#717171',
									fontSize: '14px',
									lineHeight: '16px',
								}}
							>
								{finished ? (
									moment(data.lastModified || data?.resource?.lastUpdated).format('MMM D YYYY')
								) : (
									<>
										{'Fill before '}
										<Typography
											component="span"
											sx={{
												color: '#CF6679',
												fontSize: '14px',
												lineHeight: '16px',
											}}
										>
											{moment(data.lastModified || data?.resource?.lastUpdated).format(
												'MMM D YYYY'
											)}
										</Typography>
									</>
								)}
							</Typography>
						</Stack>
					</Stack>
				</Button>
			)}
		</>
	);
};

export default FormCard;
