import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { Svg } from '..';
import {
	BackDrop,
	boxStyle,
	DateText,
	header,
	mainBox,
	NoScrollbar,
	PolicyWrapper,
	SideBarWrapper,
	sideStyle,
	StrongText,
} from './policyStyles';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { mediaMobile, mediaTablet, useIsMobile, useIsTablet, useRouterHash } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

const SideBarLinks = ({ onClick }) => {
	const { t } = useTranslation('term');
	const { hash, setHash } = useRouterHash();
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const links = [
		{ label: 'Introduction' },
		{ label: 'Interpretation And Definitions' },
		{ label: 'Disclaimer' },
		{ label: 'Acknowledgement' },
		{ label: 'Social Media' },
		{
			label: 'Intellectual Property Rights',
			children: [{ label: 'Software' }, { label: 'Title' }],
		},
		{
			label: 'Third Party Links, Products, and Integrations',
			children: [
				{ label: 'Links to Other Websites' },
				{ label: 'Third Party Accounts' },
				{ label: 'Application Programming Interfaces' },
				{ label: 'Third Party Products' },
			],
		},
		{ label: 'Prohibited Uses' },
		{ label: 'Termination' },
		{ label: 'Limitation of liability' },
		{ label: 'Disputes Resolution' },
		{ label: 'For European Union (EU) Users' },
		{ label: 'United States Legal Compliance' },
		{
			label: 'Severability and Waiver',
			children: [{ label: 'Severability' }, { label: 'Waiver' }],
		},
		{ label: 'Indemnification' },
		{ label: 'Confidentiality' },
		{ label: 'Translation Interpretation' },
		{ label: 'Changes to these Terms and Conditions' },
		{ label: 'Acceptance of these Terms' },
		{ label: 'Contact Us' },
	];

	return (
		<SideBarWrapper>
			{links.map((link, i) => (
				<>
					<Box
						key={i}
						component="li"
						sx={{
							[mediaMobile]: {
								fontSize: '14px',
								lineHeight: '48px',
								color: '#121212',
								letterSpacing: '0.25px',
							},
						}}
						onClick={onClick}
					>
						<a
							className={`${
								hash ==
									link.label
										.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
										.replace(/\s/g, '-')
										.toLowerCase() && 'active'
							}`}
							href={`#${link.label
								.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
								.replace(/\s/g, '-')
								.toLowerCase()}`}
							onClick={() => {
								setHash(
									link.label
										.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
										.replace(/\s/g, '-')
										.toLowerCase()
								);
							}}
						>
							{t(link.label)}
						</a>
					</Box>
					{!isMobile && !isTablet && link.children && (
						<ul>
							{link.children.map((childTerm, j) => (
								<li key={j} className="unblur">
									<a
										className={`${
											hash == childTerm.label.replace(/\s/g, '-').toLowerCase() && 'active'
										}`}
										href={`#${childTerm.label.replace(/\s/g, '-').toLowerCase()}`}
										onClick={() => {
											setHash(childTerm.label.replace(/\s/g, '-').toLowerCase());
										}}
									>
										{t(childTerm.label)}
									</a>
								</li>
							))}
						</ul>
					)}
				</>
			))}
		</SideBarWrapper>
	);
};

const TermsModalContent = ({ isBlumeInside }) => {
	const { t } = useTranslation('term');
	const { watchScroll } = useRouterHash();
	const handleScroll = _.debounce(e => watchScroll(e), 10);

	return (
		<PolicyWrapper isBlumeInside={Boolean(isBlumeInside)} onScroll={handleScroll}>
			<Box
				sx={{
					[mediaTablet]: {
						marginRight: '0',
					},
				}}
			>
				{isBlumeInside ? (
					<Box className="watched" id="introduction" sx={{ '& p': { lineHeight: '6px !important' } }}>
						<p>{t('Last updated on:')}</p>
						<p>{t('September 7, 2023')}</p>
					</Box>
				) : (
					<DateText className="watched" id="introduction">
						{t('*Last updated: September 7, 2023')}
					</DateText>
				)}
				{isBlumeInside && (
					<Divider
						sx={{
							margin: '20px 15px 15px 0',
							borderColor: 'rgba(100, 120, 192, 0.1)',
						}}
					/>
				)}
				<p>
					{t(
						'Please read these terms and conditions carefully before using our Services. This is a legal document. By using our Services, as defined below, you demonstrate your agreement to be bound to these terms and conditions. You may only use our Services if you can form a binding contract with us, and only if you comply with these terms and conditions and all applicable local, state, national, and international laws, rules, and regulations.'
					)}
				</p>
				<p>
					{t(
						'THIS IS A LEGAL DOCUMENT. YOU MUST AGREE TO BE BOUND TO THESE TERMS AND CONDITIONS TO USE OUR SERVICES. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, DO NOT USE OUR SERVICES.'
					)}
				</p>
				<StrongText className="watched" id="interpretation-and-definitions">
					{t('INTERPRETATION AND DEFINITIONS')}
				</StrongText>
				<p>
					<strong>{t('Definitions')}</strong>
				</p>
				<p>{t('For the purposes of these Terms and Conditions:')}</p>
				<ul>
					<li>
						<strong>&quot;{t('Affiliate')}&quot;</strong>{' '}
						{t(
							'means any entity, subsidiaries, joint venture partners or other companies under the control of RamSoft, Inc.'
						)}
					</li>
					<li>
						<strong>{t('Company')}</strong> {t('(referred to as either')} &quot;{t('the Company')}&quot;,
						&quot;{t('We')}&quot;, &quot;{t('Us')}&quot; {t('or')} &quot;{t('Our')}&quot;{' '}
						{t('in this Agreement) refers to RamSoft, Inc.')}
					</li>
					<li>
						<strong>&quot;{t('Services')}&quot;</strong>{' '}
						{t(
							'refers to OmegaAI™ & Blume™ web and mobile-based applications along with the family of associated products.'
						)}
					</li>
					<li>
						<strong>&quot;{t('Software')}&quot;</strong>{' '}
						{t(
							'refers to any intellectual property developed, owned, licensed, trademarked, and copyrighted by the Company.'
						)}
					</li>
					<li>
						<strong>&quot;{t('Terms and Conditions')}&quot;</strong>{' '}
						{t(
							'(also referred as “Terms”) mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of these Services.'
						)}
					</li>
					<li>
						<strong>&quot;{t('Third-party Social Media Service')}&quot;</strong>{' '}
						{t(
							'means any services or content (including data, information, products, or services) provided by a third-party that may be displayed, included or made available by the Services.'
						)}
					</li>
					<li>
						<strong>&quot;{t('Vendor')}&quot;</strong>
						&nbsp;{' '}
						{t(
							'refers to any third-party company which Company partners to integrate, resell, or distribute their software, service, or offering.'
						)}
					</li>
					<li>
						<strong>
							&quot;{t('You')}&quot; {t('or')} &quot;{t('User')}&quot;
						</strong>{' '}
						{t(
							'means the individual accessing or using this Service, or the company, or other legal entity on behalf of which such individual is accessing or using these Services, as applicable.'
						)}
					</li>
				</ul>

				<StrongText className="watched" id="disclaimer">
					{t('DISCLAIMER')}
				</StrongText>
				<p>
					{t(
						'This agreement defines certain use, rights, and obligations to Users not bound by any additional contractual agreements, initiated with the Company, for services outside of those outlined in these Terms and Conditions. Other contractual obligations initiated as a customer for the Company will take precedence over terms laid out here.'
					)}
				</p>

				<StrongText className="watched" id="acknowledgement">
					{t('ACKNOWLEDGEMENT')}
				</StrongText>
				<p>
					{t(
						'These are the Terms and Conditions governing the use of the Services and the agreement that operates between you and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of this Services.'
					)}
				</p>
				<p>
					{t(
						'Your access to and use of this Services is conditioned on your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users, and others who access or use the Services.'
					)}
				</p>
				<p>
					{t(
						'By accessing or using the Services, you agree to be bound by these Terms and Conditions. If you disagree with any part of these Terms and Conditions, then you may not access the Services.'
					)}
				</p>
				<p>
					{t(
						'Your access to and use of the Services is also conditioned on your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of your personal information when you use the Services and tells you about your privacy rights and how the law protects you. Please read Our'
					)}{' '}
					<a>{t('Privacy Policy')}</a> {t('carefully before using our Services.')}
				</p>

				<StrongText className="watched" id="social-media">
					{t('SOCIAL MEDIA')}
				</StrongText>
				<p>
					{t(
						'The Company’s use of social media serves as an extension of its presence online. In addition to our Services and Marketing initiatives, from time to time we may post messages, pictures, videos, news stories, or other information on certain social media platforms, such as'
					)}{' '}
					Facebook®, Twitter&reg;, LinkedIn&reg; {t('and')} YouTube&reg;.
				</p>
				<p>
					{t(
						'We are not responsible for the privacy or security policies or practices on any of the third-party websites that the Company may link to. It is recommended to review the Terms of Service and Privacy Policies of all such third-party websites. Any content you post, such as pictures, videos, information, opinions, complaints, or any personal information is public, which means that anyone can see your posts.'
					)}
				</p>
				<p>
					{t(
						'Most of our social media sites are moderated by our workforce members. We reserve the right to delete comments or posts that violate applicable laws, are misaligned with our corporate values, or are in violation of our published terms and policies.'
					)}
				</p>

				<StrongText className="watched" id="intellectual-property-rights">
					{t('INTELLECTUAL PROPERTY RIGHTS')}
				</StrongText>
				<p>
					{t(
						'This Agreement does not transfer to you any intellectual property owned by the Company or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with the Company. All trademarks, service marks, graphics and logos used in connection with this Services, are trademarks or registered trademarks of the Company. Other trademarks, service marks, graphics and logos used in connection with our Services may be the trademarks of other third parties. Your use of the Services grants you no right or license to reproduce or otherwise use any Company or third-party trademarks.'
					)}
				</p>

				<p className="watched" id="software">
					<strong>{t('Software')}</strong>
				</p>
				<p>
					{t(
						'You agree that you shall only use the Software in a manner that complies with all applicable laws, regulations, and the like in the jurisdictions in which you use the Software, including, but not limited to, applicable restrictions concerning medical uses, copyright, and other intellectual property rights. All rights not expressly granted are reserved'
					)}{' '}
				</p>
				<p>
					{t(
						'COMPANY MAKES NO GUARANTEE AND NO WARRANTY THAT SOFTWARE WILL OPERATE UNINTERRUPTED AND ERROR-FREE'
					)}
				</p>
				<p>
					{t('You may not:')}
					<br />
					(i){' '}
					{t(
						'modify, translate, reverse engineer, decompile, disassemble (except to the extent that this restriction is expressly prohibited by law) the Software, in whole or in part, or create derivative works based upon the Software;'
					)}
					<br />
					(ii) {t('copy the Software;')}
					<br />
					(iii){' '}
					{t(
						'remove any proprietary notices or labels on the Software. Any such forbidden use shall immediately terminate your license to the Software.'
					)}
				</p>
				<p>
					{t(
						'You acknowledge that in the course of using Services, you may access electronic Protected Health Information'
					)}{' '}
					("ePHI"){' '}
					{t(
						'as a part of continued medical care. You understand that you are only authorized to access the ePHI of individuals for whom you are directly providing medical care and are only authorized to access the ePHI that is necessary to provide that medical care. You further understand that your access to ePHI within our Software may be monitored and audited and that, if it is determined that you have impermissibly accessed any individual s ePHI, among other things, the operator of the Software reserves the right to limit or eliminate your future rights to access ePHI.'
					)}
				</p>

				<p className="watched" id="title">
					<strong>{t('Title')}</strong>
				</p>
				<p>
					{t(
						'The Services are protected by the copyright laws of Canada, the United States and by international copyright treaties. Aspects of the licensed materials, including the specific design and structure of individual programs, constitute trade secrets and/or copyrighted material of the Company. You agree not to disclose, provide, or otherwise make available such trade secrets or copyrighted material in any form to any third party without the prior written consent of the Company.'
					)}
				</p>

				<StrongText className="watched" id="third-party-links-products-and-integrations">
					{t('THIRD PARTY LINKS, PRODUCTS, AND INTEGRATIONS')}
				</StrongText>
				<p className="watched" id="links-to-other-websites">
					<strong>{t('Links to other Websites')}</strong>
				</p>
				<p>
					{t(
						'The Services may contain links to third-party web sites or services that are not owned or controlled by the Company.'
					)}
				</p>
				<p>
					{t(
						'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly, or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such web sites or services. By providing a link to a third-party website, Company does not endorse the products or services linked on such website, nor does it endorse any sponsoring organization of the third-party website.'
					)}
				</p>
				<p>
					{t(
						'We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.'
					)}
				</p>
				<p className="watched" id="third-party-accounts">
					<strong>{t('Third-Party Accounts')}</strong>
				</p>
				<p>
					{t(
						'With respect to any third-party services, we may enable you to access, you agree to the following:'
					)}
					<ul style={{ listStyleType: 'none' }}>
						<li>
							<p>
								{t(
									'You are solely responsible for maintaining the confidentiality of any username, password, or other access credential necessary to access the Services. Further, you are responsible for all activities that occur in connection with such access credentials and any data or information obtained using your access credentials. Neither We nor our Vendor shall be liable for any loss, cost, expense, or other liability arising out of any such use of accounts. Accordingly, you should take steps to protect the confidentiality of your access credentials. As an authorized user of our Services, you accept full responsibility for the monitoring of your accounts.'
								)}
								<br />
								{t(
									'YOU AGREE TO NOTIFY US IMMEDIATELY IF YOU BECOME AWARE OF ANY UNAUTHORIZED ACTIVITY, DISCLOSURE, LOSS, THEFT, OR OTHER UNAUTHORIZED USE OF YOUR ACCESS CREDENTIALS. YOU AGREE TO COOPERATE WITH US IN ANY INVESTIGATION AND AGREE TO TAKE CORRECTIVE MEASURES TO PROTECT YOUR ACCOUNTS FROM FURTHER FRAUDULENT ACTIVITY.'
								)}
							</p>
							<p>
								{t(
									'You are solely responsible for all fees charged by the Vendor in connection with any accounts and transactions created outside of our Service. You agree to comply with the terms and conditions of those accounts and agree that these Terms do not amend any of those terms and conditions. If you have a dispute or question about any transaction on any third-party account, you agree to direct these to the account provider.'
								)}
							</p>
						</li>
					</ul>
				</p>
				<p className="watched" id="application-programming-interfaces">
					<strong>{t('Application Programming Interfaces')}</strong>
				</p>
				<p>
					{t(
						'As part of the Company’s Services, You may have access to one or more application programming interfaces and/or software development kits (referred to collectively herein as the “API’s”). The API’s may allow You to create scripts and programs to automate certain tasks performed throughout the Service and to enable certain integrations. Unless You have entered into an agreement with the Company and received authorization, You shall not use or enable any third-party API’s when using the Services. If authorization has been ascertained, You shall not hold the Company liable for risk incurred through use of any integrations. You affirm that any applicable regulatory requirement has been fulfilled prior to use of any integration.'
					)}
				</p>

				<p className="watched" id="third-party-products">
					<strong>{t('Third Party Products')}</strong>
				</p>
				<p>
					{t(
						'The Services may contain code, content, features, functionality, and components that are provided by third parties. Any such third-party products or services shall be provided “AS IS” without warranty of any kind by the Company. All rights and obligations with respect to any such third-party products or services shall be governed exclusively by the terms and conditions of agreements between Company and suppliers of said third-party products and services, and You hereby release the Company from all liability and responsibility with respect to their use'
					)}
				</p>
				<p>
					{t(
						'Pursuant to Federal Trade Commission’s Guides Concerning the Use of Endorsements and Testimonials in Advertising, 16 C.F.R. Part 255 Sections 255.0 through 255.5, some of our links are affiliated links. This means that Company may receive payment if you purchase products or services through such links. Company only utilizes affiliate links for products or services that believe will add value to its Users.'
					)}
				</p>

				<StrongText className="watched" id="prohibited-uses">
					{t('PROHIBITED USES')}
				</StrongText>
				<p>
					{t(
						'In addition to other terms as set forth in the Agreement, you are prohibited from using this Service: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate Our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Services or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of this Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Services or any related Company offering for violating any of the prohibited uses or for any other reason or no reason.'
					)}
				</p>

				<StrongText className="watched" id="termination">
					{t('TERMINATION')}
				</StrongText>
				<p>
					{t(
						'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms and Conditions, or no reason.'
					)}
				</p>
				<p>{t('Upon termination, your right to use the Services will cease immediately.')}</p>

				<StrongText className="watched" id="limitation-of-liability">
					{t('LIMITATION OF LIABILITY')}
				</StrongText>
				<p>
					{t(
						'Notwithstanding any damages that you might incur, the entire liability of the Company and any of its Affiliates under any provision of these Terms and Conditions and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you through this Service.'
					)}
				</p>
				<p>
					{t(
						'To the maximum extent permitted by applicable law, in no event shall the Company or its Affiliates or its respective officers, directors, employees, or agents be liable for any special, incidental, indirect, exemplary, punitive, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Services, third-party software and/or third-party hardware used with the Services, or otherwise in connection with any provision of these Terms and Conditions), even if the Company or any Affiliate has been advised of the possibility of such damages and even if the warranty fails of its essential purpose. In no event shall Company, its Affiliates, or its officers, directors, employees, or agents, be liable for any special, indirect, or consequential damages whatsoever resulting from the loss of use or data, whether in action or contract, negligence, or other tortious action, arising out of or in connection with the use of the Services. Users&apos; sole and exclusive remedy for dissatisfaction with Services is to stop using Services. Company provides no indemnification of any kind.'
					)}
				</p>
				<p>
					{t(
						'Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party’s liability will be limited to the greatest extent permitted by law.'
					)}
				</p>

				<StrongText className="watched" id="disputes-resolution">
					{t('DISPUTE RESOLUTION')}
				</StrongText>
				<p>
					{t(
						'The formation, interpretation, and performance under these Terms and Conditionsand any disputes arising out of it shall be governed by the substantive and procedural laws of Ontario, Canada without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of Canada. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in Ontario, Canada, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.'
					)}
				</p>

				<StrongText className="watched" id="for-european-union-eu-users">
					{t('FOR EUROPEAN UNION (EU) USERS')}
				</StrongText>
				<p>
					{t(
						'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.'
					)}
				</p>

				<StrongText className="watched" id="united-states-legal-compliance">
					{t('UNITED STATES LEGAL COMPLIANCE')}
				</StrongText>
				<p>
					{t(
						'You represent and warrant that (i) you are a “Covered Entity” or “Business Associate” as defined under HIPAA and, as such, you are obligated to meet the requirements of HIPAA related to the access, use, and disclosure of ePHI, (ii) you are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a “terrorist supporting” country, and (iii) you are not listed on any United States government list of prohibited or restricted parties.'
					)}
				</p>

				<StrongText className="watched" id="severability-and-waiver">
					{t('SEVERABILITY AND WAIVER')}
				</StrongText>
				<p className="watched" id="severability">
					<strong>{t('Severability')}</strong>
				</p>
				<p>
					{t(
						'If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law (unless that modification is not permitted by law, in which case that provision will be disregarded) and the remaining provisions will continue in full force and effect. Any unenforceable provision will remain as written in any circumstances other than those in which the provision is held to be unenforceable.'
					)}
				</p>

				<p className="watched" id="waiver">
					<strong>{t('Waiver')}</strong>
				</p>
				<p>
					{t(
						'Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party’s ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.'
					)}
				</p>

				<StrongText className="watched" id="indemnification">
					{t('INDEMNIFICATION')}
				</StrongText>
				<p>
					{t(
						'You agree to indemnify and hold the Company and its Affiliates, directors, officers, employees, and agents harmless from and against any liabilities, losses, damages, or costs, including reasonable attorneys’ fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your content, your use of this Service or any willful misconduct on your part. The entire risk arising out of your use of the Services remains with you.'
					)}
				</p>
				<p>
					{t(
						'You further agree to indemnify Company from any suit or proceeding made or brought against Company by a third-party for claims that relate to your use of Services.'
					)}
				</p>

				<StrongText className="watched" id="confidentiality">
					{t('CONFIDENTIALITY')}
				</StrongText>
				<p>
					{t(
						'The parties hereto agree that each shall treat confidentially the terms and conditions of this agreement and all information provided by each party to the other regarding its business and operations. All confidential information provided by a party hereto shall be used by any other party solely for the purpose of rendering or obtaining services pursuant to this agreement and, except as may be required in carrying out this agreement, shall not be disclosed to any third party without the prior consent of such providing party. The foregoing shall not be applicable to any information that is publicly available when provided or thereafter becomes publicly available other than through a breach of this agreement, or that is required to be disclosed to any Regulatory Authority, any auditor of the parties hereto, or by judicial or administrative process or otherwise by Applicable Law.'
					)}
				</p>

				<StrongText className="watched" id="translation-interpretation">
					{t('TRANSLATION INTERPRETATION')}
				</StrongText>
				<p>
					{t(
						'These Terms and Conditions may have been translated if we have made them available to you in such a translated format.'
					)}
				</p>
				<p>{t('You agree that the original English text shall prevail in the case of a dispute.')}</p>

				<StrongText className="watched" id="changes-to-these-terms-and-conditions">
					{t('CHANGES TO THESE TERMS AND CONDITIONS')}
				</StrongText>
				<p>
					{t(
						'We reserve the right, at our sole discretion, to modify or replace these Terms and Conditions at any time without notice. If a revision is material, we will make reasonable efforts to provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.'
					)}
				</p>
				<p>
					{t(
						'By continuing to access or use this Service after the modified or replaced Terms become effective, you agree to be bound by them. If you do not agree to the new terms, in whole or in part, please stop using this Service. We encourage you to routinely review these Terms and Conditions at regular intervals to monitor it for updates.'
					)}
				</p>

				<StrongText className="watched" id="acceptance-of-these-terms">
					{t('ACCEPTANCE OF THESE TERMS')}
				</StrongText>
				<p>
					{t(
						'You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using this Service, you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access this Services.'
					)}
				</p>
				<p>
					{t(
						'These Terms and Conditions may not be transferred or assigned by you but may be assigned by us without restriction.'
					)}
				</p>

				<StrongText className="watched" id="contact-us">
					{t('CONTACT US')}
				</StrongText>
				<p>
					{t('If you have any questions about the Terms and Conditions, please contact us via email:')}{' '}
					<a href="mailto:legal@ramsoft.com">legal@ramsoft.com</a>.{' '}
					{t('Depending the request type, validation of your identity may be required.')}
				</p>
				<strong>
					{t(
						'Note: Email is not a secure method of transfer for Protected Health Information (PHI) or Personally Identifiable Information (PII).'
					)}
				</strong>
				<StrongText style={{ height: 200 }} />
			</Box>
		</PolicyWrapper>
	);
};

const TermsModal = ({ open, onClose }) => {
	const isTablet = useIsTablet();
	const { t } = useTranslation('term');
	const { i18n } = useTranslation();
	const [languageInitialized, setLanguageInitialized] = useState(false);

	function getQueryParamValue(paramName, url = window.location.href) {
		const queryString = url.split('#')[1] || url.split('?')[1] || '';
		const regex = new RegExp(`[?&]${paramName}=([^&#]*)`, 'i');
		const matches = regex.exec(queryString);

		if (matches && matches[1]) {
			return decodeURIComponent(matches[1]);
		}

		return '';
	}

	useEffect(() => {
		if (!languageInitialized) {
			const language = getQueryParamValue('languageSelected');

			if (language) {
				i18n.changeLanguage(language);
			}

			setLanguageInitialized(true); // State update happens after the language change
		}
	}, [languageInitialized, i18n]);
	return (
		<Modal
			BackdropComponent={() => (
				<BackDrop>
					<Box sx={mainBox}>
						<NoScrollbar style={sideStyle}>
							<IconButton
								sx={{
									position: 'absolute',
									top: 10,
									right: 20,
								}}
								onClick={onClose}
							>
								<CloseIcon sx={{ color: '#000' }} />
							</IconButton>
							<SideBarLinks />
						</NoScrollbar>
						<Box sx={boxStyle}>
							<Svg
								name="terms"
								style={{
									width: 75,
									height: 75,
									marginTop: isTablet ? 0 : 100,
								}}
								viewBox="-10 -10 75 75"
							/>
							<Box component="h3" sx={header}>
								{t('Terms of Service')}
							</Box>
							<TermsModalContent />
						</Box>
					</Box>
				</BackDrop>
			)}
			open={open}
			onClose={onClose}
		>
			<></>
		</Modal>
	);
};

export default TermsModal;
export { SideBarLinks, TermsModalContent };
