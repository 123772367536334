import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Avatar, Box, Button, Card, Divider, Icon, Stack, Typography, IconButton } from '@mui/material';
import { ChevronRight, MoreVert, Circle, Info } from '@mui/icons-material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { useConfig } from '@rs-core/context/ConfigContext';
import { useIsMobile, useIsTablet } from '@rs-core/utils/responsiveUtils';
import userIcon from '@worklist-2/ui/src/assets/img/homepage/user.png';
import share from '@worklist-2/ui/src/assets/img/homepage/share.svg';

import { useFormStore } from '@worklist-2/patientPortal/src/stores/forms';
import { useDrawersStore } from '@worklist-2/patientPortal/src/stores/drawers';
import { useShareDrawerStore } from '@worklist-2/patientPortal/src/stores/share-drawer';
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';
import MobileBottomDrawer from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import MobileStudyDetail from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetail';
import Drawer from '../../components/Drawer';
import getAddress from '@worklist-2/patientPortal/src/utils/getAddress';
import sendAnalyticsEvent from '../../analytics';
import { VIEW_STUDY } from '@worklist-2/patientPortal/src/analytics/eventTypes';
import { fetchForms, isStudyEmpty, getPatientName } from '@worklist-2/patientPortal/src/views/StudiesV2/helpers';
import DownloadStudy from '@worklist-2/patientPortal/src/views/StudiesV2/DownloadStudy';
import BurnToDisc from '@worklist-2/patientPortal/src/views/StudiesV2/BurnToDisc';
import ProgressAlert from '@worklist-2/patientPortal/src/views/StudiesV2/ProgressAlert';
import { STUDY_STATUS } from '../../consts';
import { MOBILE_ACTION_ICONS } from '@worklist-2/patientPortal/src/components/Appointment/V2/AppointmentCard/ActionButtons/icons';
import { getBlumeAccessTokenFromStorage, setBlumeAccessTokenToStorage, STUDY_CARD_STYLES } from './StudyCard.config';

export const formatStudyDate = (study, isTime) => {
	const date = study?.studyDisplayDateTime || study?.createdTimeUTC;
	if (!date) return '-';
	return moment
		.utc(date)
		.local()
		.format(isTime ? 'hh:mm a' : 'MMM D, YYYY');
};

const StudyCard = ({ study, patientMapping }) => {
	const __config = useConfig();
	const initStudyDrawer = useShareDrawerStore(state => state.initStudyDrawer);
	const getFormsByPatientId = useFormStore(state => state.getFormsByPatientId);
	const initFormDrawer = useFormStore(state => state.initFormDrawer);
	const studyInformationDetails = useDrawersStore(state => state.studyInformationDetails);
	const setStudyInformationDetails = useDrawersStore(state => state.setStudyInformationDetails);
	const { setToastMsg } = useToastMessageContext();
	const [patientName, setPatientName] = useState('');
	const [forms, setForms] = useState([]);
	const [showFormIcon, setShowFormIcon] = useState(false);
	const [moreDrawerOpen, setMoreDrawerOpen] = useState(false);

	const { t } = useTranslation('homepage');
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const navigate = useNavigate();

	const address = getAddress(study?.imagingOrganization?.address);
	const showProgressAlert = study.status !== STUDY_STATUS.COMPLETED;

	const closeMoreDrawer = () => {
		setMoreDrawerOpen(false);
	};

	useEffect(() => {
		setPatientName(getPatientName(patientMapping, study?.patientId));
	}, [patientMapping?.length]);

	useEffect(() => {
		if (!study?.patientId || forms?.length) return;
		fetchForms(__config, getFormsByPatientId, study, setForms, setShowFormIcon);
	}, []);

	const handleStudyNavigate = () => {
		const status =
			// eslint-disable-next-line no-nested-ternary
			study.status === 'ORDERED' || study.status === 'COMPLETED'
				? 'completed'
				: study.status === 'INPROCESS'
				? 'processing'
				: study.status
				? study.status?.toLowerCase()
				: 'completed';

		if (status === 'completed' || status === 'scheduled' || status === 'processing') {
			if (isMobile || isTablet) {
				setStudyInformationDetails({ study });
			} else {
				popoutInNewWindow();
			}
		} else {
			//currently not doing anything if status is unknown
		}
	};

	const handleAccessForms = () => {
		const { description, id, patientId, signedAt, createdTimeUTC } = study;

		initFormDrawer({
			date: signedAt ?? createdTimeUTC,
			type: description ?? 'All modalities',
			patientId,
			studyId: id,
		});
	};

	const popoutInNewWindow = async () => {
		if (isStudyEmpty(study)) {
			setToastMsg(t('No images and reports to show'));
			return;
		}
		let token = getBlumeAccessTokenFromStorage(study.id);
		if (!token) {
			const getAccessToken = `${__config.data_sources.blume}Study/BlumeAccessToken?StudyUID=${study.studyThumbnail.studyUID}&InternalManagingOrganizationID=${study.managingOrganization.id}&StudyId=${study.id}`;
			const response = await axios.get(getAccessToken);
			token = response.data.AccessToken;
		}
		if (token) {
			setBlumeAccessTokenToStorage(study.id, token);
			navigate('/view-study', {
				state: {
					url: `${__config.omegaai_url}iv?studyId=${study.id}&studyInstanceUID=${study?.studyThumbnail?.studyUID}&patientId=${study.patientId}&issuerOfPatientId=${study?.managingOrganization?.name}&internalManagingOrganizationID=${study?.managingOrganization?.id}&oAiAuthToken=${token}&isBlume=true`,
					dob: null,
				},
			});
		} else {
			setToastMsg(t('Access Denied'));
		}

		sendAnalyticsEvent(VIEW_STUDY, {
			studyId: study.id,
			studyDescription: study.description,
			frames: study?.studyThumbnail || 0,
			docs: study.reports?.length || 0,
		});
	};

	return (
		<>
			<Card sx={STUDY_CARD_STYLES.WRAPPER(isTablet)} variant="outlined">
				{isTablet ? (
					<>
						<Stack direction="row" justifyContent="space-between">
							<Stack
								alignItems="center"
								direction="row"
								flex={1}
								sx={{ color: '#121926', whiteSpace: 'nowrap' }}
							>
								<Typography fontSize={14} fontWeight={600}>
									{formatStudyDate(study)}
								</Typography>
								<Circle sx={{ fontSize: 6, mx: 1, color: '#667085' }} />
								<Typography color="#364152" fontSize={14} fontWeight={500}>
									{formatStudyDate(study, true)}
								</Typography>
							</Stack>
							{!!study?.studyThumbnail?.seriesUID || (forms?.length && showFormIcon) ? (
								<Drawer
									isResponsive
									buttonProps={{ width: 20 }}
									customOpen={moreDrawerOpen}
									icon={<MoreVert fontSize="small" />}
									label={t('More Options')}
									setCustomOpen={setMoreDrawerOpen}
									onClose={closeMoreDrawer}
								>
									{forms?.length && showFormIcon ? (
										<Button
											sx={STUDY_CARD_STYLES.ACCESS_FORMS_BUTTON}
											type={study?.description}
											onClick={handleAccessForms}
											onClose={closeMoreDrawer}
										>
											<Typography
												fontSize={16}
												sx={{ display: 'flex', alignItems: 'center', color: '#42A5f5' }}
											>
												<Box sx={{ mr: 1 }}>{MOBILE_ACTION_ICONS.forms}</Box>
												{t('Access forms')}{' '}
											</Typography>
											<IconButton>
												<ChevronRight />
											</IconButton>
										</Button>
									) : null}
									<BurnToDisc setMoreDrawerOpen={setMoreDrawerOpen} study={study} />
									<DownloadStudy setMoreDrawerOpen={setMoreDrawerOpen} study={study} />
								</Drawer>
							) : null}
						</Stack>
						<Divider sx={{ my: 1 }} />
						<Box sx={{ color: '#121926', display: 'flex', flexDirection: 'column', gap: '8px' }}>
							<Typography fontSize={16} fontWeight={600}>
								{study?.description}
							</Typography>
							<Typography
								alignItems="center"
								color="#364152"
								data-cy="patient-name"
								display="flex"
								fontSize={14}
								fontWeight={600}
							>
								<Avatar src={userIcon} sx={{ mr: 1, height: 24, width: 24 }} /> {patientName}
							</Typography>
							<Box alignItems="center" display="flex" gap="5px" sx={{ color: '#697586' }}>
								<Box
									component="svg"
									fill="none"
									height="18"
									sx={{ flexShrink: 0, height: 20, width: 20 }}
									viewBox="0 0 20 18"
									width="20"
								>
									<path
										d="M10.8334 8.16667H14.8334C15.7668 8.16667 16.2335 8.16667 16.59 8.34832C16.9036 8.50811 17.1586 8.76308 17.3184 9.07668C17.5 9.4332 17.5 9.89991 17.5 10.8333V16.5M10.8334 16.5V4.16667C10.8334 3.23325 10.8334 2.76654 10.6517 2.41002C10.4919 2.09641 10.2369 1.84144 9.92334 1.68166C9.56682 1.5 9.10011 1.5 8.16669 1.5H5.16669C4.23327 1.5 3.76656 1.5 3.41004 1.68166C3.09643 1.84144 2.84147 2.09641 2.68168 2.41002C2.50002 2.76654 2.50002 3.23325 2.50002 4.16667V16.5M18.3334 16.5H1.66669M5.41669 4.83333H7.91669M5.41669 8.16667H7.91669M5.41669 11.5H7.91669"
										stroke="#697586"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="1.66667"
									/>
								</Box>
								<Typography
									fontSize={{ lg: '14px' }}
									fontWeight={500}
									lineHeight={{ lg: '20px' }}
									sx={{ fontFamily: 'Roboto' }}
								>
									{study?.imagingOrganization?.name || study?.managingOrganization?.name || '-'}
								</Typography>
							</Box>
							{address && (
								<Box alignItems="center" display="flex" gap="5px" sx={{ color: '#697586' }}>
									<svg fill="none" height="20" viewBox="0 0 16 20" width="16">
										<path
											d="M7.99999 10.4167C9.38071 10.4167 10.5 9.29746 10.5 7.91675C10.5 6.53604 9.38071 5.41675 7.99999 5.41675C6.61928 5.41675 5.49999 6.53604 5.49999 7.91675C5.49999 9.29746 6.61928 10.4167 7.99999 10.4167Z"
											stroke="#697586"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="1.67"
										/>

										<path
											d="M7.99999 18.3334C9.66666 15.0001 14.6667 12.8486 14.6667 8.33342C14.6667 4.65152 11.6819 1.66675 7.99999 1.66675C4.3181 1.66675 1.33333 4.65152 1.33333 8.33342C1.33333 12.8486 6.33333 15.0001 7.99999 18.3334Z"
											stroke="#697586"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="1.67"
										/>
									</svg>

									<Box alignItems="center" display="flex">
										<Typography
											noWrap
											component="address"
											sx={{ maxWidth: { xs: '72dvw', lg: '24ch' } }}
										>
											{address}
										</Typography>
										<IconButton
											component="a"
											data-testid="appointment-card-org-address-link"
											href={`https://www.google.com/maps/place/${encodeURIComponent(address)}`}
											rel="noopener"
											sx={{ color: '#42A5F5', padding: 0 }}
											target="_blank"
										>
											<OpenInNewIcon />
										</IconButton>
									</Box>
								</Box>
							)}

							{Boolean(study?.imagingOrganization?.phone) && (
								<Box
									alignItems="center"
									display={{ xs: 'flex', lg: 'none' }}
									gap="5px"
									sx={{ color: '#697586' }}
								>
									<svg fill="none" height="18" viewBox="0 0 18 18" width="18">
										<path
											d="M5.98356 6.37779C6.56356 7.58581 7.35422 8.71801 8.35553 9.71933C9.35685 10.7206 10.4891 11.5113 11.6971 12.0913C11.801 12.1412 11.8529 12.1661 11.9187 12.1853C12.1523 12.2534 12.4392 12.2045 12.637 12.0628C12.6927 12.0229 12.7403 11.9753 12.8356 11.88C13.1269 11.5887 13.2726 11.443 13.4191 11.3478C13.9715 10.9886 14.6837 10.9886 15.2361 11.3478C15.3825 11.443 15.5282 11.5887 15.8196 11.88L15.9819 12.0424C16.4248 12.4853 16.6462 12.7067 16.7665 12.9446C17.0058 13.4175 17.0058 13.9761 16.7665 14.449C16.6462 14.6869 16.4248 14.9083 15.9819 15.3512L15.8506 15.4825C15.4092 15.9239 15.1886 16.1446 14.8885 16.3131C14.5556 16.5001 14.0385 16.6346 13.6567 16.6334C13.3126 16.6324 13.0774 16.5657 12.607 16.4322C10.0792 15.7147 7.69387 14.361 5.70388 12.371C3.7139 10.381 2.36017 7.99569 1.6427 5.46786C1.50919 4.99749 1.44244 4.7623 1.44141 4.41818C1.44028 4.03633 1.57475 3.51925 1.76176 3.18633C1.9303 2.88631 2.15098 2.66563 2.59233 2.22428L2.72369 2.09292C3.16656 1.65005 3.388 1.42861 3.62581 1.30833C4.09878 1.0691 4.65734 1.0691 5.1303 1.30832C5.36812 1.42861 5.58955 1.65005 6.03242 2.09291L6.19481 2.25531C6.48615 2.54665 6.63182 2.69231 6.72706 2.8388C7.08622 3.3912 7.08622 4.10336 6.72706 4.65576C6.63182 4.80225 6.48615 4.94791 6.19481 5.23925C6.09955 5.33451 6.05192 5.38214 6.01206 5.43782C5.87038 5.63568 5.82146 5.92256 5.88957 6.15619C5.90873 6.22193 5.93367 6.27389 5.98356 6.37779Z"
											stroke="#697586"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="1.67"
										/>
									</svg>
									<Typography
										fontSize={{ lg: '14px' }}
										fontWeight={500}
										lineHeight={{ lg: '20px' }}
										sx={{ fontFamily: 'Roboto' }}
									>
										{study?.imagingOrganization?.phone}
									</Typography>
								</Box>
							)}
						</Box>
						{showProgressAlert && <ProgressAlert sx={{ marginTop: '12px', height: 'max-content' }} />}
						<Divider sx={{ my: '12px' }} />
						<Box display="flex" gap="10px">
							<Button
								color="info"
								sx={STUDY_CARD_STYLES.BUTTON(isTablet)}
								variant="outlined"
								onClick={() => {
									initStudyDrawer({ study, forceFacilityFetch: true });
								}}
							>
								<Icon component={share} sx={{ height: '20px' }} />
								{isMobile ? t('Share') : t('Share via email')}
							</Button>
							<Button
								color="rsPrimary"
								sx={{
									...STUDY_CARD_STYLES.BUTTON(isTablet),
									background: 'var(--color-primary)',
									color: 'white',
								}}
								variant="contained"
								onClick={handleStudyNavigate}
							>
								{t('View Results')}
							</Button>
						</Box>
					</>
				) : (
					<>
						{showProgressAlert && <ProgressAlert sx={{ marginBottom: '20px' }} />}
						<Stack direction="row" justifyContent="space-between" mt={1}>
							<Box flex={0.06} sx={{ color: '#121926', whiteSpace: 'nowrap' }}>
								<Typography fontSize={20} fontWeight={600}>
									{formatStudyDate(study)}
								</Typography>
								<Typography color="#364152" fontWeight={500} mt={1}>
									{formatStudyDate(study, true)}
								</Typography>
							</Box>
							<Divider
								orientation="vertical"
								sx={{ height: '60px', flex: 0.01, width: '1px', mx: 2 }}
								variant="middle"
							/>

							<Box flex={0.35} sx={{ color: '#121926' }}>
								<Typography fontSize="18px" fontWeight={600}>
									{study?.description}
								</Typography>
								<Typography alignItems="center" color="#364152" display="flex" fontWeight={500} mt={1}>
									<Avatar src={userIcon} sx={{ mr: 1, height: 30, width: 30 }} /> {patientName}
								</Typography>
							</Box>
							<Box color="#697586" display="flex" flex={0.35} flexDirection="column" gap="8px">
								<Box
									alignItems="center"
									display={{ xs: 'none', lg: 'flex' }}
									gap="5px"
									sx={{ color: '#697586' }}
								>
									<Box
										component="svg"
										fill="none"
										height="18"
										sx={{ flexShrink: 0, height: 20, width: 20 }}
										viewBox="0 0 20 18"
										width="20"
									>
										<path
											d="M10.8334 8.16667H14.8334C15.7668 8.16667 16.2335 8.16667 16.59 8.34832C16.9036 8.50811 17.1586 8.76308 17.3184 9.07668C17.5 9.4332 17.5 9.89991 17.5 10.8333V16.5M10.8334 16.5V4.16667C10.8334 3.23325 10.8334 2.76654 10.6517 2.41002C10.4919 2.09641 10.2369 1.84144 9.92334 1.68166C9.56682 1.5 9.10011 1.5 8.16669 1.5H5.16669C4.23327 1.5 3.76656 1.5 3.41004 1.68166C3.09643 1.84144 2.84147 2.09641 2.68168 2.41002C2.50002 2.76654 2.50002 3.23325 2.50002 4.16667V16.5M18.3334 16.5H1.66669M5.41669 4.83333H7.91669M5.41669 8.16667H7.91669M5.41669 11.5H7.91669"
											stroke="#697586"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="1.66667"
										/>
									</Box>
									<Typography
										fontSize={{ lg: '14px' }}
										fontWeight={500}
										lineHeight={{ lg: '20px' }}
										sx={{ fontFamily: 'Roboto' }}
									>
										{study?.imagingOrganization?.name || study?.managingOrganization?.name || '-'}
									</Typography>
								</Box>
								{Boolean(study?.imagingOrganization?.phone) && (
									<Box alignItems="center" display="flex" gap="5px" sx={{ color: '#697586' }}>
										<svg fill="none" height="18" viewBox="0 0 18 18" width="18">
											<path
												d="M5.98356 6.37779C6.56356 7.58581 7.35422 8.71801 8.35553 9.71933C9.35685 10.7206 10.4891 11.5113 11.6971 12.0913C11.801 12.1412 11.8529 12.1661 11.9187 12.1853C12.1523 12.2534 12.4392 12.2045 12.637 12.0628C12.6927 12.0229 12.7403 11.9753 12.8356 11.88C13.1269 11.5887 13.2726 11.443 13.4191 11.3478C13.9715 10.9886 14.6837 10.9886 15.2361 11.3478C15.3825 11.443 15.5282 11.5887 15.8196 11.88L15.9819 12.0424C16.4248 12.4853 16.6462 12.7067 16.7665 12.9446C17.0058 13.4175 17.0058 13.9761 16.7665 14.449C16.6462 14.6869 16.4248 14.9083 15.9819 15.3512L15.8506 15.4825C15.4092 15.9239 15.1886 16.1446 14.8885 16.3131C14.5556 16.5001 14.0385 16.6346 13.6567 16.6334C13.3126 16.6324 13.0774 16.5657 12.607 16.4322C10.0792 15.7147 7.69387 14.361 5.70388 12.371C3.7139 10.381 2.36017 7.99569 1.6427 5.46786C1.50919 4.99749 1.44244 4.7623 1.44141 4.41818C1.44028 4.03633 1.57475 3.51925 1.76176 3.18633C1.9303 2.88631 2.15098 2.66563 2.59233 2.22428L2.72369 2.09292C3.16656 1.65005 3.388 1.42861 3.62581 1.30833C4.09878 1.0691 4.65734 1.0691 5.1303 1.30832C5.36812 1.42861 5.58955 1.65005 6.03242 2.09291L6.19481 2.25531C6.48615 2.54665 6.63182 2.69231 6.72706 2.8388C7.08622 3.3912 7.08622 4.10336 6.72706 4.65576C6.63182 4.80225 6.48615 4.94791 6.19481 5.23925C6.09955 5.33451 6.05192 5.38214 6.01206 5.43782C5.87038 5.63568 5.82146 5.92256 5.88957 6.15619C5.90873 6.22193 5.93367 6.27389 5.98356 6.37779Z"
												stroke="#697586"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="1.67"
											/>
										</svg>
										<Typography
											fontSize={{ lg: '14px' }}
											fontWeight={500}
											lineHeight={{ lg: '20px' }}
											sx={{ fontFamily: 'Roboto' }}
										>
											{study?.imagingOrganization?.phone || '-'}
										</Typography>
									</Box>
								)}
							</Box>
							<Box
								alignItems="flex-end"
								display="flex"
								flexDirection="column"
								sx={{ color: 'var(--color-primary)', fontSize: 14 }}
							>
								<DownloadStudy setMoreDrawerOpen={setMoreDrawerOpen} study={study} />
								<BurnToDisc setMoreDrawerOpen={setMoreDrawerOpen} study={study} />
								{forms?.length > 0 && showFormIcon && (
									<Button
										sx={{
											color: '#42A5F5',
											textTransform: 'none',
											fontWeight: '600',
											fontSize: '14px',
											padding: '0 0',
											lineHeight: '20px',
										}}
										onClick={() => {
											const { description, id, patientId, signedAt, createdTimeUTC } = study;

											initFormDrawer({
												date: signedAt ?? createdTimeUTC,
												type: description ?? 'All modalities',
												patientId,
												studyId: id,
											});
										}}
									>
										<Typography
											alignItems="center"
											display="flex"
											opacity={0.6}
											sx={{ cursor: 'pointer', textAlign: 'left' }}
										>
											{t('Access forms')}{' '}
											<Icon component={ChevronRight} sx={{ height: '20px' }} />
										</Typography>
									</Button>
								)}
							</Box>
						</Stack>
						<Divider orientation="horizontal" sx={{ my: 3 }} variant="fullWidth" />
						<Stack alignItems="center" direction="row" justifyContent="space-between">
							<Box>
								<Typography color="#697586" fontWeight={500}>
									{t('Description')}
								</Typography>
								<Typography color="#364152" fontWeight={500}>
									{study?.description || 'N/A'}
								</Typography>
							</Box>
							<Box display="flex" gap="10px">
								<Button
									color="info"
									sx={STUDY_CARD_STYLES.BUTTON(isTablet)}
									variant="outlined"
									onClick={() => {
										initStudyDrawer({ study, forceFacilityFetch: true });
									}}
								>
									<Icon component={share} sx={{ height: '20px' }} /> {t('Share via email')}
								</Button>
								<Button
									color="primary"
									data-testid="view-study-button"
									sx={{
										...STUDY_CARD_STYLES.BUTTON(isTablet),
										background: 'var(--color-primary)',
										color: 'white',
									}}
									variant="contained"
									onClick={handleStudyNavigate}
								>
									{t('View Results')}
								</Button>
							</Box>
						</Stack>
					</>
				)}
			</Card>
			<MobileBottomDrawer
				background="#ffffff"
				open={Boolean(studyInformationDetails)}
				onClose={() => setStudyInformationDetails(null)}
			>
				<MobileStudyDetail data={studyInformationDetails} />
			</MobileBottomDrawer>
		</>
	);
};

export default StudyCard;
