import axios from 'axios';

const readNotification = async ({ __config, id }) => {
	const response = await axios.put(`${__config.data_sources.blume}Notification/${id}`);

	if (!response?.status?.toString()?.startsWith(2)) {
		return [];
	}

	return response.data;
};

export default readNotification;
