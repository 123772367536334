import { v4 as uuidv4 } from 'uuid';

const extractEmergencyContactsFromProfile = profile => {
	const emergencyContactsArray = profile?.emergencyContacts ? [...profile.emergencyContacts] : [];
	return emergencyContactsArray.filter(
		(contact, index, self) => self.findIndex(c => c.email === contact.email) === index
	);
};

const extractEmergencyContactsFromProfileWithAdd = (profile, added) => {
	const emergencyContacts = extractEmergencyContactsFromProfile(profile);
	emergencyContacts.push({ added, id: added.emergencyContactId || uuidv4() });
	return emergencyContacts;
};

const extractEmergencyContactsFromProfileWithDelete = (profile, deletedId) => {
	const emergencyContacts = extractEmergencyContactsFromProfile(profile).filter(
		contact => contact.emergencyContactId !== deletedId
	);

	return emergencyContacts;
};

const extractEmergencyContactsFromProfileWithUpdate = (profile, updatedContact) => {
	const emergencyContacts = extractEmergencyContactsFromProfile(profile).map(contact => {
		if (updatedContact.emergencyContactId == contact.emergencyContactId)
			return {
				id: updatedContact.emergencyContactId,
				name: updatedContact.name || contact.name,
				relation: updatedContact.relation || updatedContact.relationship || contact.relation,
				phone: updatedContact.phone || contact.phone,
				email: updatedContact.email || contact.email,
				emergencyContactId: updatedContact.emergencyContactId,
			};

		return contact;
	});

	return emergencyContacts;
};

export {
	extractEmergencyContactsFromProfile,
	extractEmergencyContactsFromProfileWithAdd,
	extractEmergencyContactsFromProfileWithDelete,
	extractEmergencyContactsFromProfileWithUpdate,
};
