import React, { useCallback, useState } from 'react';
import useMeasure from 'react-use-measure';

import Box from '@mui/material/Box';
import AvatarGroup from '@mui/material/AvatarGroup';

import { useShareDrawerStore } from '@worklist-2/patientPortal/src/stores/share-drawer';
import { mediaTablet, mediaMobile } from '@rs-core/utils/responsiveUtils';
import GenericDrawer from '@rs-ui/components/Drawers/GenericDrawer/GenericDrawer';

import SharedAvatar from '@worklist-2/patientPortal/src/components/SharedHistory/SharedAvatar/SharedAvatar';
import SharedAvatarMobile from '@worklist-2/patientPortal/src/components/SharedHistory/SharedAvatarMobile/SharedAvatarMobile';

const SharedUsers = ({ matches, users, getShareContactList, isOpenSharedDrawer, setIsOpenSharedDrawer, study }) => {
	const initStudyDrawer = useShareDrawerStore(state => state.initStudyDrawer);

	const commonStyle = {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		overflow: 'hidden',
		minWidth: '130px',
		position: 'relative',
		scrollbarWidth: 'none',
		msOverflowStyle: 'none',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	};

	const mobileStyle = {
		flexDirection: 'row',
		alignItems: 'flex-start',
		backgroundColor: 'transparent',
		paddingTop: 0,
		overflow: 'auto',
	};

	const rowStyle = {
		...commonStyle,
		borderRadius: '0px',
		paddingBottom: 0,
		[mediaTablet]: {
			...mobileStyle,
			height: 'unset',
		},
		[mediaMobile]: {
			...mobileStyle,
			height: 'unset',
		},
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const [measureRef, measuredBounds] = useMeasure();

	const toggleUser = user => {
		// nothing
	};
	const onClickContact = useCallback(() => {
		initStudyDrawer({ study });
	}, [initStudyDrawer, study]);

	const maxUserCount = Math.floor(measuredBounds.width / 105);

	const getSharedAvatar = () =>
		users.map(user =>
			matches ? (
				<SharedAvatarMobile
					key={user?.shareType === 'Facility' ? user?.facilityId : user.email}
					avatar={user.avatar}
					name={user.name}
				/>
			) : (
				<SharedAvatar
					key={user?.shareType === 'Facility' ? user?.facilityId : user.email}
					avatar={user.avatar}
					isFacility={user?.shareType === 'Facility'}
					name={user.name}
				/>
			)
		);

	return (
		<Box ref={measureRef} className="shareUserList">
			<Box sx={rowStyle}>
				<AvatarGroup
					max={maxUserCount}
					sx={{
						width: '100%',
						height: '100%',
						justifyContent: 'flex-end',
						'& .MuiAvatarGroup-avatar': {
							backgroundColor: '#E3B269',
							width: '98px',
							height: '98px',
							borderRadius: '10px',
							ml: '5px !important',
						},
						'.MuiAvatar-root': {
							border: 0,
							marginLeft: 0,
						},
					}}
					variant="rounded"
					onClick={e => {
						onClickContact();
						setAnchorEl(anchorEl ? null : e.currentTarget);
					}}
				>
					{getSharedAvatar()}
				</AvatarGroup>
			</Box>
			<GenericDrawer
				headerFlag
				drawerColor="rsSecondary.medium"
				drawerOpen={isOpenSharedDrawer}
				setDrawerOpen={setIsOpenSharedDrawer}
				title="SHARED WITH"
				toggleDrawerButtonFlag={false}
				width={422}
			>
				{getShareContactList ? getShareContactList() : undefined}
			</GenericDrawer>
		</Box>
	);
};

export default SharedUsers;
