import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { mediaMobile } from '@rs-core/utils/responsiveUtils';

const SharedAvatarMobile = ({ name, avatar }) => (
	<Tooltip title={name}>
		<Button
			sx={{
				minWidth: '98px',
				width: '98px',
				height: '98px',
				boxSizing: 'border-box',
				borderRadius: '50%',
				border: '2px solid transparent',
				background:
					'linear-gradient(#F3F3F3,#F3F3F3) padding-box, linear-gradient(320.71deg, #42A5F5 7.5%, #BB82DD 88.3%) border-box',
				marginRight: '16px',
				position: 'relative',
				padding: '0',
				textTransform: 'none',
				[mediaMobile]: {
					minWidth: '75px',
					width: '75px',
					height: '75px',
				},
			}}
		>
			<Box
				sx={{
					width: '100%',
					height: '100%',
					boxSizing: 'border-box',
					borderRadius: '50%',
					border: '2px solid #F3F3F3',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Avatar
					src={avatar}
					sx={{
						width: '100%',
						height: '100%',
					}}
				/>
			</Box>
			<Typography
				component="div"
				sx={{
					position: 'absolute',
					color: '#121212',
					fontSize: '12px',
					top: 'calc(100% + 5px)',
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					width: '5rem',
				}}
			>
				{name}
			</Typography>
		</Button>
	</Tooltip>
);

SharedAvatarMobile.propTypes = {
	/**
	 * Name
	 */
	name: PropTypes.string,

	/**
	 * Image src
	 */
	avatar: PropTypes.string,
};

export default SharedAvatarMobile;
