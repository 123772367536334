import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { Svg } from '@worklist-2/ui/src/components/Svg';
import FilterCapsule from '../../FilterCapsule/FilterCapsule';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const chipBackgroundColor = '#293138';
const chipBackgroundOpenColor = '#343739';

const FilterPanel = ({
	hideDivider,
	onCapsuleFilter,
	onRemoveCapsule,
	onClearFilters,
	capsules,
	sort,
	scope,
	size,
	capsuleMaxWidth,
	panelWidth,
	filterMenuItems,
	columnMapping,
	showAdvanceFilter,
}) => {
	const elementRef = useRef();
	const [anchorEl, setAnchorEl] = useState(null);
	const [menuWidth, setMenuWidth] = useState(43);
	const [openMenu, setOpenMenu] = useState(false);
	const [activeCapsule, setActiveCapsule] = useState(null);
	const mavenNewSAYTComponent = useBooleanFlagValue('maven-new-sayt-component');

	// Get Translate file name based on scope
	// Use to translate Capsule lable
	const i18nfile = scope?.replace('search', '');
	const { t } = useTranslation(i18nfile, 'gridFilterPanel');

	const onResizePanel = () => {
		setOpenMenu(false);
		setAnchorEl(null);
		setMenuWidth(43);
	};
	useEffect(() => {
		window.addEventListener('resize', onResizePanel);
		return () => {
			window.removeEventListener('resize', onResizePanel);
		};
	}, []);

	// Onclick , show the menu
	const handleMenuDropDownClick = event => {
		setOpenMenu(true);
		setMenuWidth(capsuleMaxWidth);
		setAnchorEl(event && event.currentTarget ? event.currentTarget : null);
	};

	// if clicked somewhere, close the menu
	const handleClose = () => {
		setAnchorEl(null);
		setOpenMenu(false);
		setMenuWidth(43);
		setActiveCapsule(null);
	};

	const filterCaps = useMemo(() => {
		let fullWidth = panelWidth;

		const tempArray = [];
		const showMoreCapsules = [];
		capsules?.forEach((column, index) => {
			// Find the probable width of each capsule, and fill the panel box with them.
			// Each capsule width is dependent on label, selected options.
			let pixel = `${column.label}: ${_.join(column.values, ' | ')}`.length * 8 + 24;
			// If the capsule width is greater than maximum capsule width,
			// then capsule width is set to maximum capsule width.
			pixel = pixel < capsuleMaxWidth ? pixel : capsuleMaxWidth;
			// Common attributes for a filter capsule.
			const searchValue = column.searchValueSet || column.searchParameter;

			let capsuleMenuItems = filterMenuItems[searchValue] || [];

			if (capsuleMenuItems?.length <= 0 && column.capsuleMenuItems?.length > 0) {
				capsuleMenuItems =
					mavenNewSAYTComponent && columnMapping[column.columnIdentity]?.mavenSAYTNewComponent
						? [{}]
						: column.capsuleMenuItems[0];
			}

			if (column.customFilterOptions) {
				capsuleMenuItems = column.customFilterOptions;
			}
			const filterCapsuleProps = {
				scope,
				key: column.label,
				index,
				columnLabel: t(column.label),
				columnIdentity: column.columnIdentity,
				values: column.values,
				displayValue: column.displayValue,

				// For requeststatus and accountStatus, item.code and item.display are undefined when selecting a value from
				// the capsule menu dropdpwn, so we need to pass the item itself
				menuItems:
					capsuleMenuItems?.map(item => {
						// we want to display code for procedure code
						if (searchValue === 'procedureCode') {
							return item.code ?? item.display;
						}

						return searchValue === 'statusIndicator' ? item?.id ?? item : item.display ?? item.code ?? item;
					}) ?? [],
				onCapsuleFilter,
				capsuleMenuItems,
				onRemoveCapsule,
				searchParameter: column?.searchParameter,
				toolTip: column.toolTip,
				searchValueSet: column.searchValueSet ?? column.valueSet ?? column.columnIdentity,
				filterType:
					mavenNewSAYTComponent && columnMapping[column.columnIdentity]?.mavenSAYTNewComponent
						? 'infinite-scroll-multi-select-suggest'
						: column.filterType,
				columnMapping: columnMapping[column.columnIdentity],
				isActive: activeCapsule && activeCapsule === column.label,
				onActiveCapsule: setActiveCapsule,
				showAdvanceFilter,
			};
			// Put a capsule inside panel if panel has available space to accomodate the capsule.
			// If no space, then put capsule in Show more component.
			if (fullWidth > 0 && pixel < fullWidth && panelWidth > 0) {
				fullWidth -= pixel;
				tempArray.push(<FilterCapsule {...filterCapsuleProps} />);
			} else if ((fullWidth > 0 && pixel > fullWidth && pixel > 0) || panelWidth <= 0) {
				showMoreCapsules.push(
					<FilterCapsule
						{...filterCapsuleProps}
						// These attributes are for those capsules which are part of Show more component.
						showMore={{
							backgroundColor: chipBackgroundOpenColor,
							borderRadius: '0 0',
							isStack: true,
							avatarHover: {
								'.MuiSvgIcon-root': {
									visibility: 'visible',
								},
							},
							labelProps: {
								left: 0,
								position: 'absolute',
							},
							dropDownIconProps: {
								right: 20,
								position: 'absolute',
							},
							deleteIconProps: {
								right: 1,
								position: 'absolute',
							},
							avatarVisibility: { visibility: 'hidden' },
							capsuleWidth: capsuleMaxWidth,
							capsuleMaxWidth,
						}}
					/>
				);
			}
		});
		const filterChip = (
			<Box sx={{ paddingLeft: '8px' }}>
				<IconButton
					ref={elementRef}
					size={size}
					sx={{
						width: menuWidth,
						color: 'rsPrimary.main',
						...(openMenu
							? {
									justifyContent: 'flex-start',
									borderRadius: '16px 16px 0px 0px',
									backgroundColor: chipBackgroundOpenColor,
									'&:hover': {
										backgroundColor: chipBackgroundOpenColor,
									},
							  }
							: {
									borderRadius: '16px 16px 16px 16px',
									backgroundColor: chipBackgroundColor,
									'&:hover': {
										backgroundColor: chipBackgroundColor,
									},
							  }),
					}}
					onClick={handleMenuDropDownClick}
				>
					<MoreHorizIcon data-cy="showMoreFilterCapsule" style={{ fill: '#42A5F5' }} />
				</IconButton>
				{openMenu && (
					<Menu
						open
						PaperProps={{
							style: {
								width: menuWidth,
								backgroundColor: '#343739',
								borderTop: 'none',
								marginTop: 0,
								borderRadius: '0 0 16px 16px',
							},
						}}
						TransitionComponent={Collapse}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						elevation={0}
						sx={{
							height: 300,
							position: 'absolute',
						}}
						onClose={handleClose}
					>
						{showMoreCapsules?.map((column, index) => (
							<div key={`div_${index}`}>
								<MenuItem key={`menu_${index}`} sx={{ px: '0px', width: menuWidth, opacity: 1 }}>
									{column}
								</MenuItem>
							</div>
						))}
					</Menu>
				)}
			</Box>
		);
		if (showMoreCapsules?.length > 0) {
			tempArray.push(filterChip);
		}

		return tempArray;
	}, [capsules, panelWidth, menuWidth, capsuleMaxWidth, activeCapsule]);

	const showFilter = filterCaps?.length > 0 || sort?.length > 0;
	return (
		<Stack direction="row" justifyContent="flex-start" spacing={2} sx={{ flexGrow: 1 }}>
			{!hideDivider && <Divider flexItem orientation="vertical" sx={{ height: '16px', alignSelf: 'center' }} />}
			{filterCaps}
			{filterCaps?.length > 0 && (
				<Divider flexItem orientation="vertical" sx={{ height: '16px', alignSelf: 'center' }} />
			)}
			{showFilter && (
				<Tooltip title={t('Clear All')}>
					<IconButton sx={{ opacity: 0.6, padding: '5px 4px' }} onClick={onClearFilters}>
						<Svg htmlColor="#ffffff99;" name="clearFilter" viewBox="-2 -2 20 20" />
					</IconButton>
				</Tooltip>
			)}
		</Stack>
	);
};

FilterPanel.propTypes = {
	/**
	 * An object where each property represents a filter capsule's properties
	 */
	filterCapsuleColumn: PropTypes.object,
	/**
	 * Hides the left divider
	 */
	hideDivider: PropTypes.bool,
	/**
	 * Contains a list of sort entries
	 */
	sort: PropTypes.array,
	/**
	 * Callback used when a new filter is applied
	 */
	onCapsuleFilter: PropTypes.func,
	/**
	 * Callback used when a capsule is removed
	 */
	onRemoveCapsule: PropTypes.func,
	/**
	 * An array of objects containing two values: label, and values
	 */
	visibleCapsules: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			values: PropTypes.arrayOf(PropTypes.string),
		})
	),
	/**
	 * Callback used when to clear filters
	 */
	onClearFilters: PropTypes.func,
};

export default FilterPanel;
