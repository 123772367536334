import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import searchIcon from '@worklist-2/ui/src/assets/img/homepage/search.png';
import FormAPIAutocompleteVariant from '@rs-ui/components/FormNew/formPartials/FormFieldVariants/variants/FormAPIAutocompleteVariant';
import { useScheduleStore } from '../../../../stores';
import { InputAdornment } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

const ReferringPhysician = ({ form }) => {
	const getReferringPhysicians = useScheduleStore(state => state.getReferringPhysicians);

	const { t } = useTranslation('schedule');

	const metaSearchReferringPhysicians = async query => getReferringPhysicians({ searchTerm: query });

	return (
		<Box display="flex" flexDirection="column">
			<Typography
				component="label"
				fontSize="14px"
				fontWeight={500}
				htmlFor="referringPhysician"
				lineHeight="20px"
				sx={{ marginBottom: '8px' }}
			>
				{t('Referring Physician Name')}
			</Typography>
			<FormAPIAutocompleteVariant
				TextFieldProps={{
					InputProps: {
						endAdornment: (
							<InputAdornment position="end" sx={{ mx: 1 }}>
								<KeyboardArrowDown sx={{ color: 'black' }} />
							</InputAdornment>
						),
						placeholder: 'Doe, John DR',
						startAdornment: (
							<InputAdornment position="start" sx={{ ml: 1.6 }}>
								<img alt="search" height="20px" src={searchIcon} />
							</InputAdornment>
						),
						sx: {
							maxHeight: '40px',
							padding: '0px 0px !important',
							borderRadius: '6px',
							'.MuiAutocomplete-input': {
								padding: {
									sx: '7.5px 32px 7.5px 6px',
								},
							},
						},
					},
				}}
				formHook={form}
				name="referringPhysician"
				props={{
					isOptionEqualToValue: (option, value) => !value || option?.id === value?.id,
					getOptionLabel: option => (option?.name ? option?.name : ''),
					renderOption: (props, option) => (
						<li {...props} key={option?.id}>
							{option?.name}
						</li>
					),
				}}
				sx={{
					height: '40px',
					borderRadius: '4px',
					width: '100%',
					background: '#ffffff',
					fontSize: 14,
				}}
				onChange={option => {
					if (option) form.setValue('referringPhysician', { name: option.name, id: option.id });
					else form.setValue('referringPhysician', {});
				}}
				onSearch={metaSearchReferringPhysicians}
			/>
			<Typography
				sx={{
					color: '#475467',
					fontSize: '12px',
					lineHeight: '20px',
					marginTop: '6px',
				}}
			>
				{t('Optional')}
			</Typography>
		</Box>
	);
};

export default ReferringPhysician;
