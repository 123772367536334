import { getEnabledElement, triggerEvent, eventTarget, VolumeViewport } from '@cornerstonejs/core';
import { Enums, EllipticalROITool, utilities, annotation, cursors, drawing } from '@cornerstonejs/tools';
import { getSUVTextLines } from '../cornerstoneTools/utils/calculateSUV';
import { CORNERSTONE_EVENTS } from '../contexts/consts/consts';

const { Events } = Enums;
const { triggerAnnotationRenderForViewportIds, math } = utilities;
const { getCanvasEllipseCorners } = math.ellipse;

const { removeAnnotation, getAnnotations } = annotation.state;
const { resetElementCursor } = cursors.elementCursor;

const {
	drawHandles: drawHandlesSvg,
	drawLinkedTextBox: drawLinkedTextBoxSvg,
	drawEllipseByCoordinates: drawEllipseSvg,
	drawCircle: drawCircleSvg,
} = drawing;
const { getTextBoxCoordsCanvas } = utilities.drawing;
const { isAnnotationVisible } = annotation.visibility;
const { isAnnotationLocked } = annotation.locking;

class EllipticalROIToolUpdated extends EllipticalROITool {
	_endCallback = evt => {
		const eventDetail = evt.detail;
		const { element } = eventDetail;

		const { annotation, viewportIdsToRender, newAnnotation, hasMoved } = this.editData;
		const { data } = annotation;

		if (newAnnotation && !hasMoved) {
			return;
		}

		// Elliptical ROI tool should reset its highlight to false on mouse up (as opposed
		// to other tools that keep it highlighted until the user moves. The reason
		// is that we use top-left and bottom-right handles to define the ellipse,
		// and they are by definition not in the ellipse on mouse up.
		annotation.highlighted = false;
		data.handles.activeHandleIndex = null;

		this._deactivateModify(element);
		this._deactivateDraw(element);

		resetElementCursor(element);

		const enabledElement = getEnabledElement(element);
		const { renderingEngine } = enabledElement;

		this.editData = null;
		this.isDrawing = false;

		if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
			removeAnnotation(annotation.annotationUID);
		}

		triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

		const eventType = Events.ANNOTATION_COMPLETED;

		if (newAnnotation) {
			triggerEvent(eventTarget, CORNERSTONE_EVENTS.CORNERSTONE_ADD_ANNOTATION, {
				annotation,
				currentPoints: eventDetail?.currentPoints,
			});
		}

		triggerEvent(eventTarget, eventType, { annotation });
	};

	renderAnnotation = (enabledElement, svgDrawingHelper) => {
		let renderStatus = false;
		const { viewport } = enabledElement;
		const { element } = viewport;

		let annotations = getAnnotations(this.getToolName(), element);

		if (!annotations?.length) {
			return renderStatus;
		}

		annotations = this.filterInteractableAnnotationsForElement(element, annotations);

		if (!annotations?.length) {
			return renderStatus;
		}

		const targetId = this.getTargetId(viewport);

		const renderingEngine = viewport.getRenderingEngine();

		const styleSpecifier = {
			toolGroupId: this.toolGroupId,
			toolName: this.getToolName(),
			viewportId: enabledElement.viewport.id,
		};

		for (let i = 0; i < annotations.length; i++) {
			const annotation = annotations[i];
			const { annotationUID, data } = annotation;
			const { handles } = data;
			const { points, activeHandleIndex } = handles;

			styleSpecifier.annotationUID = annotationUID;

			const { color, lineWidth, lineDash } = this.getAnnotationStyle({
				annotation,
				styleSpecifier,
			});

			const canvasCoordinates = points.map(p => viewport.worldToCanvas(p));

			const rotation = Math.abs(viewport.getRotation() - (data.initialRotation || 0));
			const canvasCorners = getCanvasEllipseCorners(canvasCoordinates); // bottom, top, left, right, keep as is
			const { centerPointRadius } = this.configuration;

			// If cachedStats does not exist, or the unit is missing (as part of import/hydration etc.),
			// force to recalculate the stats from the points
			if (!data.cachedStats[targetId] || data.cachedStats[targetId].areaUnit == null) {
				data.cachedStats[targetId] = {
					Modality: null,
					area: null,
					max: null,
					mean: null,
					stdDev: null,
					areaUnit: null,
				};

				this._calculateCachedStats(annotation, viewport, renderingEngine, enabledElement);
			} else if (annotation.invalidated) {
				this._throttledCalculateCachedStats(annotation, viewport, renderingEngine, enabledElement);
				// If the invalidated data is as a result of volumeViewport manipulation
				// of the tools, we need to invalidate the related viewports data, so that
				// when scrolling to the related slice in which the tool were manipulated
				// we re-render the correct tool position. This is due to stackViewport
				// which doesn't have the full volume at each time, and we are only working
				// on one slice at a time.
				if (viewport instanceof VolumeViewport) {
					const { referencedImageId } = annotation.metadata;

					// invalidate all the relevant stackViewports if they are not
					// at the referencedImageId
					for (const targetId in data.cachedStats) {
						if (targetId.startsWith('imageId')) {
							const viewports = renderingEngine.getStackViewports();

							const invalidatedStack = viewports.find(vp => {
								// The stack viewport that contains the imageId but is not
								// showing it currently
								const referencedImageURI = csUtils.imageIdToURI(referencedImageId);
								const hasImageURI = vp.hasImageURI(referencedImageURI);
								const currentImageURI = csUtils.imageIdToURI(vp.getCurrentImageId());
								return hasImageURI && currentImageURI !== referencedImageURI;
							});

							if (invalidatedStack) {
								delete data.cachedStats[targetId];
							}
						}
					}
				}
			}

			// If rendering engine has been destroyed while rendering
			if (!viewport.getRenderingEngine()) {
				console.warn('Rendering Engine has been destroyed');
				return renderStatus;
			}

			let activeHandleCanvasCoords;

			if (!isAnnotationVisible(annotationUID)) {
				continue;
			}

			if (!isAnnotationLocked(annotation) && !this.editData && activeHandleIndex !== null) {
				// Not locked or creating and hovering over handle, so render handle.
				activeHandleCanvasCoords = [canvasCoordinates[activeHandleIndex]];
			}

			if (activeHandleCanvasCoords) {
				const handleGroupUID = '0';
				drawHandlesSvg(svgDrawingHelper, annotationUID, handleGroupUID, activeHandleCanvasCoords, {
					color,
				});
			}

			const dataId = `${annotationUID}-ellipse`;
			const ellipseUID = '0';
			drawEllipseSvg(
				svgDrawingHelper,
				annotationUID,
				ellipseUID,
				canvasCoordinates,
				{
					color,
					lineDash,
					lineWidth,
				},
				dataId
			);

			// draw center point, if "centerPointRadius" configuration is valid.
			if (centerPointRadius > 0) {
				const minRadius = Math.min(
					Math.abs(canvasCorners[0][0] - canvasCorners[1][0]) / 2, // horizontal radius
					Math.abs(canvasCorners[0][1] - canvasCorners[1][1]) / 2 // vertical radius
				);
				if (minRadius > 3 * centerPointRadius) {
					const centerPoint = this._getCanvasEllipseCenter(canvasCoordinates);
					drawCircleSvg(
						svgDrawingHelper,
						annotationUID,
						`${ellipseUID}-center`,
						centerPoint,
						centerPointRadius,
						{
							color,
							lineDash,
							lineWidth,
						}
					);
				}
			}

			renderStatus = true;

			const options = this.getLinkedTextBoxStyle(styleSpecifier, annotation);
			if (!options.visibility) {
				data.handles.textBox = {
					hasMoved: false,
					worldPosition: [0, 0, 0],
					worldBoundingBox: {
						topLeft: [0, 0, 0],
						topRight: [0, 0, 0],
						bottomLeft: [0, 0, 0],
						bottomRight: [0, 0, 0],
					},
				};
				continue;
			}

			const modality = data.cachedStats?.[targetId].Modality;
			const textLines =
				this.configuration.wonIvPetSuv && modality === 'PT'
					? getSUVTextLines(data, viewport, targetId)
					: this.configuration.getTextLines(data, targetId);
			if (!textLines || textLines.length === 0) {
				continue;
			}

			// Poor man's cached?
			let canvasTextBoxCoords;

			if (!data.handles.textBox.hasMoved) {
				canvasTextBoxCoords = getTextBoxCoordsCanvas(canvasCorners);

				data.handles.textBox.worldPosition = viewport.canvasToWorld(canvasTextBoxCoords);
			}

			const textBoxPosition = viewport.worldToCanvas(data.handles.textBox.worldPosition);

			const textBoxUID = '1';
			const boundingBox = drawLinkedTextBoxSvg(
				svgDrawingHelper,
				annotationUID,
				textBoxUID,
				textLines,
				textBoxPosition,
				canvasCoordinates,
				{},
				options
			);

			const { x: left, y: top, width, height } = boundingBox;

			data.handles.textBox.worldBoundingBox = {
				topLeft: viewport.canvasToWorld([left, top]),
				topRight: viewport.canvasToWorld([left + width, top]),
				bottomLeft: viewport.canvasToWorld([left, top + height]),
				bottomRight: viewport.canvasToWorld([left + width, top + height]),
			};
		}

		return renderStatus;
	};
}

export default EllipticalROIToolUpdated;
