// @flow
import React, { useContext, useEffect } from 'react';
import { generateRandomString } from '@rs-core/utils/generateRandomString';
import FormContext from '../../../formContexts/FormContext';
import { hasError } from '../../../formUtils/formUtils';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { fhirExtensionUrls } from '@rs-core/fhir';
// MUI Components
import { Button, Box, Grid, Icon, Typography, Tooltip, IconButton, InputAdornment } from '@mui/material';

// MUI Icons
import {
	Email as EmailIcon,
	Phone as PhoneIcon,
	DeleteOutline as DeleteOutlinedIcon,
	Add as AddIcon,
	StarBorder,
	StarOutlined,
} from '@mui/icons-material';

import FormTextFieldVariant from './FormTextFieldVariant';
import FormMUIPhoneVariant from './FormMUIPhoneVariant';
import BlumeInviteStatus from '@rs-ui/views/PatientInformationView/components/BlumeInviteStatus';

const CONTACT_TYPES = [
	{ key: 'email', icon: EmailIcon, title: 'Email', component: FormTextFieldVariant },
	{ key: 'phone', icon: PhoneIcon, title: 'Phone', component: FormMUIPhoneVariant },
];

const ActionButton = ({ addTooltipTitle, deleteTooltipTitle, onAddClick, onDeleteClick }) => (
	<Box sx={{ display: 'flex', alignItems: 'center' }}>
		<Tooltip title={addTooltipTitle}>
			<Button sx={styles.actionButton} onClick={onAddClick}>
				<AddIcon sx={{ color: '#FFFFFF99' }} />
			</Button>
		</Tooltip>
		<Tooltip title={deleteTooltipTitle}>
			<Button sx={styles.actionButton} onClick={onDeleteClick}>
				<DeleteOutlinedIcon sx={{ color: '#CF6679' }} />
			</Button>
		</Tooltip>
	</Box>
);

const FormContactInformationVariantV2 = ({ formHook, name, props }) => {
	const { readOnly, hoveredField, setHoveredField } = useContext(FormContext);
	const {
		control,
		getValues,
		formState: { errors },
	} = formHook;
	const { t } = useTranslation('organization');
	const phoenixReinviteBlumeUsers = useBooleanFlagValue('phoenix-reinvite-blume-users');
	const { fields, append, remove } = useFieldArray({ control, name });

	// Initialize primary email on component mount without marking form as dirty
	useEffect(() => {
		if (phoenixReinviteBlumeUsers) {
			const telecoms = getValues()[name];
			const emailTelecoms = telecoms?.filter(d => d?.system === 'email');

			if (emailTelecoms?.length) {
				const updatedTelecoms = telecoms?.map(d => ({
					...d,
					isPrimary:
						d?.system === 'email' &&
						(emailTelecoms?.length === 1 ||
							Boolean(
								d?.extension?.some(
									t =>
										t?.url?.includes(fhirExtensionUrls.common.isPrimary) && t?.valueBoolean === true
								)
							)),
				}));

				// Set value without marking as dirty
				formHook.setValue(name, updatedTelecoms, {
					shouldDirty: false,
					shouldTouch: false,
				});
			}
		}
	}, []);

	const handleTogglePrimaryEmail = index => () => {
		const values = getValues();
		const currentFields = values[name];

		// Check if there's actually going to be a change
		const isCurrentlyPrimary =
			currentFields[index]?.isPrimary ||
			Boolean(
				currentFields[index]?.extension?.some(
					item => item?.url?.includes(fhirExtensionUrls.common.isPrimary) && item?.valueBoolean === true
				)
			);

		// If clicking on an already primary email, don't do anything
		if (isCurrentlyPrimary) {
			return;
		}

		// Update all email fields
		const updatedFields = currentFields.map((field, i) => {
			if (field.system === 'email') {
				const isPrimary = i === index;
				return {
					...field,
					isPrimary,
					extension: field.extension?.map(ext =>
						ext?.url?.includes(fhirExtensionUrls.common.isPrimary)
							? { ...ext, valueBoolean: isPrimary }
							: ext
					) || [{ url: fhirExtensionUrls.common.isPrimary, valueBoolean: isPrimary }],
				};
			}
			return field;
		});

		// Only update and mark as dirty if there's an actual change
		if (JSON.stringify(currentFields) !== JSON.stringify(updatedFields)) {
			formHook.setValue(name, updatedFields, { shouldDirty: true });
		}
	};

	const handleAddContact = system => {
		const currentFields = getValues()[name];
		const isFirstEmail = system === 'email' && !currentFields?.some(f => f.system === 'email');

		append({
			system,
			value: '',
			rank: fields.length + 1,
			id: generateRandomString(16),
			isPrimary: isFirstEmail,
			extension: isFirstEmail
				? [
						{
							url: fhirExtensionUrls.common.isPrimary,
							valueBoolean: true,
						},
				  ]
				: [],
		});
	};

	const isEmailPrimary = field =>
		field.isPrimary ||
		Boolean(
			field?.extension?.some(
				item => item?.url?.includes(fhirExtensionUrls.common.isPrimary) && item?.valueBoolean === true
			)
		);

	const renderContactField = (field, index, contactType) => {
		const fieldName = `${name}.${index}.value`;
		const isHovered = hoveredField === fieldName;
		const hasFieldError = hasError(fieldName, errors);
		const isReadOnlyField = readOnly && !isHovered && !hasFieldError;
		const FieldComponent = contactType.component;
		const isPrimary = contactType.key === 'email' ? isEmailPrimary(field) : false;
		const commonProps = {
			formHook,
			name: fieldName,
			props: {
				...props,
				id: `form-field-${contactType.title}`,
				sx: { marginBottom: '6px', width: '100%' },
				disabled: props[`disable${contactType.title}`],
				readOnly: isReadOnlyField,
				hoveredField: isHovered || hasFieldError,
				placeholder: 'N/A',
			},
		};

		if (contactType.key === 'email' && phoenixReinviteBlumeUsers) {
			commonProps.props.InputProps = {
				endAdornment: (
					<InputAdornment position="start" sx={styles.inputAdornment}>
						<IconButton sx={styles.starButton} onClick={handleTogglePrimaryEmail(index)}>
							<Icon
								component={isPrimary ? StarOutlined : StarBorder}
								fontSize="small"
								sx={{
									opacity: '1 !important',
									color: isPrimary ? 'var(--color-primary)' : '#FFFFFF80',
								}}
							/>
						</IconButton>
					</InputAdornment>
				),
			};
		}

		return (
			<Box
				key={field.id}
				sx={styles.fieldContainer}
				onMouseEnter={() => !props[`disable${contactType.title}`] && setHoveredField?.(fieldName)}
				onMouseLeave={() => !props[`disable${contactType.title}`] && setHoveredField?.('')}
			>
				<FieldComponent {...commonProps} />
				{!isReadOnlyField && (
					<ActionButton
						addTooltipTitle={t(`icons.add${contactType.title}`)}
						deleteTooltipTitle={t('icons.deleteContact')}
						onAddClick={() => handleAddContact(contactType.key)}
						onDeleteClick={() => remove(index)}
					/>
				)}
				{isPrimary && phoenixReinviteBlumeUsers && <BlumeInviteStatus isHovered={!isReadOnlyField} />}
			</Box>
		);
	};

	return (
		<Grid container columnSpacing={8}>
			{CONTACT_TYPES.map(contactType => {
				if (props[`hide${contactType.title}`]) return null;

				const typeFields = fields.filter(field => field.system === contactType.key);

				return (
					<Grid key={contactType.key} item sx={{ minHeight: `${typeFields.length * 38 + 56}px` }} xs={6}>
						<Typography component="div" sx={styles.headerText}>
							<Icon component={contactType.icon} sx={{ marginRight: '10px' }} />
							{t(contactType.title)}
						</Typography>
						{typeFields.map((field, index) =>
							renderContactField(
								field,
								fields.findIndex(f => f.id === field.id),
								contactType
							)
						)}
						{!typeFields.length && (
							<Button sx={styles.addButton} onClick={() => handleAddContact(contactType.key)}>
								<AddIcon /> Add {contactType.title}
							</Button>
						)}
					</Grid>
				);
			})}
		</Grid>
	);
};

const styles = {
	headerText: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '15px',
		marginBottom: '8px',
		color: '#FFFFFF99',
	},
	actionButton: {
		width: '34px',
		minWidth: '34px',
		maxHeight: '56px',
		borderRadius: '5px',
		border: '1px solid #C4C4C41A',
		background: '#282828',
		height: '56px',
	},
	fieldContainer: {
		display: 'flex',
		columnGap: '5px',
		alignItems: 'center',
	},
	inputAdornment: {
		borderLeft: '1px solid #FFFFFF36',
		height: '40px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: '0px',
		opacity: 1,
	},
	starButton: {
		height: '30px',
		width: '30px',
		marginLeft: 1,
	},
	addButton: {
		color: '#FFFFFF99',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
		},
	},
};

export default FormContactInformationVariantV2;
