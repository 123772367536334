// React Libraries
import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect, useRef } from 'react';

// Material UI Libraries
import FormControl from '@mui/material/FormControl';
import CancelIcon from '@mui/icons-material/Cancel';
import {
	Autocomplete,
	Box,
	Chip,
	ListItemText,
	MenuItem,
	Select,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';

// utils
import { worklistFilterOptions } from '@worklist-2/ui/src/views/utils/worklistFilterOptions';
import { ColumnFilter } from '../Grid/ColumnFilter';
import { SKIP_TRANSLATION_LIST } from '@rs-ui/helpers/constants';
import _ from 'lodash';

function AdvanceFilter({
	columnMapping,
	searchValueSet,
	menuItems,
	handleClose,
	handleSelectFn,
	columnIdentity,
	filterType,
	displayVal,
	itemList,
	t,
	selectedOperator,
	setSelectedOperator,
}) {
	/**
	 * Selected values
	 */
	const [selectedValues, setSelectedValues] = useState([]);

	/**
	 * Filter operators
	 */
	const getFilterOperatorsFromType = type => {
		const returnFilterlist = [];
		if (type) {
			worklistFilterOptions.forEach(filter => {
				if (filter.type.includes(type)) {
					returnFilterlist.push({ ...filter });
				}
			});
		}
		return returnFilterlist;
	};

	/**
	 * Filter options
	 */
	const filterOptions = useMemo(() => getFilterOperatorsFromType(columnMapping?.type), [columnMapping?.type]);

	/**
	 * Selected display
	 */
	const selectedDisplay = filterOptions?.find(option => option.name === selectedOperator)?.display || '';

	/**
	 * Select ref
	 */
	const selectRef = useRef(null);

	/**
	 * Is text overflow
	 */
	const [isOverflow, setIsOverflow] = useState(false);

	/**
	 * Handle selected values change
	 */
	const handleSelectedValuesChange = (e, values) => {
		const newValue = values[values.length - 1];
		let latestValues = [...values];

		// if the value is empty, do nothing
		if (newValue.trim() === '') {
			return;
		}

		// if the value is not a number, do nothing
		if (columnMapping?.type === 'number' && isNaN(Number(newValue))) {
			return;
		}

		if (columnMapping?.type === 'number' && selectedOperator !== 'equals') {
			latestValues = [newValue];
		}

		// will clear the free input when adding free input by press enter
		if (e?.code === 'Enter' || (e?.code === 'NumpadEnter' && latestValues?.length > 0)) {
			if (!!selectedValues?.length && selectedValues[0] === newValue) {
				setSelectedValues([]);
			} else {
				setSelectedValues(latestValues);
			}
		}

		// Set to filter
		if (handleSelectFn) {
			handleSelectFn(latestValues);
		}
	};

	/**
	 * Get the display value for the filter
	 * @returns {Array}
	 */
	const getDisplayValue = () => {
		const isDateValue =
			_.isArray(displayVal) &&
			(Number.isNaN(Number(displayVal[0])) || displayVal[0] instanceof Date) &&
			new Date(displayVal[0])?.toString() !== 'Invalid Date';

		return columnMapping?.isTranslatable === false ||
			SKIP_TRANSLATION_LIST.includes(searchValueSet) ||
			!_.isArray(displayVal) ||
			isDateValue ||
			menuItems?.length === 0
			? displayVal
			: displayVal.map(value => t(`codePool:${searchValueSet}.${value}`));
	};

	const renderFilterComponent = useMemo(() => {
		if (filterType === 'text-search') {
			return (
				<Autocomplete
					disableCloseOnSelect
					freeSolo
					multiple
					data-testid={`${columnMapping?.label}-filter`}
					id={`${columnMapping?.label}-multiple-limit-tags`}
					options={[]}
					renderInput={params => (
						<TextField
							{...params}
							InputLabelProp={{
								htmlFor: `${columnMapping?.label}-multiple-limit-tags`,
								id: `${columnMapping?.label}-multiple-limit-tags-label`,
							}}
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<div
										style={{
											display: 'flex',
											flexWrap: 'wrap',
											overflow: 'auto',
											height: 'fit-content',
											maxHeight: '75px',
										}}
									>
										{!!params?.InputProps?.startAdornment?.length && (
											<Stack direction="row" flexWrap="wrap" spacing={0}>
												{params.InputProps.startAdornment.map((option, index) =>
													option.type === 'span' ? (
														<span
															key={index}
															style={{
																borderRadius: '100%',
																background: 'rgba(255,255,255,0.2)',
																padding: '4px 7px',
																margin: `3px`,
															}}
														>
															{option.props.children}
														</span>
													) : (
														React.cloneElement(option, {
															key: index,
														})
													)
												)}
											</Stack>
										)}
									</div>
								),
								endAdornment: null,
							}}
							data-testid="input-type-field"
							placeholder={t('Search')}
							sx={{
								'& input::placeholder': {
									fontStyle: 'italic',
								},
								'& .MuiOutlinedInput-root': {
									padding: '9px !important',
									minHeight: '56px',
								},
								'& .MuiOutlinedInput-input': {
									display: selectedValues.length === 0 ? 'block' : 'none',
									flexBasis: 'fit-content',
								},
								'&:focus-within .MuiOutlinedInput-input, &:hover .MuiOutlinedInput-input': {
									display: 'block !important',
								},
							}}
						/>
					)}
					renderTags={(tags, getTagProps) =>
						tags?.map((option, index) => (
							<Tooltip
								key={`${columnMapping?.label}-chip-${index}`}
								title={option?.length > 15 ? option : ''}
							>
								<Chip
									key={`${columnMapping?.label}-chip-${index}`}
									data-testid={`${columnMapping?.label}-chip-${index}`}
									{...getTagProps({ index })}
									label={option.length > 15 ? `${option.slice(0, 15)}...` : option}
									sx={{
										height: '32px',
									}}
									onDelete={() => {
										const newSelectedValues = selectedValues.filter(i => i !== option);
										setSelectedValues(newSelectedValues);
										if (handleSelectFn && newSelectedValues?.length > 0) {
											handleSelectFn(newSelectedValues);
										}
									}}
								/>
							</Tooltip>
						))
					}
					sx={{
						width: '100%',
					}}
					value={selectedValues}
					onChange={(e, values) => handleSelectedValuesChange(e, values)}
				/>
			);
		}
		return (
			<ColumnFilter
				isAdvanceFilter
				multipleTags
				columnWidth={190}
				customFilterOptions={columnMapping?.customFilterOptions}
				displayValue={
					filterType !== 'checkbox-multi-select' && filterType !== 'multi-select' && displayVal
						? displayVal
						: columnMapping?.displayValue
				}
				filterType={filterType}
				forceShowRef={{ current: false }}
				hideTags={false}
				itemList={itemList}
				label=""
				preSelectedValues={getDisplayValue()}
				searchComponentConfig={columnMapping?.searchComponentConfig}
				searchId={columnMapping?.searchValueSet ? columnMapping.searchValueSet : columnMapping?.searchParameter}
				suggestScope={columnMapping?.suggestScope}
				testId={`${columnMapping?.label}-filter`}
				valueSetExtraParam={columnMapping?.valueSetExtraParam}
				onSelected={handleSelectFn}
			/>
		);
	}, [columnIdentity, columnMapping, displayVal, menuItems, searchValueSet, selectedValues, selectedOperator]);

	useEffect(() => {
		setSelectedValues(_.isArray(displayVal) ? displayVal : [displayVal]);
	}, [displayVal]);

	useEffect(() => {
		const checkOverflow = () => {
			const element = selectRef.current?.firstChild;
			if (element) {
				setIsOverflow(element.scrollWidth > element.clientWidth);
			}
		};
		checkOverflow();
	}, [selectedOperator]);

	return (
		<Box data-testid="advance-filter-container">
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '40px' }}>
				<Typography
					sx={{
						color: 'rsPrimary.main',
					}}
				>
					{columnMapping?.label}
				</Typography>
				<CancelIcon
					data-testid="advance-filter-cancel-icon"
					sx={{ cursor: 'pointer' }}
					onClick={() => handleClose(selectedValues)}
				/>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '40px' }}>
				<Typography
					sx={{
						opacity: 0.6,
						width: '190px',
					}}
				>
					{t('workList:advancedFilter.Operator')}
				</Typography>
				<Typography
					sx={{
						opacity: 0.6,
						flex: 1,
					}}
				>
					{t('workList:advancedFilter.Value')}
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 'auto' }}>
				<Tooltip disableHoverListener={!isOverflow} placement="top" title={isOverflow ? selectedDisplay : ''}>
					<Select
						ref={selectRef}
						disableUnderline
						MenuProps={{
							PaperProps: {
								sx: {
									'& .MuiMenuItem-root.Mui-selected': {
										backgroundColor: 'rgba(65, 164, 245, 1)',
									},
								},
							},
						}}
						data-testid="filter-capsule-operator"
						id={`${columnIdentity}-filter-capsule-operator`}
						renderValue={selected => filterOptions.find(option => option.name === selected)?.display}
						sx={{
							zIndex: 91,
							border: 'none',
							'& .MuiOutlinedInput-input': {
								width: '155px',
								padding: '0',
							},
							'& fieldset': {
								border: 'none',
							},
						}}
						value={selectedOperator}
						onChange={e => setSelectedOperator(e.target.value)}
					>
						{filterOptions.map((value, index) => (
							<MenuItem
								key={value.name}
								disabled={
									columnMapping?.type === 'number' &&
									selectedValues.length > 1 &&
									value.name !== 'equals'
								}
								sx={{
									zIndex: 92,
									minWidth: '190px',
								}}
								value={value.name}
							>
								<ListItemText primary={t(value.display)} sx={{ padding: '10px 0px 10px 0px' }} />
							</MenuItem>
						))}
					</Select>
				</Tooltip>
				<FormControl fullWidth variant="standard">
					{renderFilterComponent}
				</FormControl>
			</Box>
		</Box>
	);
}

AdvanceFilter.propTypes = {
	columnMapping: PropTypes.object,
	menuItems: PropTypes.array,
	itemList: PropTypes.array,
};

AdvanceFilter.defaultProps = {
	columnMapping: {},
	menuItems: [],
	itemList: [],
};

export default AdvanceFilter;
