import React, { useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller } from 'react-hook-form';
import { getDatePickerLocale, datePickerPaperProps } from '../../../utils/calendarLocalization';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const FormDateTimePicker = ({ formHook, name, props }) => {
	const { i18n } = useTranslation();

	// State to manage open/close of date picker
	const [open, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState('');

	// Default format if not provided
	const format = props?.format || 'MM/DD/YYYY hh:mm A';

	// Function to parse input value
	const parseInputValue = value => {
		// Try parsing with the specified format or fallback to multiple common formats
		const formats = [format, 'YYYY-MM-DD HH:mm:ss', 'MM/DD/YYYY HH:mm', 'DD/MM/YYYY HH:mm', moment.ISO_8601];

		for (const formatToTry of formats) {
			const parsedDate = moment(value, formatToTry, true);
			if (parsedDate.isValid()) {
				return parsedDate.toDate();
			}
		}

		return null;
	};

	return (
		<Controller
			control={formHook.control}
			name={name}
			render={({ field }) => (
				<LocalizationProvider adapterLocale={getDatePickerLocale(i18n)} dateAdapter={AdapterDateFns}>
					<DateTimePicker
						PaperProps={datePickerPaperProps}
						disabled={props?.disabled}
						open={open}
						openTo="day"
						renderInput={textFieldProps => (
							<TextField
								{...textFieldProps}
								fullWidth
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									endAdornment: undefined,
									startAdornment: textFieldProps.InputProps.endAdornment,
									sx: {
										paddingLeft: 0,
										'& > div': {
											height: '100%',
											width: '40px',
											'& > button': {
												padding: 0,
											},
										},
									},
									...props?.InputProps,
								}}
								inputProps={{
									...textFieldProps.inputProps,
									placeholder: format,
									value: inputValue || (field.value ? moment(field.value).format(format) : ''),
									onChange: e => {
										const { value } = e.target;
										setInputValue(value);

										// Only attempt to parse and change form value if it's a valid date
										const parsedDate = parseInputValue(value);
										if (parsedDate) {
											const formattedDate = moment(parsedDate).format(format);
											field.onChange(formattedDate);
										} else {
											field.onChange(value);
										}
									},
								}}
								label={props.name}
								variant={props?.variant || 'outlined'}
								onClick={() => setOpen(true)}
							/>
						)}
						value={field.value ? moment(field.value).toDate() : null}
						views={['year', 'month', 'day', 'hours', 'minutes']}
						onChange={val => {
							if (val) {
								const dateToSave = moment(val).format(format);
								field.onChange(dateToSave);
								setInputValue(moment(val).format(format));
							}
						}}
						onClose={() => setOpen(false)}
						onOpen={() => setOpen(true)}
					/>
				</LocalizationProvider>
			)}
		/>
	);
};

export default FormDateTimePicker;
