import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { Badge } from '@mui/material';
import FaxOutlinedIcon from '@mui/icons-material/FaxOutlined';
import { FaxContext } from '../../../context/FaxContext';
import './AddNewBtn.css';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const AddNewBtn = ({ onClick }) => {
	const { t } = useTranslation('sideMenu');
	const { faxes } = React.useContext(FaxContext);
	const metaReceiveFax = useBooleanFlagValue('meta-receive-fax');

	return (
		<Button
			aria-label="Add New button"
			data-cy="sidebar-addNewBtn"
			startIcon={
				metaReceiveFax && faxes && faxes.length > 0 ? (
					<Badge
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						badgeContent={
							<FaxOutlinedIcon
								fontSize="small"
								style={{ padding: 2, width: 14, height: 14 }}
								sx={{
									right: '23px',
									bottom: '15px',
									background: '#121212',
									borderRadius: 99,
									padding: 0,
									margin: 0,
								}}
							/>
						}
						classes={{
							anchorOriginBottomRight: 'faxBadge',
						}}
						sx={{
							right: '0px',
							bottom: '0px',
							width: '100%',
						}}
					>
						<Tooltip placement="right" title={t('addNew')}>
							<AddIcon className="nav-icon" htmlColor="#ffffff99;" />
						</Tooltip>
					</Badge>
				) : (
					<Tooltip placement="right" title={t('addNew')}>
						<AddIcon className="nav-icon" htmlColor="#ffffff99;" />
					</Tooltip>
				)
			}
			sx={{
				width: '100%',
				justifyContent: 'left',
				color: '#ffffff99',
				textTransform: 'none',
				padding: '0',
				'&:hover': {
					backgroundColor: '#ffffff14',
					cursor: 'default',
				},
				'.MuiButton-startIcon': {
					margin: 0,
				},
				'.MuiSvgIcon-root': {
					height: '24px',
					width: '24px',
				},
			}}
			text="Add New"
			onClick={onClick}
		>
			{t('addNew')}
		</Button>
	);
};

AddNewBtn.propTypes = {
	onClick: PropTypes.func,
};

export default AddNewBtn;
