// @flow

// core
import React, { type Node, useCallback, useEffect, useRef, useState } from 'react';

// components
import Popup from '@rs-ui/components/Popup/Popup';
import UserSelector from '@rs-ui/components/UserSelector/UserSelector';
import Toast from '@rs-ui/components/Toast/Toast';
import CellContent from '@rs-ui/components/Worklist/WorklistGrid/cells/CellContent';
import SingleSelectAutocomplete from '@rs-ui/components/Worklist/WorklistGrid/cells/SingleSelectAutocomplete';
import { PrioritySelector } from '@rs-components/PrioritySelector/PrioritySelector';
// libs
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import Box from '@mui/material/Box';
import isArray from 'lodash/isArray';
import { useTranslation } from 'react-i18next';
//utils
import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
import getExtensionValueCoding from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueCoding';
import useToast from '@rs-core/hooks/useToast';
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';
import { searchScopes } from '@rs-core/context/consts/searchScopes';
import getExtensionValueReference from '@rs-core/fhir/resource/columnMapping/utils/getExtensionValueAttachment';

import { updatePayloadRequester } from '@rs-ui/components/Drawers/NewOrderDrawer/orderApi';
import { useWorklist } from '@rs-ui/components/Worklist/Worklist/contexts/WorklistContext';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import {
	PriorityCodes,
	addCustomPriorityExtension,
	defaultPriority,
	formatExtensionsPriority,
	renderCustomPriority,
} from '@rs-ui/components/PrioritySelector/helperMethods';

interface IInlineEditCellProps {
	/**
	 *  Name of the selected practitioners
	 */
	userName: string;
	/**
	 *  Value of the cell
	 */
	cellValue?: string;
	/**
	 *  A string that will be used for fetching default data in CodePool
	 */
	searchValueSet?: string;
	/**
	 * Internal study ID
	 */
	internalStudyId?: string;
	/**
	 * Imaging org Id
	 */
	imagingOrganizationId?: string | number;
	/**
	 * Component size. Can be `small` or `medium`
	 * */
	size: string;
	/**
	 *  Label of the autocomplete dropdown
	 */
	cellLabel: string;
	/**
	 * Internal organization ID
	 */
	internalOrderId?: string;
	/**
	 *  Search parameters fetching practitioners
	 */
	searchParams: any;
	/**
	 * Referring physician ID
	 */
	referringPhysicianId?: string;
	/**
	 * Referring column size width
	 * */
	columnSize?: number;
	/**
	 * Value update handler.
	 * */
	onUpdate: () => void;
}

export const InlineEditCell = ({
	userName,
	cellValue,
	searchValueSet,
	imagingOrganizationId,
	internalStudyId,
	onUpdate,
	size = 'medium',
	cellLabel,
	internalOrderId,
	searchParams,
	referringPhysicianId,
	columnSize,
}: IInlineEditCellProps): Node => {
	const proactWorklistColorChangeForNonOaiUsers = useBooleanFlagValue(
		'proact-worklist-color-change-for-non-oai-users'
	);
	const proactEnableCustomStudyPriority = useBooleanFlagValue('proact-enable-custom-study-priority');
	const mavenReferringOrganizationNotUpdating = useBooleanFlagValue('mav-2543-referring-organization-not-updating');
	const handleSearchCardClose = () => {
		setSearchCardOpen(false);
	};
	const [selectedPriority, setSelectedPriority] = useState(
		getExtensionValueCoding(cellValue?.row?.original, fhirExtensionUrls.common.priority)?.display ||
			defaultPriority?.display
	);
	const [selectedCustomPriority, setSelectedCustomPriority] = useState(
		getExtensionValueReference(cellValue?.row?.original, fhirExtensionUrls.studyPriority.customPriority)?.display
			? formatExtensionsPriority(
					getExtensionValueReference(
						cellValue?.row?.original,
						fhirExtensionUrls.studyPriority.customPriority
					),
					false,
					getExtensionValueCoding(cellValue?.row?.original, fhirExtensionUrls.common.priority)?.display
			  )
			: renderCustomPriority({
					priority:
						getExtensionValueCoding(cellValue?.row?.original, fhirExtensionUrls.common.priority)?.display ||
						defaultPriority?.display,
					selectedCustomPriority: null,
			  })
	);
	const [searchCardOpen, setSearchCardOpen] = useState(false);
	const { closeWorklistWheel } = useWorklist();
	const ref = useRef(null);
	const { t } = useTranslation(['studyInfo', 'codePool', 'workList']);

	const { setToastUtility, toastOpen, toastMessage, handleToastClose } = useToast();

	const studyLoader = useFhirDataLoader({
		scope: searchScopes.studyRegular,
	});

	const serviceRequestLoader = useFhirDataLoader({
		scope: searchScopes.order,
	});

	const inprogressUpdateMsg = t('Updating study, please wait...');
	const successfulUpdateMsg = t('Successfully updated study.');
	const failedUpdateMsg = t('Could not update study. Please try again.');
	const extensions = {
		performingPhysician: fhirExtensionUrls.common.performingPhysician,
		transcriptionist: fhirExtensionUrls.common.transcriptionist,
		performingTechnologist: fhirExtensionUrls.common.technologist,
		priority: fhirExtensionUrls.common.priority,
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	const onOptionSelect = useCallback(
		newOption => {
			if (newOption) {
				setToastUtility(true, inprogressUpdateMsg);
				if (cellLabel === 'readingPhysician' && internalStudyId) {
					// Update the study - update the 'interpreter' root node
					updateReadingPhysician(newOption);
				} else if (['referringPhysician', 'priority'].includes(cellLabel) && internalOrderId) {
					// Load order and update the order - update the 'requester' node
					updateOrder(newOption);
				} else if (internalStudyId) {
					// For 'performingPhysician', 'performingTechnologist', 'transcriptionist', 'priority',
					// load study and update the study - update extension
					updateStudy(newOption);
				}
			}
		},
		[internalStudyId, internalOrderId, selectedCustomPriority]
	);

	const updateReadingPhysician = newOption => {
		studyLoader
			.patch(internalStudyId, 'interpreter', [
				{
					id: newOption.id,
					reference: `Practitioner/${newOption.id}`,
				},
			])
			.then(res => {
				onUpdate?.(res.interpreter?.[0].display);

				setToastUtility(true, successfulUpdateMsg);
				handleSearchCardClose();
			});
	};

	const updateOrder = newOption => {
		serviceRequestLoader
			.load({
				id: internalOrderId,
				summary: true,
			})
			.then(res => {
				if (res) {
					let payload = { ...res };
					if (cellLabel === 'referringPhysician') {
						const requesterExt = mavenReferringOrganizationNotUpdating
							? [newOption.organizationExtension]
							: res?.requester?.extension;
						const referringOrganization = requesterExt?.length > 0 ? requesterExt[0]?.valueReference : {};
						payload = updatePayloadRequester(res, newOption?.id ? newOption : {}, referringOrganization);
					}

					if (cellLabel === 'priority') {
						if (!newOption?.display && payload?.priority) {
							delete payload?.priority;
						} else if (proactEnableCustomStudyPriority) {
							payload.priority = PriorityCodes[newOption?.priorityCode];
							payload = addCustomPriorityExtension(payload, newOption);
						} else {
							payload.priority = newOption?.display?.toLowerCase();
						}
					}

					serviceRequestLoader.update(internalOrderId, payload).then(updateRes => {
						if (updateRes) {
							setToastUtility(true, successfulUpdateMsg);
							updateRes.mavenReferringOrganizationNotUpdating = mavenReferringOrganizationNotUpdating;
							cellLabel === 'referringPhysician' &&
								onUpdate?.(
									mavenReferringOrganizationNotUpdating ? updateRes : updateRes?.requester?.display
								);
							if (cellLabel === 'priority') {
								const customPriorityIdx = getExtensionValueReference(
									updateRes,
									fhirExtensionUrls.studyPriority.customPriority
								);
								onUpdate?.(
									{ code: newOption?.code, display: updateRes?.priority?.toUpperCase() },
									proactEnableCustomStudyPriority,
									customPriorityIdx
								);
							}
						} else {
							setToastUtility(true, failedUpdateMsg);
						}
						handleSearchCardClose();
					});
				}
			});
	};

	const updateStudy = newOption => {
		studyLoader.load({ id: internalStudyId, summary: false, hideSeriesById: true }).then(res => {
			if (res) {
				const index = res?.extension?.findIndex(({ url }) => url === extensions[cellLabel]);

				if (index > -1) {
					if (!newOption?.id) {
						// This is when selecting Unassigned -> remove the existing node
						res.extension.splice(index, 1);
					} else {
						res.extension[index].valueReference.id = newOption?.id;
						res.extension[index].valueReference.reference = `Practitioner/${newOption?.id}`;
						res.extension[index].valueReference.display = newOption?.display;
					}
				} else {
					const newExt = {
						url: extensions[cellLabel],
						valueReference: {
							id: newOption?.id,
							reference: `Practitioner/${newOption?.id}`,
							display: newOption?.display,
						},
					};
					if (isArray(res.extension)) {
						res.extension.push(newExt);
					} else {
						res.extension = [newExt];
					}
				}

				studyLoader.update(internalStudyId, res).then(updateRes => {
					if (updateRes) {
						const newOptionExt = updateRes?.extension?.filter(
							ext => ext.url === extensions[cellLabel]
						)?.[0];
						setToastUtility(true, successfulUpdateMsg);
						onUpdate?.(newOptionExt?.valueReference?.display);
					} else {
						setToastUtility(true, failedUpdateMsg);
					}
					handleSearchCardClose();
				});
			}
		});
	};

	const handleClickOutside = event => {
		if (ref?.current) {
			const isOptionClicked = event.target.getAttribute('data-info') === 'option';
			if (!ref?.current?.contains(event.target) && !isOptionClicked) {
				handleSearchCardClose();
			}
		}
	};
	return (
		<Popup>
			<Toast
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				message={toastMessage}
				open={toastOpen}
				onClose={handleToastClose}
			/>
			<Box
				ref={ref}
				aria-label="user-cell"
				component="div"
				display="flex"
				flexDirection="row"
				onClick={e => {
					// suppress Clickwheel
					e.stopPropagation();

					// Close the worklist wheel if it's visible
					closeWorklistWheel();

					setSearchCardOpen(true);
				}}
			>
				{searchCardOpen ? (
					searchValueSet ? (
						searchValueSet === 'priority' && proactEnableCustomStudyPriority ? (
							<PrioritySelector
								isAutocompleteEnabled
								InputLabelProps={{
									sx: { fontSize: '14px', lineHeight: '1.3em', ml: '3px' },
								}}
								InputProps={{
									sx: {
										'& .MuiAutocomplete-input': {
											padding: '0 !important',
										},
									},
								}}
								label={t(`workList:imagingWorkListColumns.${cellLabel}`)}
								orgId={imagingOrganizationId}
								placeholder={t('worklist:Search')}
								selectedCustomPriority={selectedCustomPriority}
								selectedPriority={selectedPriority}
								setSelectedCustomPriority={priority => {
									if (priority) {
										setSelectedCustomPriority(priority);
										onOptionSelect(priority);
									}
								}}
								setSelectedPriority={priority => setSelectedPriority(priority)}
								sx={{
									'& .MuiCircularProgress-root': { mr: '5px' },
									'& .MuiOutlinedInput-root': {
										pt: '2.5px !important',
										pr: '4px !important',
										pb: '2.5px !important',
										pl: '6px !important',
									},
								}}
								t={t}
							/>
						) : (
							<SingleSelectAutocomplete
								InputLabelProps={{
									sx: { fontSize: '14px', lineHeight: '1.3em', ml: '3px' },
								}}
								InputProps={{
									sx: {
										'& .MuiAutocomplete-input': {
											padding: '0 !important',
										},
									},
								}}
								cellLabel={cellLabel}
								cellValue={
									cellLabel === 'priority'
										? getExtensionValueCoding(
												cellValue?.row?.original,
												fhirExtensionUrls.common.priority
										  )?.display
										: cellValue?.getValue()
								}
								imagingOrganizationId={imagingOrganizationId}
								placeholder={t('worklist:Search')}
								searchValueSet={searchValueSet}
								sx={{
									'& .MuiCircularProgress-root': { mr: '5px' },
									'& .MuiOutlinedInput-root': {
										pt: '2.5px !important',
										pr: '4px !important',
										pb: '2.5px !important',
										pl: '6px !important',
									},
								}}
								t={t}
								onChange={onOptionSelect}
							/>
						)
					) : (
						<UserSelector
							defaultOpen
							preloadOptions
							showUnassignedOption
							InputLabelProps={{
								sx: { fontSize: '14px', lineHeight: '1.3em', ml: '3px' },
							}}
							InputProps={{
								sx: {
									padding: '0 !important',
								},
							}}
							columnSize={columnSize}
							customSearchIcon={<FolderSharedIcon />}
							isReferringPhysicianField={cellLabel === 'referringPhysician'}
							mainLabel={cellLabel}
							referringPhysicianId={referringPhysicianId}
							searchParams={searchParams}
							shouldHighlightIcon={
								!referringPhysicianId &&
								cellLabel === 'referringPhysician' &&
								userName &&
								proactWorklistColorChangeForNonOaiUsers
							}
							size={size}
							sx={{ p: '0px', '& .MuiCircularProgress-root': { mr: '5px' } }}
							testId="user-card-selector"
							onChange={onOptionSelect}
						/>
					)
				) : (
					<CellContent
						cellLabel={cellLabel}
						cellValue={
							proactEnableCustomStudyPriority && cellLabel === 'priority'
								? selectedCustomPriority || defaultPriority
								: cellLabel === 'priority'
								? getExtensionValueCoding(
										cellValue?.row?.original,
										fhirExtensionUrls.common.priority
								  )?.display?.toUpperCase()
								: cellValue?.getValue()
						}
						searchValueSet={searchValueSet}
						shouldHighlightIcon={
							!referringPhysicianId &&
							cellLabel === 'referringPhysician' &&
							userName &&
							proactWorklistColorChangeForNonOaiUsers
						}
						t={t}
						userName={userName}
					/>
				)}
			</Box>
		</Popup>
	);
};
