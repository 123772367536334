import { List, ListItem, ListItemButton, ListItemText, Popover } from '@mui/material';
import CheckIcon from '@worklist-2/ui/src/assets/icons/checkmark.svg';
import React from 'react';
import RectangleROIToolUpdated from '../../cornerstoneTools/RectangleROITool';
import EllipticalROIToolUpdated from '../../cornerstoneTools/EllipticalROITool';
import PlanarFreehandROITool from '../../cornerstoneTools/PlanarFreehandROITool';
import { useImageViewerMeasurementContext } from '../../contexts/ImageViewerMeasurementContext';
import { SUVOptions } from '../../contexts/consts/consts';

export const ROIToolNames = [
	'RectangleROI',
	RectangleROIToolUpdated.toolName,
	EllipticalROIToolUpdated.toolName,
	PlanarFreehandROITool.toolName,
];

export const SUVMenu = ({ annotationUID, position, isOpen, onClose }) => {
	if (!annotationUID) {
		return null;
	}
	const { applyAnnotationsChanges, updateMeasurementInAnnotationManager, measurementData } =
		useImageViewerMeasurementContext();

	const currentAnnotation = measurementData?.find(x => x.annotationUID === annotationUID);
	const currentOption = currentAnnotation?.data?.selectedOption || SUVOptions.Default;

	const onSUVSelect = option => {
		const updatedAnnotation = {
			...currentAnnotation,
			data: {
				...currentAnnotation.data,
				selectedOption: option,
			},
		};

		applyAnnotationsChanges(updatedAnnotation);
		updateMeasurementInAnnotationManager(updatedAnnotation);
		onClose();
	};

	return (
		<Popover
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			anchorPosition={{ top: position.y, left: position.x }}
			anchorReference="anchorPosition"
			data-testid="SUV-Menu"
			open={isOpen}
			onClose={onClose}
		>
			<List sx={{ padding: 0 }}>
				{Object.values(SUVOptions).map((item, index) => (
					<ListItem key={index} sx={{ padding: 0 }}>
						<ListItemButton sx={{ pt: '4px', pb: '4px' }} onClick={() => onSUVSelect(item)}>
							<CheckIcon
								style={{ visibility: item === currentOption ? 'visible' : 'hidden', minWidth: '24px' }}
							/>
							<ListItemText primary={item} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Popover>
	);
};
