import axios from 'axios';
import { useAuth } from '@rs-core/context/UserAuthContext';
import { useConfig } from '@rs-core/context/ConfigContext';
import { useNewAppointmentContentV2Context } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/contexts/NewAppointmentContentV2ContextProvider';

const useOnUserChange = () => {
	const __config = useConfig();
	const { profiles, setProfiles } = useAuth();

	const { form } = useNewAppointmentContentV2Context();

	const patient = form.watch('patient');

	const onUserChange = (path, value) => {
		const opList = [
			{
				operation: 'ADD',
				path: `/Aggregatedetails/${patient?.profileId}/${path}`,
				value,
			},
		];

		const url = `${__config.data_sources.blume}User?version=3`;
		axios
			.patch(url, opList, {
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json-patch+json',
				},
			})
			.then(res => {
				if (res?.status === 200) {
					const profile = {
						...profiles[patient?.profileId],
						[path]: value,
					};
					const updatedProfiles = { ...profiles, [patient?.profileId]: profile };
					setProfiles(updatedProfiles);
				}
			});
	};

	return {
		onUserChange,
	};
};

export default useOnUserChange;
