import { menuProps } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/consts/consts';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FormSelectVariant from '@rs-ui/components/FormNew/formPartials/FormFieldVariants/variants/FormSelectVariant';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNewAppointmentContentV2Context } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/contexts/NewAppointmentContentV2ContextProvider';
import { parseName } from '@rs-core/fhir/resource/columnMapping/utils/getUserFullName';
import getFhirSourceNameFromPatient from '@worklist-2/patientPortal/src/utils/getFhirSourceNameFromPatient';

const Patient = () => {
	const { t } = useTranslation('appointments');

	const { isUpdateForm, form, patientList, handleOnPatientChange, helperText } = useNewAppointmentContentV2Context();

	const getPatientFhirName = patient => {
		if (!patient) {
			return '';
		}

		const fhirSourceName = getFhirSourceNameFromPatient({
			firstName: patient.firstName,
			lastName: patient.lastName,
			middleName: patient.middleName,
		});

		return parseName(fhirSourceName);
	};

	return (
		<FormSelectVariant
			formHook={form}
			name="patient"
			options={patientList}
			props={{
				disabled: isUpdateForm,
				label: t('Patient'),
				helperText,
				onChange: handleOnPatientChange,
				SelectProps: {
					MenuProps: menuProps,
					renderValue: option => getPatientFhirName(option),
				},
				InputProps: {
					startAdornment: (
						<InputAdornment
							position="start"
							sx={{
								'& .MuiSvgIcon-root': {
									opacity: 1.6,
								},
							}}
						>
							<AccountCircleIcon />
						</InputAdornment>
					),
				},
			}}
			type="select"
		/>
	);
};

export default Patient;
