import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import { useScreenshot } from 'use-react-screenshot';
import Drawer from '@mui/material/Drawer';

import { useConfig } from '@rs-core/context/ConfigContext';
import { mediaDesktopL, useIsTablet } from '@rs-core/utils/responsiveUtils';
import { useAppModeContext } from '@rs-core/context/AppModeContext';
import { FormTool } from '@worklist-2/ui/src/components/FormTool';

import { useFormStore } from '@worklist-2/patientPortal/src/stores/forms';
import { handleFormData, DocumentReferenceJson } from '@worklist-2/patientPortal/src/utils/forms';
import MobileBottomDrawer from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import FormFilling from '@worklist-2/patientPortal/src/components/FormDrawer/v3/FormFilling';

/**
 * Version 3 (used on Blume Web)
 * @param {
 * anchor = 'right'
 * autoSave = true
 * canEditWhenCompleted = false
 * data,
 * drawerColor = '#f8f8f8'
 * fromHistory = false
 * isOrderForm
 * isCompact = false
 * onClose = () => {}
 * onDrawer
 * onSubmit
 * open
 * pdfUrl
 * sectionName = 'Page'
 * setNumOfRequiredNotFilled = () => {}
 * setOpen
 * showBackBtn = false
 * showCloseBtn = true
 * updateForm
 * useFhirUrl = false
 * userSignature = null
 * appointmentId
 * studyId
 * patientId
 * }
 * @returns {React.Component}
 */

const FormDrawerV3 = ({
	anchor = 'right',
	autoSave = true,
	canEditWhenCompleted = false,
	data,
	drawerColor = '#f8f8f8',
	fromHistory = false,
	isOrderForm,
	isCompact = false,
	onClose = () => {},
	onDrawer,
	onSubmit,
	open,
	pdfUrl,
	sectionName = 'Page',
	setNumOfRequiredNotFilled = () => {},
	setOpen,
	showBackBtn = false,
	showCloseBtn = true,
	updateForm,
	useFhirUrl = false,
	userSignature = null,
	appointmentId,
	studyId,
	patientId,
}) => {
	const __config = useConfig();
	const saveData = useFormStore(state => state.saveData);
	const submitInProgress = useFormStore(state => state.isSubmitting);
	const [formData, setFormData] = useState(data?.resource);
	const [lastSyncedForm, setLastSyncedForm] = useState(null);
	const [progressPercentage, setProgressPercentage] = useState(0);
	const [validateFormData, setValidateFormData] = useState(false);
	const [isValidForm, setIsValidForm] = useState(false);
	const [pdfInstance, setPdfInstance] = useState(null);

	const ref = useRef(null);
	const pageRef = useRef([]);

	const [image, takeScreenshot] = useScreenshot();
	const { isPatientPortalMode } = useAppModeContext();
	const appModeIsPatientPortal = isPatientPortalMode();
	const isTablet = useIsTablet();

	const isPdfForm = useMemo(() => !!data?.pdfFileName, [data]);

	const getBlobStringFromBlumeForm = async () => {
		const leftMargin = 30;
		const topMargin = 10;
		const doc1 = new jsPDF('p', 'pt', 'a4', true);
		let doc2;

		const width = doc1.internal.pageSize.getWidth() + 60;
		const height = doc1.internal.pageSize.getHeight();
		const ratio = height / (width - 60);
		const pageImgArray = await getPageImages();

		pageImgArray.forEach(({ index, image: pageImg }) => {
			const imgProps = doc1.getImageProperties(pageImg);

			const imgRatio = imgProps.height / imgProps.width;
			const resize = imgRatio > ratio;

			const pageWidth = width;
			const pageHeight = resize ? (width * imgProps.height) / imgProps.width : height;

			if (index === 0) {
				doc2 = new jsPDF('p', 'pt', [pageWidth, pageHeight], true);
			} else {
				doc2.addPage([pageWidth, pageHeight]);
			}

			doc2.addImage(
				pageImg,
				'PNG',
				leftMargin,
				topMargin,
				pageWidth - leftMargin * 2,
				(resize ? pageHeight : (imgProps.height * width) / imgProps.width) - topMargin * 2,
				undefined,
				'FAST'
			);
		});

		const blob = doc2.output('blob');
		return blob;
	};

	useEffect(() => {
		/**
		 * Enable input form
		 */
		if (pdfInstance) {
			const { annotationManager } = pdfInstance.Core;
			annotationManager.disableReadOnlyMode();
		}
	}, [pdfInstance]);

	useEffect(() => {
		if (data?.questionData?.length > 0) {
			pageRef.current = pageRef.current.slice(0, data.questionData.length);
		}
	}, [data?.questionData]);

	useEffect(() => {
		// calculate total number of questions and check if they have answers
		if (formData && !isPdfForm) {
			let totalRequiredQues = null;
			let answerRequiredQues = 0;
			let subAnswerQuestions = 0;
			let hasAnswer;
			const allQuestions = formData?.formTemplate?.content.flat(1);
			allQuestions.forEach(question => {
				// reset flag per question
				hasAnswer = false;
				if (
					question?.type === 'multipleChoiceGrid' ||
					question?.type === 'checkboxGrid' ||
					question?.type === 'shortAnswer'
				) {
					subAnswerQuestions = 0;
					question?.options?.forEach((eachQ, index) => {
						if (
							question?.answer &&
							question?.answer[question?.answer?.length - 1]?.value[index]?.length > 0
						) {
							subAnswerQuestions += 1;
						}
					});
					hasAnswer = question?.options?.length == subAnswerQuestions;
				} else if (question?.type === 'checkbox') {
					if (
						question?.answer &&
						question?.answer[question?.answer?.length - 1]?.value?.filter(ans => ans.checked).length > 0
					) {
						hasAnswer = true;
					}
				} else if (question?.type === 'multipleChoice') {
					let answerFlag = false;
					if (question.answer) {
						question.answer[question?.answer?.length - 1].value?.forEach(v => {
							if (v.checked) {
								answerFlag = true;
							}
						});
					}

					hasAnswer = answerFlag;
				} else if (
					question?.answer &&
					question?.answer[question?.answer?.length - 1]?.value &&
					question?.answer[question?.answer?.length - 1]?.value !== ''
				) {
					hasAnswer = true;
				}

				// logic to enable/disable Submit button
				// Submit button should not be enabled until all required info has been entered
				if (question?.required) {
					totalRequiredQues += 1;

					if (hasAnswer) {
						answerRequiredQues += 1;
					}
				}
			});
			if (fromHistory) {
				setNumOfRequiredNotFilled(totalRequiredQues - answerRequiredQues);
			}
			setProgressPercentage((answerRequiredQues / totalRequiredQues) * 100);
			setIsValidForm(!totalRequiredQues || totalRequiredQues == answerRequiredQues);
		}
	}, [formData]);

	const closeDrawer = useCallback(() => {
		onClose();
		setOpen(false);
	}, [onClose, setOpen]);

	const getPageImages = useCallback(async () => {
		const imageArray = [];

		const promises = pageRef.current.map((e, index) =>
			takeScreenshot(e).then(img => imageArray.push({ index, image: img }))
		);

		await Promise.all(promises);

		return imageArray.sort((a, b) => parseInt(a.index) - parseInt(b.index));
	}, [takeScreenshot]);

	const saveDocumentReference = useCallback((saveStudyId, savePatientId, saveAppointmentId) => {
		const leftMargin = 30;
		const topMargin = 10;
		const doc1 = new jsPDF('p', 'pt', 'a4', true);
		let doc2;

		const width = doc1.internal.pageSize.getWidth() + 60;
		const height = doc1.internal.pageSize.getHeight();
		const ratio = height / (width - 60);

		getPageImages().then(pageImgArray => {
			pageImgArray.forEach(({ index, image: pageImg }) => {
				const imgProps = doc1.getImageProperties(pageImg);

				const imgRatio = imgProps.height / imgProps.width;
				const resize = imgRatio > ratio;

				const pageWidth = width;
				const pageHeight = resize ? (width * imgProps.height) / imgProps.width : height;

				if (index === 0) {
					doc2 = new jsPDF('p', 'pt', [pageWidth, pageHeight], true);
				} else {
					doc2.addPage([pageWidth, pageHeight]);
				}

				doc2.addImage(
					pageImg,
					'PNG',
					leftMargin,
					topMargin,
					pageWidth - leftMargin * 2,
					(resize ? pageHeight : (imgProps.height * width) / imgProps.width) - topMargin * 2,
					undefined,
					'FAST'
				);
			});

			const blob = doc2.output('datauristring').split(',')[1];

			if (saveStudyId && savePatientId && saveAppointmentId && blob) {
				const documentReferenceTosave = JSON.parse(JSON.stringify(DocumentReferenceJson));

				documentReferenceTosave.subject.id = savePatientId;
				documentReferenceTosave.subject.reference += savePatientId;

				documentReferenceTosave.context.related[0].id = saveStudyId;
				documentReferenceTosave.context.related[0].reference += saveStudyId;

				documentReferenceTosave.context.related[1].id = saveAppointmentId;
				documentReferenceTosave.context.related[1].reference += saveAppointmentId;

				documentReferenceTosave.content[0].attachment.data = blob;

				axios.post(`${__config.data_sources.blume}DocumentReference`, documentReferenceTosave).then(result => {
					if (result?.status === 200) {
						console.log('successfully saved document reference', result);
					} else {
						console.error('failed to save document reference');
					}
				});
			}
		});
	}, []);

	const autoSaveDebounce = useCallback(
		nextValue => {
			if (!submitInProgress && !lastSyncedForm?.completed && nextValue !== lastSyncedForm) {
				saveData({
					forceCloseDrawer: false,
					autoSave: true,
					isPdfForm,
					formData,
					setFormData,
					setValidateFormData,
					isOrderForm,
					updateForm,
					saveDocumentReference,
					useFhirUrl,
					data,
					closeDrawer,
					setLastSyncedForm,
					studyId,
					patientId,
					appointmentId,
					pdfInstance,
					appModeIsPatientPortal,
					getBlobStringFromBlumeForm,
				});
			}
		},
		[
			submitInProgress,
			lastSyncedForm,
			saveData,
			isPdfForm,
			formData,
			isOrderForm,
			updateForm,
			saveDocumentReference,
			useFhirUrl,
			data,
			closeDrawer,
			studyId,
			patientId,
			appointmentId,
			pdfInstance,
			appModeIsPatientPortal,
			getBlobStringFromBlumeForm,
		]
	);

	return isTablet ? (
		<MobileBottomDrawer fullHeight open={open} onClose={() => setOpen(false)}>
			<FormTool
				footer
				noDefaultBanner
				banner={data?.resource?.formTemplate?.header?.banner}
				colour={data?.resource?.formTemplate?.header?.colour}
				formDescription={data?.description}
				formId={isPdfForm ? data?.id : data?.referenceTemplate ? data.referenceTemplate : data?.id}
				formTitle={data?.title}
				handleFormData={(key, index, value) =>
					handleFormData({
						key,
						index,
						value,
						lastSyncedForm,
						autoSave,
						formData,
						setFormData,
						validateFormData,
						autoSaveDebounce,
						data,
					})
				}
				headerString={isOrderForm ? 'Order Form' : data?.title}
				isCompact={isCompact}
				isReadOnly={data?.completed && !canEditWhenCompleted}
				isValidForm={isPdfForm ? true : isValidForm}
				logo={data?.resource?.formTemplate?.header?.logoImage}
				pageRef={pageRef}
				pdfHeight="82vh"
				pdfUrl={pdfUrl}
				progressPercentage={`${progressPercentage}%`}
				questionsData={data?.questionData}
				sectionName={sectionName}
				setPdfInstance={setPdfInstance}
				userSignature={userSignature}
				onCloseClick={closeDrawer}
				onSave={() =>
					saveData({
						forceCloseDrawer: false,
						autoSave: false,
						isPdfForm,
						formData,
						setFormData,
						setValidateFormData,
						isOrderForm,
						updateForm,
						saveDocumentReference,
						useFhirUrl,
						data,
						closeDrawer,
						setLastSyncedForm,
						studyId,
						patientId,
						appointmentId,
						pdfInstance,
						appModeIsPatientPortal,
						getBlobStringFromBlumeForm,
					})
				}
				onSubmit={onSubmit}
			/>
		</MobileBottomDrawer>
	) : fromHistory || !onDrawer ? (
		<div ref={ref} data-testId="fullscreen">
			<FormFilling
				appModeIsPatientPortal={appModeIsPatientPortal}
				appointmentId={appointmentId}
				autoSave={autoSave}
				autoSaveDebounce={autoSaveDebounce}
				formData={formData}
				fromHistory={fromHistory}
				getBlobStringFromBlumeForm={getBlobStringFromBlumeForm}
				isCompact={isCompact}
				isOrderForm={isOrderForm}
				isPdfForm={isPdfForm}
				isReadOnly={data?.completed && !canEditWhenCompleted}
				isValidForm={isValidForm}
				lastSyncedForm={lastSyncedForm}
				pageRef={pageRef}
				patientId={patientId}
				pdfInstance={pdfInstance}
				pdfUrl={pdfUrl}
				progressPercentage={progressPercentage}
				saveDocumentReference={saveDocumentReference}
				sectionName={sectionName}
				setFormData={setFormData}
				setLastSyncedForm={setLastSyncedForm}
				setPdfInstance={setPdfInstance}
				setValidateFormData={setValidateFormData}
				showBackBtn={showBackBtn}
				showCloseBtn={showCloseBtn}
				studyId={studyId}
				updateForm={updateForm}
				useFhirUrl={useFhirUrl}
				userSignature={userSignature}
				validateFormData={validateFormData}
				onSubmit={onSubmit}
				canEditWhenCompleted={canEditWhenCompleted}
				// closeDrawer={closeDrawer}
				data={data}
			/>
		</div>
	) : (
		<Drawer
			PaperProps={{
				sx: {
					background: drawerColor,
					boxSizing: 'border-box',
					maxWidth: '100vw',
					left: anchor === 'left' ? '64px' : null,
					overflow: 'scroll',
					width: '850px',
					[mediaDesktopL]: {
						width: '652px',
					},
				},
			}}
			anchor={anchor}
			data-testid="form-drawer"
			open={open}
		>
			<div ref={ref}>
				<FormFilling
					appModeIsPatientPortal={appModeIsPatientPortal}
					appointmentId={appointmentId}
					autoSave={autoSave}
					autoSaveDebounce={autoSaveDebounce}
					canEditWhenCompleted={canEditWhenCompleted}
					closeDrawer={closeDrawer}
					data={data}
					formData={formData}
					fromHistory={fromHistory}
					getBlobStringFromBlumeForm={getBlobStringFromBlumeForm}
					isCompact={isCompact}
					isOrderForm={isOrderForm}
					isPdfForm={isPdfForm}
					isReadOnly={data?.completed && !canEditWhenCompleted}
					isValidForm={isValidForm}
					lastSyncedForm={lastSyncedForm}
					pageRef={pageRef}
					patientId={patientId}
					pdfInstance={pdfInstance}
					pdfUrl={pdfUrl}
					progressPercentage={progressPercentage}
					saveDocumentReference={saveDocumentReference}
					sectionName={sectionName}
					setFormData={setFormData}
					setLastSyncedForm={setLastSyncedForm}
					setPdfInstance={setPdfInstance}
					setValidateFormData={setValidateFormData}
					showBackBtn={showBackBtn}
					showCloseBtn={showCloseBtn}
					studyId={studyId}
					updateForm={updateForm}
					useFhirUrl={useFhirUrl}
					userSignature={userSignature}
					validateFormData={validateFormData}
					onSubmit={onSubmit}
				/>
			</div>
		</Drawer>
	);
};

export default FormDrawerV3;
