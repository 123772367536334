import React from 'react';
import Box from '@mui/material/Box';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import AppointmentCalendarDots from '@worklist-2/patientPortal/src/components/Appointment/AppointmentCalendar/AppointmentCalendarDots';
import moment from 'moment';

const AppointmentCalendarSmallDay = ({
	appointments,
	day,
	selectedDay,
	today,
	selectedMonth,
	setSelectedDay,
	sx = {},
	fromFilter,
}) => {
	const isSelected = day.toDateString() === selectedDay?.toDateString();

	const isToday = day.toDateString() === today.toDateString();

	const isOutsideCurrentMonth = day.getMonth() !== selectedMonth.getMonth();
	const isDisabled = fromFilter ? false : moment(day).isBefore(today, 'day');

	const month = day.getMonth() + 1;
	const dayTestId = `calendar-day-${month < 10 ? `0${month}` : month}-${day.getDate()}-${day.getFullYear()}`;
	return (
		<Box
			sx={{
				position: 'relative',
				width: '46px',
				height: '46px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				...sx,
			}}
		>
			{!isOutsideCurrentMonth && (
				<AppointmentCalendarDots appointments={appointments} data-testid="AppointmentCalendarDots" />
			)}

			<PickersDay
				data-testid={dayTestId}
				day={day}
				outsideCurrentMonth={isOutsideCurrentMonth}
				selected={isSelected}
				sx={{
					backgroundColor: 'unset',
					fontSize: '16px',
					color: isDisabled ? '#12121240' : '#212121',
					cursor: isDisabled && 'default',
					fontWeight: '300',
					width: '25px',
					height: '25px',
					lineHeight: 'unset',
					'&.MuiPickersDay-root': {
						'&:hover, &:focus': {
							backgroundColor: isDisabled && 'transparent',
							pointerEvents: isDisabled && 'none',
						},
					},
					'&.MuiPickersDay-today': {
						backgroundColor: '#42A5F5 !important',
						border: 'unset',
						color: '#fff !important',
					},
					'&.Mui-selected': {
						border: '1px solid #42A5F5',
						color: '#42A5F5',
						backgroundColor: 'unset',
						'&:hover, &:focus': {
							backgroundColor: 'unset',
						},
					},
				}}
				today={isToday}
				onDaySelect={setSelectedDay}
			/>
		</Box>
	);
};

export default AppointmentCalendarSmallDay;
