import moment from 'moment';
import { DIAGNOSTIC_REPORT_STATUS, REPORT_RESOURCE_TYPE } from '../consts/consts';
import getDiagnosticReportStudyId from './getDiagnosticReportStudyId';
import _ from 'lodash';
import { getReportNameSuffix } from './getReportNameSuffix';

/**
 * @returns boolean value if final document
 * @param report
 */
export const isFinalReport = report => {
	return Boolean(
		report?.reportType === DIAGNOSTIC_REPORT_STATUS.FINAL || report?.status === DIAGNOSTIC_REPORT_STATUS.FINAL
	);
};
export const isPreliminaryReport = report => {
	return Boolean(
		report?.reportType === DIAGNOSTIC_REPORT_STATUS.PRELIMINARY ||
			report?.status === DIAGNOSTIC_REPORT_STATUS.PRELIMINARY
	);
};
export const isAmendedReport = report => {
	return Boolean(report?.status === DIAGNOSTIC_REPORT_STATUS.AMENDED);
};
export const isStudyDocument = report => {
	return Boolean(report?.resourceType === REPORT_RESOURCE_TYPE.DOCUMENT);
};

export const isStudyForm = report => {
	return Boolean(report?.resourceType === REPORT_RESOURCE_TYPE.FORM);
};

export const isAmendingReport = report => {
	return (
		Boolean(report?.resourceType === REPORT_RESOURCE_TYPE.DIAGNOSTIC) &&
		Boolean(report?.status === DIAGNOSTIC_REPORT_STATUS.PARTIAL)
	);
};

/**
 * @returns boolean value if current document
 * @param studyId
 * @param document
 */
export const isCurrentStudy = (studyId, document) => {
	const diagnosticReportStudyId = getDiagnosticReportStudyId(document);
	return studyId === diagnosticReportStudyId;
};

// Reorder Function
export const reorderList = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

export const isValidDocumentType = (document, allowedTypes) => {
	if (Array.isArray(allowedTypes)) {
		return allowedTypes.includes(document?.resourceType);
	}

	return document?.resourceType === allowedTypes;
};

export const matchesMomentFormat = (inputString, format) => {
	const parsedDate = moment(inputString, format, true); // The "true" flag enables strict parsing
	return parsedDate.isValid();
};

export const generateReportTitle = (default_title, current_title, isCreate, document, org_TZ, isStudyDocTitle) => {
	if (isCreate) {
		return `${default_title} ${getReportNameSuffix(org_TZ)}`;
	}

	const existingName = isStudyDocTitle || _.get(document, 'presentedForm[0].title', undefined);

	if (existingName?.includes(current_title)) {
		const stripedKeyTimeStamp = existingName.replace(current_title, '').trim();
		if (matchesMomentFormat(stripedKeyTimeStamp, 'MMMM DD YYYY hh:mm:ss')) {
			return `${default_title} ${getReportNameSuffix(org_TZ)}`;
		}
		return isStudyDocTitle || default_title;
	}

	return isStudyDocTitle || default_title;
};

export const updateReportTitle = (default_title, document) => {
	const existingName = _.get(document, 'presentedForm[0].title', undefined);
	return existingName ? existingName : default_title;
};
export const getRenamedBookmarks = bookmarkOptions => {
	const renamedBookmarks = bookmarkOptions.map(bookmark => {
		const newBookmark = { ...bookmark };
		if (newBookmark.code === 'initCursorPos') {
			newBookmark.display = 'Body';
		}
		return newBookmark;
	});
	return renamedBookmarks;
};

export const isDvInMultiMonitor = screenNumber => {
	const isDocumentViewer = window.location.pathname === '/document-viewer-v3';
	// Validate: exists (not undefined/null/'') && is a number (e.g., "1", "42") && is non-negative (>= 0)
	const validScreenNumber = Boolean(screenNumber) && !isNaN(Number(screenNumber)) && Number(screenNumber) >= 0;

	return isDocumentViewer && validScreenNumber;
};
