import React, { useEffect } from 'react';
import moment from 'moment-timezone';

// components
import AppointmentDateTimePicker from '@worklist-2/patientPortal/src/components/AppointmentDateTimePicker/AppointmentDateTimePicker';
import CancelSaveButtons from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/components/CancelSaveButtons';
import PriorStudies from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/sections/SecondPage/PriorStudies';
import Notes from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/sections/SecondPage/Notes';

// hooks
import { useIsMobile } from '@rs-core/utils/responsiveUtils';
import useGetAvailableTimeSlots from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/hooks/useGetAvailableTimeSlots';
import useOnSubmit from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/hooks/useOnSubmit';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

// mui
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// context
import { useNewAppointmentContentV2Context } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/contexts/NewAppointmentContentV2ContextProvider';

const SecondPage = () => {
	const isMobile = useIsMobile();
	const phoenixBlumeSharePriorDicomFromAppointments = useBooleanFlagValue(
		'phoenix-blume-share-prior-dicom-from-appointments'
	);
	const { getAvailableTimeSlots } = useGetAvailableTimeSlots();
	const { onSubmit } = useOnSubmit();

	const {
		isUpdateForm,
		inputDateTime,
		setInputDateTime,
		inputDateTimeEng,
		setInputDateTimeEng,
		validate,
		setValidate,
		setPage,
		availableTimeSlots,
		setAvailableTimeSlots,
		isRequestLoading,
		setIsRequestLoading,
		organizationTimeZone,
		appointmentSlotsLoading,
		appointment,
	} = useNewAppointmentContentV2Context();

	useEffect(() => {
		if (inputDateTime && inputDateTimeEng) return;
		if (isUpdateForm && appointment?.dateStart && appointment?.dateEnd) {
			const formattedDate =
				moment(appointment.dateStart).format('dddd, MMMM D, YYYY h:mm A - ') +
				moment(appointment.dateEnd).format('h:mm A');

			setInputDateTime(formattedDate);
			setInputDateTimeEng(formattedDate);
		}
	}, [appointment, inputDateTime, isUpdateForm, setInputDateTime, setInputDateTimeEng]);

	return (
		<Box>
			<Stack
				flexGrow={1}
				spacing={4}
				sx={{
					px: !isMobile ? '32px' : undefined,
					pt: isMobile ? '20px' : '30px',
					pb: !isMobile ? '32px' : undefined,
					overflow: 'auto',
				}}
			>
				<AppointmentDateTimePicker
					availableTimeSlots={availableTimeSlots}
					disabled={isRequestLoading}
					getAvailableTimeSlots={getAvailableTimeSlots}
					inputDateTime={inputDateTime}
					isUpdateForm={isUpdateForm}
					isValid={
						moment(inputDateTimeEng, 'dddd, MMMM D, YYYY h:mm A - h:mm A', true).isValid() ||
						moment(inputDateTimeEng, 'dddd, MMMM D, YYYY hh:mm A - hh:mm A', true).isValid()
					}
					organizationTimeZone={organizationTimeZone}
					setAvailableTimeSlots={setAvailableTimeSlots}
					setInputDateTime={setInputDateTime}
					setInputDateTimeEng={setInputDateTimeEng}
					setValidate={setValidate}
					slotsLoading={appointmentSlotsLoading}
					validate={validate}
				/>

				{phoenixBlumeSharePriorDicomFromAppointments && <Divider />}

				{phoenixBlumeSharePriorDicomFromAppointments && <PriorStudies />}

				<Notes uploadedAttachments={appointment?.attachments} />
			</Stack>

			<CancelSaveButtons
				backDisabled={isUpdateForm}
				isRequestLoading={isRequestLoading}
				isValid={
					moment(inputDateTimeEng, 'dddd, MMMM D, YYYY h:mm A - h:mm A', true).isValid() ||
					moment(inputDateTimeEng, 'dddd, MMMM D, YYYY hh:mm A - hh:mm A', true).isValid()
				}
				setIsRequestLoading={setIsRequestLoading}
				onBackClick={() => setPage('first')}
				onRequestClick={onSubmit}
			/>
		</Box>
	);
};

export default SecondPage;
