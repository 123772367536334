import { useEffect } from 'react';
import _ from 'lodash';

import { useConfig } from '@rs-core/context/ConfigContext';

import {
	useShareDrawerStore,
	useFormStore,
	useAppointmentStore,
	useScheduleStore,
	useLicenseStore,
	useStudiesStore,
} from '@worklist-2/patientPortal/src/stores';

const useStoreSync = () => {
	const __config = useConfig();
	const setShareDrawerSharedStates = useShareDrawerStore(state => state.setSharedStates);
	const setScheduleSharedStates = useScheduleStore(state => state.setSharedStates);
	const setAppointmentSharedStates = useAppointmentStore(state => state.setSharedStates);
	const setFormSharedStates = useFormStore(state => state.setSharedStates);
	const setLicenseSharedStates = useLicenseStore(state => state.setSharedStates);
	const setStudiesSharedStates = useStudiesStore(state => state.setSharedStates);

	useEffect(() => {
		// Extract only necessary configuration data to save memory
		const filteredConfig = _.pick(__config, ['data_sources', 'patient_portal']);

		setShareDrawerSharedStates({ __config: filteredConfig });
		setScheduleSharedStates({ __config: filteredConfig });
		setAppointmentSharedStates({ __config: filteredConfig });
		setFormSharedStates({ __config: filteredConfig });
		setLicenseSharedStates({ __config: filteredConfig });
		setStudiesSharedStates({ __config: filteredConfig });
	}, [
		__config,
		setAppointmentSharedStates,
		setFormSharedStates,
		setLicenseSharedStates,
		setScheduleSharedStates,
		setShareDrawerSharedStates,
		setStudiesSharedStates,
	]);
};

export default useStoreSync;
