import { v4 as uuid } from 'uuid';
import { VIEW_CODES } from '../../contexts/consts/consts';
import MLO_CC from '../../assets/img/hangingProtocolIcons/MLO_CC.svg';
import OVERVIEW from '../../assets/img/hangingProtocolIcons/OVERVIEW.svg';
import CC from '../../assets/img/hangingProtocolIcons/CC.svg';
import MLO from '../../assets/img/hangingProtocolIcons/MLO.svg';
import LCC from '../../assets/img/hangingProtocolIcons/LCC.svg';
import LMLO from '../../assets/img/hangingProtocolIcons/LMLO.svg';
import RCC from '../../assets/img/hangingProtocolIcons/RCC.svg';
import RMLO from '../../assets/img/hangingProtocolIcons/RMLO.svg';
import LMLO_LCC from '../../assets/img/hangingProtocolIcons/LMLO_LCC.svg';
import RMLO_RCC from '../../assets/img/hangingProtocolIcons/RMLO_RCC.svg';

const layout00 = {
	x: 0,
	y: 0,
};

const layout10 = {
	x: 1,
	y: 0,
};

const orientationRight = {
	type: 'orientation',
	values: {
		align: 'right',
	},
};

const orientationLeft = {
	type: 'orientation',
	values: {
		align: 'left',
	},
};

const getSeriesMetadata = series => series?.metadata?.[0];

const isTomo3D = series => {
	const metadata = getSeriesMetadata(series);

	if (!metadata) {
		return false;
	}

	const imageTypeAttribute = metadata?.['00080008']?.Value;

	return imageTypeAttribute.includes('TOMOSYNTHESIS') || imageTypeAttribute.includes('VOLUME');
};

const isNotTomo3D = series => !isTomo3D(series);

const isID = series => {
	const metadata = getSeriesMetadata(series);

	if (!metadata) {
		return false;
	}

	return metadata?.['00540220']?.Value?.[0]?.['00540222']?.Value?.[0]?.['00080100']?.Value?.[0]
		?.toUpperCase()
		?.includes('R-102D5');
};

const isNotID = series => !isID(series);

const stages = Array.from(Array(4)).map((_, index) => index);

const stageFilters = {
	0: [isNotTomo3D, isNotID],
	1: [isNotTomo3D, isID],
	2: [isTomo3D, isNotID],
	3: [isTomo3D, isID],
};

const addFilters = (monitors, stageIndex) =>
	monitors.map(monitor => ({
		...monitor,
		filters: stageFilters[stageIndex],
	}));

const MammographyHangingProtocols = [
	{
		icon: OVERVIEW,
		ProtocolID: 'MG_OVERVIEW',
		'Hanging Protocol Name': 'Overview',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MG'],
					'Anatomic Region Sequence': {
						'Item 1': '',
						Laterality: '',
						BodyPart: '',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: stages.map(stageIndex => ({
			monitors: addFilters(
				[
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RCC,
						rules: [orientationRight],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LCC,
						rules: [orientationLeft],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RCC,
						rules: [orientationRight],
						layout: layout00,
						prior: 1,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LCC,
						rules: [orientationLeft],
						layout: layout00,
						prior: 1,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RMLO,
						rules: [orientationRight],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LMLO,
						rules: [orientationLeft],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RMLO,
						rules: [orientationRight],
						layout: layout00,
						prior: 1,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LMLO,
						rules: [orientationLeft],
						layout: layout00,
						prior: 1,
					},
				],
				stageIndex
			),
			layout: {
				x: 3,
				y: 1,
			},
		})),
	},
	{
		icon: MLO_CC,
		ProtocolID: 'MG_MLO_CC',
		'Hanging Protocol Name': 'MLO CC',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MG'],
					'Anatomic Region Sequence': {
						'Item 1': '',
						Laterality: '',
						BodyPart: '',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: stages.map(stageIndex => ({
			monitors: addFilters(
				[
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RMLO,
						rules: [orientationRight],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LMLO,
						rules: [orientationLeft],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RCC,
						rules: [orientationRight],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LCC,
						rules: [orientationLeft],
						layout: layout00,
					},
				],
				stageIndex
			),
			layout: {
				x: 3,
				y: 0,
			},
		})),
	},
	{
		icon: CC,
		ProtocolID: 'MG_CC',
		'Hanging Protocol Name': 'CC',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MG'],
					'Anatomic Region Sequence': {
						'Item 1': '',
						Laterality: '',
						BodyPart: '',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: stages.map(stageIndex => ({
			monitors: addFilters(
				[
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RCC,
						rules: [orientationRight],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LCC,
						rules: [orientationLeft],
						layout: layout00,
					},
				],
				stageIndex
			),
			layout: layout10,
		})),
	},
	{
		icon: MLO,
		ProtocolID: 'MG_MLO',
		'Hanging Protocol Name': 'MLO',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MG'],
					'Anatomic Region Sequence': {
						'Item 1': '',
						Laterality: '',
						BodyPart: '',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: stages.map(stageIndex => ({
			monitors: addFilters(
				[
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RMLO,
						rules: [orientationRight],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LMLO,
						rules: [orientationLeft],
						layout: layout00,
					},
				],
				stageIndex
			),
			layout: layout10,
		})),
	},
	{
		icon: LCC,
		ProtocolID: 'MG_LCC',
		'Hanging Protocol Name': 'LCC',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MG'],
					'Anatomic Region Sequence': {
						'Item 1': '',
						Laterality: '',
						BodyPart: '',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: stages.map(stageIndex => ({
			monitors: addFilters(
				[
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LCC,
						rules: [orientationLeft],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LCC,
						rules: [orientationLeft],
						layout: layout00,
						prior: 1,
					},
				],
				stageIndex
			),
			layout: layout10,
		})),
	},
	{
		icon: LMLO,
		ProtocolID: 'MG_LMLO',
		'Hanging Protocol Name': 'LMLO',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MG'],
					'Anatomic Region Sequence': {
						'Item 1': '',
						Laterality: '',
						BodyPart: '',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: stages.map(stageIndex => ({
			monitors: addFilters(
				[
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LMLO,
						rules: [orientationLeft],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LMLO,
						rules: [orientationLeft],
						layout: layout00,
						prior: 1,
					},
				],
				stageIndex
			),
			layout: layout10,
		})),
	},
	{
		icon: RCC,
		ProtocolID: 'MG_RCC',
		'Hanging Protocol Name': 'RCC',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MG'],
					'Anatomic Region Sequence': {
						'Item 1': '',
						Laterality: '',
						BodyPart: '',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: stages.map(stageIndex => ({
			monitors: addFilters(
				[
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RCC,
						rules: [orientationRight],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RCC,
						rules: [orientationRight],
						layout: layout00,
						prior: 1,
					},
				],
				stageIndex
			),
			layout: layout10,
		})),
	},
	{
		icon: RMLO,
		ProtocolID: 'MG_RMLO',
		'Hanging Protocol Name': 'RMLO',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MG'],
					'Anatomic Region Sequence': {
						'Item 1': '',
						Laterality: '',
						BodyPart: '',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: stages.map(stageIndex => ({
			monitors: addFilters(
				[
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RMLO,
						rules: [orientationRight],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RMLO,
						rules: [orientationRight],
						layout: layout00,
						prior: 1,
					},
				],
				stageIndex
			),
			layout: layout10,
		})),
	},
	{
		icon: LMLO_LCC,
		ProtocolID: 'MG_LMLO_LCC',
		'Hanging Protocol Name': 'LMLO LCC',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MG'],
					'Anatomic Region Sequence': {
						'Item 1': '',
						Laterality: '',
						BodyPart: '',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: stages.map(stageIndex => ({
			monitors: addFilters(
				[
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LMLO,
						rules: [orientationLeft],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_LCC,
						rules: [orientationLeft],
						layout: layout00,
					},
				],
				stageIndex
			),
			layout: layout10,
		})),
	},
	{
		icon: RMLO_RCC,
		ProtocolID: 'MG_RMLO_RCC',
		'Hanging Protocol Name': 'RMLO RCC',
		'Hanging Protocol Definition Sequence': [
			{
				'Item 1': {
					Modality: ['MG'],
					'Anatomic Region Sequence': {
						'Item 1': '',
						Laterality: '',
						BodyPart: '',
						'Procedure Code Sequence': '',
						'Reason for Requested Procedure Code Sequence': '',
					},
				},
			},
		],
		Stages: stages.map(stageIndex => ({
			monitors: addFilters(
				[
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RMLO,
						rules: [orientationRight],
						layout: layout00,
					},
					{
						id: uuid(),
						viewCode: VIEW_CODES.MG_RCC,
						rules: [orientationRight],
						layout: layout00,
					},
				],
				stageIndex
			),
			layout: layout10,
		})),
	},
];

export default MammographyHangingProtocols;
