// Core
import React from 'react';

// MUI
import { Box, Typography } from '@mui/material';
import { useFormStore } from '@worklist-2/patientPortal/src/stores/forms';

const FormToolHeader = ({ sx }) => {
	const selectedForm = useFormStore(state => state.selectedForm);

	return (
		<Box
			sx={{
				background: selectedForm?.resource?.formTemplate?.header?.colour || '#3a6df1',
				borderRadius: 'var(--element-content-border-radius)',
				boxSizing: 'border-box',
				...sx,
			}}
		>
			{!!selectedForm?.resource?.formTemplate?.header?.banner && (
				<Box
					sx={{
						height: '150px',
						filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
						position: 'relative',
						zIndex: 1,
					}}
				>
					<Box
						component="img"
						src={selectedForm?.resource?.formTemplate?.header?.banner}
						sx={{
							objectFit: 'contain',
							width: '700px',
							height: '150px',
							borderRadius: 'var(--element-content-border-radius)',
						}}
					/>
				</Box>
			)}
			<Box
				sx={{
					padding: '20px 30px',
					display: 'flex',
					justifyContent: 'start',
					gap: '10px',
				}}
			>
				{selectedForm?.resource?.formTemplate?.header?.logoImage && (
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Box
							component="img"
							src={selectedForm?.resource?.formTemplate?.header?.logoImage}
							sx={{
								objectFit: 'contain',
								width: '46px',
								height: '46px',
								borderRadius: '10px',
								marginLeft: '-10px',
							}}
						/>
					</Box>
				)}
				<Box>
					<Typography
						component="h1"
						sx={{
							color: '#ffffff',
							fontSize: '20px',
							marginBottom: '5px',
							lineHeight: '24px',
							width: '400px',
						}}
					>
						{selectedForm.title}
					</Typography>

					<Typography
						sx={{
							color: 'rgba(255, 255, 255, 0.6)',
							fontSize: '14px',
							lineHeight: '16px',
						}}
					>
						{selectedForm.description}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default FormToolHeader;
