import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import { useIsTablet } from '@rs-core/utils/responsiveUtils';

const DrawerV2 = ({
	children,
	label,
	heading,
	icon,
	buttonProps,
	isResponsive,
	isOpened,
	customOpen,
	setCustomOpen,
	onOpen,
	onClose,
}) => {
	const [open, setOpen] = useState(() => isOpened || false);
	const isTablet = useIsTablet();

	useEffect(() => {
		if (onOpen && (customOpen ?? open)) {
			onOpen();
		}
	}, [customOpen, open]);

	const toggleDrawer = newOpen => () => {
		if (customOpen !== undefined && typeof setCustomOpen === 'function') {
			setCustomOpen(newOpen);
			return;
		}

		setOpen(newOpen);
	};

	return (
		<>
			{icon ? (
				<IconButton onClick={toggleDrawer(true)} {...buttonProps}>
					{icon}
				</IconButton>
			) : (
				<Button onClick={toggleDrawer(true)} {...buttonProps}>
					{label}
				</Button>
			)}
			<SwipeableDrawer
				anchor={isTablet && isResponsive ? 'bottom' : 'right'}
				open={customOpen ?? open}
				sx={{
					[`.MuiBackdrop-root`]: {
						backdropFilter: 'blur(3px)',
					},

					[`.MuiPaper-root`]: {
						width: '90vw',
					},

					[`.MuiDrawer-paperAnchorBottom`]: {
						boxSizing: 'border-box',
						borderTopLeftRadius: '20px',
						borderTopRightRadius: '20px',
						overflow: 'hidden',
						padding: { xs: '16px 16px 20px 16px', lg: 0 },
					},
				}}
				onClose={() => null}
				onOpen={() => null}
			>
				<IconButton
					sx={{
						height: '36px',
						width: '36px',
						position: 'absolute',
						top: '10px',
						right: '30px',
						zIndex: 10,
						padding: '20px',
					}}
					onClick={ev => {
						toggleDrawer(false)(ev);
						if (onClose) onClose();
					}}
				>
					<CloseIcon />
				</IconButton>
				<Box
					role="presentation"
					sx={{
						padding: { xs: '20px 10px 10px 10px', lg: '15px 20px 20px 20px' },
						width: '100%',
						overflowX: 'hidden',
						overflowY: 'auto',
						position: 'relative',
						boxSizing: 'border-box',
						height: '100%',
					}}
				>
					<Box display="flex" justifyContent="space-between" sx={{ maxWidth: '100%' }}>
						<Typography
							component="h2"
							fontSize="20px"
							fontWeight={500}
							lineHeight="30px"
							sx={{ color: '#364152' }}
						>
							{heading}
						</Typography>
					</Box>
					{children}
				</Box>
			</SwipeableDrawer>
		</>
	);
};

export default DrawerV2;
