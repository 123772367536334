import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConfigProvider, AppModeProvider, SelectorContextProvider } from '@worklist-2/core/src';
import { UserAuthProvider } from '@rs-core/context/UserAuthContext';
import { HelpCenterContextProvider } from '@rs-core/context/HelpCenterContext';
import { ChatGlobalContextProvider } from '@worklist-2/ui';
import HelpPopOver from '@worklist-2/ui/src/views/HelpView/HelpCenter/HelpPopOver';
import { darkTheme } from '@worklist-2/ui';
import { CypressHistorySupport } from 'cypress-react-router';
import UnauthenticatedPage from './routes/UnauthenticatedPages';
import { ThemeProvider } from '@mui/material/styles';
import ToastMessageProvider from './context/ToastMessageContext';

// Libraries
import { datadogRum } from '@datadog/browser-rum';
import i18n from './i18n.js';
import './analytics/index';
import { OpenFeatureProvider, OpenFeature } from '@openfeature/react-sdk';
import DevCycleProvider from '@devcycle/openfeature-web-provider';
import mixpanel from 'mixpanel-browser';

const config = JSON.parse(process.env.NODE_CONFIG);

if (!config.data_dog?.applicationId.includes('DATADOG_APPLICATION_ID')) {
	datadogRum.init({
		applicationId: config.data_dog.applicationId,
		clientToken: config.data_dog.clientToken,
		site: config.data_dog.site,
		service: 'blume',
		env: config.data_dog.env,
		sessionSampleRate: 100,
		sessionReplaySampleRate: 100, // if not included, the default is 100
		trackResources: true,
		trackLongTasks: true,
		trackUserInteractions: true,
		version: config.data_dog.version,
		defaultPrivacyLevel: 'allow',
		allowedTracingUrls: [config.data_sources.fhir],
		enableExperimentalFeatures: ['feature_flags'],
	});

	datadogRum.startSessionReplayRecording();
}

if (config?.mixpanel && !config?.mixpanel?.includes('MIXPANEL_TOKEN')) {
	mixpanel.init(config.mixpanel, {
		track_pageview: true,
	});
}

/**
 *
 * @returns application router based on pathname
 * We need termsofservice and privacy policy seprate from theentire application
 */
const getApplication = () => {
	let pathname = window.location.pathname.toLocaleLowerCase();
	let unautherticatedUtls = [
		'/termsofservice',
		'/privacypolicy',
		'/region',
		'/language',
		'/news',
		'/helppopup',
		'/blume/termsofservice',
		'/blume/privacypolicy',
		'/blume/region',
		'/blume/language',
		'/blume/news',
		'/blume/helppopup',
	];
	if (unautherticatedUtls.includes(pathname.toLocaleLowerCase())) {
		return (
			<Router basename={config.router_basename}>
				<HelpCenterContextProvider PopOverComponent={HelpPopOver}>
					<ThemeProvider theme={darkTheme}>
						<SelectorContextProvider>
							<Routes>
								<Route path="privacyPolicy" element={<UnauthenticatedPage page="privacy" />} />
								<Route path="termsOfService" element={<UnauthenticatedPage page="terms" />} />
								<Route path="region" element={<UnauthenticatedPage page="region" />} />
								<Route path="language" element={<UnauthenticatedPage page="language" />} />
								<Route path="news" element={<UnauthenticatedPage page="news" />} />
								<Route path="helppopup" element={<UnauthenticatedPage page="helppopup" />} />
							</Routes>
						</SelectorContextProvider>
					</ThemeProvider>
				</HelpCenterContextProvider>
			</Router>
		);
	} else {
		return (
			<Router basename={config.blume_router_basename}>
				<CypressHistorySupport />
				<AppModeProvider>
					<ChatGlobalContextProvider>
						<Suspense fallback={null}>
							<UserAuthProvider>
								<ToastMessageProvider>
									<App />
								</ToastMessageProvider>
							</UserAuthProvider>
						</Suspense>
					</ChatGlobalContextProvider>
				</AppModeProvider>
			</Router>
		);
	}
};

const userContext = {
	kind: 'multi',
	entity: {
		name: `${config.resource_group}`.trim(),
		key: `${config.resource_group}`.trim().toUpperCase(),
	},
};

const params = new URLSearchParams(location.search);
const devCycleUserContext = {
	user_id: 'DEFAULT',
	customData: {
		entity: `${config.resource_group}`.trim().toUpperCase(),
		'organization-id': params.get('orgId'),
	},
};

const devCycleProvider = new DevCycleProvider(config.devCycleKey, {
	disableRealtimeUpdates: true,
});

OpenFeature.setContext(devCycleUserContext);
await OpenFeature.setProviderAndWait(devCycleProvider);

const devCycleClient = devCycleProvider.devcycleClient;
devCycleClient?.subscribe('variableEvaluated:*', (key, variable) => {
	datadogRum.addFeatureFlagEvaluation(key, variable.value);
});

ReactDOM.render(
	<React.StrictMode>
		<OpenFeatureProvider suspend={true}>
			<Suspense fallback={null}>
				<ConfigProvider>{getApplication()}</ConfigProvider>
			</Suspense>
		</OpenFeatureProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
