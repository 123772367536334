import React, { useEffect } from 'react';
import { ChatBubble, ChatWrapper } from '@rs-ui/components/Help/helpStyles';
import { ChatBox } from '@worklist-2/ui/src/components/SideBar/HelpSideBar/HelpCenterChat';
import { H3 } from '@worklist-2/ui/src/views/HelpView/HelpCenter/helpCenterStyles';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { useAuth } from '@rs-core/context/UserAuthContext';

const PatientChat = () => {
	const { userInfo } = useAuth();

	// dummy variables because I removed useLiveChat()
	const messages = [];
	const initializing = true;
	const liveChatSender = {};
	const initChat = () => {};
	const liveAgent = {};
	const sendTextMessage = () => {};

	useEffect(() => {
		initChat(userInfo);
	}, []);

	useEffect(() => {
		try {
			if (messages.length > 0) {
				const ele = document.getElementById(`bubble-${messages.length - 1}`);
				ele?.scrollIntoView();
			}
		} catch (error) {
			console.error(error);
		}
	}, [messages]);

	const sendMessage = async data => {
		try {
			sendTextMessage(data);
		} catch (error) {
			console.error('Error =>', error);
		}
	};

	return (
		<ChatWrapper className="patient-chat">
			<div className="chat-start-wrapper">
				{initializing ? (
					<SentimentSatisfiedAltIcon style={{ fontSize: 14, margin: '0px 5px' }} />
				) : (
					<LockOpenOutlinedIcon style={{ fontSize: 14, margin: '0px 5px' }} />
				)}

				<H3 style={{ fontSize: '12px' }}>
					{initializing
						? 'We will connect you with an agent shortly. This won’t take long.'
						: `You are now connected to ${liveAgent?.name}.`}
				</H3>
			</div>
			<div className="chat-content-wrapper">
				{messages.map((entry, i) => (
					<ChatBubble id={`bubble-${i}`} isUser={entry.sender?.uid == liveChatSender?.uid}>
						<div className="content patient-chat-bubble">{entry.text}</div>
					</ChatBubble>
				))}
			</div>
			<ChatBox send={sendMessage} sending={false} />
		</ChatWrapper>
	);
};

export default PatientChat;
