// @flow

// core
import React, { type Node } from 'react';
// components
import Stack from '@mui/material/Stack';
import { type ISkeletonProps, LoaderSkeleton } from './LoaderSkeleton';

export const LinkCards = ({ children, isLoading }: ISkeletonProps): Node =>
	isLoading ? (
		<Stack data-testid="link-cards-loader" rowGap="10px" sx={{ padding: '15px 50px' }}>
			<LoaderSkeleton sx={{ height: '350px', width: '100%' }} />
			<LoaderSkeleton sx={{ height: '350px', width: '100%' }} />
		</Stack>
	) : (
		children
	);
