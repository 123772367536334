import { useConfig, useAppModeContext } from '@worklist-2/core/src';
import { useCache } from '@rs-core/context/CacheContext';
import { useMeasurements } from '../hooks/useMeasurements';
import { useSearchParams } from 'react-router-dom';
import { useImageViewerExternalContext } from './ImageViewerExternalContext';
import { useEffect, useRef } from 'react';
import loadSeriesWithMetadata from '../api/loadSeriesWithMetadata';
import { v4 as uuid } from 'uuid';
import { getKeyImages } from '../api/keyImage';
import { useBooleanFlagValue, useNumberFlagValue } from '@rs-core/hooks/useFlags';

const LoadData = ({ disabledKeyImages }) => {
	const __config = useConfig();
	const { fetchData } = useMeasurements();
	const { cacheLinks } = useCache();
	const [searchParams] = useSearchParams();
	const { isPatientPortalMode } = useAppModeContext();
	const wonIvSeriesSplitter = useBooleanFlagValue('won-iv-series-splitter');
	const wonIvPlaneSplitter = useBooleanFlagValue('won-iv-plane-splitter');
	const wonIvDwiseriessplitter = useBooleanFlagValue('WON-IV-DWISERIESSPLITTER');
	const wonIvDisablexasplit = useBooleanFlagValue('WON-IV-DISABLEXASPLIT');
	const wonIvSeriesSplitterUpperLimit = useNumberFlagValue('won-iv-series-splitter-upper-limit');
	const wonIvEnhancedcurvedsort = useBooleanFlagValue('WON-IV-ENHANCEDCURVEDSORT');
	const wonIvRefactorsorting = useBooleanFlagValue('WON-IV-REFACTORSORTING');
	const wonIvStillsfirst = useBooleanFlagValue('WON-IV-STILLSFIRST');
	const sprinterOmegaailinkWebcache = useBooleanFlagValue('sprinter-omegaailink-webcache');
	const internalStudyId = searchParams.get('StudyId');

	const {
		internalManagingOrganizationID,
		studyInstanceUID,
		setSeries,
		setKeyImages,
		setMeasurements,
		setViewport,
		creatorUserId,
		isUploaded,
		setImagesLoading,
	} = useImageViewerExternalContext();

	const sprinterOmegaailinkWebcacheRef = useRef(sprinterOmegaailinkWebcache);
	const isBlumeApp = isPatientPortalMode();
	sprinterOmegaailinkWebcacheRef.current = sprinterOmegaailinkWebcache;

	useEffect(() => {
		const loadData = async () => {
			setImagesLoading(true);
			const [seriesData, keyImagesData, measurementsData] = await Promise.all([
				loadSeriesWithMetadata({
					__config,
					managingOrganizationId: internalManagingOrganizationID,
					studyInstanceUID,
					wonIvSeriesSplitter,
					wonIvDisablexasplit,
					wonIvSeriesSplitterUpperLimit,
					cacheLinks,
					wonIvPlaneSplitter,
					wonIvDwiseriessplitter,
					wonIvEnhancedcurvedsort,
					wonIvRefactorsorting,
					wonIvStillsfirst,
					is3D: true,
					isBlumeApp,
					creatorUserId,
					isUploaded,
					internalStudyId,
				}),
				!disabledKeyImages
					? getKeyImages({
							__config,
							studyInstanceUID,
							internalManagingId: internalManagingOrganizationID,
					  })
					: () => [],
				!isBlumeApp
					? fetchData(null, {
							StudyInstanceUID: studyInstanceUID,
							InternalManagingOrgId: internalManagingOrganizationID,
					  })
					: () => [],
			]);

			setMeasurements(measurementsData || []);
			setSeries(seriesData || []);
			setKeyImages(keyImagesData);

			if (seriesData?.length) {
				setViewport({
					id: uuid(),
					seriesUniqueId: seriesData[0].uniqueId,
					initialInstanceIndex: 0,
				});
			}
			setImagesLoading(false);
		};

		loadData();
	}, []);

	return null;
};

export default LoadData;
