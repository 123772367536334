import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import { crossFormatPersonNameGV } from './globalVariablesForFormatName';

const getUserFullName = (nameObj, lastNameFirst = false) => {
	let name = '';
	let firstName = '';
	let middleName = '';
	let lastName = '';

	if (!nameObj) {
		return name;
	}

	// parse FHIR Human Name
	if (isObject(nameObj)) {
		if (nameObj?.given?.length > 0) {
			firstName = isArray(nameObj.given) ? nameObj.given[0] : nameObj.given;
		}
		if (nameObj?.given?.length > 1) {
			middleName = isArray(nameObj.given) ? nameObj.given[1] : '';
		}
		if (nameObj?.family?.length > 0) {
			lastName = nameObj?.family;
		}

		if (lastNameFirst) {
			if (lastName) {
				name = lastName;
			}
			if (firstName) {
				name += ` ${firstName} ${middleName}`;
			}
			name = name.trim();
		} else {
			let nameList = [firstName, middleName, lastName];
			nameList = nameList.filter(n => n != '');
			name = nameList.join(' ');
		}
	}

	// parse DICOM name
	if (name.length <= 0) {
		const userFullName = nameObj?.text?.split('^') || nameObj?.split('^');
		let actualName = [];
		let count = 0;
		while (count < 5) {
			if (count < userFullName?.length) {
				actualName.push(userFullName[count]);
			} else {
				actualName.push('');
			}
			count++;
		}
		if (lastNameFirst) {
			let nameList = [actualName[0], actualName[1], actualName[2]];
			nameList = nameList.filter(n => n != '');
			name = nameList.shift(); // Remove and store the first word

			if (nameList.length > 0) {
				return name + ' ' + nameList.join(' '); // Join the remaining words with spaces
			}
		} else {
			let nameList = [actualName[3], actualName[1], actualName[2], actualName[0], actualName[4]];
			nameList = nameList.filter(n => n != '');
			return nameList.join(' ');
		}
	}
	return name;
};

/**
 * Given an array of strings, filter out falsey values and any empty strings and return a joined string
 * @param {Array<string>} nameList
 * @param {string} separator
 * @output {string}
 */
const filterAndJoinNames = (nameList, separator) => {
	return nameList
		?.filter(Boolean)
		?.filter(n => n.trim() !== '')
		.join(separator);
};
/**
 * Given a FHIR Human Name, parse and return the full name like:
 * Doe, John Middle, Dr, Jr
 * FhirName structure will look like:
 * {
 *   family: 'Doe',
 *   given: ['John', 'Middle'],
 *   prefix: ['Dr'],
 *   suffix: ['Jr']
 * }
 * @param {Object} fhirName
 * @output {string}
 */
const parseFhirName = fhirName => {
	if (!fhirName) {
		return '';
	}

	const { family, given, prefix, suffix } = fhirName;

	const firstNameMiddle = filterAndJoinNames(given, ' ');
	const fullName = filterAndJoinNames([family, firstNameMiddle, prefix?.[0], suffix?.[0]], ', ');

	return fullName;
};

/**
 * Given a FHIR Human Name, parse and return the DICOM name like:
 * DOE^JOHN^MIDDLE^DR^JR
 * FhirName structure will look like:
 * {
 *   family: 'Doe',
 *   given: ['John', 'Middle'],
 *   prefix: ['Dr'],
 *   suffix: ['Jr']
 * }
 * @param {string} fhirName
 * @output {string}
 */
const parseFhirNameToDicom = fhirName => {
	if (!fhirName) {
		return '';
	}

	let prefix = '';
	let suffix = '';
	let firstName = '';
	let middleName = '';
	let lastName = '';

	// parse FHIR Human Name
	if (isObject(fhirName)) {
		if (fhirName?.given?.length > 0) {
			firstName = isArray(fhirName.given) ? fhirName.given[0] : fhirName.given;
		}
		if (fhirName?.given?.length > 1) {
			middleName = isArray(fhirName.given) ? fhirName.given[1] : '';
		}
		if (fhirName?.family?.length > 0) {
			lastName = fhirName?.family;
		}
		if (fhirName?.prefix?.length > 0) {
			prefix = filterAndJoinNames(fhirName?.prefix, '^');
		}
		if (fhirName?.suffix?.length > 0) {
			suffix = filterAndJoinNames(fhirName?.suffix, '^');
		}
	}

	let dicomName = [lastName, firstName, middleName, prefix, suffix]?.map(n => n ?? '').join('^');
	// any trailing carets in a Dicom Person Name may be removed
	dicomName = dicomName.replace(/\^+$/, '');

	return dicomName?.toUpperCase();
};

/**
 * Given a DICOM name, parse and return the full name like:
 * Doe, John Middle, Dr, Jr
 * Dicom name looks like: DOE^JOHN^MIDDLE^DR^JR
 * @param {string} dicomName
 * @output {string}
 */
const parseDicomName = dicomName => {
	if (!dicomName) {
		return '';
	}

	const [lastName, firstName, middleName, prefix, suffix] = dicomName.split('^');

	const firstNameMiddle = filterAndJoinNames([firstName, middleName], ' ');
	const fullName = filterAndJoinNames([lastName, firstNameMiddle, prefix, suffix], ', ');

	return fullName;
};

const parseName = (name, lastNameFirst = false, type = 'FHIR') => {
	if (type === 'DICOM') {
		return crossFormatPersonNameGV ? parseDicomName(name) : getUserFullName(name, lastNameFirst);
	} else if (type === 'FHIR') {
		return crossFormatPersonNameGV ? parseFhirName(name) : getUserFullName(name, lastNameFirst);
	} else {
		return name;
	}
};

const splitDicomPatientNameComponents = dicomName => {
	if (!dicomName) {
		return '';
	}

	const [lastName, firstName, middleName, prefix, suffix] = dicomName.split('^');

	const firstNameMiddle = filterAndJoinNames([firstName, middleName], ' ');
	return { lastName, firstNameMiddle, prefix, suffix };
};

export { parseFhirName, parseDicomName, parseFhirNameToDicom, parseName, splitDicomPatientNameComponents };

export default getUserFullName;
