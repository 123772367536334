/* eslint-disable import/no-relative-packages */
import _ from 'lodash';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { loadMarkdown } from '../../../../markdown/patient-portal/help-center/categories/markdownLoader';
import { HIDDEN_HELP_CENTER_CATEGORIES, HIDDEN_HELP_CENTER_ARTICLES_CRITERIA } from '../consts';
import removeArticles from '@worklist-2/patientPortal/src/utils/removeArticles';
import { useUserStore } from '@worklist-2/patientPortal/src/stores';

const BlumeHelpCenterContext = createContext();

const BlumeHelpCenterContextProvider = ({ children }) => {
	const [blumeHelpLinks, setBlumeHelpLinks] = useState([]);
	const { selectedLanguage } = useUserStore(state => ({
		selectedLanguage: state.selectedLanguage,
	}));

	useEffect(() => {
		if (selectedLanguage) {
			const blumeHelpLinksClone = _.cloneDeep(blumeHelpConfig);
			if (blumeHelpLinksClone && blumeHelpLinksClone instanceof Array && blumeHelpLinksClone.length > 0) {
				for (const cat of blumeHelpLinksClone) {
					if (cat.path) {
						cat.path = `${selectedLanguage}/${cat.path}`;
					}
					if (cat.articles > 0) {
						const articles = [];
						for (let index = 0; index < cat.articles; index++) {
							articles.push({ path: `${cat.path}/articles/` + `article${index + 1}` });
						}
						cat.articles = articles;
					}
					cat.name = cat.label;
				}
			}

			// Filter out the hidden articles beforehand
			const filteredBlumeHelpLinksClone = removeArticles(
				blumeHelpLinksClone,
				HIDDEN_HELP_CENTER_ARTICLES_CRITERIA
			);

			setBlumeHelpLinks(filteredBlumeHelpLinksClone);
		}
	}, [selectedLanguage]);

	const blumeHelpConfig = useMemo(() => {
		try {
			const helpConfig = require('../../../../markdown/patient-portal/help-center/categories/index.json');
			return helpConfig?.items || [];
		} catch (error) {
			console.error(error);
			return [];
		}
	}, []);

	function loadMDX(language, subCategories) {
		const catPath = `${language}/${subCategories.path.split('/').slice(1).join('/')}`;
		const catArticles = _.map(subCategories.articles, (art, key) => ({
			path: `${language}/${art.path.split('/').slice(1).join('/')}`,
		}));

		const cat = loadMarkdown(subCategories.name, catPath, catArticles);
		if (cat) {
			cat.banner = subCategories.icon;
			cat.name = subCategories.name;
		}
		return cat;
	}

	const helpArticles = useMemo(() => {
		let language = selectedLanguage;

		const articles = _.map(blumeHelpLinks, (subCategories, key) => {
			// dynamically load contents
			let cat = {};
			try {
				cat = loadMDX(language, subCategories);
			} catch (error) {
				// cannot load mdx for this languague, use default EN
				if (language == selectedLanguage && language != 'en') {
					language = 'en';
					cat = loadMDX(language, subCategories);
				}
				console.error(error);
			}
			return cat;
		});

		// Only return articles that have data
		return (
			articles
				?.filter(article => article?.name)
				.filter(article => !HIDDEN_HELP_CENTER_CATEGORIES.includes(article.name)) || []
		);
	}, [blumeHelpLinks]);

	function importAll(r) {
		const images = {};
		r.keys().map((item, index) => {
			images[item.replace('./', '')] = r(item).default;
		});
		return images;
	}

	const helpIcons =
		process.env.NODE_ENV !== 'test'
			? importAll(require.context('../../../ui/src/assets/icons/BlumeHelpCenter', false, /\.(png|jpe?g|svg)$/))
			: {};

	const value = {
		helpArticles,
		blumeHelpLinks,
		helpIcons,
	};

	return <BlumeHelpCenterContext.Provider value={value}>{children}</BlumeHelpCenterContext.Provider>;
};

const useBlumeHelpCenter = () => useContext(BlumeHelpCenterContext);

export { BlumeHelpCenterContext, BlumeHelpCenterContextProvider, useBlumeHelpCenter };
