import React, { useState, useEffect, useRef, useCallback } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';

import DocumentCard from './DocumentCard';
import Filters from './Filters';
import PatientDocumentIcon from '../../../assets/icons/PatientInfoCards/registration-icon';
import { PATIENT_DOCUMENT_FILTERS } from '@worklist-2/worklist/src/DocumentViewerV3/consts/consts';

import { usePatientStore } from '@rs-ui/views/PatientInformationView/store/patientStore';
import NoDocuments from './NoDocuments';
import createPatientDocumentPayload from '@rs-ui/views/PatientInformationView/utils/createPatientDocumentPayload';
import { useConfig, arrayBufferToBase64 } from '@worklist-2/core/src';
import { usePatientInfoCardStore } from '@rs-ui/components/PatientInfoCards/store/patientInfoCardStore';

import useUploadPatientDocument from '../hooks/useUploadPatientDocument';
import usePatientDocuments from '@rs-ui/views/PatientInformationView/hooks/usePatientDocuments';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import FilesDropZone from '@worklist-2/worklist/src/DocumentViewerV3/components/DropZone/FilesDropZone';
import { useTranslation } from 'react-i18next';

// Allowed Document/Reports Formats
const DOCUMENT_MIME_TYPES = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/pdf',
	'text/plain',
];

const FILTER_LABEL = {
	[PATIENT_DOCUMENT_FILTERS.ALL]: 'All Documents',
	[PATIENT_DOCUMENT_FILTERS.UPLOADED_DOCUMENT]: 'Uploaded Documents',
	[PATIENT_DOCUMENT_FILTERS.REGISTRATION_FORM]: 'Registration Form',
};

const PatientDocument = ({ rootHeight, isSelected, isHover, setCollapsed, isExpandMain, handleUploadDocument }) => {
	const { documents: patientDocuments, patient } = usePatientStore();
	const { selectedDocFilter, setSelectedDocFilter, selectedPatientDocument, setSelectedPatientDocument, initConfig } =
		usePatientInfoCardStore();
	const { handleOnUpload, handleOnDelete } = usePatientDocuments();
	const { enableCollapse } = initConfig || {};
	const galaxyDvGlobalNotes = useBooleanFlagValue('galaxy-dv-global-notes');
	const galaxyDvPatientChartRefactor = useBooleanFlagValue('galaxy-dv-patient-chart-refactor');
	const galaxyDvDragDropPatientDoc = useBooleanFlagValue('galaxy-dv-drag-drop-patient-doc');
	const __config = useConfig();
	const { onDocumentUploadHandler } = useUploadPatientDocument(handleUploadDocument || handleOnUpload);
	const [patientDocs, setPatientDocs] = useState([]);
	const [expanded, setExpanded] = useState(true);

	const refInput = useRef();
	const { t } = useTranslation('reports');
	useEffect(() => {
		if (Array.isArray(patientDocuments)) {
			if (selectedDocFilter === PATIENT_DOCUMENT_FILTERS.ALL) {
				setPatientDocs(patientDocuments);
			} else {
				setPatientDocs(patientDocuments.filter(x => x.documentType === selectedDocFilter));
			}
		} else {
			setPatientDocs([]); // Set default value or handle error
		}
	}, [patientDocuments, selectedDocFilter]);

	useEffect(() => {
		setExpanded(isExpandMain);
	}, [isExpandMain]);

	const toggleExpanded = e => {
		e?.preventDefault();
		if (isSelected) {
			setExpanded(val => !val);
			setCollapsed(!expanded);
		}
	};

	const onClickUpload = useCallback(() => {
		refInput?.current?.click();
	}, [refInput]);
	const handleFileChange = e => {
		e.preventDefault();
		onUpload(e.target.files);
	};
	const onUpload = async files => {
		const file = files[0];
		const patientId = patient?.patient?.id;

		if (file && DOCUMENT_MIME_TYPES.includes(file.type)) {
			const attachment = {
				contentType: file.type,
				creation: new Date(file.lastModified),
				data: await file.arrayBuffer().then(res => arrayBufferToBase64(res)),
				size: file.size,
				title: file?.name,
			};

			const filePayload = createPatientDocumentPayload(patientId, attachment);

			const appURL = `${__config.data_sources.fhir}/DocumentReference?_dc=${new Date().getTime()}`;

			const payload = {
				type: 'PatientDocument',
				appURL,
				resourcePayload: filePayload,
			};

			// Create a copy of the files array
			const filesCopy = Array.from(files);

			onDocumentUploadHandler(payload, filesCopy, 'DocumentReference');

			// Reset file input value
			refInput.current.value = '';
		}
	};

	return (
		<Accordion
			disableGutters
			expanded={expanded}
			id="doc"
			sx={{
				...STYLES.ACCORDIAN,
				...(galaxyDvPatientChartRefactor && {
					height: '100%',
					'& .MuiCollapse-root': {
						height: 'calc(100% - 64px) !important',
						'.MuiCollapse-wrapper, .MuiCollapse-wrapperInner, .MuiAccordion-region, .MuiAccordionDetails-root':
							{
								height: '100% !important',
							},
					},
				}),
			}}
		>
			<AccordionSummary
				expandIcon={enableCollapse && <ExpandMoreIcon />}
				id="doc-summary"
				sx={STYLES.ACCORDIAN_SUMMARY}
				onClick={toggleExpanded}
			>
				<Typography
					sx={{
						display: 'inline-flex',
						alignItems: 'center',
					}}
				>
					<PatientDocumentIcon
						style={{
							width: '13px',
							height: 'auto',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							color: 'white',
							marginRight: '8px',
							fontSize: '18px',
							fontFamily: 'Roboto',
							fontWeight: '400',
						}}
					/>
					{t('Patient Document')}
				</Typography>
				{patientDocuments?.length > 0 && (
					<Typography sx={STYLES.LIST_COUNT}>{patientDocuments.length}</Typography>
				)}
			</AccordionSummary>
			{galaxyDvDragDropPatientDoc && (
				<FilesDropZone
					enableDropZone
					data-testid="patient-doc-file-drop-zone"
					wrapperSx={{ height: 'calc(100% - 48px)', top: '48px' }}
					onFileDrop={onUpload}
				/>
			)}
			<AccordionDetails sx={STYLES.ACCORDIAN_DETAILS}>
				<input
					ref={refInput}
					accept=".pdf, .doc, .docx, .txt"
					multiple={false}
					name="files"
					style={STYLES.HIDDEN_FILE_INPUT}
					type="file"
					onChange={handleFileChange}
				/>
				<Filters selectedFilter={selectedDocFilter} setSelectedFilter={setSelectedDocFilter} />
				{patientDocs?.length > 0 && (
					<Box sx={STYLES.CATEGORY_HEAD}>
						<Typography sx={STYLES.CATEGORY_LABEL}>{t(FILTER_LABEL[selectedDocFilter])}:</Typography>
						{selectedDocFilter !== PATIENT_DOCUMENT_FILTERS.REGISTRATION_FORM && (
							<Tooltip title={t('Upload Document')}>
								<IconButton sx={STYLES.UPLOAD_BTN} onClick={onClickUpload}>
									<PublishOutlinedIcon sx={{ fontSize: '20px' }} />
								</IconButton>
							</Tooltip>
						)}
					</Box>
				)}
				<Box
					sx={{
						...STYLES.DOC_LIST_CONT({
							isEmpty: patientDocs?.length === 0,
						}),
						...(!galaxyDvPatientChartRefactor && {
							maxHeight: `${
								!isSelected && isHover
									? 234
									: Math.max(
											(galaxyDvGlobalNotes ? rootHeight : rootHeight + 30) -
												64 -
												66 -
												90 -
												(patientDocs?.length === 0 ? 0 : 24),
											0
									  )
							}px`,
							height: `${
								!isSelected && isHover
									? 234
									: Math.max(
											(galaxyDvGlobalNotes ? rootHeight : rootHeight + 30) -
												64 -
												66 -
												90 -
												(patientDocs?.length === 0 ? 0 : 24),
											0
									  )
							}px`,
						}),
					}}
				>
					{patientDocs?.length ? (
						patientDocs.map(x => (
							<DocumentCard
								key={x.id}
								date={x.createdDate}
								fileName={x.fileName}
								id={x.id}
								isSelected={selectedPatientDocument?.id === x.id}
								type={x.documentType}
								onClick={() => setSelectedPatientDocument(x)}
								onDelete={() => handleOnDelete(x)}
								onDragStart={e => e.dataTransfer.setData('text/document', JSON.stringify(x))}
							/>
						))
					) : (
						<NoDocuments
							t={t}
							onClick={
								selectedDocFilter !== PATIENT_DOCUMENT_FILTERS.REGISTRATION_FORM
									? onClickUpload
									: undefined
							}
						/>
					)}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

export default PatientDocument;

const STYLES = {
	ACCORDIAN: {
		boxShadow: 'unset',
		background: 'unset',
		'&:before': {
			display: 'none',
		},
	},
	ACCORDIAN_SUMMARY: {
		background: '#363636',
		borderRadius: '10px 10px 0 0',
		position: 'sticky',
		top: '0',
		zIndex: '2',
		minHeight: '64px',
		'& .MuiAccordionSummary-content': {
			margin: '0',
		},
		'&:hover': {
			backgroundColor: '#4f4f4f',
		},
	},
	ACCORDIAN_DETAILS: {
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
		background: '#303030',
		borderRadius: '4px',
		position: 'relative',
		'& > *:not([data-testid="patient-doc-file-drop-zone"])': {
			zIndex: 2,
		},
	},
	DOC_LIST_CONT: ({ isEmpty }) => ({
		display: 'flex',
		flexDirection: 'column',
		padding: `${isEmpty ? 13 : 1}px 15px 15px`,
		gap: '10px',
		overflowY: 'auto',
	}),
	LIST_COUNT: {
		width: '26px',
		height: '26px',
		borderRadius: '6px',
		background: '#282828',
		fontWeight: '400',
		fontSize: '13px',
		color: '#fff',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		ml: '8px',
	},
	HIDDEN_FILE_INPUT: {
		visibility: 'hidden',
		height: 0,
		overflow: 'hidden',
		width: 0,
		opacity: 0,
		display: 'none',
	},
	CATEGORY_HEAD: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '40px',
		padding: '0 16px',
	},
	CATEGORY_LABEL: {
		fontSize: '14px',
		lineHeight: '16px',
		fontWeight: '400',
	},
	UPLOAD_BTN: {
		width: '30px',
		height: '30px',
		boxSizing: 'border-box',
	},
};
