import fhirExtensionUrls from '@worklist-2/core/src/fhir/extension/fhirExtensionUrls';
import { useRouter } from '@worklist-2/core/src/hooks/useRouter';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import useToast from '@worklist-2/core/src/hooks/useToast';
import { useGlobalStore } from '@worklist-2/core/src/store';
import { useAuth } from '@rs-core/context/UserAuthContext';
import { getViewerURLs } from '@rs-ui/components/Worklist/WorklistGrid/getViewerURLs';
import { useMultiscreen } from '@worklist-2/core/src/context/MultiscreenContext';
import { useDeviceStore } from '@worklist-2/worklist/src/DocumentViewerV3/store/deviceStore';
import { logInfo } from '@worklist-2/core/src/utils/logger';
import { useRecognitionContext } from '@worklist-2/worklist/src/DocumentViewerV3/contexts/RecognitionContext';
import { GRID_NAME } from '@rs-ui/helpers/constants';

const useWorklistWheel = ({ t }) => {
	const proactEnableNewVisitOrderPage = useBooleanFlagValue('proact-enable-new-visit-order-page');
	const sprinterOmegaaiMultiscreen = useBooleanFlagValue('sprinter-omegaai-multiscreen');
	const galaxyDvEnableAugnitoSdk = useBooleanFlagValue('galaxy-dv-enable-augnito-sdk');
	const galaxyDvEnableGlobalStoreWithIndexedDb = useBooleanFlagValue('galaxy-dv-enable-global-store-with-IndexedDB');
	const { openToSettingScreen } = useMultiscreen();
	const { goTo } = useRouter();
	const { setToastUtility } = useToast();
	const { setCurrentStudyInfo } = useGlobalStore();
	const { loggedInUser, displaySettings } = useAuth();
	const { deviceManager } = useDeviceStore();
	const { toggleRequestDevice: toggleRequestDeviceFromContext } = useRecognitionContext();
	const { toggleRequestDevice: toggleRequestDeviceFromStore } = useGlobalStore();

	const toggleRequestDevice = galaxyDvEnableAugnitoSdk
		? toggleRequestDeviceFromStore
		: toggleRequestDeviceFromContext;

	const imageViewerUrl = loggedInUser?.imageViewerUrl;
	const documentViewerUrl = loggedInUser?.documentViewerUrl;

	const isValidGUID = guid => {
		const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

		return guidPattern.test(guid);
	};

	const handleWorklistWheelClick = async (
		selectedOption,
		studyRecord,
		onWheelSendStudyClicked,
		gridName = GRID_NAME.worklist,
		isEmergencySearch = false
	) => {
		if (!studyRecord) {
			return;
		}

		const patientID = studyRecord?.patientID;
		const internalPatientId = studyRecord?.subject?.id;
		const studyInstanceUid = studyRecord?.identifier
			?.find(item => item.system === 'urn:dicom:uid')
			?.value?.replace('urn:oid:', '');
		const issuerOfPatientId = studyRecord?.extension?.find(ext => ext.url === fhirExtensionUrls.organization.issuer)
			?.valueReference?.display;
		const internalStudyId = studyRecord?.id;
		const orderId = studyRecord?.basedOn?.[0]?.id;
		const internalManagingOrganizationID = studyRecord?.internalManagingOrganizationID;
		const { referringFacilityId } = studyRecord;
		let creatorBlumeId = studyRecord?.patientID?.toLowerCase() || null;
		if (!isValidGUID(creatorBlumeId)) {
			creatorBlumeId = null;
		}

		switch (selectedOption?.icon) {
			case 'patient':
				goTo.patientDetail({ patientId: internalPatientId, screen: 'info' });
				return;
			case 'order':
				goTo.any(`/order/${orderId}`); // goTo.orderDetail({ orderId, patientId: internalPatientId }); // #TODO: useRouter
				return;
			case 'billing':
				goTo.any(`/order/${orderId}/billing/${internalStudyId}/generate-invoice`); // goTo.orderGenerateInvoice({ orderId, patientId: internalPatientId, studyId: internalStudyId }); // #TODO: useRouter
				return;
			case 'study':
				if (proactEnableNewVisitOrderPage) {
					goTo.any(`/order/${orderId}#study=${internalStudyId}`);
				} else {
					goTo.any(`/order/${orderId}#study-list`); // goTo.orderStudyList({ orderId, patientId: internalPatientId }); // #TODO: useRouter
				}
				return;

			case 'sendstudy':
				if (studyInstanceUid) {
					onWheelSendStudyClicked(studyRecord);
				} else {
					setToastUtility(true, t('workList:selectedStudyToast'));
				}

				return;
			case 'studyexplorer':
				goTo.patientDetail({ patientId: internalPatientId, screen: 'study-history' });
				return;
			case 'imageviewer':
				if (gridName === GRID_NAME.worklist) {
					setCurrentStudyInfo(studyRecord);
				}
				if (imageViewerUrl && !(sprinterOmegaaiMultiscreen && displaySettings && displaySettings.enabled)) {
					window.open(imageViewerUrl.replace('<studyuid>', studyInstanceUid));
				} else {
					const imageViewerArgs = {
						patientID,
						internalPatientId,
						orderId,
						internalStudyId,
						internalManagingOrganizationID,
						issuerOfPatientId,
						referringFacilityId,
						studyInstanceUid,
						imageViewerUrl,
						documentViewerUrl,
					};

					if ([GRID_NAME.worklist, GRID_NAME.teachingFolder].includes(gridName)) {
						imageViewerArgs.creatorBlumeId = creatorBlumeId;
					}

					let { documentViewerNavigateURL, imageViewerNavigateURL } = getViewerURLs(imageViewerArgs);

					if (isEmergencySearch) {
						imageViewerNavigateURL += '&isEmergencySearch=true';
					}

					if (sprinterOmegaaiMultiscreen && window.screen.isExtended) {
						if (gridName === GRID_NAME.worklist) {
							await openToSettingScreen(
								documentViewerNavigateURL,
								'DV',
								documentViewerUrl,
								false,
								studyRecord
							);
						} else {
							await openToSettingScreen(documentViewerNavigateURL, 'DV', documentViewerUrl);
						}

						const resultIV = await openToSettingScreen(imageViewerNavigateURL, 'IV', imageViewerUrl);
						if (!resultIV) {
							goTo.any(imageViewerNavigateURL); // #TODO: useRouter safe-type this !
						}
					} else {
						goTo.any(imageViewerNavigateURL); // #TODO: useRouter safe-type this !
					}
				}

				return;
			case 'documentviewer':
				if (galaxyDvEnableGlobalStoreWithIndexedDb) {
					setCurrentStudyInfo(studyRecord);
				} else {
					localStorage.setItem('currentStudyInfo', JSON.stringify(studyRecord));
				}
				if (documentViewerUrl && !(sprinterOmegaaiMultiscreen && displaySettings && displaySettings.enabled)) {
					window.open(documentViewerUrl.replace('<studyuid>', studyInstanceUid));
				} else {
					const docViewerArgs = {
						patientID,
						internalPatientId,
						orderId,
						internalStudyId,
						internalManagingOrganizationID,
						issuerOfPatientId,
						referringFacilityId,
						studyInstanceUid,
					};

					if ([GRID_NAME.worklist, GRID_NAME.teachingFolder].includes(gridName)) {
						docViewerArgs.creatorBlumeId = creatorBlumeId;
					}

					const { documentViewerNavigateURL } = getViewerURLs(docViewerArgs);

					if (sprinterOmegaaiMultiscreen && window.screen.isExtended) {
						const resultDV =
							gridName === GRID_NAME.worklist
								? await openToSettingScreen(
										documentViewerNavigateURL,
										'DV',
										documentViewerUrl,
										false,
										studyRecord
								  )
								: await openToSettingScreen(documentViewerNavigateURL, 'DV', documentViewerUrl);

						if (!resultDV) {
							goTo.any(documentViewerNavigateURL); // #TODO: safe-type this !
						}
					} else {
						goTo.any(documentViewerNavigateURL); // #TODO: safe-type this !
					}
				}
				if (gridName === GRID_NAME.worklist) {
					// request permission for connected HID device
					const pairedDevice = JSON.parse(localStorage.getItem('pairedDevice'));
					if (pairedDevice && deviceManager) {
						const devices = deviceManager.getDevices();
						logInfo('MIKE::WorklistGrid::', 'devices permitted already', devices);
						if (devices?.length <= 0) {
							await toggleRequestDevice(true);
						}
					}
				}
				return;
			default:
				console.error(`Could not find action for ${selectedOption?.icon}`);
		}
	};

	return { handleWorklistWheelClick };
};

export default useWorklistWheel;
