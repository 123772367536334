import moment from 'moment';
import { v4 as uuid } from 'uuid';
import {
	dateTimeUtil as dateUtils,
	getUserFullName,
	getExtensionValueReference,
	getExtensionValueString,
	getExtensionValueCoding,
	generateRandomString,
	fhirExtensionUrls,
} from '@worklist-2/core/src';
import { isEmpty, trimEnd } from 'lodash';
import { convertPractitioner, createUrnUuid } from '@rs-ui/components/Drawers/AddPhysicianDrawer/generalHelperMethods';

export const formatDateTime = dateTimeString => moment(dateTimeString).format('MM/DD/YYYY | hh:mm A');

export const parsedDate = date =>
	typeof date === 'string' && !date.includes('+') && !date.includes('Z') ? `${date}+00:00` : date;

export const convertDateFormat = dateTimeString => moment(dateTimeString).format('MM-DD-YYYY hh:mm A');

export const transAccidentFormNotes = extension => {
	return extension?.map(note => {
		const getExtensionValueDateTime = url => note?.extension?.find(item => item.url === url)?.valueDateTime || '';
		return {
			type: note?.url,
			text: getExtensionValueString(note, 'text') || note?.valueString || '',
			author: getExtensionValueReference(note, 'author')?.display,
			authorId: getExtensionValueReference(note, 'author')?.id,
			time: parsedDate(getExtensionValueDateTime('time')),
			id: note?.id,
			isAddNew: false,
			date: dateUtils.parseLocaleDate(getExtensionValueDateTime('date')),
			state: getExtensionValueCoding(note, 'state')?.code,
		};
	});
};

export const transFormNotes = notes => {
	return notes?.map(note => {
		return {
			id: note?.id,
			text: note?.text || '',
			author: note?.authorReference?.display,
			authorId: note?.authorReference?.id,
			time: parsedDate(note?.time),
			isAddNew: false,
			authorReference: note?.authorReference,
		};
	});
};

export const getValuesFromPatientNotes = notes => {
	return notes?.map(note => {
		const author = note.extension.find(ext => ext.url === 'author')?.valueReference;
		const text = note.extension.find(ext => ext.url === 'text')?.valueString || '';
		const time = note.extension.find(ext => ext.url === 'time')?.valueDateTime || '';

		return {
			id: note?.id,
			text: text,
			author: author?.display,
			authorId: author?.id,
			time: parsedDate(time),
			isAddNew: false,
			authorReference: author,
		};
	});
};

const createOrderRequesterNode = (physician, organization) => {
	const node = {};

	if (physician?.display || physician?.name?.[0]) {
		Object.assign(node, createPhysicianNode(physician));
	}

	if (organization?.display || organization?.name) {
		Object.assign(node, createOrganizationNode(organization));
	}

	return node;
};

const updatePayloadRequester = (payload, physician, organization) => {
	if (payload) {
		delete payload.requester;
		const newRequester = createOrderRequesterNode(physician, organization);
		if (Object.keys(newRequester).length > 0) {
			payload.requester = newRequester;
		}
	}
	return { ...payload };
};

export const createConsultingPhysicianNode = consultingPhysicians => {
	return consultingPhysicians?.map(item => ({
		valueReference: {
			id: item?.practitioner?.id,
			extension: [
				{
					url: fhirExtensionUrls.imagingStudy.facility,
					valueReference: {
						...item?.organization,
					},
				},
			],
			display: getUserFullName(item?.practitioner?.display),
		},
	}));
};

const orderCodes = {
	accession: 'ACSN',
	fillOrder: 'FILL',
	placerOrder: 'PLAC',
};

const getOrderIndex = (identifier, code) => identifier?.findIndex(item => item.type?.coding?.[0]?.code === code);

const getDefaultOrderIdentifier = (code, value) => ({
	type: {
		coding: [{ system: 'http://hl7.org/fhir/ValueSet/identifier-type', code }],
	},
	value: value,
});

const createPhysicianNode = physician => {
	const display = physician?.display ?? getUserFullName(physician?.name[0]);

	return physician?.id
		? {
				id: physician?.id,
				reference: `Practitioner/${physician?.id}`,
				display,
		  }
		: { display };
};

const createOrganizationNode = organization => {
	const display = organization?.display ?? organization?.name;
	const organizationInfo = {
		url: 'http://www.ramsoft.com/fhir/extension/organization',
		valueReference: { display },
	};

	if (organization?.id) {
		organizationInfo.valueReference.id = organization.id;
		organizationInfo.valueReference.reference = `Organization/${organization.id}`;
	}

	return { extension: [organizationInfo] };
};

const createNewVisitPayload = (organizationId, patient) => ({
	status: 'planned',
	resourceType: 'Encounter',
	subject: {
		id: patient?.id,
		reference: `Patient/${patient?.id}`,
		display: getUserFullName(patient?.name?.[0]),
	},
	serviceProvider: {
		id: organizationId,
		reference: `Organization/${organizationId}`,
	},
});

const createNewOrderPayload = (
	priority,
	patient,
	authoredOn,
	occurrenceDateTime,
	urnUuid,
	physician,
	organization,
	consultingPhysicians,
	orderStatus,
	code,
	accessionNumber,
	fillerOrderNumber,
	placerOrderNumber,
	identifier,
	note
) => {
	const payload = {
		resourceType: 'ServiceRequest',
		active: true,
		status: orderStatus,
		intent: 'order',
		priority: priority,
		authoredOn,
		occurrenceDateTime,
		subject: patient,
		encounter: { reference: urnUuid },
		code,
		note,
	};

	if (identifier) {
		identifier[getOrderIndex(identifier, orderCodes.accession)] = getDefaultOrderIdentifier(
			orderCodes.accession,
			accessionNumber || ''
		);
		identifier[getOrderIndex(identifier, orderCodes.fillOrder)] = getDefaultOrderIdentifier(
			orderCodes.fillOrder,
			fillerOrderNumber || ''
		);
		identifier[getOrderIndex(identifier, orderCodes.placerOrder)] = getDefaultOrderIdentifier(
			orderCodes.placerOrder,
			placerOrderNumber || ''
		);
		payload.identifier = identifier;
	}

	if (consultingPhysicians?.length) {
		payload.extension = [
			...(payload.extension || []),
			{
				url: 'http://www.ramsoft.com/fhir/StructureDefinition/consulting-physician',
				extension: createConsultingPhysicianNode(consultingPhysicians),
			},
		];
	}

	return updatePayloadRequester(payload, physician, organization);
};

export const createBundlePayload = (
	encounterId,
	serviceRequestId,
	patient,
	code,
	identifier,
	organizationId,
	note,
	form
) => {
	const [
		priority,
		authoredOn,
		occurrenceDateTime,
		referringPhysician,
		referringOrganization,
		consultingPhysicians,
		orderStatus,
		accessionNumber,
		fillerOrderNumber,
		placerOrderNumber,
	] = form?.getValues([
		'priority',
		'authoredOn',
		'occurrenceDateTime',
		'referringPhysician',
		'referringOrganization',
		'consultingPhysician',
		'orderStatus',
		'accessionNumber',
		'fillerOrderNumber',
		'placerOrderNumber',
	]);
	const urnUuid1 = `urn:uuid${uuid()}`;
	const urnUuid2 = `urn:uuid${uuid()}`;
	const authoredOnFormatted = moment(authoredOn).toISOString();
	const occurrenceDateTimeFormatted = moment(occurrenceDateTime).toISOString();

	return {
		id: 'bundle-transaction',
		resourceType: 'Bundle',
		type: 'transaction',
		entry: [
			{
				request: { method: 'PUT', url: `/Encounter/${encounterId}` },
				fullUrl: urnUuid1,
				resource: createNewVisitPayload(organizationId, patient),
			},
			{
				request: { method: 'PUT', url: `/ServiceRequest/${serviceRequestId}` },
				fullUrl: urnUuid2,
				resource: createNewOrderPayload(
					priority,
					patient,
					authoredOnFormatted,
					occurrenceDateTimeFormatted,
					urnUuid1,
					referringPhysician,
					referringOrganization,
					consultingPhysicians,
					orderStatus,
					code,
					accessionNumber,
					fillerOrderNumber,
					placerOrderNumber,
					identifier,
					note
				),
			},
		],
	};
};

const transformName = nameObj => {
	const givenNames = nameObj?.givenNames ? [nameObj?.givenNames]?.join('^') : '';
	const text = `${nameObj.familyName || ''}^${givenNames}`.trim();
	return trimEnd(text, '^ ');
};

const formatName = clonedPractitioner => {
	return isEmpty(clonedPractitioner)
		? []
		: [
				{
					given: [clonedPractitioner.familyName.trim()],
					family: clonedPractitioner.givenNames.trim(),
					text: transformName(clonedPractitioner),
					suffix: [clonedPractitioner.suffix],
					prefix: [clonedPractitioner.prefix],
				},
		  ];
};

export const createAddUserPayload = (practitionerJson, selectedRefOrganizationRole) => {
	const clonedPractitioner = { ...practitionerJson };
	const payload = {};
	const practitionerUrnUuid = createUrnUuid();
	payload.name = formatName(clonedPractitioner);
	payload.telecom = !isEmpty(clonedPractitioner?.telecom) ? clonedPractitioner?.telecom : [];
	payload.address = !isEmpty(clonedPractitioner?.address) ? clonedPractitioner?.address : [];
	payload.extension = isEmpty(clonedPractitioner) ? [] : convertPractitioner(clonedPractitioner);
	payload.identifier = [
		{
			value: clonedPractitioner?.npiId || '',
			system: '',
			type: {
				coding: [
					{
						system: 'http://hl7.org/fhir/ValueSet/identifier-type',
						code: 'NPI',
					},
				],
			},
			use: 'usual',
		},
	];
	// Need to make a bundle call to both practitioner and practitioner role
	const practitionerPayload = {
		fullUrl: practitionerUrnUuid,
		request: {
			method: 'POST',
			url: '/Practitioner/',
		},
		resource: {
			...payload,
			active: true,
			birthDate: null,
			resourceType: 'Practitioner',
		},
	};

	// Will need to loop through selected roles to create payload for each of them and
	// append them to entry
	const practitionerRolePayloads = {
		fullUrl: createUrnUuid(),
		request: { method: 'POST', url: '/PractitionerRole/' },
		resource: {
			active: true,
			extension: [
				{
					url: 'http://www.ramsoft.com/fhir/StructureDefinition/role',
					valueReference: {
						id: selectedRefOrganizationRole?.id,
						reference: `Role/${selectedRefOrganizationRole?.id}`,
						display: 'REFERRING PHYSICIAN',
					},
				},
				{
					url: 'http://www.ramsoft.com/fhir/extension/practitioner/studyPlayerType',
					valueCoding: {
						code: '1',
						display: 'Referring Physician',
					},
				},
			],
			organization: {
				display: clonedPractitioner?.referringOrganization?.display,
				id: clonedPractitioner?.referringOrganization?.id,
				reference: `Organization/${clonedPractitioner?.referringOrganization?.id}`,
			},
			practitioner: {
				reference: practitionerUrnUuid,
			},
			resourceType: 'PractitionerRole',
		},
	};

	return {
		entry: [practitionerPayload, practitionerRolePayloads],
		id: 'bundle-transaction',
		resourceType: 'bundle',
		type: 'transaction',
	};
};

export const moveObjectToTop = (array: Object[], key: string, value: Object) => {
	// Find the index of the object with the given key-value pair
	const index = array.findIndex(
		item =>
			item?.url?.includes('http://www.ramsoft.com/fhir/extension/organization') &&
			item?.valueReference?.id === value?.id
	);

	// If the object is found, move it to the top of the array
	if (index !== -1) {
		const obj = array[index];
		array.splice(index, 1); // Remove the object from its current position
		array.unshift(obj); // Add the object to the top of the array
	} else {
		// If the object is not found, add a new object to the start of the array
		const newObj = { url: 'http://www.ramsoft.com/fhir/extension/organization', valueReference: value };
		array.unshift(newObj);
	}

	return array;
};

export const getDefaultPhysicianObject = () => {
	return {
		familyName: '',
		givenNames: '',
		loginEmail: '',
		phone: '',
		address: [
			{
				city: '',
				country: '',
				line: [],
				postalCode: '',
				state: '',
			},
		],
		referringOrganization: null,
		prefix: '',
		suffix: '',
		specialty: null,
		dea: '',
		npiId: '',
		telecom: [
			{ system: 'email', value: '', rank: 4, id: generateRandomString(16) },
			{ system: 'phone', value: '', rank: 3, id: generateRandomString(16) },
			{ system: 'fax', value: '', rank: 4, id: generateRandomString(16) },
		],
	};
};

export const getDefaultReferringOrgObject = () => {
	return {
		orgName: '',
		practiceTypeArray: 'Referring',
		corporateWebsite: '',
		address: [
			{
				city: '',
				country: '',
				line: [],
				postalCode: '',
				state: '',
			},
		],
		timeZone: null,
		telecom: [
			{ system: 'email', value: '', rank: 4, id: generateRandomString(16) },
			{ system: 'phone', value: '', rank: 3, id: generateRandomString(16) },
			{ system: 'fax', value: '', rank: 4, id: generateRandomString(16) },
		],
	};
};

export const extensionValueGetter = extensionArray => {
	const result = {};

	extensionArray?.forEach(item => {
		// Extract the last part of the URL to use as the key
		const key = item?.url?.split('/').pop();

		// Determine the value field dynamically, as it can be of different types
		const valueKey = Object.keys(item).find(k => k.startsWith('value'));

		if (valueKey) {
			result[key] = item[valueKey];
		}
	});

	return result;
};

export const createStudyPayload = (study, form) => {
	const { studyStatus, requestedProcedureID, started, description } = form.getValues();
	const { extension } = study;
	const studyStatusIndex = extension.findIndex(item => item.url.includes(fhirExtensionUrls.common.status));
	const studyExtensionValue = {
		url: fhirExtensionUrls.common.status,
		valueString: studyStatus?.status,
	};
	if (studyStatusIndex === -1) {
		extension.push(studyExtensionValue);
	} else {
		extension[studyStatusIndex] = studyExtensionValue;
	}

	const requestedProcedureIDIndex = extension.findIndex(item =>
		item.url.includes(fhirExtensionUrls.common.requestedProcedureID)
	);
	const requestedProcedureIDValue = {
		url: fhirExtensionUrls.common.requestedProcedureID,
		valueString: requestedProcedureID,
	};
	if (requestedProcedureIDIndex === -1) {
		extension.push(requestedProcedureIDValue);
	} else {
		extension[requestedProcedureIDIndex] = requestedProcedureIDValue;
	}

	study.started = started;
	study.description = description;
	study.status = studyStatus?.status;
	return study;
};

export const transformPatientNotes = notes => {
	return notes.map(note => ({
		id: note?.id,
		url: 'note',
		extension: [
			{
				url: 'author',
				valueReference: {
					display: note?.author,
					id: note?.authorId,
					reference: `practitioner/${note?.authorId}`,
				},
			},
			...(!isEmpty(note?.text)
				? [
						{
							url: 'text',
							valueString: note?.text,
						},
				  ]
				: []),
			...(!isEmpty(note?.time)
				? [
						{
							url: 'time',
							valueDateTime: note?.time,
						},
				  ]
				: []),
		],
	}));
};
