import axios from 'axios';

const getStudyReports = async ({ __config, id }) => {
	const response = await axios.get(`${__config.data_sources.blume}Study/${id}/report`);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default getStudyReports;
