/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { mediaTablet, useAuth, useConfig, useIsTablet, useIsMobile, mediaMobile } from '@worklist-2/core/src';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import NoNotification from '@worklist-2/ui/src/assets/icons/NoNotification.svg';
import { useDrawersStore } from '@worklist-2/patientPortal/src/stores';
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';
import { useAppointmentContext } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentContext/AppointmentContext';
import getNotification from '@worklist-2/patientPortal/src/api/getNotification';
import deleteNotificationAPI from '@worklist-2/patientPortal/src/api/deleteNotification';
import MobileStudyDetail from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetail';
import MobileBottomDrawer from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import MobileList from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileList/MobileList';
import FormDrawer from '@worklist-2/patientPortal/src/components/FormDrawer/FormDrawer';
import NoDataAvailable from '@worklist-2/patientPortal/src/components/NoDataAvailable';

import generateNotification from './generateNotification';

const Notifications = ({ setDrawerOpen }) => {
	const setHomePageTab = useDrawersStore(state => state.setHomePageTab);
	const { setOldAppointmentOpen } = useAppointmentContext();
	const [notificationDetails, setNotificationDetails] = useState([]);
	const [notificationAvailable, setNotificationAvailable] = useState(false);
	const [selectedForm, setSelectedForm] = useState(null);
	const [appointmentData, setAppointmentData] = useState(null);
	const [openFormDrawer, setOpenFormDrawer] = useState(false);
	const [studyInDetailedView, setStudyInDetailedView] = useState(null);
	const [showLoading, setShowLoading] = useState(true);

	const { t } = useTranslation('drawer');
	const navigate = useNavigate();
	const phoenixExpandedBookAppointmentDrawer = useBooleanFlagValue('phoenix-expanded-book-appointment-drawer');
	const { setNotificationReceived, notificationReceived } = useAuth();
	const __config = useConfig();
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const { setToastMsg } = useToastMessageContext();

	useEffect(() => {
		fetchNotifications();
	}, []);

	useEffect(() => {
		let timeoutId;
		if (notificationReceived) {
			setShowLoading(true);
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				fetchNotifications();
				setNotificationReceived(false);
			}, 10000);
		}
		return () => clearTimeout(timeoutId);
	}, [notificationReceived]);

	const fetchNotifications = () => {
		getNotification({ __config }).then(result => {
			setNotificationDetails(result.sort((a, b) => (b?.generationTime || 0) - (a?.generationTime || 0)));

			if (result?.length > 0) {
				setNotificationAvailable(true);
			} else {
				setNotificationAvailable(false);
			}
			setShowLoading(false);
		});
	};

	const deleteNotification = details => () => {
		if (!details.isSeen) {
			setToastMsg('Unseen notification cannot be deleted!');
			return;
		}

		deleteNotificationAPI({ __config, id: details.id }).then(() => {
			setNotificationDetails(notificationDetails.filter(item => item.id !== id));
		});

		if (notificationDetails.length === 1) {
			setNotificationAvailable(false);
		}
	};

	return (
		<>
			{openFormDrawer && (
				<FormDrawer
					appointment={appointmentData}
					data={selectedForm}
					open={openFormDrawer}
					setOpen={setOpenFormDrawer}
					onDrawer
				/>
			)}
			{(isMobile || isTablet) && (
				<MobileBottomDrawer
					background="#ffffff"
					open={Boolean(studyInDetailedView)}
					onClose={() => setStudyInDetailedView(null)}
				>
					<MobileStudyDetail data={studyInDetailedView} isShared="withMe" />
				</MobileBottomDrawer>
			)}
			{notificationAvailable ? (
				<Box sx={{ marginLeft: '10px' }}>
					<MobileList
						background={isTablet ? '#f9f9f9' : '#fafafa'}
						border="0px"
						items={notificationDetails
							.map(details =>
								generateNotification({
									details,
									setNotificationReceived,
									notificationDetails,
									phoenixExpandedBookAppointmentDrawer,
									setNotificationDetails,
									setDrawerOpen,
									setHomePageTab,
									setOldAppointmentOpen,
									setStudyInDetailedView,
									setAppointmentData,
									setOpenFormDrawer,
									setSelectedForm,
									deleteNotification,
									navigate,
									t,
									__config,
									isMobile,
									isTablet,
								})
							)
							.filter(Boolean)}
						subheader={isMobile ? <h2>{t('Notifications')}</h2> : ''}
						sx={{ position: 'relative' }}
						sxListItem={isTablet ? { padding: '16px', marginTop: '10px' } : null}
						sxListItemText={{ textOverflow: 'none', whiteSpace: 'wrap' }}
					/>
				</Box>
			) : showLoading ? (
				<Skeleton
					data-testid="notifications-component"
					sx={{
						height: '850px',
						borderRadius: '10px',
						marginLeft: '20px',
						marginRight: '10px',

						[mediaTablet]: { marginLeft: 0, marginRight: 0 },
					}}
					variant="rectangular"
				/>
			) : (
				<Box
					data-testid="noNotifications"
					sx={{
						marginTop: '250px',
						[mediaTablet]: {
							marginLeft: '30px',
						},
						[mediaMobile]: {
							marginTop: '100px',
							marginLeft: '20px',
						},
					}}
				>
					<NoDataAvailable
						direction="column"
						image={{ imagePath: NoNotification, width: '290px', height: '250px' }}
						primaryText={{
							text: t('No Notifications Found'),
							fontSize: '18px',
							fontWeight: '500',
							color: '#121212',
						}}
						secondaryText={{
							text: t('We will notify you when something new arrives'),
							fontSize: '12px',
							fontWeight: '400',
							color: '#12121299',
						}}
					/>
				</Box>
			)}
		</>
	);
};
export default Notifications;
