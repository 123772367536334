export default function getChangedFieldsOnEditProfile(inputData, user) {
	const changedFields = {};

	for (const key in inputData) {
		if (
			key === 'address' ||
			key === 'city' ||
			key === 'country' ||
			key === 'zip' ||
			key === 'state' ||
			key === 'addressLine2'
		) {
			if (!changedFields.address) {
				changedFields.address = [
					{
						AddressLine1: inputData.address ? inputData.address : '',
						City: inputData.city || user.city,
						Country: inputData.country || user.country,
						Zip: inputData.zip || user.zip,
						State: inputData.state || user.state,
						AddressLine2: inputData.addressLine2 || user.addressLine2,
					},
				];
			}
		} else {
			changedFields[key] = inputData[key];
		}
	}

	return changedFields;
}
