import React, { useState, useEffect } from 'react';
import Header from './Header';
import Box from '@mui/material/Box';
import EmergencyIcon from '@worklist-2/ui/src/assets/img/emergencyIcon.svg';
import AddNewButton from '@rs-ui/components/AddNewButton/AddNewButton';
import GenericDrawer from '@rs-ui/components/Drawers/GenericDrawer/GenericDrawer';

import { useTranslation } from 'react-i18next';

// MUI icons
import EmergencyContactCard from '@worklist-2/patientPortal/src/views/NewSettingsView/Components/UserDetailDrawer/Details/EmergencyContactCard';
import GenerateForm from '@worklist-2/patientPortal/src/views/NewSettingsView/Components/GenerateForm/GenerateForm';
import EditDeleteButton from '@worklist-2/patientPortal/src/views/NewSettingsView/Components/EditDeleteButton/EditDeleteButton';
import MobileBottomDrawer from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import { useIsMobile } from '@rs-core/utils/responsiveUtils';
import { useUserStore } from '@worklist-2/patientPortal/src/stores';
import { useConfig } from '@rs-core/context/ConfigContext';

export const contactEmailExistencyCheck = (email, list, setMessage) => {
	let exist = false;
	const contact = list.find(element => element.email === email);
	if (contact) {
		setMessage(true);
		exist = true;
	}
	if (!exist) {
		setMessage(false);
	}
	return exist;
};

const EmergencyContact = () => {
	const { emergencyContacts, createEmergencyContact, updateEmergencyContacts, deleteEmergencyContact } = useUserStore(
		state => ({
			emergencyContacts: state.emergencyContacts,
			createEmergencyContact: state.createEmergencyContact,
			updateEmergencyContacts: state.updateEmergencyContacts,
			deleteEmergencyContact: state.deleteEmergencyContact,
		})
	);
	const [isOpenAddDrawer, setIsOpenAddDrawer] = useState(false);
	const [updateData, setUpdateData] = useState({});
	const [message, setMessage] = useState(false);
	const { t } = useTranslation('profile');
	const __config = useConfig();

	const isMobile = useIsMobile();

	const isEditing = Object.keys(updateData).length > 0;

	const onEdit = editData => {
		setUpdateData(editData);
		setIsOpenAddDrawer(true);
	};

	const onCancel = () => setIsOpenAddDrawer(false);

	const onSave = async data => {
		if (contactEmailExistencyCheck(data?.email, emergencyContacts, setMessage)) return;
		await createEmergencyContact({ __config, data });
		onCancel();
	};

	const onUpdate = async data => {
		await updateEmergencyContacts({ __config, data });
		onCancel();
	};

	const onDelete = data => {
		deleteEmergencyContact({ __config, data });
	};

	useEffect(() => {
		if (!isOpenAddDrawer) {
			setUpdateData({});
		}
	}, [isOpenAddDrawer]);

	return (
		<Box
			sx={{
				marginBottom: '25px',
			}}
		>
			<Header
				icon={{
					component: EmergencyIcon,
					color: 'var(--color-primary)',
				}}
				text={t('Emergency Contact')}
			/>

			<AddNewButton
				sx={{
					marginTop: '20px',
					height: '78px',
					width: '100%',
					color: '#12121233',
					border: '1px dashed #12121233',
					borderRadius: '10px',
				}}
				tooltipTitle={t('Add New Contact')}
				onClick={() => setIsOpenAddDrawer(true)}
			/>

			<Box>
				{emergencyContacts.map(emerContact => (
					<Box
						key={emerContact.id}
						sx={{
							position: 'relative',
							backgroundColor: '#F7F1FF',
							padding: '12px',
							borderRadius: '10px',
							marginTop: '10px',
							'&:hover': {
								'& .CardItemActions': {
									display: 'flex',
								},
							},
						}}
					>
						<EmergencyContactCard contactInfo={emerContact} />
						<EditDeleteButton
							sx={{ right: '14px', top: '29%', bottom: 'unset' }}
							onDelete={() => onDelete(emerContact)}
							onEdit={() => onEdit(emerContact)}
						/>
					</Box>
				))}
			</Box>
			{isMobile ? (
				<MobileBottomDrawer
					open={isOpenAddDrawer}
					sxTitle={{ fontWeight: '500', fontSize: '16px', color: '#121212DE', letterSpacing: '0.1em' }}
					title={isEditing ? t('Edit Emergency Contact') : t('Add New Emergency Contact')}
					onClose={setIsOpenAddDrawer}
				>
					<GenerateForm
						formData={updateData}
						formType="emergencyContact"
						onCancel={onCancel}
						onSave={isEditing ? onUpdate : onSave}
					/>
				</MobileBottomDrawer>
			) : (
				<GenericDrawer
					headerFlag
					showBackBtn
					PaperProps={{ sx: { '.headerSection': { pl: '9px' } } }}
					customHeaderTextProps={{ textTransform: 'uppercase' }}
					drawerColor="#FFFFFF"
					drawerOpen={isOpenAddDrawer}
					setDrawerOpen={setIsOpenAddDrawer}
					title={isEditing ? t('Edit Emergency Contact') : t('Add New Emergency Contact')}
					width={498}
				>
					<GenerateForm
						formData={updateData}
						formType="emergencyContact"
						message={message}
						onCancel={onCancel}
						onSave={isEditing ? onUpdate : onSave}
					/>
				</GenericDrawer>
			)}
		</Box>
	);
};

export default EmergencyContact;
