export const worklistFilterOptions = [
	{
		display: 'Exact Match',
		name: 'equals',
		type: ['string', 'time', 'boolean'], // removed time
		shortName: '=',
	},
	{
		display: 'Equals',
		name: 'equals',
		type: ['number'], // removed time
		shortName: '=',
	},
	{
		display: 'Not Equals',
		name: 'notEquals',
		shortName: '≠',
		type: [],
	},
	{
		display: 'Contains',
		name: 'contains',
		shortName: 'contains',
		type: ['string'],
	},
	{
		display: 'Not Contains',
		name: 'notContains',
		shortName: 'not contain',
		type: ['string'],
	},
	{
		display: 'Starts With',
		name: 'startsWith',
		shortName: 'starts with',
		type: ['string'],
	},
	{
		display: 'Ends With',
		name: 'endsWith',
		shortName: 'ends with',
		type: ['string'],
	},
	{
		display: 'Greater Than',
		name: 'gt',
		type: ['number'],
		shortName: '>',
	},
	{
		display: 'Greater Than or Equal To',
		name: 'gte',
		type: ['number'],
		shortName: '>=',
	},
	{
		display: 'Less Than',
		name: 'lt',
		type: ['number'],
		shortName: '<',
	},
	{
		display: 'Less Than or Equal To',
		name: 'lte',
		type: ['number'],
		shortName: '<=',
	},
	{
		display: 'Is Set',
		name: 'set',
		type: ['time', 'boolean'],
		shortName: 'is set',
	},
	{
		display: 'Is Not Set',
		name: 'notSet',
		type: ['time', 'boolean'],
		shortName: 'is not set',
	},
	{
		display: 'In Date Range',
		name: 'inDateRange',
		type: ['time'],
		shortName: 'between',
	},
	{
		display: 'Not in Date Range',
		name: 'notInDateRange',
		shortName: 'not between',
		type: ['time'],
	},
	{
		display: 'Before Date',
		name: 'beforeDate',
		shortName: 'before',
		type: ['time'],
	},
	{
		display: 'After Date',
		name: 'afterDate',
		shortName: 'after',
		type: ['time'],
	},
	{
		display: 'Fuzzy Match',
		name: 'fuzzy',
		type: ['string'], // removed time
		shortName: 'fuzzy',
	},
];
