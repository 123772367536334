import axios from 'axios';

const deleteNotification = async ({ __config, id }) => {
	const response = await axios.delete(`${__config.data_sources.blume}Notification/${id}`);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default deleteNotification;
