import moment from 'moment';

const BLUME_ACCESS_TOKEN_EXPIRATION_IN_MINUTES = 10;

export const getBlumeAccessTokenFromStorage = studyId => {
	const token = localStorage.getItem(`blumeAccessToken-${studyId}`);

	if (!token) {
		return null;
	}

	const parsedToken = JSON.parse(token);
	if (moment().diff(moment(parsedToken.timestamp), 'minutes') < BLUME_ACCESS_TOKEN_EXPIRATION_IN_MINUTES) {
		return parsedToken.token;
	}

	localStorage.removeItem(`blumeAccessToken-${studyId}`);
	return null;
};

export const setBlumeAccessTokenToStorage = (studyId, token) => {
	localStorage.setItem(`blumeAccessToken-${studyId}`, JSON.stringify({ token, timestamp: new Date() }));
};

export const STUDY_CARD_STYLES = {
	WRAPPER: isTablet => ({
		py: isTablet ? 2 : 3,
		px: isTablet ? 2 : 4,
		my: 1.5,
		borderRadius: '12px',
		borderColor: '#EAECF0',
	}),

	BUTTON: isTablet => ({
		height: '36px',
		borderRadius: '8px',
		textTransform: 'none',
		letterSpacing: '0.5px',
		fontSize: isTablet ? 12 : 14,
		fontWeight: 600,
		background: 'var(--borderradius)',
		color: 'var(--color-primary)',
		borderColor: 'var(--color-primary)',
		boxShadow: 'none',
		minWidth: isTablet ? 'calc(50% - 1%)' : '89px',
		whiteSpace: 'nowrap',

		'&.MuiButton-containedSizeMedium:hover': {
			backgroundColor: isTablet ? 'var(--color-primary)' : '#4096DC',
		},
	}),

	ACCESS_FORMS_BUTTON: {
		color: '#42A5F5',
		textTransform: 'none',
		padding: '0 0',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '20px',
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
	},
};
