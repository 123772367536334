import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Popper from '@mui/material/Popper';
import { searchScopes } from '@rs-core/context/consts/searchScopes';
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';
import { loadValueSet } from '@worklist-2/core/src/fhir/resource/columnMapping/utils';
import _ from 'lodash';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { Svg } from '../Svg';

import { useTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';

const DropdownPaper = props => <Paper {...props} sx={{ minWidth: 300 }} />;

const SearchableCheckboxSingleSelectColumnFilter = ({
	valueSetType,
	onSelect,
	capitalizeOptions = false,
	fieldRef,
	displayValue = 'display',
	labelAlwaysShrunk = false,
	label,
	defaultValue,
	testId,
	style,
	width,
	fullWidth = false,
	hideIcon = true,
	options,
	placeholder = 'Search',
	customFilterOptions,
	onFocus,
	onBlur,
	popperProps,
	valueSetExtraParam,
}) => {
	const { t } = useTranslation('organization');
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;

	const [optionsList, setOptionsList] = useState([]);
	const fhirDataLoader = useFhirDataLoader({
		scope: searchScopes.valueSet,
	});
	const [selectedValue, setSelectedValue] = useState(_.isArray(defaultValue) ? defaultValue[0] : defaultValue);

	// For Grid columns, get valueSet from API when component loads
	useEffect(() => {
		if (!_.isEmpty(customFilterOptions)) {
			setOptionsList(customFilterOptions);
		} else if (options) {
			setOptionsList(options);
		} else {
			const getValueSet = async () =>
				loadValueSet(
					fhirDataLoader,
					valueSetType,
					displayValue,
					capitalizeOptions,
					['display'],
					valueSetExtraParam
				);
			getValueSet()
				.then(output => setOptionsList(output))
				.catch(console.error);
		}
	}, [options, customFilterOptions]);

	return (
		<Autocomplete
			PaperComponent={DropdownPaper}
			PopperComponent={({ ...props }) => <Popper {...props} {...popperProps} />}
			componentsProps={{
				popper: {
					style: {
						width: '600px',
					},
				},
			}}
			getOptionLabel={option => {
				if (option && option[displayValue]) {
					return t(option[displayValue]);
				}

				return '';
			}}
			options={optionsList}
			renderInput={params => (
				<TextField
					data-cy={testId}
					{...params}
					InputLabelProps={labelAlwaysShrunk && label ? { shrink: true } : undefined}
					InputProps={{
						...params.InputProps,
						startAdornment: !hideIcon && (
							<InputAdornment position="start">
								<SearchIcon />
								{params.InputProps.startAdornment ? (
									<label style={{ marginLeft: 12 }}>{params.InputProps.startAdornment}</label>
								) : (
									''
								)}
							</InputAdornment>
						),
					}}
					inputRef={fieldRef}
					label={label}
					placeholder={placeholder}
					onBlur={() => (onBlur ? onBlur() : '')}
					onFocus={() => (onFocus ? onFocus() : '')}
				/>
			)}
			renderOption={(props, option, { selected }) => (
				<li id={option.id} {...props}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							marginRight: '15px',
						}}
					>
						<Checkbox checked={selected} checkedIcon={checkedIcon} icon={icon} />
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							{option.icon && (
								<Svg
									data-testid={option.icon}
									name={option.icon}
									sx={{
										width: '15px',
										height: '15px',
										flexShrink: 0,
										marginRight: '12px',
									}}
								/>
							)}
							{t(option[displayValue])}
						</Box>
					</Box>
				</li>
			)}
			sx={{ ...(fullWidth ? '' : { width }), ...style }}
			value={selectedValue}
			onChange={(event, value) => {
				// If the option has an "id" property, then we should select the ID instead of the text content.
				if (onFocus) {
					onFocus();
				}
				let optionValue = '';
				let translatedOptionValue = '';

				if (value) {
					optionValue = value?.id || value[displayValue];
					translatedOptionValue = t(value[displayValue]);
				}

				setSelectedValue(value);

				if (onBlur) {
					setTimeout(() => {
						onBlur();
					}, 10000);
				}
				return onSelect(
					{
						selectedOptions: optionValue,
						fullOptionsList: optionsList,
					},
					translatedOptionValue,
					valueSetType
				);
			}}
		/>
	);
};

export default SearchableCheckboxSingleSelectColumnFilter;

SearchableCheckboxSingleSelectColumnFilter.propTypes = {
	/**
	 * Whether the input label should be explicitly in shrink mode
	 */
	labelAlwaysShrunk: PropTypes.bool,
	/**
	 * The type of valueSet that will be used to populate the list of options
	 */
	valueSetType: PropTypes.string,
	/**
	 * Handler for when a menu item is selected
	 */
	onSelect: PropTypes.func,
	/**
	 * The valueSet property that will be displayed
	 */
	displayValue: PropTypes.string,
	/**
	 * Whether or not the options should be capitalized
	 */
	capitalizeOptions: PropTypes.bool,

	/**
	 * Use this option if you want to pass custom filter options instead of a search set.
	 */
	customFilterOptions: PropTypes.array,
};
