import React from 'react';
// Svgs
import CodeIcon from '../../assets/icons/code.svg';
import HamburgerIcon from '../../assets/icons/hamburger.svg';
import HelpIcon from '../../assets/icons/help.svg';
import HomesIcon from '../../assets/icons/home.svg';
import LogIcon from '../../assets/icons/log.svg';
import TeachingIcon from '../../assets/icons/teaching.svg';
import MyAppIcon from '../../assets/icons/myapp.svg';
import SaveReportIcon from '../../../public/icons/finish-study.svg';
import SignReportIcon from '../../../public/icons/sign-study.svg';
import TranscriptionDoneIcon from '../../assets/icons/folderTickIcon.svg';
import OrganizationIcon from '../../assets/icons/organization.svg';
import AddNewIcon from '../../assets/icons/addnew.svg';
import StudyIcon from '../../assets/icons/study.svg';
import DocumentViewerIcon from '../../assets/icons/document_viewer.svg';
import DocumentViewerSmallIcon from '../../assets/icons/document_viewer_small.svg';
import ImageViewerIcon from '../../assets/icons/image_viewer.svg';
import StudyExplorerIcon from '../../assets/icons/study_explorer.svg';
import SendStudyIcon from '../../assets/icons/send_study.svg';
import PatientIcon from '../../assets/icons/patient.svg';
import PatientPendingSignatureIcon from '../../assets/icons/PatientPendingSignature.svg';
import VisitIcon from '../../assets/icons/visit.svg';
import OrderIcon from '../../assets/icons/order.svg';
import InvoiceIcon from '../../assets/icons/invoice.svg';
import FileIcon from '../../assets/icons/file.svg';
import BriefCaseIcon from '../../assets/icons/briefcase.svg';
import ListIcon from '../../assets/icons/list.svg';
import PrivacyIcon from '../../assets/icons/privacy.svg';
import TermsIcon from '../../assets/icons/terms.svg';
import OctagonCheck from '../../assets/icons/octagon_check.svg';
import WarningIcon from '../../assets/icons/warning.svg';
import ClearFilterIcon from '../../assets/icons/clear_filter.svg';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ClearAllIcon from '../../assets/icons/clearAll.svg';
import EditCalendar from '../../assets/icons/edit_calendar.svg';
import DocumentIcon from '../../assets/icons/document.svg';
import CircleIcon from '../../assets/icons/circle.svg';
import WorklistIcon from '../../assets/icons/WorklistIcon.svg';
import BlumeLogo from '../../assets/icons/blume_logo.svg';
import RootLogo from '../../assets/icons/root_logo.svg';
import Amended from '../../assets/icons/amended.svg';
import Signstudy from '../../assets/icons/Signstudy.svg';
import SignStudySm from '../../assets/icons/SignStudySm.svg';
import Cancel from '../../assets/icons/Cancel.svg';
import Peerreview from '../../assets/icons/peerreview.svg';
import Copy from '../../assets/icons/Copy.svg';
import Amendedhistory from '../../assets/icons/amendedhistory.svg';
import OmegaAILogo from '../../assets/branding/svg/omega-ai-icon.svg';
import AddGrid from '../../assets/icons/AddGrid.svg';
import NoReportIcon from '../../assets/icons/noReport.svg';
import SvgIcon from '@mui/material/SvgIcon';
import NotificationCloseReadIcon from '../../assets/icons/notification/NotificationCloseRead.svg';
import NotificationCloseNewIcon from '../../assets/icons/notification/NotificationCloseNew.svg';
import NotificationOpenReadIcon from '../../assets/icons/notification/NotificationOpenRead.svg';
import NotificationOpenNewIcon from '../../assets/icons/notification/NotificationOpenNew.svg';
import BroomStickIcon from '../../assets/icons/notification/BroomStick.svg';
import AllIcon from '../../assets/icons/studystatus/all.svg';
import RequestedIcon from '../../assets/icons/studystatus/requested.svg';
import ScheduledIcon from '../../assets/icons/studystatus/scheduled.svg';
import ConfirmedIcon from '../../assets/icons/studystatus/confirmed.svg';
import ArrivedIcon from '../../assets/icons/studystatus/arrived.svg';
import ReadyForScanIcon from '../../assets/icons/studystatus/readyforscan.svg';
import NoShowIcon from '../../assets/icons/studystatus/noshow.svg';
import StatusCancelIcon from '../../assets/icons/studystatus/statuscancel.svg';
import StatusCheckIcon from '../../assets/icons/studystatus/statusCheck.svg';
import StatusConfettiIcon from '../../assets/icons/studystatus/statusConfetti.svg';
import StatusLockIcon from '../../assets/icons/studystatus/statusLock.svg';
import StatusStarIcon from '../../assets/icons/studystatus/statusStar.svg';
import StatusPending from '../../assets/icons/studystatus/statusPending.svg';
import StatusApproved from '../../assets/icons/studystatus/statusApproved.svg';
import SelectedNoShowIcon from '../../assets/icons/studystatus/selected-noshow.svg';
import SelectedCancelIcon from '../../assets/icons/studystatus/selected-cancel.svg';
import SelectedAllIcon from '../../assets/icons/studystatus/selected-all.svg';
import SelectedRequestedIcon from '../../assets/icons/studystatus/selectedRequestedIcon.svg';
import SelectedScheduledIcon from '../../assets/icons/studystatus/selected-scheduled.svg';
import SelectedConfirmedIcon from '../../assets/icons/studystatus/selected-confirmed.svg';
import SelectedPendingIcon from '../../assets/icons/studystatus/selectedPendingIcon.svg';
import SelectedApprovedIcon from '../../assets/icons/studystatus/selectedApprovedIcon.svg';
import SelectedArrivedIcon from '../../assets/icons/studystatus/selected-arrived.svg';
import SelectedReadyForScanIcon from '../../assets/icons/studystatus/selected-readyforscan.svg';
import templateIcon from '../../assets/icons/templateIcon.svg';
import uploadIcon from '../../assets/icons/uploadIcon.svg';
import noData from '../../assets/icons/noData.svg';
import noResult from '../../assets/icons/noResult.svg';
import EditSignature from '../../assets/icons/EditSignature.svg';
import CrownIcon from '../../assets/icons/crown.svg';
import XRayIcon from '../../assets/icons/xray.svg';
import SortingIcon from '../../assets/icons/SortingIcon.svg';
import BoldIcon from '../../assets/icons/documentViewer/bold.svg';
import ItalicIcon from '../../assets/icons/documentViewer/italic.svg';
import UnderlineIcon from '../../assets/icons/documentViewer/underline.svg';
import TextColorIcon from '../../assets/icons/documentViewer/textcolor.svg';
import BulletIcon from '../../assets/icons/documentViewer/bulletedlist.svg';
import AnnotateIcon from '../../assets/icons/documentViewer/annotate.svg';
import TableIcon from '../../assets/icons/documentViewer/table.svg';
import NoTemplatesIcon from '../../assets/icons/documentViewer/no-templates.svg';
import editIcon from '../../assets/icons/documentViewer/editIcon.svg';
import AscendingIcon from '../../assets/icons/root/ascending_icon.svg';
import DescendingIcon from '../../assets/icons/root/descending_icon.svg';
import RootVector from '../../assets/icons/root/vector.svg';
import RootGridOn from '../../assets/icons/root/grid_on.svg';
import RootAlarmOn from '../../assets/icons/root/alarm_add.svg';
import RootTableChart from '../../assets/icons/root/table_chart.svg';
import RootFieldCheck from '../../assets/icons/root/check.svg';
import PaymentSuccess from '../../assets/icons/PaymentSuccess.svg';
import PaymentSuccessCash from '../../assets/icons/PaymentSuccessCash.svg';
import PaymentDeclined from '../../assets/icons/PaymentDeclined.svg';
import NothingHere from '../../assets/icons/NothingHere.svg';
import ArrowUp from '../../assets/icons/arrow_up.svg';
import ArrowDown from '../../assets/icons/arrow_down.svg';
import Fingerprint from '../../assets/icons/fingerprint.svg';
import FingerprintChecked from '../../assets/icons/fingerprintChecked.svg';
import ClickTarget from '../../assets/icons/clickTarget.svg';
import CloseRecognition from '../../assets/icons/closeRecognition.svg';
import AttachImage from '../../assets/icons/attachImage.svg';
import DragHandle from '../../assets/icons/formBuilder/drag_handle.svg';
import MultipleChoiceGrid from '../../assets/icons/formBuilder/multiple_choice_grid.svg';
import Paragraph from '../../assets/icons/formBuilder/paragraph.svg';
import RadioButtonChecked from '../../assets/icons/formBuilder/radio_button_checked.svg';
import RadioButtonUnchecked from '../../assets/icons/formBuilder/radio_button_unchecked.svg';
import CheckButtonChecked from '../../assets/icons/formBuilder/check_button_checked.svg';
import CheckButtonCheckedGrey from '../../assets/icons/formBuilder/check_button_checked_grey.svg';
import CheckButtonUnchecked from '../../assets/icons/formBuilder/check_button_unchecked.svg';
import CheckboxGrid from '../../assets/icons/formBuilder/checkbox_grid.svg';
import Checkbox from '../../assets/icons/formBuilder/checkbox.svg';
import Signature from '../../assets/icons/formBuilder/signature.svg';
import CalendarMonth from '../../assets/icons/formBuilder/calendar_month.svg';
import ShortAnswer from '../../assets/icons/formBuilder/short_answer.svg';
import ContentCopy from '../../assets/icons/formBuilder/content_copy.svg';
import GooglePlusReshare from '../../assets/icons/formBuilder/google_plus_reshare.svg';
import CloseQuestion from '../../assets/icons/formBuilder/close_question.svg';
import ArrowDropDownCircle from '../../assets/icons/formBuilder/arrow_drop_down_circle.svg';
import TextFields from '../../assets/icons/formBuilder/text_fields.svg';
import Delete from '../../assets/icons/formBuilder/delete.svg';
import ChipDelete from '../../assets/icons/formBuilder/chip_delete.svg';
import Palette from '../../assets/icons/formBuilder/palette.svg';
import ImageIcon from '../../assets/icons/formBuilder/image.svg';
import ClinicFilled from '../../assets/icons/formBuilder/assignment_added.svg';
import ClinicUnfilled from '../../assets/icons/formBuilder/assignment_add.svg';
import RegistrationFilled from '../../assets/icons/formBuilder/registration_filled.svg';
import RegistrationUnfilled from '../../assets/icons/formBuilder/registration_unfilled.svg';
import ClinicEdit from '../../assets/icons/formBuilder/assignment_edit.svg';
import DeleteMui from '../../assets/icons/formBuilder/delete_mui.svg';
import DropdownMap from '../../assets/icons/formBuilder/dropdown_map.svg';
import ParagraphMap from '../../assets/icons/formBuilder/paragraph_map.svg';
import SignatureMap from '../../assets/icons/formBuilder/signature_map.svg';
import MedicalInformation from '../../assets/icons/formBuilder/medical_information.svg';
import TableRows from '../../assets/icons/formBuilder/table_rows.svg';
import ViewWeek from '../../assets/icons/formBuilder/view_week.svg';
import AddPhoto from '../../assets/icons/formBuilder/add_photo_alternate.svg';
import FormUpload from '../../assets/icons/formBuilder/upload.svg';
import VideoCam from '../../assets/icons/formBuilder/auto_videocam.svg';
import LedgerIcon from '../../assets/icons/ledger_icon.svg';
import DemographyIcon from '../../assets/icons/demography.svg';
import ScanDocument from '../../../public/icons/scan-document.svg';
import AcuteIcon from '../../assets/icons/acute.svg';
import PdfIcon from '../../assets/icons/pdf.svg';
import ChangeRequestStatusIcon from '../../assets/icons/ChangeRequestStatusIcon.svg';
import Download from '../../assets/icons/download.svg';
import Legal from '../../assets/icons/NewSettings/policy.svg';
import Notification from '../../assets/icons/NewSettings/notification.svg';
import Translate from '../../assets/icons/NewSettings/translate.svg';
import Security from '../../assets/icons/NewSettings/security.svg';
import ImageUploadIcon from '../../assets/icons/ImageUploadIcon.svg';
import HeaderFooterIcon from '../../assets/icons/HeaderFooter.svg';
import DocxIcon from '@worklist-2/ui/src/assets/icons/UserProfile/DocIcon.svg';
import Like from '../../assets/icons/like.svg';
import Dislike from '../../assets/icons/dislike.svg';

// Payment Methods
import Affirm from '../../assets/icons/paymentMethods/affirm.svg';
import Alipay from '../../assets/icons/paymentMethods/alipay.svg';
import Amazon from '../../assets/icons/paymentMethods/amazon.svg';
import Amex from '../../assets/icons/paymentMethods/amex.svg';
import ApplePay from '../../assets/icons/paymentMethods/applePay.svg';
import Bancontact from '../../assets/icons/paymentMethods/bancontact.svg';
import Bitcoin from '../../assets/icons/paymentMethods/bitcoin.svg';
import BitcoinCash from '../../assets/icons/paymentMethods/bitcoinCash.svg';
import Bitpay from '../../assets/icons/paymentMethods/bitpay.svg';
import Citadele from '../../assets/icons/paymentMethods/citadele.svg';
import DinersClub from '../../assets/icons/paymentMethods/dinersClub.svg';
import Discover from '../../assets/icons/paymentMethods/discover.svg';
import Elo from '../../assets/icons/paymentMethods/elo.svg';
import Etherium from '../../assets/icons/paymentMethods/etherium.svg';
import FacebookPay from '../../assets/icons/paymentMethods/facebookPay.svg';
import Forbrugsforeningen from '../../assets/icons/paymentMethods/forbrugsforeningen.svg';
import Giropay from '../../assets/icons/paymentMethods/giropay.svg';
import GooglePay from '../../assets/icons/paymentMethods/googlePay.svg';
import Ideal from '../../assets/icons/paymentMethods/ideal.svg';
import Interac from '../../assets/icons/paymentMethods/interac.svg';
import Jcb from '../../assets/icons/paymentMethods/jcb.svg';
import Klarna from '../../assets/icons/paymentMethods/klarna.svg';
import Lightcoin from '../../assets/icons/paymentMethods/lightcoin.svg';
import Maestro from '../../assets/icons/paymentMethods/maestro.svg';
import Mastercard from '../../assets/icons/paymentMethods/mastercard.svg';
import PayAllIcon from '../../assets/icons/paymentMethods/payAllIcon.svg';
import Payoneer from '../../assets/icons/paymentMethods/payoneer.svg';
import Paypal from '../../assets/icons/paymentMethods/paypal.svg';
import Paysafe from '../../assets/icons/paymentMethods/paysafe.svg';
import Poli from '../../assets/icons/paymentMethods/poli.svg';
import Qiwi from '../../assets/icons/paymentMethods/qiwi.svg';
import Sepa from '../../assets/icons/paymentMethods/sepa.svg';
import ShopPay from '../../assets/icons/paymentMethods/shopPay.svg';
import Skrill from '../../assets/icons/paymentMethods/skrill.svg';
import Sofort from '../../assets/icons/paymentMethods/sofort.svg';
import Stripe from '../../assets/icons/paymentMethods/stripe.svg';
import UnionPay from '../../assets/icons/paymentMethods/unionPay.svg';
import Venmo from '../../assets/icons/paymentMethods/venmo.svg';
import Verifone from '../../assets/icons/paymentMethods/verifone.svg';
import Visa from '../../assets/icons/paymentMethods/visa.svg';
import Webmoney from '../../assets/icons/paymentMethods/webmoney.svg';
import WeChat from '../../assets/icons/paymentMethods/weChat.svg';
import Yandex from '../../assets/icons/paymentMethods/yandex.svg';

// Status Indicator
import AccessIcon from '../../assets/icons/StatusIndicators/access.svg';
import FaxErrorIcon from '../../assets/icons/StatusIndicators/faxError.svg';
import FaxFailedIcon from '../../assets/icons/StatusIndicators/faxFailed.svg';
import FaxInProgressIcon from '../../assets/icons/StatusIndicators/faxInProgress.svg';
import FaxSuccessIcon from '../../assets/icons/StatusIndicators/faxSuccess.svg';
import NotPaidIcon from '../../assets/icons/StatusIndicators/notPaid.svg';
import PaidIcon from '../../assets/icons/StatusIndicators/paid.svg';
import PartiallyPaidIcon from '../../assets/icons/StatusIndicators/partiallyPaid.svg';
import PreAuthAllClearedIcon from '../../assets/icons/StatusIndicators/preAuthAllCleared.svg';
import PreAuthDeniedIcon from '../../assets/icons/StatusIndicators/preAuthDenied.svg';
import PreAuthPartiallyClearedIcon from '../../assets/icons/StatusIndicators/preAuthPartiallyCleared.svg';
import PreAuthPendingIcon from '../../assets/icons/StatusIndicators/preAuthPending.svg';
import TeachingFile from '../../assets/icons/StatusIndicators/teachingFile.svg';
import EligibleIcon from '../../assets/icons/StatusIndicators/eligible.svg';
import EligibleMixedIcon from '../../assets/icons/StatusIndicators/mixEligible.svg';
import EligibleDeniedIcon from '../../assets/icons/StatusIndicators/eligibleDenied.svg';
import EligiblePendingIcon from '../../assets/icons/StatusIndicators/eligiblePending.svg';

// Appointment Priority
import StatIcon from '../../assets/icons/Appointments/statPriority.svg';
import AsapIcon from '../../assets/icons/Appointments/asapPriority.svg';
import UrgentIcon from '../../assets/icons/Appointments/urgentPriority.svg';
import RoutineIcon from '../../assets/icons/Appointments/routinePriority.svg';
import StylusNoteIcon from '../../assets/icons/Appointments/stylusNote.svg';
import StepOverIcon from '../../assets/icons/Appointments/stepOver.svg';
import VrConfigIcon from '@worklist-2/worklist/src/DocumentViewerV3/assets/vr-config.svg';
import VrConfigSkeleton from '@worklist-2/worklist/src/DocumentViewerV3/assets/vrconfig-skeleton.svg';

//text alignment
import AlignLeft from '../../assets/icons/align-left.svg';
import AlignCenter from '../../assets/icons/align-center.svg';
import AlignRight from '../../assets/icons/align-right.svg';
import AlignJustify from '../../assets/icons/align-justify.svg';

//guarantor register/edit
import AddGuarantor from '../../assets/icons/NewPatient.svg';
import EditGuarantor from '../../assets/icons/GuarantorDrawer.svg';

// Payer
import PayerIcon from '../../assets/icons/payer.svg';

// Financial Type
import FinancialTypeIcon from '../../assets/icons/financialType.svg';

// Change Post
import ChargePosted from '../../assets/icons/ChargePost/charge_posted.svg';
import ChargePostFailed from '../../assets/icons/ChargePost/charge_post_failed.svg';
import ChargePostPending from '../../assets/icons/ChargePost/charge_post_pending.svg';

// Organization Affiliation
import Affiliation from '../../assets/icons/Organization/Affiliation.svg';
import OrgAffiliationIcon from '../../assets/icons/Organization/OrgAffiliationIcon.svg';

const SVGS = {
	code: CodeIcon,
	hamburger: HamburgerIcon,
	help: HelpIcon,
	home: HomesIcon,
	log: LogIcon,
	teaching: TeachingIcon,
	myapp: MyAppIcon,
	organization: OrganizationIcon,
	addnew: AddNewIcon,
	study: StudyIcon,
	documentviewer: DocumentViewerIcon,
	documentviewersmall: DocumentViewerSmallIcon,
	billing: InvoiceIcon,
	imageviewer: ImageViewerIcon,
	studyexplorer: StudyExplorerIcon,
	sendstudy: SendStudyIcon,
	patient: PatientIcon,
	PatientPendingSignature: PatientPendingSignatureIcon,
	visit: VisitIcon,
	order: OrderIcon,
	file: FileIcon,
	briefcase: BriefCaseIcon,
	list: ListIcon,
	privacy: PrivacyIcon,
	terms: TermsIcon,
	octagonCheck: OctagonCheck,
	warning: WarningIcon,
	acute: AcuteIcon,
	share: ShareOutlinedIcon,
	clearFilter: ClearFilterIcon,
	clearAll: ClearAllIcon,
	editcalendar: EditCalendar,
	document: DocumentIcon,
	circle: CircleIcon,
	worklist: WorklistIcon,
	blumelogo: BlumeLogo,
	rootlogo: RootLogo,
	omegaailogo: OmegaAILogo,
	amended: Amended,
	signstudy: Signstudy,
	signstudysm: SignStudySm,
	cancel: Cancel,
	peerreview: Peerreview,
	amendedhistory: Amendedhistory,
	copy: Copy,
	addGrid: AddGrid,
	noReport: NoReportIcon,
	template: templateIcon,
	upload: uploadIcon,
	noData,
	noResult,
	EditSignature,
	crown: CrownIcon,
	xray: XRayIcon,
	sorting: SortingIcon,
	bold: BoldIcon,
	italic: ItalicIcon,
	underline: UnderlineIcon,
	textcolor: TextColorIcon,
	bullet: BulletIcon,
	annotate: AnnotateIcon,
	table: TableIcon,
	noTemplates: NoTemplatesIcon,
	editIcon,
	documentReportDrawerArrowUp: ArrowUp,
	documentReportDrawerArrowDown: ArrowDown,
	CloseRecognition,
	fingerprint: Fingerprint,
	fingerprintChecked: FingerprintChecked,
	clickTarget: ClickTarget,
	AttachImage,
	medicalInformation: MedicalInformation,
	tableRows: TableRows,
	viewWeek: ViewWeek,
	ledgerIcon: LedgerIcon,
	demographyIcon: DemographyIcon,
	pdfIcon: PdfIcon,
	download: Download,
	legal: Legal,
	notification: Notification,
	translate: Translate,
	security: Security,
	imageUploadIcon: ImageUploadIcon,
	docxIcon: DocxIcon,
	headerFooter: HeaderFooterIcon,
	likeIcon: Like,
	dislikeIcon: Dislike,

	// study statuses
	all: AllIcon,
	requested: RequestedIcon,
	scheduled: ScheduledIcon,
	confirmed: ConfirmedIcon,
	approved: StatusApproved,
	arrived: ArrivedIcon,
	readyforscan: ReadyForScanIcon,
	noshow: NoShowIcon,
	statuscancel: StatusCancelIcon,
	statusCheck: StatusCheckIcon,
	statusConfetti: StatusConfettiIcon,
	statusLock: StatusLockIcon,
	statusStar: StatusStarIcon,
	selectedall: SelectedAllIcon,
	selectedrequested: SelectedRequestedIcon,
	selectedscheduled: SelectedScheduledIcon,
	selectedconfirmed: SelectedConfirmedIcon,
	selectedstatuspending: SelectedPendingIcon,
	selectedapproved: SelectedApprovedIcon,
	selectedarrived: SelectedArrivedIcon,
	selectedreadyforscan: SelectedReadyForScanIcon,
	selectednoshow: SelectedNoShowIcon,
	selectedstatuscancel: SelectedCancelIcon,
	saveReport: SaveReportIcon,
	signReport: SignReportIcon,
	transcriptionDone: TranscriptionDoneIcon,
	actionDone: TranscriptionDoneIcon,
	scanDocument: ScanDocument,
	statuspending: StatusPending,
	vrConfigIcon: VrConfigIcon,
	vrConfigSkeleton: VrConfigSkeleton,

	// Root
	ascendingIcon: AscendingIcon,
	descendingIcon: DescendingIcon,
	rootVector: RootVector,
	rootGridOn: RootGridOn,
	rootAlarmOn: RootAlarmOn,
	rootTableChart: RootTableChart,
	rootFieldCheck: RootFieldCheck,

	// Notification
	broomStick: BroomStickIcon,
	notificationCloseRead: NotificationCloseReadIcon,
	notificationCloseNew: NotificationCloseNewIcon,
	notificationOpenRead: NotificationOpenReadIcon,
	notificationOpenNew: NotificationOpenNewIcon,

	// Billing
	paymentSuccess: PaymentSuccess,
	paymentSuccessCash: PaymentSuccessCash,
	paymentDeclined: PaymentDeclined,

	// Pre-Auth
	nothingHere: NothingHere,

	// Payment Methods
	affirm: Affirm,
	alipay: Alipay,
	amazon: Amazon,
	amex: Amex,
	applePay: ApplePay,
	bancontact: Bancontact,
	bitcoin: Bitcoin,
	bitcoinCash: BitcoinCash,
	bitpay: Bitpay,
	citadele: Citadele,
	dinersClub: DinersClub,
	discover: Discover,
	elo: Elo,
	etherium: Etherium,
	facebookPay: FacebookPay,
	forbrugsforeningen: Forbrugsforeningen,
	giropay: Giropay,
	googlePay: GooglePay,
	ideal: Ideal,
	interac: Interac,
	jcb: Jcb,
	klarna: Klarna,
	lightcoin: Lightcoin,
	maestro: Maestro,
	mastercard: Mastercard,
	payAllIcon: PayAllIcon,
	payoneer: Payoneer,
	paypal: Paypal,
	paysafe: Paysafe,
	poli: Poli,
	qiwi: Qiwi,
	sepa: Sepa,
	shopPay: ShopPay,
	skrill: Skrill,
	sofort: Sofort,
	stripe: Stripe,
	unionPay: UnionPay,
	venmo: Venmo,
	verifone: Verifone,
	visa: Visa,
	webmoney: Webmoney,
	weChat: WeChat,
	yandex: Yandex,

	// Form Builder
	dragHandle: DragHandle,
	multipleChoiceGrid: MultipleChoiceGrid,
	paragraph: Paragraph,
	radioButtonChecked: RadioButtonChecked,
	checkButtonChecked: CheckButtonChecked,
	checkButtonCheckedGrey: CheckButtonCheckedGrey,
	checkButtonUnchecked: CheckButtonUnchecked,
	checkboxGrid: CheckboxGrid,
	checkbox: Checkbox,
	signature: Signature,
	calendarMonth: CalendarMonth,
	shortAnswer: ShortAnswer,
	contentCopy: ContentCopy,
	radioButtonUnchecked: RadioButtonUnchecked,
	googlePlusReshare: GooglePlusReshare,
	closeQuestion: CloseQuestion,
	arrowDropDownCircle: ArrowDropDownCircle,
	textFields: TextFields,
	delete: Delete,
	chipDelete: ChipDelete,
	palette: Palette,
	image: ImageIcon,
	clinicFilled: ClinicFilled,
	clinicUnfilled: ClinicUnfilled,
	registrationFilled: RegistrationFilled,
	registrationUnfilled: RegistrationUnfilled,
	clinicEdit: ClinicEdit,
	deleteMui: DeleteMui,
	dropdownMap: DropdownMap,
	paragraphMap: ParagraphMap,
	signatureMap: SignatureMap,
	addPhoto: AddPhoto,
	formUpload: FormUpload,
	videoCam: VideoCam,

	// Status Indicator
	access: AccessIcon,
	faxError: FaxErrorIcon,
	faxFailed: FaxFailedIcon,
	faxInProgress: FaxInProgressIcon,
	faxSuccess: FaxSuccessIcon,
	notPaid: NotPaidIcon,
	paid: PaidIcon,
	partiallyPaid: PartiallyPaidIcon,
	preAuthAllCleared: PreAuthAllClearedIcon,
	preAuthDenied: PreAuthDeniedIcon,
	preAuthPartiallyCleared: PreAuthPartiallyClearedIcon,
	preAuthPending: PreAuthPendingIcon,
	teachingFile: TeachingFile,
	eligible: EligibleIcon,
	eligibleMixed: EligibleMixedIcon,
	eligibleDenied: EligibleDeniedIcon,
	eligiblePending: EligiblePendingIcon,
	ChangeRequestStatusIcon,

	// Appointments Priority
	statIcon: StatIcon,
	asapIcon: AsapIcon,
	urgentIcon: UrgentIcon,
	routineIcon: RoutineIcon,
	stylusNote: StylusNoteIcon,
	stepOver: StepOverIcon,

	//text alignment
	AlignLeft,
	AlignCenter,
	AlignRight,
	AlignJustify,

	//patient register/edit
	addGuarantor: AddGuarantor,
	editGuarantor: EditGuarantor,

	// Payer
	payerIcon: PayerIcon,

	// Financial Type
	financialTypeIcon: FinancialTypeIcon,

	// Change Post
	chargePosted: ChargePosted,
	chargePostFailed: ChargePostFailed,
	chargePostPending: ChargePostPending,

	// Organization Affiliation
	affiliation: Affiliation,
	affiliationIcon: OrgAffiliationIcon,
};

/**
 * Return the matching SVG as a React Component.
 * Results in an inlined SVG Element. If there's no match,
 * return `null`
 */
export default function getSvg(key) {
	const attributes = { name: undefined, ...key };

	if (!key || !SVGS[key.name]) {
		//Load the Scheduled ICON when the passed in Icon is not present
		return <SvgIcon component={ScheduledIcon} inheritViewBox={!key.viewBox} {...attributes} />;
	}

	return <SvgIcon component={SVGS[key.name]} inheritViewBox={!key.viewBox} {...attributes} />;
}
export { SVGS };
