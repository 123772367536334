// Core
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import classnames from 'classnames';

// Components
import RsGrid from '@rs-ui/components/Grid';
import ShareAvatarGroup from '../ShareAvatarGroup';
import { useTreeContext } from '@worklist-2/ui/src/context/TreeContext';
import SelectedStudiesToolbar from '@rs-ui/components/Worklist/WorklistGrid/SelectedStudiesToolbar/SelectedStudiesToolbar';

// Utils
import { wheelMenus, isValidGUID } from '@worklist-2/ui/src/components/utils/gridUtils';
import {
	fhirExtensionUrls,
	searchScopes,
	useAppModeContext,
	useAuth,
	useRouter,
	useMultiscreen,
	useToast,
} from '@worklist-2/core/src';
import useWorklistWheel from '@rs-ui/components/WorklistWheel/hooks/useWorklistWheel';

// libs
import { getViewerURLs } from '@worklist-2/ui/src/components/Worklist/WorklistGrid/getViewerURLs';
import { useTranslation } from 'react-i18next';
import SendDrawer from '@rs-ui/components/Worklist/WorklistGrid/SendDrawer';
import { useGlobalStore } from '@worklist-2/core/src/store';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { GRID_NAME } from '@rs-ui/helpers/constants';

// TeachingFolderGrid component
const TeachingFolderGrid = ({
	className,
	name,
	columns,
	filters,
	setFilters,
	sort,
	setSort,
	removeStudies = () => {},
	readyForData,
	setReadyForData,
	folderId,
}) => {
	// Translation
	const { t } = useTranslation('worklist');

	// update app mode
	const { updateAppMode } = useAppModeContext();

	// Context
	const { selectedFolderData } = useTreeContext();

	// Feature flags
	const proactEnableVisitOnOmegaDial = useBooleanFlagValue('proact-enable-visit-on-omega-dial');
	const sprinterOmegaaiMultiscreen = useBooleanFlagValue('sprinter-omegaai-multiscreen');
	const galaxyDvEnableGlobalStoreWithIndexedDb = useBooleanFlagValue('galaxy-dv-enable-global-store-with-IndexedDB');
	const gridCustomHeader = useBooleanFlagValue('meta-grid-custom-header');
	const crossGetPrivilegeOnDemand = useBooleanFlagValue('cross-get-privilege-on-demand');
	const proactEnableBilling = useBooleanFlagValue('proact-enable-billing');
	const proactEnableWorklistWheelFunction = useBooleanFlagValue('proact-enable-worklist-wheel-function');

	const { handleWorklistWheelClick } = useWorklistWheel({ t });

	// Auth
	const {
		displaySettings,
		authorized,
		loggedInUser,
		getGuiRoleByOrganizationAndResource,
		getGuiPrivilegeByOrganizationAndResourceAsync,
	} = useAuth();

	// Route
	const { goTo } = useRouter();
	const { openToSettingScreen } = useMultiscreen();

	// Toast
	const { showToast } = useToast();

	// State
	const [imageViewerUrl, setImageViewerUrl] = useState(null);
	const [documentViewerUrl, setDocumentViewerUrl] = useState(null);
	const [permissions, setPermissions] = useState(null);
	const [isSendMultiReport, setIsSendMultiReport] = useState(false);
	const [sendDrawerOpen, setSendDrawerOpen] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const { setCurrentStudyInfo } = useGlobalStore();

	useEffect(() => {
		updateAppMode('worklist');
	}, []);

	useEffect(() => {
		if (authorized) {
			updateAppMode('worklist');

			if (loggedInUser) {
				setImageViewerUrl(loggedInUser.imageViewerUrl);
				setDocumentViewerUrl(loggedInUser.documentViewerUrl);
			}
		}
	}, [authorized, loggedInUser]);

	const extraParam = useMemo(
		() => ({
			defaultlayoutsystem: 'true',
			active: 'true',
			teachingfolderid: folderId,
		}),
		[folderId]
	);

	/**
	 * Menu items on wheel
	 */
	const wheelMenuItems = useMemo(
		() => wheelMenus(t, permissions, proactEnableVisitOnOmegaDial, proactEnableBilling),
		[permissions, proactEnableVisitOnOmegaDial, proactEnableBilling]
	);

	/**
	 * Handle click on wheel
	 */
	const handleWheelClick = useCallback(
		async (item, record) => {
			if (proactEnableWorklistWheelFunction) {
				handleWorklistWheelClick(item, record, onWheelSendStudyClicked, GRID_NAME.teachingFolder);
			} else {
				const patientID = record?.patientID;
				const internalPatientId = record?.subject?.id;
				let studyInstanceUid = record?.identifier?.find(
					recordItem => recordItem.system === 'urn:dicom:uid'
				)?.value;
				studyInstanceUid = studyInstanceUid?.replace('urn:oid:', '');
				const issuerOfPatientId = record?.extension?.find(
					ext => ext.url === fhirExtensionUrls.organization.issuer
				)?.valueReference?.display;
				const internalStudyId = record?.id;
				const orderId = record?.basedOn?.[0]?.id;
				const internalManagingOrganizationID = record?.internalManagingOrganizationID;
				const { referringFacilityId } = record;
				let creatorBlumeId = record?.patientID?.toLowerCase() || null;
				if (!isValidGUID(creatorBlumeId)) {
					creatorBlumeId = null;
				}
				switch (item.icon) {
					case 'patient':
						goTo.patientDetail({ patientId: internalPatientId, screen: 'info' });
						return;
					case 'order':
						goTo.any(`/order/${orderId}`);
						return;
					case 'billing':
						goTo.any(`/order/${orderId}/billing/${internalStudyId}/generate-invoice`);
						return;
					case 'study':
						goTo.any(`/order/${orderId}#study-list`);
						return;

					case 'sendstudy':
						if (studyInstanceUid) {
							onWheelSendStudyClicked(record);
						}
						return;
					case 'studyexplorer':
						goTo.patientDetail({ patientId: internalPatientId, screen: 'study-history' });
						return;
					case 'imageviewer':
						if (
							imageViewerUrl &&
							!(sprinterOmegaaiMultiscreen && displaySettings && displaySettings.enabled)
						) {
							const studyUrl = imageViewerUrl.replace('<studyuid>', studyInstanceUid);
							window.open(studyUrl);
						} else {
							const { documentViewerNavigateURL, imageViewerNavigateURL } = getViewerURLs({
								patientID,
								internalPatientId,
								orderId,
								internalStudyId,
								internalManagingOrganizationID,
								issuerOfPatientId,
								referringFacilityId,
								studyInstanceUid,
								imageViewerUrl,
								documentViewerUrl,
								creatorBlumeId,
							});

							if (sprinterOmegaaiMultiscreen && window.screen.isExtended) {
								await openToSettingScreen(documentViewerNavigateURL, 'DV', documentViewerUrl);
								const resultIV = await openToSettingScreen(
									imageViewerNavigateURL,
									'IV',
									imageViewerUrl
								);
								if (!resultIV) {
									goTo.any(imageViewerNavigateURL); // #TODO: useRouter safe-type this !
								}
							} else {
								goTo.any(imageViewerNavigateURL); // #TODO: useRouter safe-type this !
							}
						}

						return;
					case 'documentviewer':
						if (galaxyDvEnableGlobalStoreWithIndexedDb) {
							setCurrentStudyInfo(record);
						} else {
							localStorage.setItem('currentStudyInfo', JSON.stringify(record));
						}
						if (
							documentViewerUrl &&
							!(sprinterOmegaaiMultiscreen && displaySettings && displaySettings.enabled)
						) {
							window.open(documentViewerUrl.replace('<studyuid>', studyInstanceUid));
						} else {
							const { documentViewerNavigateURL } = getViewerURLs({
								patientID,
								internalPatientId,
								orderId,
								internalStudyId,
								internalManagingOrganizationID,
								issuerOfPatientId,
								referringFacilityId,
								studyInstanceUid,
								creatorBlumeId,
							});

							if (sprinterOmegaaiMultiscreen && window.screen.isExtended) {
								const resultDV = await openToSettingScreen(
									documentViewerNavigateURL,
									'DV',
									documentViewerUrl
								);
								if (!resultDV) {
									goTo.any(documentViewerNavigateURL); // #TODO: safe-type this !
								}
							} else {
								goTo.any(documentViewerNavigateURL); // #TODO: safe-type this !
							}
						}
						return;
					default:
						console.error(`Could not find action for ${item.id}`);
				}
			}
		},
		[imageViewerUrl, documentViewerUrl, displaySettings, sprinterOmegaaiMultiscreen]
	);

	/**
	 * Handle send study click
	 */
	const onWheelSendStudyClicked = useCallback(record => {
		setSelectedRecord([record]);
		setSendDrawerOpen(true);
		setIsSendMultiReport(false); // Only sends a single report when clicked from the wheel.
	}, []);

	/**
	 * Fetch permission
	 * @param {} organizationId
	 * @param {*} referringFacilityId
	 * @param {*} resource
	 */
	const fetchHomePermissions = useCallback(
		async (organizationId, referringFacilityId, resource) => {
			const temPermissions = {};
			if (crossGetPrivilegeOnDemand) {
				const organizationPermission = await getGuiPrivilegeByOrganizationAndResourceAsync(
					organizationId,
					resource,
					referringFacilityId
				);

				if (organizationPermission) {
					Object.keys(organizationPermission).forEach(item => {
						temPermissions[item] = organizationPermission[item];
					});
					setPermissions(temPermissions);
				}
			} else {
				const organizationPermission = getGuiRoleByOrganizationAndResource(
					organizationId,
					resource,
					referringFacilityId
				);

				if (organizationPermission) {
					Object.keys(organizationPermission).forEach(item => {
						temPermissions[item] = organizationPermission[item];
					});
					setPermissions(temPermissions);
				}
			}
		},
		[crossGetPrivilegeOnDemand]
	);

	/**
	 * Row select
	 * @param {*} organizationId
	 * @param {*} referringFacilityId
	 */
	const onWheelRowSelect = useCallback(
		async (organizationId, referringFacilityId) => {
			await fetchHomePermissions(organizationId, referringFacilityId, 'Home'); // Currently using same with worklist, will update if UAC for TF completed
		},
		[fetchHomePermissions]
	);

	/**
	 * When the user clicks send from the floating toolbar
	 */
	const onSendClick = (record = null) => {
		let studyInstanceUid;

		if (record && record.length > 0) {
			// We only support single study for send study drawer for now.
			for (let index = 0; index < record.length; index++) {
				const study = record[index];
				studyInstanceUid = study?.rawData?.identifier?.find(
					recordItem => recordItem.system === 'urn:dicom:uid'
				)?.value;
				studyInstanceUid = studyInstanceUid?.replace('urn:oid:', '');
				if (studyInstanceUid) {
					break;
				}
			}

			setSelectedRecord(record);
			setIsSendMultiReport(true); // Sends multiple reports when clicked from the multiselect widget.
		}

		// If we selectd study and the study doesn't have the study UID, then we display a toast msg and don't opent the send study drawer
		if (!!record && !studyInstanceUid) {
			showToast(t('workList:selectedStudyToast'));
		} else {
			setSendDrawerOpen(true);
		}
	};

	return (
		<>
			<RsGrid
				className={classnames(className)}
				columns={columns}
				fetchInterval={15000}
				filters={filters}
				gridCustomHeader={gridCustomHeader}
				gridToolBarChildren={selectedFolderData ? <ShareAvatarGroup /> : null}
				name={name}
				newWorklistSelector={false}
				ready={readyForData}
				scope={searchScopes.all}
				searchExtra={extraParam}
				setFilters={setFilters}
				setReady={setReadyForData}
				setSort={setSort}
				sort={sort}
				title=""
				wheelMenuItems={wheelMenuItems}
				onWheelClick={handleWheelClick}
				onWheelRowSelect={onWheelRowSelect}
			/>

			<SelectedStudiesToolbar onRemoveStudiesFromTF={removeStudies} onSendClick={onSendClick} />
			<SendDrawer
				drawerOpen={sendDrawerOpen}
				isSendMultiReport={isSendMultiReport}
				setDrawerOpen={open => {
					setSendDrawerOpen(open);
					if (!open) {
						setSelectedRecord(null);
					}
				}}
				studies={selectedRecord}
			/>
		</>
	);
};

export default TeachingFolderGrid;
