import { useNewAppointmentContentV2Context } from '@worklist-2/patientPortal/src/components/Appointment/AppointmentDrawer/NewAppointmentContentV2/contexts/NewAppointmentContentV2ContextProvider';
import { norAcceptedRejected } from '@worklist-2/patientPortal/src/components/Notifications/OaiPatientSyncNotification';

const useGetNotificationsPatient = () => {
	const { oaiSyncPendingNotifications, setOaiSyncPatientNotifications, setHasPendingCRs } =
		useNewAppointmentContentV2Context();

	// Get the notifications for a specific patient
	const getNotificationsPatient = patientInternalId => {
		const filteredPatientNotifications = oaiSyncPendingNotifications?.filter(
			pData =>
				pData?.properties?.userHash === patientInternalId &&
				pData?.properties?.status?.toLowerCase() === 'pending'
		);
		setHasPendingCRs(Boolean(filteredPatientNotifications?.filter(d => norAcceptedRejected(d))?.length));
		setOaiSyncPatientNotifications(filteredPatientNotifications);
	};

	return {
		getNotificationsPatient,
	};
};

export default useGetNotificationsPatient;
