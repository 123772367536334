import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import Star from '@mui/icons-material/Star';
import ShareIcon from '@mui/icons-material/Share';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckCircle from '@mui/icons-material/CheckCircle';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HourglassFull from '@mui/icons-material/HourglassFull';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import AssignmentAddOutlinedIcon from '@worklist-2/ui/src/assets/icons/assignment_outlined_add.svg';
import readNotification from '@worklist-2/patientPortal/src/api/readNotification';

export const icons = {
	STUDY_COMPLETED: {
		background: '#D3E4D4',
		component: <CheckCircle sx={{ color: '#75B678' }} />,
	},
	STUDY_PROCESSING: {
		background: '#E0EAFA',
		component: <HourglassFull sx={{ color: '#1E8FEA' }} />,
	},
	STUDY_SCHEDULED: {
		background: '#F1E0FA',
		component: <Star sx={{ color: '#C480DC' }} />,
	},
	STUDY_SHARED: {
		background: '#FFEDD0',
		component: <ShareIcon sx={{ color: '#C38323C7' }} />,
	},
	APPOINTMENT_REMINDER: {
		background: '#F1E0FA',
		component: <ScheduleIcon sx={{ color: '#C480DC' }} />,
	},
	APPOINTMENT_CONFIRMED: {
		background: '#D3E4D4',
		component: <EventAvailableIcon sx={{ color: '#75B678' }} />,
	},
	APPOINTMENT_CANCELLED: {
		background: '#CF667914',
		component: <EventBusyIcon sx={{ color: '#CF6679' }} />,
	},
	APPOINTMENT_RESCHEDULED: {
		background: '#D3E4D4',
		component: <EventNoteIcon sx={{ color: '#75B678' }} />,
	},
	DOCUMENT_SHARED: {
		background: '#FFEDD0',
		component: <ShareIcon sx={{ color: '#C38323C7' }} />,
	},
	WORKFLOW_AUTOMATION_NOTIFICATION: {
		background: '#f3c7f1',
		component: <NotificationsIcon sx={{ color: '#A66BA3' }} />,
	},
	STUDY_UPDATED: {
		background: '#f3c7f1',
		component: <NotificationsIcon sx={{ color: '#A66BA3' }} />,
	},
	OAI_PATIENT_UPDATED: {
		background: '#f3c7f1',
		component: <NotificationsIcon sx={{ color: '#A66BA3' }} />,
	},
	FORM_CREATED: {
		background: '#CF667933',
		component: <AssignmentAddOutlinedIcon sx={{ color: '#CF6679' }} />,
	},
	APPOINTMENT_CREATED: {
		background: '#D3E4D4',
		component: <EventAvailableIcon sx={{ color: '#75B678' }} />,
	},
	'': {
		background: '#f3c7f1',
		component: <NotificationsIcon sx={{ color: '#A66BA3' }} />,
	},
};

export const detailedView =
	({
		details: _details,
		isMobile,
		isTablet,
		notificationDetails,
		setNotificationReceived,
		setStudyInDetailedView,
		setDrawerOpen,
		navigate,
		setOldAppointmentOpen,
		setHomePageTab,
		phoenixExpandedBookAppointmentDrawer,
		__config,
		setAppointmentData,
		setOpenFormDrawer,
		setSelectedForm,
	}) =>
	() => {
		// Read Unread Notification.....
		const details = _details;

		if (!details?.isSeen) {
			readNotification({ __config, id: details.id });
			details.isSeen = true;
			let temp = true;
			_.map(notificationDetails, notification => {
				temp = temp && notification.isSeen;
			});
			if (temp === true) {
				setNotificationReceived(false);
			}
		}

		if (
			['STUDY_SHARED', 'STUDY_COMPLETED', 'STUDY_PROCESSING', 'STUDY_SCHEDULED'].includes(
				details.properties.NotificationType
			)
		) {
			if (isMobile || isTablet) {
				setStudyInDetailedView({
					study: {
						id: details.properties.InternalStudyId,
					},
				});
			} else {
				// Collapse drawer
				setDrawerOpen('');
				const studyStatus = _.lowerCase(
					details.properties.StudyStatus === 'INPROCESS' ? 'processing' : details.properties.StudyStatus
				);
				navigate(`/studyDetail/${details.properties.InternalStudyId}/${studyStatus}/details`);
			}
		} else if (
			[
				'APPOINTMENT_REMINDER',
				'APPOINTMENT_CONFIRMED',
				'APPOINTMENT_CANCELLED',
				'APPOINTMENT_RESCHEDULED',
				'APPOINTMENT_CREATED',
			].includes(details.properties.NotificationType)
		) {
			if (isMobile) {
				setDrawerOpen('');
				if (phoenixExpandedBookAppointmentDrawer) {
					navigate('/book-appointment');
				} else {
					setOldAppointmentOpen(true);
				}
			} else {
				navigate('/home');
				setHomePageTab(0);
			}
		} else if (details.properties.NotificationType === 'DOCUMENT_SHARED') {
			navigate('/sharedHistory?filter=document');
			setDrawerOpen('');
		} else if (details.properties.NotificationType === 'FORM_CREATED') {
			// Open Form Drawer and display contents
			fetchForm({
				id: details.properties.formId,
				appointmentId: details.properties?.appointmentId,
				setAppointmentData,
				setOpenFormDrawer,
				setSelectedForm,
				__config,
			});
		} else {
			return false;
		}
	};

export const fetchForm = async ({
	id,
	appointmentId,
	setAppointmentData,
	setOpenFormDrawer,
	setSelectedForm,
	__config,
}) => {
	axios.get(`${__config.data_sources.blume}Form/${id}`).then(result => {
		if (result?.status === 200 && result?.data) {
			setSelectedForm({
				id: result.data.id,
				patient: result.data?.patient,
				completed: result.data?.completed,
				organizationId: result.data.organizationId,
				status: result.data.status.charAt(0).toUpperCase() + result.data.status.slice(1),
				title: result.data.name,
				type: result.data.type,
				description: result.data.description,
				questionData: result.data.formTemplate?.content,
				lastModified: result.data.lastModified,
				resource: result.data,
				pdfFileName: result.data?.pdfFileName,
				referenceTemplate: result.data?.referenceTemplate,
			});
			setOpenFormDrawer(true);
			const studyId = result.data?.study?.id;
			const patientId = result.data?.patient?.id;

			if (studyId && patientId && appointmentId) {
				const appointment = {
					participant: [
						{
							actor: { id: patientId, reference: `patient/${patientId}` },
						},
					],
					supportingInformation: [
						{
							id: studyId,
							reference: `ImagingStudy/${studyId}`,
						},
					],
					id: appointmentId,
				};

				setAppointmentData(appointment);
			}
		}
	});
};

export const formatDateTime = stringDate => moment(Date.parse(stringDate)).format('MMM DD, YYYY hh:mm A');

export const getTranslatedNotificationMessage = ({ notificationType, t, message, properties }) => {
	switch (notificationType) {
		case 'STUDY_SHARED':
			// message template: "{0} shared a study with you"
			return t(notificationType, {
				sender: message.substring(0, message.indexOf('shared')),
			});
		case 'APPOINTMENT_CANCELLED':
			// message template:"Appointment at {0} on {1} is cancelled."
			return t(notificationType, {
				imagingOrgName: message.substring(message.indexOf('at') + 2, message.indexOf('on')),
				scanDate: message.substring(message.indexOf('on') + 2, message.indexOf('is')),
			});
		case 'APPOINTMENT_CONFIRMED':
			// message template: "Appointment confirmed for {0}"
			return t(notificationType, {
				scanDate: formatDateTime(message.substring(message.indexOf('for') + 3)),
			});
		case 'APPOINTMENT_REMINDER':
			// message template: "You have an upcoming appointment in {0}"
			return t(notificationType, {
				duration: message.substring(message.indexOf(' in') + 3),
			});
		case 'APPOINTMENT_RESCHEDULED':
			// message template: "Appointment at {0} is rescheduled for {1}"
			return t(notificationType, {
				imagingOrgName: message.substring(message.indexOf('at') + 2, message.indexOf('is')),
				scanDate: formatDateTime(message.substring(message.indexOf('for') + 3)),
			});
		case 'APPOINTMENT_CREATED':
			// message template: "Appointment at {0} is scheduled for {1}"
			return t(notificationType, {
				imagingOrgName: message.substring(message.indexOf('at') + 2, message.indexOf('is')),
				scanDate: formatDateTime(message.substring(message.indexOf('for') + 3)),
			});
		case 'DOCUMENT_SHARED':
			// message template: "{0} has shared a document with you!"
			return t(notificationType, {
				userName: message.substring(0, message.indexOf('has')),
			});
		case 'FORM_CREATED':
			if (properties.formType.toLowerCase() === 'clinic') {
				// message template: "Clinical Form needs to be filled - {0}"
				return t(`${notificationType}_clinical`, {
					name: message.substring(message.indexOf('-') + 1),
				});
			}
			// message template: "Registration Form needs to be filled - {0}"
			return t(`${notificationType}_registration`, {
				name: message.substring(message.indexOf('-') + 1),
			});

		default:
			return t(message) ?? message;
	}
};
