import axios from 'axios';

const getNotification = async ({ __config }) => {
	const response = await axios.get(`${__config.data_sources.blume}Notification`);

	if (!response?.status?.toString()?.startsWith(2)) {
		return [];
	}

	return response.data;
};

export default getNotification;
