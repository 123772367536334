import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useIsTablet } from '@rs-core/utils/responsiveUtils';
import { useTranslation } from 'react-i18next';

const MobileFixedButtons = ({ actionButtonText = 'Add', onCancel, onAction }) => {
	const isTablet = useIsTablet();
	const { t } = useTranslation('drawer');

	return (
		<Box
			sx={{
				position: 'fixed',
				bottom: '0',
				left: !isTablet ? 'calc(100% - 421px)' : '0',
				zIndex: '1',
				width: !isTablet ? '420px' : '100%',
				borderRadius: !isTablet ? '15px 0px 0px 15px' : undefined,
				padding: !isTablet ? '16px 30px' : '16px 20px',
				display: 'flex',
				justifyContent: 'space-between',
				backgroundColor: '#fff',
				boxShadow: '0px -4px 5px rgba(0, 0, 0, 0.08)',
				boxSizing: 'border-box',
				transform: 'translate3d(0,0,0)',
			}}
		>
			{onCancel && (
				<Button
					color="rsPrimary"
					sx={{ minWidth: '100px', padding: '9px 16px' }}
					variant="outlined"
					onClick={onCancel}
				>
					{t('Cancel')}
				</Button>
			)}

			<Button
				color="rsPrimary"
				data-cy="ADD"
				data-testid="submit-btn"
				disabled={!onAction}
				fullWidth={!onCancel}
				sx={{
					minWidth: '100px',
					padding: '9px 16px',
					'&.Mui-disabled': {
						backgroundColor: '#42A5F580',
						color: 'white',
					},
				}}
				variant="contained"
				onClick={onAction}
			>
				{t(actionButtonText)}
			</Button>
		</Box>
	);
};

export default MobileFixedButtons;
