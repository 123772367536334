import React from 'react';
import { Box } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import { mediaTablet } from '@worklist-2/core/src';

import DateTime from '../DateTime';
import { icons, detailedView, getTranslatedNotificationMessage } from './utils';
import OaiPatientSyncNotification from './OaiPatientSyncNotification';

const generateNotification = ({
	details,
	setNotificationReceived,
	notificationDetails,
	phoenixExpandedBookAppointmentDrawer,
	setDrawerOpen,
	setHomePageTab,
	setOldAppointmentOpen,
	setStudyInDetailedView,
	setAppointmentData,
	setOpenFormDrawer,
	setSelectedForm,
	setNotificationDetails,
	deleteNotification,
	navigate,
	t,
	__config,
	isMobile,
	isTablet,
}) => {
	const { properties, message, generationTime, isSeen, id } = details;

	const notificationType = properties?.NotificationType;
	const isSharedNotification = notificationType === 'DOCUMENT_SHARED' || notificationType === 'STUDY_SHARED';
	const isOaiPatientSync = notificationType === 'OAI_PATIENT_SYNC' || notificationType === 'OAI_PATIENT_DATA_SYNC';

	if (!properties?.NotificationType) return null;

	// If the notification is shared, we don't want to show it in the notification list
	if (isSharedNotification) return null;

	if (isOaiPatientSync) {
		return {
			notificationType,
			component: (
				<Box
					key={id}
					sx={{
						marginLeft: isTablet ? '0px' : '15px',
						marginRight: isTablet ? '0px' : '15px',
						marginTop: '10px',
					}}
				>
					<OaiPatientSyncNotification details={details} setNotifications={setNotificationDetails} />
				</Box>
			),
		};
	}

	return {
		onDelete: deleteNotification(details),
		onClick: detailedView({
			__config,
			isMobile,
			isTablet,
			navigate,
			details,
			setNotificationReceived,
			notificationDetails,
			setDrawerOpen,
			setHomePageTab,
			setOldAppointmentOpen,
			setStudyInDetailedView,
			phoenixExpandedBookAppointmentDrawer,
			setAppointmentData,
			setOpenFormDrawer,
			setSelectedForm,
		}),
		secondaryAction: (
			<Box className="relativeDateSection" sx={{ display: 'flex', alignItems: 'center' }}>
				<DateTime
					datetime={new Date(`${generationTime} UTC`)}
					fontColor="#393939"
					sx={{
						paddingRight: '7px',
						fontSize: '12px',
						lineHeight: '16px',
						fontWeight: '400',
						letterSpacing: '0.25px',
					}}
					type="relative_Blume"
				/>
				{!isSeen && (
					<CircleIcon
						sx={{
							color: '#42A5F5',
							height: '7px',
							width: '7px',
							marginRight: '5px',
							opacity: 1,
						}}
					/>
				)}
			</Box>
		),
		avatar: {
			children: icons[notificationType].component,
			sx: { opacity: 1, bgcolor: icons[notificationType].background },
		},
		primary: getTranslatedNotificationMessage({ notificationType, t, message, properties }),
		secondary: properties.StudyName,
		sx: {
			marginBottom: 0,
			minHeight: '100px',
			'.ExpandableSectionButton': {
				padding: 0,
				display: 'none',
			},

			'& .MuiListItemText-primary': {
				'& .MuiTypography-root': {
					fontWeight: '400',
					width: '100%', // Decreased the width of the message
					color: '#272727',
				},
			},
			'&:hover': {
				'.MuiListItemSecondaryAction-root': {
					top: '50%',
				},

				'.ExpandableSectionButton': {
					display: 'inline-flex',
					visibility: 'visible',
				},

				'.relativeDateSection': {
					display: 'none',
				},
			},

			'& .MuiListItemSecondaryAction-root': {
				right: '4px',
				top: '94%',
			},
			[mediaTablet]: {
				'& .MuiListItemButton-root': { paddingLeft: '0' },

				'& .MuiListItemText-primary': {
					fontSize: '14px',
					lineHeight: '20px',
					letterSpacing: '0.25px',
					color: '#272727',
				},

				'& .MuiListItemText-secondary': {
					fontSize: '12px',
					lineHeight: '20px',
					letterSpacing: '0.25px',
					color: '#393939',
				},

				'& .MuiListItemSecondaryAction-root': {
					right: '0',
					top: 'unset',
					transform: 'unset',
					bottom: '13px',
					height: '20px',
				},

				'&:hover': {
					'.ExpandableSectionButton': {
						display: 'none',
					},

					'.relativeDateSection': {
						display: 'flex',
					},
				},
			},
		},
	};
};

export default generateNotification;
