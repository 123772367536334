import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { TextField, Box, InputAdornment, Autocomplete, Popper, ListItem } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import { crmSearchScopes } from '@worklist-2/core/src/context/CRMSearchScopeContext';
import { fhirExtensionUrls } from '@worklist-2/core/src/fhir/extension/fhirExtensionUrls';
import { useLoading } from '@worklist-2/core/src/hooks/useLoading';
import useFhirDataLoader from '@rs-core/hooks/useFhirDataLoader';
import { useCRMDataLoader } from '@worklist-2/core/src/hooks/useCRMDataLoader';
import { searchScopes } from '@worklist-2/core/src/context/consts/searchScopes';

import Avatar from '../../assets/img/crmUserImg.png';

const get = require('lodash.get');

const Suggest = ({
	hideIcon,
	labelAlwaysShrunk,
	data,
	size,
	text,
	style,
	optionId,
	width,
	fieldRef,
	fullWidth,
	onSelect,
	onOpen,
	onClose,
	disabled,
	freeSolo = true,
	isLoading = false,
	onInputChange,
	className,
	listSearchScope,
	externalCall = false,
	value,
	error = false,
	errorText = '',
	clearOnBlur = false,
	testId,
	valueField = 'id',
	variant = 'outlined',
	customDataLoader,
	showAvatar = false,
	optionStyle,
	disableClearable = false,
	isCRM,
	testid = '',
	placeholder = 'Search',
	summary = false,
	disableSearch = false,
	listAvoid = [],
	removeStudyInput = false,
	setRemoveStudyInput,
	enableSearchChildrenOrganizationStudies,
	multiParam,
	onBlur,
	onFocus,
	popperProps,
}) => {
	const initialDataSet = useMemo(() => data, []);
	const [open, setOpen] = useState(false);
	const [optionsData, setOptionsData] = useState([]);
	const [suggestionValue, setSuggestionValue] = useState('');
	const [selected, setSelected] = useState('');
	const [searchLoading, setSearchLoading] = useState(false);
	const [searchParam, setSearchParam] = useState(null);

	const fhirDataLoader = useFhirDataLoader({
		scope: listSearchScope ? searchScopes[listSearchScope.resource] : searchScopes.valueSet,
	});

	const crmDataLoader = useCRMDataLoader({
		scope: listSearchScope && crmSearchScopes[listSearchScope.resource],
	});

	const { loading, setLoading } = useLoading();
	const { t } = useTranslation('root');

	const getMultiParamLabel = (element, param) => {
		if (param === 'codedescription') {
			return `${element.code} - ${element.display}`;
		}
		if (param === 'studytypedescription') {
			return `${element.studyType} - ${element.description}`;
		}
	};

	useEffect(() => {
		try {
			if (!loading) {
				if (disableSearch) {
					setSelected('');
					setOptionsData([]);
					setLoading(false);
					setSearchLoading(false);
					return;
				}

				if (onSelect && suggestionValue) {
					setLoading(true);
					customDataLoader
						? customDataLoader
								.load({
									[listSearchScope.searchKey]: [suggestionValue],
									summary,
								})
								.then(loadedData => {
									if (!!loadedData && !_.isEmpty(loadedData)) {
										setSelected('');
										if (listAvoid) {
											loadedData = loadedData.filter(item => !listAvoid.includes(item[optionId]));
										}
										setOptionsData(
											loadedData.map(elem => {
												if (multiParam) {
													return {
														[optionId]: getMultiParamLabel(elem, multiParam),
														record: elem,
													};
												}
												return {
													[optionId]: elem[listSearchScope.label],
													record: elem,
												};
											})
										);
									}
								})
								.catch(console.error)
								.finally(() => {
									setLoading(false);
									setSearchLoading(false);
								})
						: (isCRM ? crmDataLoader : fhirDataLoader)
								.load(
									isCRM
										? {
												[listSearchScope.label]: [suggestionValue],
										  }
										: {
												value: [
													{
														label:
															multiParam === 'codedescription'
																? multiParam
																: listSearchScope.label,
														values: [suggestionValue],
													},
												],

												extraValue: listSearchScope.extraParam,
										  }
								)
								.then(loadedData => {
									if (!!loadedData && !_.isEmpty(loadedData)) {
										setSelected('');

										setOptionsData(
											loadedData.map(elem => {
												let optionDisplay = '';
												if (listSearchScope.searchKey) {
													if (listSearchScope.searchKey === 'text.div') {
														optionDisplay = elem.text.div;
													} else if (listSearchScope.searchKey === 'studyImport') {
														const patientName = elem.subject.display;
														const { description } = elem;
														const studyID = elem.studyID || elem.id;

														const studystatus = elem.extension?.find(
															({ url }) => url === fhirExtensionUrls.common.studyStatus
														)?.valueCoding?.display;

														const accessionNumber = elem.identifier?.find(({ type }) =>
															type?.coding?.some(acc => acc.code === 'ACSN')
														)?.value;

														const studyDateTime = new Date(elem.started);
														const studyDateTimeDisplay = `${studyDateTime.toLocaleDateString()} ${studyDateTime.toLocaleTimeString()}`;

														optionDisplay = [
															patientName,
															description,
															studyID,
															studystatus,
															studyDateTimeDisplay,
															accessionNumber,
														]
															.filter(Boolean)
															.join(' - ')
															.replace(/em>/g, 'mark>')
															.toUpperCase();
													} else {
														optionDisplay = elem[listSearchScope.searchKey];
													}
												} else if (multiParam) {
													optionDisplay = getMultiParamLabel(elem, multiParam);
												} else {
													optionDisplay = elem[listSearchScope.label];
												}

												return {
													[optionId]: optionDisplay,
													record: elem,
												};
											})
										);
									}
								})
								.then(loadedData => {
									if (!!loadedData && !_.isEmpty(loadedData)) {
										setSelected('');

										setOptionsData(
											loadedData.map(elem => ({
												[optionId]: listSearchScope.searchKey
													? listSearchScope.searchKey == 'text.div'
														? elem.text.div
														: elem[listSearchScope.searchKey]
													: multiParam
													? getMultiParamLabel(elem, multiParam)
													: elem[listSearchScope.label],
												record: elem,
											}))
										);
									}
								})
								.catch(console.error)
								.finally(() => {
									setLoading(false);
									setSearchLoading(false);
								});
				} else {
					setOptionsData(initialDataSet || []);
				}
			}
		} catch {}
	}, [suggestionValue]);

	const debounceSetSuggestionValue = useCallback(
		_.debounce(nextValue => {
			// clear current value
			setSuggestionValue(null);
			// add new value
			setSuggestionValue(nextValue);
		}, 500),
		[]
	);

	useEffect(() => {
		if (removeStudyInput) {
			setSelected('');
			setSuggestionValue('');
			setOptionsData([]);
			setRemoveStudyInput(false);
		}
	}, [removeStudyInput]);

	return (
		<Autocomplete
			ref={fieldRef}
			autoHighlight
			PopperComponent={({ ...props }) => <Popper {...props} {...popperProps} />}
			className={className}
			clearOnBlur={clearOnBlur}
			data-cy={`sayt-${text}`}
			data-testid={testid}
			defaultValue={value}
			disableClearable={disableClearable}
			disabled={disabled}
			freeSolo={freeSolo}
			fullWidth={fullWidth}
			getOptionLabel={option => get(option, optionId) || (_.isString(option) ? option : '')}
			id={testid || 'search-as-you-type'}
			isOptionEqualToValue={(option, val) => get(option, optionId) === get(val, optionId)}
			loading={externalCall ? isLoading : loading}
			open={open}
			options={externalCall ? data : selected ? [] : optionsData} // don't show list of options if one has already been selected
			renderInput={params => (
				<TextField
					{...params}
					InputLabelProps={{ shrink: labelAlwaysShrunk }}
					InputProps={{
						...params.InputProps,
						startAdornment: !hideIcon && (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),

						endAdornment: (
							<>
								{isLoading || searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
								{selected && params.InputProps.endAdornment}
							</>
						),

						placeholder: t(placeholder),
					}}
					data-cy={testId}
					data-testid={`${testid}_ComboBox`}
					error={error}
					helperText={errorText}
					label={text}
					variant={variant}
					onBlur={() => (onBlur ? onBlur() : '')}
					onFocus={() => (onFocus ? onFocus() : '')}
				/>
			)}
			renderOption={(props, option) => (
				<ListItem
					sx={{
						backgroundColor: 'transparent',
						'&:hover': {
							backgroundColor: '#2727274a !important',
							'.searchChildrenOrganizationStudies': {
								display: 'flex',
							},
						},
					}}
					{...props}
				>
					{showAvatar ? (
						<>
							<Box
								sx={{
									borderRadius: '50%',
									width: '30px',
									height: '30px',
									backgroundSize: 'cover !important',
									marginLeft: '2px',
									background: `url(${Avatar})`,
								}}
							/>

							<Box id={`${props.id}-box`} sx={optionStyle || { ml: 1 }}>
								{get(option, optionId)}
							</Box>
						</>
					) : (
						<>
							<Box flexGrow={1} id={`${props.id}-box`} sx={optionStyle || {}}>
								{multiParam ? `${option[optionId]}` : get(option, optionId)}
							</Box>
							{enableSearchChildrenOrganizationStudies &&
								optionId === 'internalManagingOrganizationID' && (
									<IconButton
										className="searchChildrenOrganizationStudies"
										sx={{
											border: '1px solid #42A5F5',
											color: '#42A5F6',
											borderRadius: '12px',
											float: 'right',
											width: '36px',
											height: '24px',
											display: 'none',
										}}
										onClick={e => {
											e.stopPropagation();
											const val = option ? option[optionId] : '';
											const record = option?.record;

											if (onSelect && val) {
												setSelected(val);
												onSelect(
													valueField && record ? record[valueField] : val,
													val,
													optionId,
													record,
													'internalParentOrganizationID'
												);

												setOptionsData([]);
											}
											// For worklist grid \ Managing Organization column, use this search parameter for filtering studies of the parent organanization and all child
											// organizations instead of the searchParam configured in ImagingStudyWorklistMapping
											setSearchParam('internalParentOrganizationID');
											setOpen(false);
										}}
									>
										<AccountTreeOutlinedIcon
											sx={{
												color: '#42A5F6',
											}}
										/>
									</IconButton>
								)}
						</>
					)}
				</ListItem>
			)}
			size={size || 'medium'}
			sx={{ ...(fullWidth ? '' : { width }), ...style }}
			value={selected || value}
			onBlur={() => {
				setOpen(false);
			}}
			onChange={(event, val) => {
				if (onFocus) {
					onFocus();
				}
				// Fires when an item is selected
				if (externalCall) {
					if (val) {
						onSelect(event, val);
					}
				} else {
					const newVal = val ? val[optionId] : '';
					const record = val?.record;
					if (onSelect && newVal) {
						setSelected(newVal);
						onSelect(valueField && record ? record[valueField] : newVal, newVal, optionId, record, null);
						setOptionsData([]);
					}
					searchParam && setSearchParam(null);
				}
				setOpen(false);
				if (onBlur) {
					setTimeout(() => {
						onBlur();
					}, 10000);
				}
			}}
			onClose={
				onClose ||
				(() => {
					setOpen(false);
				})
			}
			onFocus={() => {
				setOpen(true);
			}}
			onInputChange={(event, val, reason) => {
				// Fires when typing or clearing field
				if (reason !== 'reset') {
					if (!open) setOpen(true);
					if (externalCall) {
						onInputChange(event);
					} else {
						// input means user is typing
						if (reason === 'input' && !!val) {
							if (testid === 'study_Suggest') {
								onInputChange(event);
							}
							setSearchLoading(true);
							debounceSetSuggestionValue(val);
						} else {
							setOptionsData([]);
							setSelected(val ? val[optionId] : '');
							onSelect(val ? val[optionId] : '');
							setSearchLoading(false);
							searchParam && setSearchParam(null);
						}
					}
				}
			}}
			onKeyDown={event => {
				const regex = /[%&#*]/;
				if (regex.test(event.key)) {
					event.preventDefault();
				} else if (event.key === 'Enter') {
					event.defaultPrevented = true;
				}
			}}
			onOpen={
				onOpen ||
				(() => {
					setOpen(true);
				})
			}
		/>
	);
};

Suggest.defaultProps = {
	size: 'medium',
	width: 300,
	fullWidth: false,
	disabled: false,
};

Suggest.propTypes = {
	/**
	 * Predefined data to be loaded into the Suggest. Note: May be deprecated if we are loading data from an API call.
	 */
	data: PropTypes.array,

	/**
	 * A reference to the input field of the component; used for clearing the value when a filter is removed
	 */
	fieldRef: PropTypes.shape({ current: PropTypes.any }),

	/**
	 * Hides the magnifier icon
	 */
	hideIcon: PropTypes.bool,

	/**
	 * Whether the label is always shrunk or not
	 */
	labelAlwaysShrunk: PropTypes.bool,

	/**
	 * Size of the components. Can be either `small` or `medium`.
	 */
	size: PropTypes.oneOf(['small', 'medium']),

	/**
	 * Label for the component.
	 */
	text: PropTypes.string,
	/**
	 * Indicates which field in the data will be passed back in the onSelect handler.
	 */
	optionId: PropTypes.string,

	/**
	 * Controls the width of the component. If `fullWidth` is true, this value does not take effect.
	 */
	width: PropTypes.number,

	/**
	 * Determines if the component should take up the full width of the container.
	 */
	fullWidth: PropTypes.bool,

	/**
	 * Callback fired when an item is selected
	 */
	onSelect: PropTypes.func,

	/**
	 * Callback fired when Suggest opens
	 */
	onOpen: PropTypes.func,

	/**
	 * Callback fired when Suggest closes.
	 */
	onClose: PropTypes.func,

	/**
	 * enable and disable the component
	 */
	disabled: PropTypes.bool,

	/**
	 * show drop down selection arrow
	 */
	freeSolo: PropTypes.bool,

	/**
	 * show or hide loading
	 */
	isLoading: PropTypes.bool,

	/**
	 * show required warning when input empty
	 */
	required: PropTypes.bool,

	/**
	 * call input change function when input changes on auto complete
	 */
	onInputChange: PropTypes.func,

	/**
	 * add class name
	 */
	className: PropTypes.string,
	/*
	 * the search scope that defines which API endpoint the list is populated from
	 * resource - The name of the FHIR resource/endpoint
	 * label - Which field to read from (e.g.: name, id, etc.)
	 */
	listSearchScope: PropTypes.shape({
		resource: PropTypes.string,
		label: PropTypes.string,
	}),

	/*
	 * on select and on change input handles externally from parent component
	 */
	externalCall: PropTypes.bool,
	/*
	 * selected value
	 */
	value: PropTypes.object,
	/*
	 * show or hide error
	 */
	error: PropTypes.bool,
	/*
	 * input error text
	 */
	errorText: PropTypes.string,
	/**
	 * clear when lost focus
	 */
	clearOnBlur: PropTypes.bool,
	/*
	 * TextField variant type
	 */
	variant: PropTypes.string,
	/*
	 * Custom data loader
	 */
	customDataLoader: PropTypes.object,
	/*
	 * Show or hide avatar in search result
	 */
	showAvatar: PropTypes.bool,
	/*
	 * style of option
	 */
	optionStyle: PropTypes.string,
	/*
	 * Show or hide clear input button
	 */
	disableClearable: PropTypes.bool,
	/*
	 * Feature flag for searching children organization's studies
	 */
	enableSearchChildrenOrganizationStudies: PropTypes.bool,
};

export default Suggest;
