// core
import React from 'react';

// libraries
import { Controller } from 'react-hook-form';
import { MuiTelInput } from 'mui-tel-input';

// utils
import { useConfig } from '@rs-core/context/ConfigContext';
import { hasError } from '../../../formUtils/formUtils';

// types
import FormFieldVariantType from '../../../formTypes/FormFieldVariantType';

export const handleDefaultCountryCode = resourceGroup => {
	const countryCodeMap = {
		'po-ca01': 'CA',
		'po-in01': 'IN',
	};

	return countryCodeMap[resourceGroup] || 'US';
};

const FormMUIPhoneVariant = ({ formHook, name, props, defaultCountryCode }) => {
	const { control } = formHook;

	const __config = useConfig();
	const defaultCountry = defaultCountryCode || handleDefaultCountryCode(__config.resource_group);

	const getReadOnlyProps = () => {
		if (!props.readOnly && props.disabled) {
			return {
				helperText: 'Read-only',
			};
		}

		return props.readOnly
			? {
					disabled: true,
					placeholder: 'N/A',
					variant: 'standard',
					sx: {
						'& .Mui-disabled': {
							'&:before': { display: 'none' },
						},
						'& .MuiInput-input.Mui-disabled': {
							WebkitTextFillColor: 'rgba(255, 255, 255, 0.87) !important',
						},
						'& .MuiInput-input': {
							WebkitTextFillColor: 'rgba(255, 255, 255, 0.87) !important',
						},
						...props.sx,
					},
					InputLabelProps: { shrink: true },
					InputProps: {
						startAdornment: undefined,
						endAdornment: undefined,
					},
			  }
			: {};
	};

	return (
		<Controller
			control={control}
			name={name}
			render={({ field, fieldState }) => (
				<MuiTelInput
					defaultCountry={defaultCountry}
					{...field}
					MenuProps={{
						sx: {
							maxHeight: '400px',
						},
					}}
					error={fieldState.invalid}
					helperText={
						fieldState.invalid && field.value
							? 'Invalid Phone Number'
							: (props.required || props.contactsRequired) &&
							  !field.value &&
							  hasError(name, formHook.formState.errors)
							? 'Required'
							: ''
					}
					inputProps={{ maxLength: 20 }}
					value={field.value}
					onChange={(value, info) => {
						const validValue = info.numberValue || '+';
						field.onChange(validValue);
					}}
					{...props}
					{...getReadOnlyProps()}
				/>
			)}
		/>
	);
};

FormMUIPhoneVariant.propTypes = FormFieldVariantType();

export default FormMUIPhoneVariant;
