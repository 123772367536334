import { getCalibratedLengthUnits } from '@cornerstonejs/tools/dist/esm/utilities';
import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';
import calculatePerimeter from './calculatePerimeter';
import { SUVOptions } from '../../contexts/consts/consts';

const roundNumber = (number, precision) => {
	return parseFloat(number?.toFixed(precision));
};

const calculateSUV = (data, viewport, targetId) => {
	const cachedVolumeStats = data.cachedStats[targetId];
	if (!cachedVolumeStats) {
		return null;
	}

	const { area, mean: SUVbwMean, max: SUVbwMax, stdDev, areaUnit, modalityUnit } = cachedVolumeStats;

	const UNIT_TAG = '00541001';
	const imageId = viewport?.getCurrentImageId();
	const curMetaData = cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageId);
	const unitTagValue = curMetaData?.[UNIT_TAG]?.Value?.[0];
	const imageData = viewport?.getImageData();

	const perimeter = calculatePerimeter(data.handles.points);
	const lengthUnit = getCalibratedLengthUnits(null, imageData);

	let max, mean, SUVlbmMax, SUVlbmMean, SUVbsaMax, SUVbsaMean;

	if (modalityUnit === 'SUV') {
		const suvbw = imageData?.preScale?.scalingParameters?.suvbw;
		const suvbwToSuvbsa = imageData?.scaling?.PT?.suvbwToSuvbsa;
		const suvbwToSuvlbm = imageData?.scaling?.PT?.suvbwToSuvlbm;

		if (suvbw) {
			max = SUVbwMax / suvbw;
			mean = SUVbwMean / suvbw;
		}
		if (suvbwToSuvbsa) {
			SUVbsaMax = SUVbwMax * suvbwToSuvbsa;
			SUVbsaMean = SUVbwMean * suvbwToSuvbsa;
		}

		if (suvbwToSuvlbm) {
			SUVlbmMax = SUVbwMax * suvbwToSuvlbm;
			SUVlbmMean = SUVbwMean * suvbwToSuvlbm;
		}
	}

	return {
		area,
		areaUnit,
		mean,
		max,
		stdDev,
		modalityUnit,
		unitTagValue,
		perimeter,
		lengthUnit,
		SUVbwMax,
		SUVbwMean,
		SUVlbmMax,
		SUVlbmMean,
		SUVbsaMax,
		SUVbsaMean,
	};
};

const getSUVTextLines = (data, viewport, targetId) => {
	const suvData = calculateSUV(data, viewport, targetId);

	if (!suvData) {
		return [];
	}

	const {
		area,
		areaUnit,
		mean,
		max,
		stdDev,
		modalityUnit,
		unitTagValue,
		perimeter,
		lengthUnit,
		SUVbwMax,
		SUVbwMean,
		SUVlbmMax,
		SUVlbmMean,
		SUVbsaMax,
		SUVbsaMean,
	} = suvData;

	let curAreaValue = area,
		curAreaUnit = areaUnit,
		curPerimeter = perimeter,
		curlengthUnit = lengthUnit;
	if (curAreaUnit === 'mm²') {
		curAreaValue /= 100;
		curAreaUnit = 'cm²';
	}
	if (curlengthUnit === 'mm') {
		curPerimeter /= 10;
		curlengthUnit = 'cm';
	}

	const textLines = [];
	const precision = 3;

	const suvText = {};

	suvText.Area = { name: 'Area', value: `${roundNumber(curAreaValue, precision)} ${curAreaUnit}` };
	suvText.Perimeter = { name: 'Perimeter', value: `${roundNumber(curPerimeter, precision)} ${curlengthUnit}` };

	const SUVUnits = {
		SUVbw: 'g/mL',
		SUVlbm: 'g/mL',
		SUVbsa: 'cm²/mL',
	};

	const option = data?.selectedOption || SUVOptions.Default;

	switch (option) {
		case SUVOptions.Default:
			suvText.Max = { name: 'Max', value: `${roundNumber(max, precision)} ${unitTagValue || modalityUnit}` };
			suvText.Mean = { name: 'Mean', value: `${roundNumber(mean, precision)} ${unitTagValue || modalityUnit}` };
			suvText.SD = { name: 'SD', value: `${roundNumber(stdDev, precision)} ${unitTagValue || modalityUnit}` };
			break;
		case SUVOptions.SUVbw:
			suvText.SUVbwMax = { name: 'SUVbw Max', value: `${roundNumber(SUVbwMax, precision)} ${SUVUnits.SUVbw}` };
			suvText.SUVbwMean = { name: 'SUVbw Mean', value: `${roundNumber(SUVbwMean, precision)} ${SUVUnits.SUVbw}` };
			break;
		case SUVOptions.SUVbm:
			suvText.SUVlbmMax = {
				name: 'SUVlbm Max',
				value: `${roundNumber(SUVlbmMax, precision)} ${SUVUnits.SUVlbm}`,
			};
			suvText.SUVlbmMean = {
				name: 'SUVlbm Mean',
				value: `${roundNumber(SUVlbmMean, precision)} ${SUVUnits.SUVlbm}`,
			};
			break;
		case SUVOptions.SUVbsa:
			suvText.SUVbsaMax = {
				name: 'SUVbsa Max',
				value: `${roundNumber(SUVbsaMax, precision)} ${SUVUnits.SUVbsa}`,
			};
			suvText.SUVbsaMean = {
				name: 'SUVbsa Mean',
				value: `${roundNumber(SUVbsaMean, precision)} ${SUVUnits.SUVbsa}`,
			};
			break;
	}

	Object.keys(suvText).forEach(key => {
		textLines.push(`${suvText[key].name}: ${suvText[key].value}`);
	});

	data.suvText = suvText;

	return textLines;
};

export { calculateSUV, getSUVTextLines };
