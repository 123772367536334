import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, CircularProgress, Typography, Box, Stack, IconButton, Slide } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';

import { useIsTablet } from '@worklist-2/core/src/utils/responsiveUtils';
import downloadIcon from '@worklist-2/ui/src/assets/img/homepage/download.svg';
import { useStudiesStore } from '@worklist-2/patientPortal/src/stores';
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';
import { STUDY_DOWNLOAD } from '@worklist-2/patientPortal/src/analytics/eventTypes';
import sendAnalyticsEvent from '@worklist-2/patientPortal/src/analytics';

const DownloadStudy = ({ study, setMoreDrawerOpen }) => {
	const downloadStudy = useStudiesStore(state => state.downloadStudy);
	const progressMBs = useStudiesStore(useShallow(state => state.studyDownloadStatus?.[study?.id]?.progress));
	const isLoading = useStudiesStore(useShallow(state => state.studyDownloadStatus?.[study?.id]?.loading));
	const { setToastMsg } = useToastMessageContext();

	const { t } = useTranslation('homepage');
	const isTablet = useIsTablet();

	const disabled = !study?.studyThumbnail?.seriesUID;

	const handleStudyDownload = useCallback(async () => {
		await downloadStudy({ study, setToastMsg, t });

		setMoreDrawerOpen(false);

		sendAnalyticsEvent(STUDY_DOWNLOAD, { studyId: study.id, studyDescription: study.description });
	}, [downloadStudy, setMoreDrawerOpen, setToastMsg, study, t]);

	if (isTablet) {
		return (
			<Stack
				alignItems="center"
				direction="row"
				justifyContent="space-between"
				sx={{ display: disabled ? 'none' : 'flex', height: '35px' }}
				onClick={handleStudyDownload}
			>
				<Typography color="var(--color-primary)" fontSize={16} sx={{ display: 'flex', alignItems: 'center' }}>
					<Icon component={downloadIcon} sx={{ mr: 1 }} />
					{t('Download Results')}
				</Typography>
				<Slide mountOnEnter unmountOnExit direction="down" in={isLoading}>
					<IconButton sx={{ fontSize: '16px' }}>
						<Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', fontFamily: 'Roboto' }}>
							<CircularProgress color="info" size={16} />
							&#40;{progressMBs || 0}MB&#41;
						</Box>
					</IconButton>
				</Slide>
			</Stack>
		);
	}

	return (
		<Box sx={{ overflow: 'hidden' }}>
			{isLoading ? (
				<Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', fontFamily: 'Roboto' }}>
					<CircularProgress color="info" size={16} />
					&#40;{progressMBs || 0}MB&#41;
				</Box>
			) : (
				<Typography
					alignItems="center"
					display="flex"
					opacity={0.6}
					sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
					onClick={handleStudyDownload}
				>
					<Icon component={downloadIcon} sx={{ height: '20px' }} /> {t('Download Results')}
				</Typography>
			)}
		</Box>
	);
};

export default DownloadStudy;
