import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useDateTimeFormatter } from '@worklist-2/core/src';
import { mediaTablet } from '@rs-core/utils/responsiveUtils';
import { useTranslation } from 'react-i18next';

const DateTime = ({ datetime, type, fontColor, format, sx }) => {
	const formattedDateTime = useCallback(() => {
		const dateTime = useDateTimeFormatter(datetime, type, format);
		const { t } = useTranslation('appointments');

		if (type === 'split') {
			const datetimeArray = typeof dateTime === 'string' ? dateTime?.split(',') : [];
			return (
				<>
					<Typography
						color={fontColor}
						sx={{
							fontSize: '20px',
							lineHeight: '22px',
							letterSpacing: '0.15em',
							p: '3px 3px 0px 8px',
							[mediaTablet]: {
								fontSize: '18px',
								lineHeight: '22px',
							},
						}}
					>
						{datetimeArray[0]}
					</Typography>
					<Typography
						color={fontColor}
						sx={{
							fontSize: '20px',
							lineHeight: '22px',
							letterSpacing: '0.15em',
							p: '3px 3px 0px 8px',
							[mediaTablet]: {
								fontSize: '18px',
								lineHeight: '22px',
							},
						}}
					>
						{datetimeArray[1]}
					</Typography>
				</>
			);
		}
		if (type === 'relative' || type === 'relative_Blume' || type === 'relative_OmegaAI') {
			return (
				<Typography
					color={fontColor}
					sx={{
						fontSize: '14px',
						lineHeight: '22px',
						fontWeight: type === 'relative_OmegaAI' ? 500 : 400,
						fontFamily: 'Roboto',
						letterSpacing: type === 'relative_OmegaAI' ? undefined : '0.15em',
						...sx,
					}}
					textAlign="left"
				>
					{dateTime}
				</Typography>
			);
		}
		return (
			<Typography
				color={fontColor}
				sx={{
					fontSize: '14px',
					lineHeight: '22px',
					fontWeight: 500,
					letterSpacing: type === 'relative_OmegaAI' ? undefined : '0.15em',
				}}
				textAlign="left"
			>
				{`${t(dateTime.split(' ')[0].toUpperCase().substring(0, 3))}, ${t(dateTime.split(' ')[1]).substring(
					0,
					3
				)} ${dateTime.split(' ')[2]}`}
			</Typography>
		);
	}, [datetime, type, fontColor]);

	return formattedDateTime();
};

DateTime.propTypes = {
	/**
	 * Datetime input which has to be formatted
	 */
	datetime: PropTypes.instanceOf(Date),
	/**
	 * Actual will return the same date with formatting and relative will
	 * return the time difference between datetime and current datetime
	 */
	type: PropTypes.oneOf(['actual', 'relative', 'split']),
	/**
	 * The color of the formatted date string can be set in this prop
	 */
	fontColor: PropTypes.string,
	/**
	 * The lenght of the date: short will only return the first three letters of a day / month
	 */
	format: PropTypes.oneOf(['long', 'short']),
};

DateTime.defaultProps = {
	datetime: '',
	type: 'actual',
	fontColor: '#ffffff',
	format: 'long',
};

export default DateTime;
