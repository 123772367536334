import { getNewResource } from '../../fhir/resource/getNewResource';
import axios from 'axios';
import _ from 'lodash';
import getColumnMapping from '../../fhir/resource/columnMapping/getColumnMapping';
import sleep from '@rs-core/utils/sleep';
import { searchScopes } from '@rs-core/context/consts/searchScopes';
import { logInfo, logError } from '@rs-core/utils/logger';
import moment from 'moment';
import generatePillDateFormat from '@worklist-2/ui/src/views/utils/generatePillDateFormat';

// this is necessary because our production build hard codes config values based on this variable name
let __config;
// this should be a config value, which I had but took out and I'm too lazy to put it back in right this second
const MAX_RETRIES = 5;

class FhirDataLoader {
	results = null;

	context = {
		name: 'searchAll',
		// prettier-ignore
		// we want prettier to ignore this line because we use shelljs' sed command to inject
		// the value, which only substitutes line by line, unlike the proper GNU sed
		bearerToken: '',
		// prettier-ignore-end
		sessionId: '',
		endpoint: '',
	};

	httpOptions;

	searchScope;

	abortController;

	isGlobalSearch = false;
	isWorklistDateTimeDisplay;

	constructor({
		config,
		scopeContext,
		httpOptions,
		abortController,
		isGlobal,
		mavenWorklistWorklistDateTimeDisplay,
	}) {
		__config = this.config = config;
		this.httpOptions = {
			...{
				timeout: __config?.fhir?.http_timeout || 30000,
				headers: { ...__config?.fhir?.request_headers } | {},
				transformResponse: [data => data],
			},
			...httpOptions,
		};
		this.searchScope = scopeContext || {};
		this.abortController = abortController;
		this.isGlobalSearch = isGlobal;
		this.isWorklistDateTimeDisplay = mavenWorklistWorklistDateTimeDisplay;
	}

	__testValue = 106;

	__op__updateScope = newScope => {
		this.searchScope = newScope;
	};

	__op__load = async (props, returnRawData, bodyData, customUrl, isStatus = false) => {
		let processResults = this.processResults;
		props = props || {};

		let retries = 0;

		let uri = this.buildUrl(props);

		try {
			let results;

			if (this.isGlobalSearch) {
				//Cancel the previous axios token if any
				if (this.cancel) {
					this.cancel.cancel();
				}

				// Get a new token
				this.cancel = axios.CancelToken.source();
			}

			let arr;
			do {
				try {
					arr = await axios[bodyData ? 'post' : 'get'](
						(customUrl ?? __config.data_sources.fhir) + (uri?.startsWith('?') ? '' : '/') + (uri || ''),
						!bodyData
							? {
									headers: {
										Accept: '*/*',
									},
									cancelToken: this.isGlobalSearch ? this.cancel.token : null,
							  }
							: bodyData,
						{
							cancelToken: this.isGlobalSearch ? this.cancel.token : null,
						}
					);

					if (arr.status === 200 && arr.data?.resourceType === 'OperationOutcome') {
						retries = MAX_RETRIES; // Don't retry if we get an OperationOutcome
						logError('FhirDataLoader', 'FHIR Operation Outcome Error', arr.data.issue);
						throw new Error('FHIR Operation Outcome Error');
					}

					if (retries > MAX_RETRIES) {
						console.warn(
							`Could not load data from ${this.searchScope.endpoint}; maximum retries (${MAX_RETRIES}) exceeded.`
						);
						results = [];
					} else {
						results = returnRawData ? arr.data : processResults(arr.data);
					}

					break;
				} catch (thrown) {
					// because axios throws an error for 4xx/5xx responses
					if (axios.isCancel(thrown)) {
						logInfo('FhirDataLoader', 'Request canceled');
						break;
					} else {
						logInfo('FhirDataLoader', 'Not canceled');
					}

					retries++;
					sleep(500);
					continue;
				}
			} while (retries <= MAX_RETRIES);

			return isStatus ? { ...results, status: arr.status } : results;
		} catch (e) {
			// TODO: Implement better error handling...for now we can log warnings, but this should be fixed
			console.error(e);
			throw e;
		}
	};

	__op__save = async (props, payload, enforcePost, customUrl) => {
		let uri = '';

		props = props || {};

		if (this.searchScope.scope !== searchScopes.bundle) {
			uri = this.buildUrl(props);
		}

		try {
			return await axios[props['id'] && !enforcePost ? 'put' : 'post'](
				(customUrl ?? __config.data_sources.fhir) + '/' + uri,
				payload,
				{
					headers: {
						Accept: '*/*',
						'Content-Type': 'application/json',
						...(props.headers ? props.headers : {}),
					},
				}
			);
		} catch (e) {
			// TODO: Do this better.
			console.error(e);
			throw e;
		}
	};

	__op__delete = async (id, props, customUrl) => {
		let uri = `${this.searchScope.endpoint}/${id}`;

		if (props) {
			uri = this.buildUrl(props);
		}

		try {
			let resp = await axios.delete(`${customUrl ?? __config.data_sources.fhir}/${uri}`, {
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
				},
			});
			return !!resp && resp?.status >= 200 && resp?.status < 300;
		} catch (e) {
			// TODO: Do this better.
			console.error(e);
			throw e;
		}
	};

	__op__update = async (id, payload, customUrl, props, headerProps, returnStatus = false) => {
		let uri = `${this.searchScope.endpoint}/${id}`;

		if (!_.isEmpty(props)) {
			uri = this.buildUrl(props);
		}

		try {
			let resp = await axios.put(`${customUrl ?? __config.data_sources.fhir}/${uri}`, payload, {
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
					...(headerProps ? headerProps : {}),
				},
			});

			if (returnStatus) {
				return { data: resp.data, status: resp.status };
			} else {
				return resp?.data;
			}
		} catch (e) {
			// TODO: Do this better.
			console.error(e);
			throw e;
		}
	};

	__op__patch = async (id, fieldName, value, op = 'add') => {
		let uri = `${this.searchScope.endpoint}/${id}`;
		let payload = [];
		if (Array.isArray(fieldName)) {
			payload = _.map(fieldName, (item, index) => {
				return {
					op: op,
					path: '/' + item,
					value: value[index],
				};
			});
		} else {
			payload = [
				{
					op: op,
					path: '/' + fieldName,
					value: value,
				},
			];
		}

		try {
			let resp = await axios.patch(`${__config.data_sources.fhir}/${uri}`, payload, {
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json-patch+json',
				},
			});
			return !!resp && resp.status >= 200 && resp.status < 300 ? resp.data : null;
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	processResults = result => {
		let processedValues;
		if (result.entry && result.entry.length > 0 && result['resourceType'] && result['resourceType'] === 'Bundle') {
			let resourceType = result.entry[0].resource['resourceType']
				? result.entry[0].resource['resourceType']
				: result.entry[0].resource['ResourceType'];

			if (resourceType === 'helpcenter') {
				// Parse the result for HelpCenter resource.
				processedValues = this.parseHelpCenter(result);
			} else if (resourceType === 'CodeSystem') {
				// Parse the result for CodeSystem resource.
				processedValues = this.parseCodeSystem(result);
			} else if (resourceType === 'ValueSet') {
				// Parse the result for ValueSet resource.
				processedValues = this.parseValueSet(result);
			} else if (resourceType === 'DiagnosticReport') {
				// Parse the result for Diagnosticreport resource.
				processedValues = this.parseDiagnosticReport(result);
			} else if (resourceType === 'CarePlan') {
				// Parse the result for Diagnosticreport resource.
				processedValues = this.parseCarePlan(result);
			} else if (resourceType === 'StudyStatus') {
				// Parse the result for StudyStatus resource.
				processedValues = result?.entry
					?.filter(item => item?.resource?.active)
					?.map(elem => {
						let item = {};
						item.status = elem.resource.status;
						item.statusValue = elem.resource.statusValue;
						return item;
					});
			} else {
				// For other resources
				processedValues = _.map(result.entry, elem => {
					let item = getNewResource(elem.resource.resourceType, elem.resource);
					if (resourceType === 'Task') {
						_.forEach(item.extension, i => {
							let fieldNmae = i.url.substring(_.lastIndexOf(i.url, '/') + 1);

							if (fieldNmae) {
								let value;
								if (i.valueInteger) {
									value = i.valueInteger;
								} else if (i.valueDateTime) {
									value = i.valueDateTime;
								} else if (i.valueString) {
									value = i.valueString;
								} else if (i.valueReference) {
									value = i.valueReference?.display;
								}
								item[fieldNmae] = value;
							}
						});
					}

					item.eTag = elem.response?.eTag;
					return item;
				});
			}
		} else if (result['resourceType'] && result['resourceType'] !== 'Bundle') {
			// process single resource
			return getNewResource(result.resourceType, result);
		}
		return processedValues || [];
	};

	parseCodeSystem = data => {
		return data.entry[0]?.resource?.concept;
	};

	parseValueSet = data => {
		return data.entry[0]?.resource?.compose?.include[0]?.concept;
	};
	parseDiagnosticReport = data => {
		//fetching all reports
		return data.entry;
	};

	parseCarePlan = data => {
		//fetching all carePlans
		return data.entry;
	};

	parseHelpCenter = data => {
		//fetching all articles
		return data.entry;
	};

	buildUrl = args => {
		let cacheBuster = `_dc=${new Date().getTime()}`,
			returnUrl = this.searchScope.endpoint || '',
			exactMatchValue = !!args['exactMatch'];

		if (args.id) {
			let summaryParam = args.summary ? `&_summary=${args.summary}` : '';
			let hideSeriesByIdParam = args.hideSeriesById ? `&hideseriesbyid=${args.hideSeriesById}` : '';

			let url = `${this.searchScope.endpoint}/${args.id}`;

			if (args.history) {
				url = `${url}/_history`;
			}

			if (args.descendant) {
				url = `${url}/_descendant`;
			}

			if (args.defaultRoleId) {
				url = `${url}/SetDefaultRole/${args.defaultRoleId}`;
			}

			if (args.linkedOrganization) {
				url = `${url}/linkedOrganization`;

				if (args.linkedId) {
					url = `${url}/${args.linkedId}`;
				}
			}

			return `${url}?${cacheBuster}${summaryParam}${hideSeriesByIdParam}`;
		}

		if (args.idParam) {
			let summaryParam = args.summary ? `&_summary=${args.summary}` : '';
			let url = `${this.searchScope.endpoint}`;

			if (args.history) {
				url = `${url}/_history`;
			}

			if (args.descendant) {
				url = `${url}/_descendant`;
			}

			if (args.defaultRoleId) {
				url = `${url}/SetDefaultRole/${args.defaultRoleId}`;
			}

			if (args.isreferring) {
				return `${url}?id=${args.idParam}&isreferring=${args.isreferring}&${cacheBuster}${summaryParam}`;
			} else {
				return `${url}?id=${args.idParam}&${cacheBuster}${summaryParam}`;
			}
		}

		// gives us the ability to prefix with a special endpoint, like ImagingStudy/<studyid>/Fax
		if (args.endpoint) {
			returnUrl = `${returnUrl}/${args.endpoint}`;
		}

		if (!_.find(args, (value, key, collection) => key === 'page')) {
			// we need a page value
			args = { ...args, page: 1 };
		} else if (args['page'] <= 0) {
			// ... and it needs to be 1 or greater; we're not 0-indexed
			args['page'] = 1;
		}

		if (!_.find(args, (value, key, collection) => key === 'count')) {
			// we need a count value
			args = { ...args, count: __config.fhir.results_per_search || 50 };
		} else if (args['count'] <= 0) {
			// ... and it needs to be 1 or greater or else what's the point
			args['count'] = __config.fhir.results_per_search || 50;
		}

		let params = _.map(args, (value, key, collection) => {
			switch (key) {
				case 'value':
					return this.getSearchParam(value, exactMatchValue);
				case 'extraValue':
					return this.getExtraParam(value);
				case 'content':
					return [`_content=${value}`];
				case 'count':
					return [`_count=${value}`];
				case 'sort':
					return [!_.isEmpty(value) ? `_sort=${value}` : ''];
				case 'summary':
					return [`_summary=${value}`];
				case 'hideSeriesById':
					return [`hideseriesbyid=${value}`];
				case 'deviceName':
					return [`device-name=${value}`];
				case 'partOfMissing':
					return [`partOf:missing=true`];
				case 'page':
					if (_.endsWith(this.searchScope.endpoint, 'elk') || _.endsWith(this.searchScope.endpoint, 'task')) {
						return [`_start=${(value - 1) * collection['count']}`];
					} else {
						return [`page=${value}`];
					}
				case 'exactMatch':
				case 'id':
				case 'endpoint':
					// add cases here for argument names we want to skip
					return [''];
				case 'lastLoginDateTimeUTC':
					return this.getDateRangeSearchParam(key, value);
				default:
					return [`${_.toLower(key)}=${value}`];
			}
		});

		return `${returnUrl}${
			'?' +
			_.join(_.sortBy(_.filter(_.flatten(params), elem => !_.isNil(elem) && !_.isEmpty(elem))), '&') +
			`&${cacheBuster}`
		}`;
	};

	getExtraParam(value) {
		let extraParams = [''];

		if (value && !_.isEmpty(value)) {
			for (let key in value) {
				extraParams.push(`${key}=${value[key]}`);
			}
		}

		return extraParams;
	}

	getDateRangeSearchParam(key, value, convertToUTC = false) {
		let valueParams = [];
		if (_.isArray(value)) {
			if (convertToUTC) {
				value = value.map(date => moment.utc(date).format('YYYY-MM-DDTHH:mm:ss'));
			}
			valueParams.push(`${_.toLower(key)}=ge${moment(value[0]).format('YYYY-MM-DDTHH:mm:ss')}`);
			valueParams.push(`${_.toLower(key)}=le${moment(value[1]).format('YYYY-MM-DDTHH:mm:ss')}`);
		} else {
			let pillDateRangeValue = generatePillDateFormat(value);
			if (convertToUTC) {
				pillDateRangeValue = pillDateRangeValue.map(date => moment.utc(date).format('YYYY-MM-DDTHH:mm:ss'));
			}
			valueParams.push(`${_.toLower(key)}=ge${moment(pillDateRangeValue[0]).format('YYYY-MM-DDTHH:mm:ss')}`);
			valueParams.push(`${_.toLower(key)}=le${moment(pillDateRangeValue[1]).format('YYYY-MM-DDTHH:mm:ss')}`);
		}
		return valueParams;
	}

	getDateSearchParam(key, value) {
		return `${_.toLower(key)}=${moment(value).format('YYYY-MM-DD')}`;
	}

	getSearchParam(value, exactMatch) {
		let valueParams = [];

		if (value && !_.isEmpty(value)) {
			let columnMapping = getColumnMapping(this.searchScope.endpoint, this.isWorklistDateTimeDisplay);
			_.forEach(value, element => {
				let mappingField = element.columnIdentity ? element.columnIdentity : element.label;
				// If the element contains searchParameter at the top level that is different with the searchParameter configured in ImagingStudyWorklistMapping,
				// then use this searchParameter instead of the one in ImagingStudyWorklistMapping. Worklist grid filtering by Managing Organization
				// and child organizations is using this parameter
				let param = `${
					columnMapping[mappingField]
						? element?.searchParameter
							? element?.searchParameter
							: columnMapping[mappingField]['searchParameter']
						: ''
				}${exactMatch ? '%3Aexact' : ''}`; // %3A = ':'
				let filterType = element?.groupFilterType
					? element.groupFilterType
					: columnMapping[mappingField]?.['filterType']
					? columnMapping[mappingField]['filterType']
					: 'none';

				// If the columnMapping has convertToUTC property, then use it to determine whether to convert the date to UTC
				const convertToUTC = columnMapping[mappingField]?.convertToUTC ?? false;
				if (param && filterType === 'date-range') {
					// TODO: For Matt to use variable stored in i18n package
					valueParams = valueParams.concat(this.getDateRangeSearchParam(param, element.values, convertToUTC));
				} else if (param && filterType === 'date-time') {
					valueParams.push(this.getDateSearchParam(param, element.values));
				} else if (param && _.isArray(element.values)) {
					valueParams.push(
						`${param}=${_.join(
							element.values,
							'%2C' // = ','
						)}`
					);
				} else if (param) {
					valueParams.push(`${param}=${encodeURIComponent(element.values.trim())}`);
				} else {
					valueParams.push(`${mappingField}=${encodeURIComponent(element.values)}`);
				}
			});
		}

		return valueParams;
	}
}

export default FhirDataLoader;
