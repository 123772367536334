import patchUser from '@worklist-2/patientPortal/src/api/patchUser';

function getUpdateUserPayload({ path, value }) {
	return [
		{
			operation: 'ADD',
			path,
			value,
		},
	];
}

export const updateEmergencyContacts = ({ __config, profileId, payload }) => {
	const path = `/Aggregatedetails/${profileId}/emergencyContacts`;
	const value = payload.map(contact => ({
		name: contact.name || '',
		relation: contact.relation || '',
		phone: contact.phone || '',
		email: contact.email || '',
	}));

	const data = getUpdateUserPayload({ path, value });
	patchUser({ __config, data });
};

export const updatePhysicians = async ({ __config, profileId, payload }) => {
	const path = `/Aggregatedetails/${profileId}/myPhysician`;
	const value = payload.map(physician => ({
		name: physician.name || '',
		specialisation: physician.specialisation || '',
		phone: physician.phone || '',
		email: physician.email || '',
	}));

	const data = getUpdateUserPayload({ path, value });
	await patchUser({ __config, data });
};

export const updateLanguage = ({ __config, Language }) => {
	const path = `/userSettings`;
	const value = { Language };

	const data = getUpdateUserPayload({ path, value });
	patchUser({ __config, data });
};
