import React, { useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import SwipeableViews from 'react-swipeable-views';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { useIsTablet } from '@rs-core/utils/responsiveUtils';
import ChatEmpty from '@rs-ui/components/Chat/ChatEmpty';

import ShareContactListItem from '@worklist-2/patientPortal/src/components/ShareContact/ShareContactList/ShareContactListItem';
import MobileListSubheader from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileList/MobileListSubheader';
import getAddress from '@worklist-2/patientPortal/src/utils/getAddress';
import { getInitials } from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/utils';

const MobileMyContactList = ({ users, onClick, isContact, isFacility, isShared }) => {
	const { t } = useTranslation('drawer');
	const isTablet = useIsTablet();

	const contactListItems = useMemo(
		() =>
			users?.map(user => ({
				id: isFacility ? user?.resource?.id : user.email,
				onClick: () => onClick(user),
				primary: isFacility ? user?.resource?.name : user.name,
				secondary: isFacility
					? getAddress(user?.resource?.address ? user?.resource?.address[0] : {}, true)
					: user.email,
				avatar: isFacility
					? null
					: {
							children: getInitials(user.name),
							variant: 'rounded',
							src: user.avatar,
							sx: { background: 'rgba(0, 0, 0, 0.6)' },
					  },
				selected: user.selected,
				selectedFloating: true,
				sx: {
					marginBottom: '12px',
					backgroundColor: user.selected ? 'rgba(66, 165, 245, 0.2) !important' : undefined,
				},
				user,
				isFacility,
				isShared,
			})),
		[isFacility, isShared, onClick, users]
	);

	const isListEmpty = contactListItems.length === 0;
	const showEmptyMessage = isListEmpty && !isFacility;
	const userSubheader = isShared ? t('SHARED WITH CONTACT LIST') : t('MY CONTACT LIST');
	const facilitySubheader = isShared ? t('SHARED WITH HEALTHCARE PROVIDERS') : t('MY HEALTHCARE PROVIDERS');

	return (
		<>
			{showEmptyMessage ? (
				<ChatEmpty sx={{ flex: 'auto' }}>
					<Typography
						component="div"
						sx={{ color: 'var(--element-foreground-color)', fontSize: '20px', marginTop: '40px' }}
					>
						{t('No Contacts')}
					</Typography>

					<Typography
						component="div"
						sx={{ color: 'var(--element-foreground-color-secondary)', fontSize: '12px', marginTop: '5px' }}
					>
						{t('Go ahead and add new contacts')}
					</Typography>
				</ChatEmpty>
			) : null}
			{!isListEmpty ? (
				<List
					disablePadding
					subheader={<MobileListSubheader subheader={isFacility ? facilitySubheader : userSubheader} />}
					sx={{ top: '25px', flex: 0 }}
				>
					{contactListItems.map(item => (
						<Fragment key={item.id}>
							{isTablet ? (
								<SwipeableViews
									resistance
									index={0}
									slideStyle={{ overflow: 'hidden' }}
									style={{ overflow: 'hidden', marginRight: '5px' }}
								>
									<ShareContactListItem
										showSelectCircle
										background="rgba(196, 196, 196, 0.2)"
										border="1px solid rgba(196, 196, 196, 0.2)"
										isContact={isContact}
										isFacility={isFacility}
										isShared={isShared}
										item={item}
									/>

									<Box
										sx={{
											height: '67px',
											width: '100%',
											display: 'flex',
											alignItems: 'center',
											background: '#CF6679',
										}}
									>
										<Icon
											component={DeleteOutlineOutlinedIcon}
											sx={{ color: '#fff', paddingLeft: '10px' }}
										/>
									</Box>
								</SwipeableViews>
							) : (
								<ShareContactListItem
									showSelectCircle
									background="rgba(196, 196, 196, 0.2)"
									border="1px solid rgba(196, 196, 196, 0.2)"
									isContact={isContact}
									isFacility={isFacility}
									isShared={isShared}
									item={item}
								/>
							)}
						</Fragment>
					))}
				</List>
			) : null}
		</>
	);
};

export default MobileMyContactList;
