/* eslint-disable react/no-unstable-nested-components */
// core
import React, { useEffect, useState } from 'react';

// libraries
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tooltip from '@mui/material/Tooltip';

// compoonents
import useFhirAPI from '@rs-core/hooks/useFhirAPI';
import { useRouter } from '@rs-core/hooks/useRouter';
import { useAppModeContext } from '@rs-core/context/AppModeContext';
import { useAuth } from '@rs-core/context/UserAuthContext';
import useToast from '@rs-core/hooks/useToast';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import GenericDrawer from '@rs-ui/components/Drawers/GenericDrawer';
import PrimaryButton from '@rs-ui/components/PrimaryButton';
import SecondaryButton from '@rs-ui/components/SecondaryButton';
import Toast from '@rs-ui/components/Toast';
import { usePatientStore } from '@rs-ui/views/PatientInfoView/patientStore';
import { useUpdatePatient } from '@rs-ui/context/UpdatePatientContext';
import { logDebug } from '@rs-core/utils/logger';

const validationSchema = yup
	.object()
	.shape({
		firstName: yup
			.string()
			.required('First name exact match is required')
			.test('name', 'Please enter a valid worklist name.', val => val?.trim()?.length > 0),
		lastName: yup
			.string()
			.required('Last name exact match is required')
			.test('name', 'Please enter a valid worklist name.', val => val?.trim()?.length > 0),
		dateOfBirth: yup
			.date()
			.min(new Date(1900, 0, 1), 'Date of birth must be later than 01/01/1900')
			.max(new Date(), 'Date of birth cannot be in the future'),
		phoneNumber: yup
			.string()
			.test('valid phone', ' Please enter a valid phone number', val => matchIsValidTel(val)),
		emailAddress: yup.string().lowercase().email('Please enter a valid email address'),
	})
	.test(
		'atLeastOne',
		'At least one of date of birth, phone number, or email address is required',
		obj => obj.dateOfBirth || obj.phoneNumber || obj.emailAddress
	);

const EmergencySearchDrawer = ({ drawerOpen, setDrawerOpen, onCloseDialog }) => {
	const { setToastUtility, toastOpen, toastMessage, handleToastClose } = useToast();
	const { t } = useTranslation('emergencySearchDrawer');
	const navigate = useNavigate();
	const { loggedInUser } = useAuth();
	const { isEmergencySearch, setIsEmergencySearch } = useAppModeContext();
	const { fetchPatientNameForEmergencySearch, grantPatientViewAccess } = useFhirAPI();

	const drawerWidth = 487;
	const [loading, setLoading] = useState(false);
	const [foundPatientSearchResults, setFoundPatientSearchResults] = useState(null);
	const [selectedOption, setSelectedOption] = useState(-1);
	const [isFormValid, setIsFormValid] = useState(false);
	const { goTo } = useRouter();
	const { resetPatientStore } = usePatientStore();
	const { triggerUpdate } = useUpdatePatient();

	const form = useForm({
		mode: 'onChange',
		resolver: yupResolver(validationSchema),
	});

	const {
		control,
		formState: { errors },
		reset,
		clearErrors,
		getValues,
		setValue,
		watch,
	} = form;
	const firstName = watch('firstName');
	const lastName = watch('lastName');
	const dateOfBirth = watch('dateOfBirth');
	const phoneNumber = watch('phoneNumber');
	const emailAddress = watch('emailAddress');

	const closeDrawer = () => {
		setFoundPatientSearchResults(null);
		reset();
		setSelectedOption(-1);
		setDrawerOpen(false);
		setLoading(false);
	};

	const handleFetchEmergencySearch = async () => {
		setLoading(true);
		const { firstName, lastName, dateOfBirth, phoneNumber, emailAddress } = getValues();
		if (
			firstName &&
			lastName &&
			[Date.parse(dateOfBirth), matchIsValidTel(phoneNumber), /\S+@\S+\.\S+/.test(emailAddress)].some(Boolean)
		) {
			const dateOfBirthFormatted = dateOfBirth
				? new Date(dateOfBirth - dateOfBirth.getTimezoneOffset() * 60000).toISOString().split('T')[0]
				: null;
			const formattedPhoneNumber = phoneNumber ? phoneNumber.replace(/\s/g, '').replace('+', '') : null;
			const response = await fetchPatientNameForEmergencySearch(
				firstName,
				lastName,
				dateOfBirthFormatted,
				formattedPhoneNumber,
				emailAddress
			);
			if (response?.entry) {
				const parsedPatientData = response.entry.map(({ resource }) => ({
					id: resource.id,
					birthDate: resource.birthDate,
					managingOrganization: resource.managingOrganization?.display,
					name: resource.name[0].text,
				}));
				if (parsedPatientData?.length === 1) {
					await goToPatient(parsedPatientData[0]);
				}
				setFoundPatientSearchResults(parsedPatientData);
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		setIsFormValid(
			firstName &&
				lastName &&
				[
					Date.parse(dateOfBirth) && new Date('01/01/1900') <= dateOfBirth && dateOfBirth <= new Date(),
					matchIsValidTel(phoneNumber),
					/\S+@\S+\.\S+/.test(emailAddress),
				].some(Boolean)
		);
	}, [firstName, lastName, dateOfBirth, phoneNumber, emailAddress]);

	useEffect(() => {
		setValue('dateOfBirth', '');
		setValue('phoneNumber', '');
		setValue('emailAddress', '');
	}, [selectedOption]);

	const handleSubmit = () => {
		if (isFormValid) {
			handleFetchEmergencySearch();
		}
	};

	const goToPatient = async patient => {
		logDebug('DV', 'emergencySearchDrawer', 'goToPatient');
		resetPatientStore();
		await grantPatientViewAccess(patient?.id, loggedInUser.id);
		onCloseDialog();
		setIsEmergencySearch(true);
		if (window.location.pathname.startsWith(`/patient/${patient?.id}`)) {
			// need to trigger patient update if we are already in patient view page
			// otherwise fetchPatient won't load in PatientInfoVIew
			triggerUpdate();
		}
		goTo.patientDetail({ patientId: patient?.id, screen: 'study-history' });
	};

	const CancelContinueButtons = () => (
		<Box
			sx={{
				position: 'absolute',
				bottom: 0,
				left: 0,
				width: 427,
				zIndex: 1,
				margin: '0 30px',
			}}
		>
			<Stack direction="row">
				<Icon
					component={WarningIcon}
					height={16}
					sx={{ width: 16, height: 16, margin: '3px 5px 0 0 ', color: 'yellow' }}
					width={16}
				/>
				<Typography
					sx={{
						color: '#FFFFFF85',
						fontSize: '14px',
						textDecoration: 'underline',
						marginBottom: '10px',
						fontWeight: 1000,
					}}
				>
					{t('disclaimer')}
				</Typography>
			</Stack>
			<Typography
				sx={{
					color: '#FFFFFF85',
					fontSize: '12px',
				}}
			>
				{t('consent1')}
			</Typography>
			<br />
			<Typography
				sx={{
					color: '#FFFFFF85',
					fontSize: '12px',
				}}
			>
				{t('consent2')}
			</Typography>
			<Box
				sx={{
					flexDirection: 'row',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					backgroundColor: '#272727',
					padding: '20px 0',
				}}
			>
				<SecondaryButton label={t('cancel')} testId="CancelSettings" onClick={closeDrawer} />
				<PrimaryButton
					disabled={!isFormValid}
					isLoading={loading}
					label={t('continue')}
					onClick={handleSubmit}
				/>
			</Box>
		</Box>
	);

	const RadioOption = ({ value, label }) => (
		<FormControlLabel
			control={
				<Radio
					sx={{
						'&.Mui-checked': {
							color: 'rgba(66, 165, 245, 1)',
						},
					}}
				/>
			}
			label={label}
			value={value}
		/>
	);

	const WarningBox = ({ icon: { component, color }, title, text }) => (
		<Stack
			alignItems="top"
			data-testid="warning"
			direction="row"
			gap="10px"
			sx={{
				bgcolor: '#3d3d3d',
				borderRadius: '0',
				color: '#ddd',
				padding: '15px 30px',
				margin: '20px 0',
				minWidth: '180px',
				width: '100%',
			}}
		>
			<Icon component={component} height={18} sx={{ width: 18, height: 18, color }} width={18} />
			<Box>
				<Typography
					sx={{
						marginBottom: '5px',
						color: '#a4a4a4',
						fontWeight: 700,
						fontSize: '12px',
						lineHeight: '1.1',
						letterSpacing: '0.25px',
						textTransform: 'uppercase',
					}}
					textAlign="left"
				>
					{title}
				</Typography>
				<Typography
					sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '1.1', letterSpacing: '0.25px' }}
					textAlign="left"
				>
					{text}
				</Typography>
			</Box>
		</Stack>
	);

	return (
		<>
			<Toast message={toastMessage} open={toastOpen} onClose={handleToastClose} />
			<GenericDrawer
				anchor="right"
				drawerOpen={drawerOpen}
				setDrawerOpen={setDrawerOpen}
				showHeader={false}
				title={t('title')}
				width={drawerWidth}
				onClose={() => {
					closeDrawer();
				}}
			>
				<Stack
					alignItems="center"
					className="headerSection"
					direction="row"
					justifyContent="space-between"
					sx={{
						height: 54,
						minHeight: 54,
						pl: '27px',
						pr: '20px',
						pt: '15px',
						borderBottom: '1px solid #FFFFFF1A',
						marginBottom: '5px',
					}}
				>
					<Typography
						sx={{
							width: '100%',
							fontSize: '16px',
							fontWeight: 500,
							lineHeight: '19px',
							letterSpacing: '0.1em',
							textAlign: 'left',
							paddingLeft: '9px',
						}}
					>
						{t('title')}
					</Typography>
					<IconButton data-cy={`${t('title')}_close`} onClick={closeDrawer}>
						<CloseIcon />
					</IconButton>
				</Stack>
				<Stack
					sx={{
						position: 'relative',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
						height: '100%',
						overflowX: 'hidden',
					}}
				>
					<Box
						sx={{
							position: 'absolute',
							transition: 'transform cubic-bezier(0.4, 0, 0.2, 1) 0.5s',
							height: '75%',
							overflowX: 'hidden',
							width: '100%',
							display: foundPatientSearchResults ? 'none' : 'initial',
						}}
					>
						<form>
							<Stack
								spacing={2}
								sx={{
									width: '100%',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Controller
									required
									control={control}
									name="firstName"
									render={({ field }) => (
										<TextField
											{...field}
											autoFocus
											fullWidth
											autoComplete="off"
											error={!!errors.firstName}
											helperText={t('Required')}
											label={t('firstName')}
											sx={{
												marginTop: '10px',
												width: '90%',
											}}
											testid="emergencySearchFirstName"
										/>
									)}
								/>
								<Controller
									required
									control={control}
									name="lastName"
									render={({ field }) => (
										<TextField
											{...field}
											fullWidth
											autoComplete="off"
											error={!!errors.lastName}
											helperText={t('Required')}
											label={t('lastName')}
											sx={{
												width: '90%',
											}}
											testid="emergencySearchLastName"
										/>
									)}
								/>
							</Stack>
							<Stack
								spacing={1}
								sx={{
									width: '100%',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Typography
									sx={{
										width: '92%',
										fontSize: '16px',
										fontWeight: 500,
										lineHeight: '19px',
										letterSpacing: '0.1em',
										textAlign: 'left',
										paddingLeft: '9px',
										marginTop: '15px',
									}}
								>
									{t('optionsTitle')}
								</Typography>
								<FormControl sx={{ width: '90%' }}>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										name="selectedOption"
										//value={selectedOption}
										onChange={e => setSelectedOption(parseInt(e.target.value, 10))}
									>
										<RadioOption label={t('dateOfBirth')} value={1} />
										<Controller
											required
											control={control}
											name="dateOfBirth"
											render={({ field }) => (
												<LocalizationProvider dateAdapter={AdapterDateFns}>
													<DatePicker
														{...field}
														disableFuture
														error={!!errors.dateOfBirth}
														label={t('dateOfBirth')}
														renderInput={params => (
															<TextField
																{...params}
																helperText={t('Required')}
																inputRef={input => {
																	if (
																		input != null &&
																		document.activeElement.getAttribute('name') !==
																			'firstName' &&
																		document.activeElement.getAttribute('name') !==
																			'lastName'
																	) {
																		input.focus();
																	}
																}}
																name="dateOfBirth"
																sx={{
																	transition: 'all cubic-bezier(0.4, 0, 0.2, 1) 0.5s',
																	opacity: selectedOption === 1 ? '1' : '0',
																	display: selectedOption === 1 ? 'initial' : 'none',
																	height: selectedOption === 1 ? '80px' : '0px',
																}}
															/>
														)}
														testid="emergencySearchDateOfBirth"
														value={dateOfBirth || null}
														onChange={val => setValue('dateOfBirth', val)}
													/>
												</LocalizationProvider>
											)}
										/>
										<RadioOption label={t('phoneNumber')} value={2} />
										<Controller
											required
											control={control}
											name="phoneNumber"
											render={({ field }) => (
												<MuiTelInput
													{...field}
													fullWidth
													MenuProps={{
														sx: {
															maxHeight: '400px',
														},
													}}
													data-cy="patient-telecom-es"
													data-testid="es-patient-phone"
													error={!!errors.phoneNumber}
													helperText={t('Required')}
													inputRef={input => {
														if (
															input != null &&
															document.activeElement.getAttribute('name') !==
																'firstName' &&
															document.activeElement.getAttribute('name') !== 'lastName'
														) {
															input.focus();
														}
													}}
													sx={{
														transition: 'all cubic-bezier(0.4, 0, 0.2, 1) 0.5s',
														opacity: selectedOption === 2 ? '1' : '0',
														display: selectedOption === 2 ? 'initial' : 'none',
														height: selectedOption === 2 ? '80px' : '0px',
														width: '100%',
													}}
												/>
											)}
										/>
										<RadioOption label={t('emailAddress')} value={3} />
										<Controller
											required
											control={control}
											name="emailAddress"
											render={({ field }) => (
												<TextField
													{...field}
													fullWidth
													autoComplete="off"
													error={!!errors.emailAddress}
													helperText={t('Required')}
													inputRef={input => {
														if (
															input != null &&
															document.activeElement.getAttribute('name') !==
																'firstName' &&
															document.activeElement.getAttribute('name') !== 'lastName'
														) {
															input.focus();
														}
													}}
													label={t('emailAddress')}
													sx={{
														transition: 'all cubic-bezier(0.4, 0, 0.2, 1) 0.5s',
														opacity: selectedOption === 3 ? '1' : '0',
														display: selectedOption === 3 ? 'initial' : 'none',
														height: selectedOption === 3 ? '80px' : '0',
													}}
													testid="emergencySearchEmailAddress"
												/>
											)}
										/>
									</RadioGroup>
								</FormControl>
							</Stack>
						</form>
					</Box>
					{!foundPatientSearchResults && <CancelContinueButtons />}
					<Box
						sx={{
							width: '100%',
							height: '100%',
							position: 'absolute',
							transition: 'transform cubic-bezier(0.4, 0, 0.2, 1) 0.5s',
							transform: foundPatientSearchResults ? 'translate(0px, 0px)' : 'translate(550px, 0px)',
						}}
					>
						{(foundPatientSearchResults?.length || 0) > 1 && (
							<WarningBox
								icon={{
									component: InfoIcon,
									color: '#3CA3F5',
								}}
								text={t('info.text')}
								title={t('info.title')}
							/>
						)}
						{foundPatientSearchResults &&
							foundPatientSearchResults.length > 1 &&
							foundPatientSearchResults.map(patient => (
								<Box
									key={patient?.id}
									sx={{
										display: 'flex',
										padding: '15px',
										borderRadius: '8px',
										border: 'none',
										background: '#444343',
										width: 'auto',
										alignItems: 'center',
										margin: '13px',
										cursor: 'pointer',
									}}
									onClick={() => goToPatient(patient)}
								>
									<ListItemAvatar>
										<div
											data-testid="check-btn"
											id="avatarWrapper"
											style={{ position: 'relative' }}
										>
											<Avatar
												sx={{
													bgcolor: '#B96877',
													width: '37.5px',
													height: '37.5px',
												}}
											>
												<AssignmentIndOutlinedIcon sx={{ color: '#fff' }} />
											</Avatar>
										</div>
									</ListItemAvatar>
									<ListItemText
										// ref={itemRef}
										primary={
											<Tooltip title={`${patient?.name} - ${patient?.id}`}>
												<span
													style={{
														display: 'block',
														color: '#ddd',
														fontWeight: '300px',
														whiteSpace: 'nowrap',
														textOverflow: 'ellipsis',
														overflow: 'hidden',
													}}
												>
													{patient?.name} - {patient?.id}
												</span>
											</Tooltip>
										}
										secondary={
											<Tooltip title={`${patient?.birthDate} - ${patient?.managingOrganization}`}>
												<span
													style={{
														display: 'block',
														color: '#ddd',
														fontWeight: '300px',
														whiteSpace: 'nowrap',
														textOverflow: 'ellipsis',
														overflow: 'hidden',
													}}
												>
													{patient?.birthDate} - {patient?.managingOrganization}
												</span>
											</Tooltip>
										}
									/>
									<IconButton>
										<ChevronRightOutlinedIcon />
									</IconButton>
								</Box>
							))}
						{foundPatientSearchResults?.length === 0 && (
							<Box
								gap={1}
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center',
									paddingTop: '50px',
									height: '90%',
								}}
							>
								<Typography sx={{ marginTop: 5 }} variant="h6">
									{t('noResults.title')}
								</Typography>
								<Typography
									color="#ccc"
									maxWidth={300}
									sx={{ maxWidth: '300px', textAlign: 'center' }}
									textAlign="center"
									variant="body2"
								>
									{t('noResults.text')}
								</Typography>
								<PrimaryButton
									label={t('tryAgain')}
									sx={{ marginTop: 5 }}
									onClick={() => setFoundPatientSearchResults(null)}
								/>
							</Box>
						)}
					</Box>
				</Stack>
			</GenericDrawer>
		</>
	);
};
EmergencySearchDrawer.propTypes = {
	/**
	 * State getter boolean to check drawer opening status.
	 */
	drawerOpen: PropTypes.bool.isRequired,

	/**
	 * State setter callback function to open or close drawer.
	 */
	setDrawerOpen: PropTypes.func.isRequired,
};
export default EmergencySearchDrawer;
