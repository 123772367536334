import React from 'react';
import classnames from 'classnames';
import RsGrid from '@rs-ui/components/Grid';
import { searchScopes } from '@rs-core/context/consts/searchScopes';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AuditLogGrid = ({ className, name, columns, onColumnOrderChange, filters, setFilters, sort, setSort }) => {
	const { t } = useTranslation('logElk');

	return (
		<RsGrid
			/* 	technically redundant to call classnames right now, but makes
					it apparent in the future that the classes can be expanded 	*/
			className={classnames(className)}
			columns={columns}
			enableRowClick={false}
			enableRowDnd={false}
			filters={filters}
			name={name}
			scope={searchScopes.logsElk}
			setFilters={setFilters}
			setSort={setSort}
			sort={sort}
			title={t('Audit Log')}
			onColumnOrderChange={onColumnOrderChange}
		/>
	);
};

AuditLogGrid.propTypes = {
	/**
	 * A list of column names to be displayed in the grid: ['column1', 'column2', 'column2']
	 */
	columns: PropTypes.arrayOf(PropTypes.string),
	/**
	 * Callback function when column order has been changed by dragging
	 */
	onColumnOrderChange: PropTypes.func,
	/**
	 * Array of filter objects
	 */
	filters: PropTypes.arrayOf(PropTypes.object),
	/**
	 * Setter function for filters
	 */
	setFilters: PropTypes.func,
};

export default AuditLogGrid;
