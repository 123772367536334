import React from 'react';
import { Box } from '@mui/material';
import Chat from '@worklist-2/ui/src/components/Chat';
import { useTranslation } from 'react-i18next';
import AppointmentDrawerStickyTop, { APPOINTMENT_DRAWER_STICKY_TOP_HEIGHT } from './AppointmentDrawerStickyTop';

const ChatDrawerContent = () => {
	const { t } = useTranslation('chat');

	return (
		<Box
			sx={{
				'--app-drawer-header-height': `${APPOINTMENT_DRAWER_STICKY_TOP_HEIGHT}px`,

				display: 'grid',
				gridTemplateRows: 'var(--app-drawer-header-height) calc(100vh - var(--app-drawer-header-height))',
			}}
		>
			<AppointmentDrawerStickyTop title={t('CHAT')} />
			<Chat />
		</Box>
	);
};

export default ChatDrawerContent;
