import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useIsTablet, useIsMobile } from '@rs-core/utils/responsiveUtils';
import { useAuth } from '@rs-core/context/UserAuthContext';
import { useConfig } from '@rs-core/context/ConfigContext';
import { useShareDrawerStore } from '@worklist-2/patientPortal/src/stores/share-drawer';
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';

import MobileBottomDrawer from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import MobileShareOptionsList from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileShare/MobileShareOptionsList';
import getPatientId from '@worklist-2/patientPortal/src/utils/getPatientId';
import MobileListFloatingIcon from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileList/MobileListFloatingIcon';
import NewCheckIcon from './CheckIcon';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const ShareContactListItem = ({
	color = 'rgba(18, 18, 18, 0.6)',
	background = '#FAFAFA',
	border = '1px solid rgba(18, 18, 18, 0.1)',
	sxListItem,
	sxListItemText = {},
	isFacility,
	showSelectCircle,
	isShared,
	isContact,
	item,
}) => {
	const __config = useConfig();
	const { profiles, patientMapping, setContacts } = useAuth();
	const studyId = useShareDrawerStore(state => state.studyId);
	const documentId = useShareDrawerStore(state => state.documentId);
	const user = useShareDrawerStore(state => state.user);
	const unshareStudy = useShareDrawerStore(state => state.unshareStudy);
	const unshareStudyWithFacility = useShareDrawerStore(state => state.unshareStudyWithFacility);
	const unshareDocument = useShareDrawerStore(state => state.unshareDocument);
	const { setToastMsg } = useToastMessageContext();
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedShareOptionUser, setSelectedShareOptionUser] = useState(null);
	const phoenixBlumeRestrictHomeScreenFunctionality = useBooleanFlagValue(
		'phoenix-blume-restrict-home-screen-functionality'
	);

	const { t } = useTranslation('profile');
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const handleOptionClick = useCallback(
		async (option, email) => {
			try {
				// Unshare option in dropdown
				if (option.toLowerCase() === 'unshare') {
					// Unshare study
					if (studyId) {
						await unshareStudy({ __config, studyId, email: selectedShareOptionUser?.email });

						setContacts(prevState =>
							prevState.map(contact => {
								if (contact.email === selectedShareOptionUser?.email) {
									return {
										...contact,
										sharedStudiesByMe: contact.sharedStudiesByMe.filter(id => id !== studyId),
									};
								}

								return contact;
							})
						);

						setToastMsg(`${selectedShareOptionUser?.name} ${t('has been removed from the shared study')}`);
					}

					// Unshare document
					if (documentId) {
						await unshareDocument({ __config, documentId, email });

						setToastMsg(
							`${selectedShareOptionUser?.name} ${t('has been removed from the shared documents')}`
						);
					}
				}

				handleClose();
				setSelectedShareOptionUser(null);
				setToastMsg(`${selectedShareOptionUser?.name} ${t('permission has been changed to')} ${t(option)}`);
			} catch (err) {
				console.error(err);
			}
		},
		[
			__config,
			documentId,
			handleClose,
			selectedShareOptionUser?.email,
			selectedShareOptionUser?.name,
			setContacts,
			setToastMsg,
			studyId,
			t,
			unshareDocument,
			unshareStudy,
		]
	);

	const onShareMenuClick = useCallback(
		(e, usr) => {
			e.stopPropagation();

			if (!isMobile) {
				setAnchorEl(e.currentTarget);
			}

			setSelectedShareOptionUser(usr);
		},
		[isMobile]
	);

	const handleUnshare = async () => {
		if (documentId) {
			await unshareDocument({ __config, documentId, email: item?.id, isFacility: true });
		} else {
			await unshareStudyWithFacility({
				__config,
				internalManagingOrgId: item.id,
				studyId,
				patientId: getPatientId({
					patientMapping,
					managingOrgId: item.id,
					profiles,
					profileId: user.profileId,
				}),
			});
		}
	};

	return (
		<>
			<ListItem
				disablePadding
				data-cy={`share-contact-list-item${isShared ? '-shared' : ''}`}
				data-testid="share-contact-list-item-list"
				secondaryAction={
					<div className="secondaryActionSection">
						{phoenixBlumeRestrictHomeScreenFunctionality && (item.selected || isShared) && (
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								{((!isContact && !isFacility) || isContact) && (
									<IconButton
										sx={{ marginRight: '-18px' }}
										onClick={e => onShareMenuClick(e, item.user)}
									>
										<Icon component={KeyboardArrowDownIcon} />
									</IconButton>
								)}
							</Box>
						)}
						{item.selected && !item.selectedFloating && (
							<Icon component={CheckCircleIcon} sx={{ color: '#42A5F5', fontSize: '16px' }} />
						)}
						{item?.isFacility && isShared && phoenixBlumeRestrictHomeScreenFunctionality && (
							<Button
								color="rsPrimary"
								size="small"
								sx={{
									position: 'absolute',
									fontSize: '12px',
									right: '-10px',
									top: '50%',
									transform: 'translateY(-50%)',
								}}
								variant="contained"
								onClick={handleUnshare}
							>
								{t('Unshare')}
							</Button>
						)}
						{!item.selected && !isMobile && !isTablet && !isFacility && !isShared && (
							<Box sx={{ display: 'flex', flexDirection: 'row' }}>
								{showSelectCircle && (
									<Box
										className="ExpandableSectionButton"
										sx={{
											backgroundColor: '#FFF9F9',
											color: '#FFF9F9',
											border: '2px dotted #42A5F5',
											borderRadius: '50%',
											boxSizing: 'border-box',
											width: '15px',
											height: '15px',
											visibility: 'hidden',
											marginRight: '-10px',
											marginTop: '-10px',
											cursor: 'pointer',
										}}
										onClick={item.onClick}
									/>
								)}
							</Box>
						)}
					</div>
				}
				selected={item.selected}
				sx={{
					border,
					borderRadius: '6px',
					background,
					marginBottom: '6px',
					position: 'relative',

					'&.Mui-selected': {
						border: '1px solid #4BAAF6',

						'&:hover .ExpandableSectionButton': { visibility: 'hidden' },
					},

					...sxListItem,
					...item.sx,
				}}
			>
				{item.selected && item.selectedFloating && <MobileListFloatingIcon />}
				<ListItemButton
					component={item.href ? 'a' : undefined}
					disabled={item.primary === 'Remove'}
					href={item.href}
					sx={{ width: '100%' }}
					target={item?.target}
					onClick={item.onClick}
				>
					{item.avatar && (
						<ListItemAvatar>
							<Avatar {...item.avatar} />
						</ListItemAvatar>
					)}
					{isFacility && (
						<Box
							sx={{
								height: '40px',
								width: '40px',
								backgroundColor: '#7566CF',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: '4px',
								marginRight: '15px',
							}}
						>
							<Icon component={CorporateFareIcon} sx={{ color: 'white', fontSize: '26px' }} />
						</Box>
					)}
					{item.icon && (
						<ListItemIcon sx={{ minWidth: '44px' }}>
							<Icon component={item.icon} sx={{ color, fontSize: '26px' }} />
						</ListItemIcon>
					)}
					<ListItemText
						primary={
							<Tooltip title={t(item.primary)}>
								<Typography
									sx={{
										fontSize: '14px',
										overflow: 'hidden',
										fontWeight: '700',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										width: '15rem',
										...sxListItemText,
									}}
								>
									{t(item.primary)}
								</Typography>
							</Tooltip>
						}
						secondary={
							item.secondary && (
								<Tooltip title={item.secondary}>
									<Typography
										noWrap
										sx={{
											fontSize: '12px',
											overflow: 'hidden',
											width: isFacility ? '180px' : '100%',
										}}
									>
										{item.secondary}
									</Typography>
								</Tooltip>
							)
						}
						sx={{ maxWidth: '200px', overflow: 'hidden' }}
					/>
				</ListItemButton>
			</ListItem>
			{/* Options (Unshare) */}
			{phoenixBlumeRestrictHomeScreenFunctionality &&
				(isMobile ? (
					<MobileBottomDrawer
						background="#ffffff"
						open={Boolean(selectedShareOptionUser)}
						onClose={() => setSelectedShareOptionUser(null)}
					>
						<MobileShareOptionsList isSharedStudy={isShared} user={item.user} onClick={handleOptionClick} />
					</MobileBottomDrawer>
				) : (
					<Menu
						anchorEl={anchorEl}
						open={!!anchorEl}
						sx={{
							'& .MuiPaper-root': { boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '10px' },
							'& .MuiList-root': { padding: '0' },
							'& .MuiMenuItem-root': { fontSize: '12px' },
						}}
						onClose={handleClose}
					>
						{/* Unshare button */}
						{isShared && (
							<MenuItem
								sx={{ borderTop: '1px solid rgba(18, 18, 18, 0.1)', justifyContent: 'center' }}
								onClick={() => handleOptionClick('Unshare', item.user?.email)}
							>
								{selectedShareOptionUser?.shareType === 'Contact' && <NewCheckIcon />}
								{t('Unshare')}
							</MenuItem>
						)}
					</Menu>
				))}
		</>
	);
};

export default ShareContactListItem;
