import { parseName } from '@rs-core/fhir/resource/columnMapping/utils/getUserFullName';

export const getPatientName = (patientMapping, patientId) => {
	let name;
	patientMapping?.forEach(patient => {
		if (patient?.patientId === patientId) {
			const dicomSourceName = `${patient?.firstName ? `${patient.firstName.trim()}^` : ''}${
				patient?.middleName ? `${patient.middleName.trim()}^` : ''
			}${patient?.lastName ? patient.lastName.trim() : ''}`;

			name = parseName(dicomSourceName, false, 'DICOM');
		}
	});

	return name;
};

export const isStudyEmpty = study => Boolean(!study?.studyThumbnail?.seriesUID && !study?.reports[0]);

export const fetchForms = async (__config, getFormsByPatientId, study, setForms, setShowFormIcon) => {
	const result = await getFormsByPatientId({ __config, patientId: study?.patientId });
	const filteredForms = result?.filter(
		item =>
			item.resource?.type === 'registration' ||
			(item.resource?.type === 'clinic' && item?.resource?.study?.id === study.id)
	);

	const transformedForms = filteredForms?.map(item => ({
		id: item.resource.id,
		patient: item.resource?.patient,
		completed: item.resource?.completed,
		organizationId: item.resource.organizationId,
		status: item.resource.status.charAt(0).toUpperCase() + item.resource.status.slice(1),
		title: item.resource.name,
		type: item.resource.type,
		description: item.resource.description,
		questionData: item.resource.formTemplate?.content,
		lastModified: item.resource.lastModified,
		resource: item.resource,
		referenceTemplate: item.resource?.referenceTemplate,
		pdfFileName: item.resource.pdfFileName,
	}));

	if (transformedForms?.length > 0) {
		if (transformedForms?.filter(form => form?.completed !== true).length === 0) {
			//HAVE SOME PENDING FORMS
		}
		setShowFormIcon(true);
	}
	setForms(transformedForms);
};
