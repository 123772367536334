import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { v4 as uuid } from 'uuid';
import getDefaultRenderingEngine from '../cornerstone/getDefaultRenderingEngine';
import { ViewportType } from '@cornerstonejs/core/dist/esm/enums';
import { ToolGroupManager, utilities, utilities as toolsUtilities } from '@cornerstonejs/tools';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Enums, eventTarget } from '@cornerstonejs/core';
import { useBooleanFlagValue, useNumberFlagValue } from '@rs-core/hooks/useFlags';

import { toolGroupId } from './toolGroup';
import { renderingEngineId } from '../contexts/ImageViewerCornerstoneContext';
import FrameNumber from './FrameNumber';
import { getFPS } from '../ImageViewerLayoutItem';
import { useFrames } from './useFrames';
import { useImageViewerExternalContext } from './ImageViewerExternalContext';

const { ELEMENT_DISABLED } = Enums.Events;

const ImageViewerExternalViewportCornerstone = ({ series, viewport, isMobile }) => {
	const isCine =
		series.metadata?.[0]?.['00280008']?.Value?.[0] && series.metadata?.[0]?.['00080060']?.Value?.[0] === 'US';
	const FPS = getFPS(series.metadata?.[0]);

	const [initialized, setInitialized] = useState(false);
	const [, setIsCineActive] = useState(isCine);

	const wonIvExternalSwipeEnhancement = useBooleanFlagValue('WON-IV-EXTERNAL-SWIPE-ENHANCEMENT');
	const wonIvMaxNumRequests = useNumberFlagValue('won-iv-maxnumrequests');

	const { series: allSeries, setViewport } = useImageViewerExternalContext();

	const goToPrevSeries = useCallback(() => {
		const activeSeriesIndex = allSeries.findIndex(s => s.uniqueId === series?.uniqueId);
		let prevSeries;
		if (activeSeriesIndex > 0) {
			prevSeries = allSeries[activeSeriesIndex - 1];
			setViewport({
				id: uuid(),
				seriesUniqueId: prevSeries.uniqueId,
				initialInstanceIndex: 0,
			});
		}
	}, [allSeries, series?.uniqueId, setViewport]);

	const goToNextSeries = useCallback(() => {
		const activeSeriesIndex = allSeries.findIndex(s => s.uniqueId === series?.uniqueId);
		let nextSeries;
		if (activeSeriesIndex < allSeries.length && activeSeriesIndex !== -1) {
			nextSeries = allSeries[activeSeriesIndex + 1];
			setViewport({
				id: uuid(),
				seriesUniqueId: nextSeries.uniqueId,
				initialInstanceIndex: 0,
			});
		}
	}, [allSeries, series?.uniqueId, setViewport]);

	const handlers = useSwipeable({
		onSwipedLeft: () => {
			if (wonIvExternalSwipeEnhancement) {
				goToNextSeries();
			}
		},
		onSwipedRight: () => {
			if (wonIvExternalSwipeEnhancement) {
				goToPrevSeries();
			}
		},
		trackMouse: true,
	});

	const { currentFrame, shouldRender } = useFrames({
		viewportId: viewport.id,
		isInitialized: initialized,
	});

	const resizeObserverRef = useRef(
		new ResizeObserver(() => {
			const renderingEngine = getDefaultRenderingEngine();

			if (!renderingEngine) {
				return;
			}

			renderingEngine.resize();
		})
	);

	useEffect(() => {
		const element = document.getElementById(viewport.id);

		if (!element) {
			return;
		}

		const enabledElement = async () => {
			const renderingEngine = getDefaultRenderingEngine();

			if (!renderingEngine) {
				return;
			}

			renderingEngine.enableElement({
				viewportId: viewport.id,
				element,
				type: ViewportType.STACK,
			});

			resizeObserverRef.current.observe(element);

			const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);

			if (toolGroup) {
				toolGroup.addViewport(viewport.id, renderingEngine.id);
			}

			const cornerstoneViewport = renderingEngine.getViewport(viewport.id);

			await cornerstoneViewport.setStack(series.imageIds, viewport.initialInstanceIndex || 0);

			const stackPrefetchConfig = {
				preserveExistingPool: false,
			};

			// if the ff is off, fallback to the older implementation
			// which is to prefetch 20 images at max.
			if (wonIvMaxNumRequests === -1) {
				stackPrefetchConfig.maxImagesToPrefetch = 20;
			}

			toolsUtilities.stackPrefetch.setConfiguration(stackPrefetchConfig);
			toolsUtilities.stackPrefetch.enable(element);

			if (isCine) {
				utilities.cine.playClip(element, { framesPerSecond: parseInt(FPS), waitForRendered: true });
			}

			const properties = cornerstoneViewport.getProperties();

			cornerstoneViewport.setProperties(properties);

			cornerstoneViewport.render();

			setInitialized(true);
		};

		const onElementDisabled = e => {
			const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);

			if (!toolGroup) {
				return;
			}

			const { viewportId, element } = e.detail;

			if (element) {
				try {
					toolsUtilities.cine.stopClip(element);

					toolsUtilities.stackPrefetch.disable(element);
				} catch (error) {
					console.error(error);
				}
			}

			toolGroup.removeViewports(renderingEngineId, viewportId);
		};

		const onClick = () => {
			if (!isCine) {
				return;
			}

			setIsCineActive(prevState => {
				if (prevState) {
					toolsUtilities.cine.stopClip(element);
				} else {
					utilities.cine.playClip(element, { framesPerSecond: parseInt(FPS) });
				}

				return !prevState;
			});
		};

		enabledElement();

		element?.addEventListener('CORNERSTONE_TOOLS_MOUSE_CLICK', onClick);
		element?.addEventListener('CORNERSTONE_TOOLS_TAP', onClick);

		eventTarget.addEventListener(ELEMENT_DISABLED, onElementDisabled);

		return () => {
			element?.removeEventListener('CORNERSTONE_TOOLS_MOUSE_CLICK', onClick);
			element?.removeEventListener('CORNERSTONE_TOOLS_TAP', onClick);

			const renderingEngine = getDefaultRenderingEngine();

			if (!renderingEngine) {
				return;
			}

			renderingEngine.disableElement(viewport.id);

			eventTarget.removeEventListener(ELEMENT_DISABLED, onElementDisabled);
		};
	}, []);

	const cornerstoneElement = useMemo(
		() => (
			<Box
				id={viewport.id}
				sx={{
					position: 'absolute',
					top: '0',
					left: '0',
					width: '100%',
					height: '100%',
				}}
				onContextMenu={e => {
					e.preventDefault();
				}}
			/>
		),
		[]
	);

	return (
		<Box
			sx={{
				position: 'relative',
				width: '100%',
				height: '100%',
			}}
			data-testid="imageviewer-external-viewport-cornerstone"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...handlers}
		>
			{cornerstoneElement}

			{initialized && <FrameNumber viewportId={viewport.id} />}

			{initialized && isMobile && shouldRender && currentFrame === 1 && (
				<ExpandMoreIcon
					sx={{
						position: 'absolute',
						bottom: '15px',
						color: '#fff',
						left: '50%',
						transform: 'translateX(-50%)',
						background: '#393939',
						borderRadius: '50%',
					}}
				/>
			)}
		</Box>
	);
};

export default ImageViewerExternalViewportCornerstone;
