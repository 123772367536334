/* eslint-disable react/prop-types */
import React from 'react';
import { getInitials } from '../utils';
import MobileList from '../MobileList/MobileList';

const MobileUsersList = ({ users = [], onClick }) => {
	const items = users
		.filter(d => Boolean(d?.name))
		.map(user => ({
			onClick: () => onClick(user),
			primary: user.name,
			avatar: {
				children: getInitials(user.name),
				variant: 'rounded',
				sx: {
					background: 'rgba(0, 0, 0, 0.6)',
				},
			},
			sx: {
				padding: '2px 0',
			},
		}));

	return <MobileList background="#FAFAFA" border="none" items={items} showCount={3} />;
};

export default MobileUsersList;
