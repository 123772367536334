import { create } from 'zustand';
import commonMiddlewares from '@rs-core/store/middlewares';

export const useProfileStore = create(
	commonMiddlewares((set, get) => ({
		cachedProfileId: null,

		setCachedProfileId: cachedProfileId =>
			set(() => ({
				cachedProfileId,
			})),
	}))
);
