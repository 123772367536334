import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, CircularProgress, Typography, Box, Stack, IconButton, Slide } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';

import { useIsTablet } from '@rs-core/utils/responsiveUtils';
import discIcon from '@worklist-2/ui/src/assets/img/homepage/disc.svg';
import { useStudiesStore } from '@worklist-2/patientPortal/src/stores';
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';
import { STUDY_BURN_TO_DISC } from '@worklist-2/patientPortal/src/analytics/eventTypes';
import sendAnalyticsEvent from '@worklist-2/patientPortal/src/analytics';

const BurnToDisc = ({ study, setMoreDrawerOpen }) => {
	const downloadStudyDiscBurner = useStudiesStore(state => state.downloadDiskBurner);
	const progressMBs = useStudiesStore(
		useShallow(state => state.studyDiscBurnerDownloadStatus?.[study?.id]?.progress)
	);
	const isLoading = useStudiesStore(useShallow(state => state.studyDiscBurnerDownloadStatus?.[study?.id]?.loading));
	const { setToastMsg } = useToastMessageContext();

	const { t } = useTranslation('homepage');
	const isTablet = useIsTablet();

	const disabled = !study?.studyThumbnail?.seriesUID;

	const handleDownloadDiscBurner = useCallback(async () => {
		await downloadStudyDiscBurner({ study, setToastMsg, t });

		setMoreDrawerOpen(false);

		sendAnalyticsEvent(STUDY_BURN_TO_DISC, { studyId: study.id, studyDescription: study.description });
	}, [downloadStudyDiscBurner, study, setToastMsg, t, setMoreDrawerOpen]);

	if (isTablet) {
		return (
			<Stack
				alignItems="center"
				direction="row"
				justifyContent="space-between"
				sx={{ display: disabled ? 'none' : 'flex', height: '35px' }}
				onClick={handleDownloadDiscBurner}
			>
				<Typography color="var(--color-primary)" fontSize={16} sx={{ display: 'flex', alignItems: 'center' }}>
					<Icon component={discIcon} sx={{ mr: 1 }} />
					{t('Burn to disc')}
				</Typography>
				<Slide mountOnEnter unmountOnExit direction="down" in={isLoading}>
					<IconButton sx={{ fontSize: '16px' }}>
						<Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', fontFamily: 'Roboto' }}>
							<CircularProgress color="info" size={16} />
							&#40;{progressMBs || 0}MB&#41;
						</Box>
					</IconButton>
				</Slide>
			</Stack>
		);
	}

	return (
		<Box sx={{ overflow: 'hidden' }}>
			{isLoading ? (
				<Box
					sx={{
						display: 'flex',
						gap: '10px',
						alignItems: 'center',
						fontFamily: 'Roboto',
						margin: '10px 0 0 0',
					}}
				>
					<CircularProgress color="info" size={16} />
					&#40;{progressMBs || 0}MB&#41;
				</Box>
			) : (
				<Typography
					alignItems="center"
					display="flex"
					my={1}
					opacity={0.6}
					sx={{ cursor: 'pointer' }}
					onClick={handleDownloadDiscBurner}
				>
					<Icon component={discIcon} sx={{ height: '20px' }} />
					{t('Burn to disc')}
				</Typography>
			)}
		</Box>
	);
};

export default BurnToDisc;
