import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInYears from 'date-fns/differenceInYears';
import { isValidDateMapping } from '@rs-ui/components/utils/dateUtils';

const calculatePatientAgeAtStudy = resource => {
	if (!resource) {
		return;
	}

	let ageAtStudy = resource?.age || 0; // Age in years
	if (ageAtStudy >= 2) {
		return `${ageAtStudy}y`;
	}

	if (ageAtStudy >= 0 && ageAtStudy < 2) {
		if (
			!resource?.started ||
			!resource?.birthDate ||
			!isValidDateMapping(new Date(resource?.started)) ||
			!isValidDateMapping(new Date(resource?.birthDate))
		) {
			return;
		}

		// Exclude time from Study Date Time because birthDate is Date type
		const studyDate = new Date(resource.started.split('T')[0]);
		const birthDate = new Date(resource.birthDate);

		if (studyDate.toDateString() === birthDate.toDateString()) {
			return '1d';
		}

		// Years, Months, Days
		const ageInYears = differenceInYears(studyDate, birthDate);
		const ageInMonths = differenceInMonths(studyDate, birthDate);
		const ageInDays = differenceInDays(studyDate, birthDate);

		// Show negative age if Study Date is before Birth Date
		if (ageInYears < -1) {
			return `${ageInYears}y`;
		}

		if (ageInMonths < 0) {
			return `${ageInMonths}m`;
		}

		if (ageInDays < 0) {
			return ageInDays === 30 ? '-1m' : `${ageInDays}d`;
		}

		// Show age in months if age is greater than 1 month
		if (ageInMonths >= 1) {
			return `${ageInMonths}m`;
		}

		if (ageInDays === 30) {
			// This is where a month has 31 days, ex: studyDate = Dec 08 2023, birthDate = Jan 07 2024
			// differenceInMonths = 0 and ageInDays = 30. It does not make sense to show 30d in this case -> show 1m
			return '1m';
		}

		if (ageInDays > 0) {
			return `${ageInDays}d`;
		}
	}

	return ageAtStudy;
};

export default calculatePatientAgeAtStudy;
