/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import {
	AppBar,
	Avatar,
	Badge,
	Button,
	Icon,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Toolbar,
	Typography,
} from '@mui/material';
import { Add, Search, Menu } from '@mui/icons-material';
import { Box } from '@mui/system';
import { AppointmentGenericBottomDrawer } from '../Appointment/AppointmentDrawer/AppointmentDrawerButtonBook';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { useAppointmentContext } from '../Appointment/AppointmentContext/AppointmentContext';
// import star from '@worklist-2/ui/src/assets/img/homepage/star.svg';
import chat from '@worklist-2/ui/src/assets/img/homepage/chat.svg';
import userIcon from '@worklist-2/ui/src/assets/img/homepage/user.png';
import { useDrawersStore, useUserStore } from '../../stores';
import ProfileMenu from './ProfileMenu';

import { useIsTablet, mediaTabletOnly } from '@rs-core/utils/responsiveUtils';
import { useAuth } from '@rs-core/context/UserAuthContext';
import { useConfig } from '@rs-core/context/ConfigContext';
import MobileBottomDrawer from '../MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import SearchBar from '@worklist-2/ui/src/components/SearchBar';
import Notifications from '../Notifications';
import BlumeAssistant from '../BlumeAssistant';

import sendAnalyticsEvent from '../../analytics';
import { USER_SEARCH } from '../../analytics/eventTypes';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useIsMobile } from '@rs-core/utils';
import aiStar from '@worklist-2/ui/src/assets/img/homepage/ai_star.svg';
import { parseName } from '@rs-core/fhir/resource/columnMapping/utils/getUserFullName';
import getFhirSourceNameFromPatient from '@worklist-2/patientPortal/src/utils/getFhirSourceNameFromPatient';
import { handleBlumeLogout } from '@worklist-2/patientPortal/src/views/NewSettingsView/Sections/Settings/Logout/Logout';

const BlumeHeaderV2 = ({ toggleSidebar }) => {
	const {
		isOpen,
		setIsOpen,
		oldAppointmentOpen: openDrawer,
		setOldAppointmentOpen: setOpenDrawer,
	} = useAppointmentContext();
	const { t } = useTranslation(['homepage', 'appointments']);
	const phoenixBlumeDisableChat = useBooleanFlagValue('phoenix-blume-disable-chat');
	const phoenixExpandedBookAppointmentDrawer = useBooleanFlagValue('phoenix-expanded-book-appointment-drawer');
	const phoenixWebLogoutProfileAvatar = useBooleanFlagValue('phoenix-web-logout-to-profile-avatar');
	const { primaryUser, user } = useUserStore(state => ({ primaryUser: state.primaryUser, user: state.user }));
	const { notificationReceived, resetAndLogOut } = useAuth();
	const [hidePlaceholder, setHidePlaceholder] = useState(false);
	const [profileMenuOpen, setProfileMenuOpen] = useState(false);
	const __config = useConfig();
	const navigate = useNavigate();
	const {
		searchInput,
		setSearchInput,
		setSearchText,
		setHomeFilterOpen,
		setMobileFilterDrawerOpen,
		mobileSearchDrawerOpen,
		setMobileSearchDrawerOpen,
	} = useDrawersStore(state => ({
		searchInput: state.searchInput,
		setSearchInput: state.setSearchInput,
		setSearchText: state.setSearchText,
		setHomeFilterOpen: state.setHomeFilterOpen,
		setMobileFilterDrawerOpen: state.setMobileFilterDrawerOpen,
		mobileSearchDrawerOpen: state.mobileSearchDrawerOpen,
		setMobileSearchDrawerOpen: state.setMobileSearchDrawerOpen,
	}));
	const [, setSearchParams] = useSearchParams();

	const isTablet = useIsTablet();
	const isMobile = useIsMobile();
	const { pathname, key } = useLocation();
	const hidden = pathname.startsWith('/external-form');
	const searchInputRef = useRef();

	const SEARCH_INPUT_DELAY = 500;

	useEffect(() => {
		const interval = setInterval(() => {
			setHidePlaceholder(prev => !prev);
		}, 4000);
		return () => clearInterval(interval);
	}, []);

	const getChildren = useCallback(
		() => <SearchBar placeholder="All" setOpenDrawer={setMobileSearchDrawerOpen} />,
		[]
	);

	const getNotifications = useCallback(
		() => (
			<Box sx={{ [mediaTabletOnly]: { padding: '0px 16px 0px 16px' } }}>
				<Notifications setDrawerOpen={setIsOpen} />
			</Box>
		),
		[]
	);

	const getBlumeAssistant = useCallback(
		() => (
			<Box sx={{ [mediaTabletOnly]: { padding: '0px 16px 0px 16px' } }}>
				<BlumeAssistant />
			</Box>
		),
		[]
	);

	const debouncedSearch = useCallback(
		_.debounce(input => {
			setSearchText(input);
			if (input) sendAnalyticsEvent(USER_SEARCH, { searchText: input });
		}, SEARCH_INPUT_DELAY),
		[]
	);

	const primaryUserFhirName = useMemo(() => {
		if (!primaryUser) {
			return '';
		}

		const fhirSourceName = getFhirSourceNameFromPatient({
			firstName: primaryUser.firstName,
			lastName: primaryUser.lastName,
			middleName: primaryUser.middleName,
		});

		return parseName(fhirSourceName);
	}, [primaryUser]);

	useEffect(() => {
		debouncedSearch(searchInput);
	}, [debouncedSearch, searchInput]);

	useEffect(() => {
		if (isOpen) setIsOpen(null);
	}, [key]);

	const handleBookAppointment = () => {
		if (phoenixExpandedBookAppointmentDrawer) {
			navigate('/book-appointment');
		} else {
			setHomeFilterOpen(false);
			setOpenDrawer(true);
		}
	};

	return (
		<>
			<AppBar
				color="inherit"
				elevation={0}
				position="static"
				sx={{
					height: isTablet ? '60px' : '80px',
					background: 'white',
					zIndex: isTablet ? 1299 : 9,
					position: 'sticky',
					top: 0,
					display: hidden ? 'none' : 'flex',
				}}
			>
				<Toolbar
					sx={{ height: '100%', borderBottom: '1px solid #EAECF0', minHeight: isTablet ? '60px' : '80px' }}
				>
					{isTablet ? (
						<IconButton onClick={toggleSidebar}>
							<Menu sx={{ color: '#053A65' }} />
						</IconButton>
					) : (
						<TextField
							InputProps={{
								sx: {
									height: '40px',
									borderRadius: '8px',
									'& .hide-on-focus': {
										display: searchInput ? 'none' : 'block',
									},
									'&:focus-within .hide-on-focus': {
										display: 'none',
									},
								},
								placeholder:
									!hidePlaceholder &&
									searchInputRef?.current !== document.activeElement &&
									t('Search for appointments'),
								startAdornment: (
									<Stack
										alignItems="center"
										direction="row"
										onClick={() => searchInputRef.current.focus()}
									>
										<InputAdornment position="start">
											<Search sx={{ color: '#697586' }} />
										</InputAdornment>
										{hidePlaceholder && (
											<>
												<Typography
													className="hide-on-focus"
													color="#697586"
													mr={1}
													sx={{ whiteSpace: 'nowrap' }}
												>
													{t('Ask AI')}
												</Typography>
												<Box className="hide-on-focus" mt={1}>
													<Icon component={aiStar} sx={{ height: '23px' }} />
												</Box>
											</>
										)}
									</Stack>
								),
							}}
							inputProps={{
								'data-cy': 'global-search-input',
								ref: searchInputRef,
							}}
							size="small"
							sx={{
								background: 'transparent',
								width: '350px',
								maxHeight: '40px',
								padding: '0px 5px',
								outline: 'none',
							}}
							value={searchInput}
							variant="outlined"
							onChange={e => {
								setHomeFilterOpen(false);
								setSearchInput(e.target.value);
							}}
						/>
					)}
					{/* UNCOMMENT IT BACK AFTER THE IMPLEMENTATION OF CHATGPT */}
					{/* {!isTablet && (
						<Button
							sx={{
								background: '#EBF5FE',
								color: '#42A5F5',
								height: '40px',
								borderRadius: '5px',
								mx: 1,
								textTransform: 'none',
							}}
						>
							<Icon component={star} sx={{ height: '23px' }} />
							{t('Ask AI')}
						</Button>
					)} */}
					<Box sx={{ flexGrow: 1 }} />
					<Stack alignItems="center" direction="row">
						{!isTablet && (
							<Button
								color="rsPrimary"
								data-testid="book-button"
								sx={{
									borderRadius: '8px',
									textTransform: 'none',
									fontWeight: 400,
									fontSize: '1vw',
									height: '40px',
									boxShadow: 'none',
								}}
								variant="contained"
								onClick={handleBookAppointment}
							>
								<Add
									sx={{
										height: '1.4vw',
										width: '1.4vw',
									}}
								/>{' '}
								{t('Book new appointment')}
							</Button>
						)}
						{isTablet && (
							<IconButton
								color="inherit"
								data-testid="search-icon"
								sx={STYLES.ICON_BUTTON(isTablet, !!searchInput)}
								onClick={() => {
									setMobileSearchDrawerOpen(true);
									setIsOpen(null);
									setMobileFilterDrawerOpen(false);
									setSearchParams({ from: 'global-search' });
								}}
							>
								<Icon component={Search} sx={{ fontSize: isTablet ? 20 : 22 }} />
							</IconButton>
						)}
						{!phoenixBlumeDisableChat && (
							<IconButton
								color="inherit"
								data-testid="chat-icon"
								sx={STYLES.ICON_BUTTON(isTablet, isOpen === 'Chat')}
								onClick={() => {
									setSearchText('');
									setSearchInput('');
									setHomeFilterOpen(false);
									setMobileFilterDrawerOpen(false);
									setIsOpen(isOpen === 'Chat' ? null : 'Chat');
								}}
							>
								<Icon component={chat} sx={{ fontSize: isTablet ? 20 : 22 }} />
							</IconButton>
						)}
						{/* UNCOMMENT IT BACK AFTER THE IMPLEMENTATION OF CHATGPT */}
						{/* {isTablet && (
							<IconButton
								color="inherit"
								data-testid="star-icon"
								sx={STYLES.ICON_BUTTON(isTablet, isOpen === 'Assistant')}
								onClick={() => {
									setSearchText('');
                                     setSearchInput('')
									setIsOpen(isOpen === 'Assistant' ? null : 'Assistant');
								}}
							>
								<Icon component={star} sx={{ fontSize: isTablet ? 20 : 22 }} />
							</IconButton>
						)} */}
						<Badge
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							badgeContent=""
							color={notificationReceived ? 'error' : undefined}
							sx={{ '& .MuiBadge-badge': { top: '6px', right: '6px' } }}
							variant="dot"
						>
							<IconButton
								color="inherit"
								data-testid="notifications-icon"
								sx={STYLES.ICON_BUTTON(isTablet, isOpen === 'Notifications')}
								onClick={() => {
									setSearchText('');
									setSearchInput('');
									setHomeFilterOpen(false);
									setMobileFilterDrawerOpen(false);
									setIsOpen(isOpen === 'Notifications' ? null : 'Notifications');
								}}
							>
								<svg fill="none" height="20" viewBox="0 0 20 20" width="20">
									<path
										d="M11.6667 17.5H8.33333M15 6.66663C15 5.34054 14.4732 4.06877 13.5355 3.13109C12.5978 2.19341 11.3261 1.66663 10 1.66663C8.67391 1.66663 7.40214 2.19341 6.46446 3.13109C5.52678 4.06877 5 5.34054 5 6.66663C5 9.24178 4.35039 11.0049 3.62472 12.1711C3.0126 13.1549 2.70654 13.6467 2.71777 13.7839C2.73019 13.9359 2.76238 13.9938 2.88481 14.0846C2.99538 14.1666 3.49382 14.1666 4.49071 14.1666H15.5093C16.5062 14.1666 17.0046 14.1666 17.1152 14.0846C17.2376 13.9938 17.2698 13.9359 17.2822 13.7839C17.2934 13.6467 16.9874 13.1549 16.3753 12.1711C15.6496 11.0049 15 9.24178 15 6.66663Z"
										stroke="#42A5F5"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="1.67"
									/>
								</svg>
							</IconButton>
						</Badge>
						{isTablet && (
							<IconButton
								color="inherit"
								data-testid="chat-icon"
								sx={STYLES.ICON_BUTTON(isTablet, openDrawer, true)}
								onClick={handleBookAppointment}
							>
								<Icon component={Add} sx={{ fontSize: isTablet ? 20 : 22 }} />
							</IconButton>
						)}
						<Stack
							alignItems="center"
							data-cy="sidebar-profile"
							direction="row"
							ml={isTablet ? 1.5 : 4}
							mr={isTablet ? 0 : 2}
							sx={{ cursor: 'pointer' }}
							onClick={e => {
								if (phoenixWebLogoutProfileAvatar) {
									setProfileMenuOpen(e.currentTarget);
								} else {
									setSearchText('');
									setSearchInput('');
									setHomeFilterOpen(false);
									navigate('/profile');

									// to scroll to top
									if (isMobile) window.scrollTo(0, 0);
								}
							}}
							onMouseEnter={e => phoenixWebLogoutProfileAvatar && setProfileMenuOpen(e.currentTarget)}
						>
							<Avatar
								src={userIcon}
								sx={{ mr: isTablet ? 0 : 1, height: isTablet ? 32 : 37, width: isTablet ? 32 : 37 }}
							/>
							{!isTablet && <Typography fontWeight={500}>{primaryUserFhirName}</Typography>}
						</Stack>
						<ProfileMenu
							anchor={profileMenuOpen}
							email={user?.email}
							handleLogout={() => handleBlumeLogout(__config, resetAndLogOut)}
							handleProfileRedirect={() => {
								setSearchText('');
								setSearchInput('');
								setHomeFilterOpen(false);
								navigate('/profile');
								setProfileMenuOpen(null);
								// to scroll to top
								if (isMobile) window.scrollTo(0, 0);
							}}
							name={primaryUserFhirName}
							navigateTermsPrivacy={isPrivacy => {
								navigate(`/profile/settings?legalTabValue=${isPrivacy ? 2 : 1}`);
								setProfileMenuOpen(false);
							}}
							onClose={() => setProfileMenuOpen(null)}
						/>
					</Stack>
				</Toolbar>
			</AppBar>
			{isTablet && (
				<>
					<MobileBottomDrawer
						fullHeight
						open={mobileSearchDrawerOpen}
						onClose={() => setMobileSearchDrawerOpen(false)}
					>
						{getChildren()}
					</MobileBottomDrawer>

					<MobileBottomDrawer fullHeight open={isOpen === 'Notifications'} onClose={() => setIsOpen(false)}>
						{getNotifications()}
					</MobileBottomDrawer>
					<MobileBottomDrawer fullHeight open={isOpen === 'Assistant'} onClose={() => setIsOpen(false)}>
						{getBlumeAssistant()}
					</MobileBottomDrawer>
				</>
			)}
			{openDrawer && !phoenixExpandedBookAppointmentDrawer ? (
				<AppointmentGenericBottomDrawer
					openDrawer={openDrawer}
					setOpenDrawer={setOpenDrawer}
					title={t('New Appointment', { ns: 'appointment' })}
				/>
			) : null}
		</>
	);
};

export default BlumeHeaderV2;

const STYLES = {
	ICON_BUTTON: (isTablet, selected, reverseBg) => ({
		backgroundColor: reverseBg ? '#42A5F5 !important' : '#EBF5FE',
		color: reverseBg ? '#ffffff !important' : '#42A5F5',
		border: selected ? '2px solid #42A5F5' : 'none',
		ml: isTablet ? 1.5 : 4,
		height: isTablet ? 32 : 40,
		width: isTablet ? 32 : 40,
	}),
};
