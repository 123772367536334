import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import SendIcon from '@mui/icons-material/Send';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import useHelpCenter from '@rs-core/hooks/useHelpCenter';
import { H3 } from '@worklist-2/ui/src/views/HelpView/HelpCenter/helpCenterStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ChatBubble, ChatWrapper, InputWrapper } from '../../../Help/helpStyles';
import ChatBoxInput from './ChatBoxInput';

const HelpCenterChat = ({ dismiss }) => {
	// dummy variables because I removed useLiveChat()
	const messages = [];
	const initializing = true;
	const liveChatSender = {};
	const liveAgent = {};
	const sendTextMessage = () => {};

	useEffect(() => {
		try {
			if (messages.length > 0) {
				const ele = document.getElementById(`bubble-${messages.length - 1}`);
				ele?.scrollIntoView();
			}
		} catch (error) {
			console.error(error);
		}
	}, [messages]);

	const sendMessage = async data => {
		try {
			sendTextMessage(data);
		} catch (error) {
			console.error('Error =>', error);
		}
	};

	return (
		<Grid container direction={'column'} height={'100%'} minHeight={640} spacing={0} wrap={'nowrap'}>
			<Paper
				className={'organizationLogoSection'}
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					padding: '20px 10px 15px 0px',
					borderRadius: 10,
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<IconButton
					onClick={dismiss}
					style={{
						width: 40,
						height: 40,
						marginLeft: 'auto',
						marginRight: 10,
					}}
				>
					<CloseIcon />
				</IconButton>
				<ChatWrapper>
					<div className="chat-start-wrapper">
						<ForumOutlinedIcon sx={{ fontSize: 36, color: '#FFF' }} />
						<H3 style={{ fontSize: '14px' }}>
							{initializing ? 'Connecting...' : `You are now connected to ${liveAgent?.name}.`}
						</H3>
					</div>
					<div className="chat-content-wrapper">
						{messages.map((entry, i) => (
							<ChatBubble id={`bubble-${i}`} isUser={entry.sender?.uid == liveChatSender?.uid}>
								<div className="content">{entry.text}</div>
							</ChatBubble>
						))}
					</div>
					<ChatBox send={sendMessage} sending={initializing} />
				</ChatWrapper>
			</Paper>
		</Grid>
	);
};

HelpCenterChat.propTypes = {
	dismiss: PropTypes.func.isRequired,
};

export default HelpCenterChat;

export const ChatBox = ({ send, sending }) => {
	const [message, setMessage] = useState('');
	const { toggleScreenShare } = useHelpCenter();

	const handleSubmit = e => {
		e.preventDefault();
		if (message.trim().length > 0) {
			send({
				message,
				isUser: true,
			});
			setMessage('');
		} else {
			console.error('no message', message);
		}
	};

	return (
		<form onSubmit={handleSubmit} className="chat-box">
			<IconButton
				onClick={() => toggleScreenShare(true)}
				type="button"
				style={{ width: 40, height: 40 }}
				className="share"
			>
				<DriveFolderUploadOutlinedIcon />
			</IconButton>
			<InputWrapper className="input-wrapper">
				<ChatBoxInput
					style={{ flex: 1 }}
					placeholder="Enter your message here"
					value={message}
					onChange={e => setMessage(e.target.value)}
				/>
				<IconButton
					type="submit"
					aria-label="toggle password visibility"
					edge="end"
					sx={{ m: 0 }}
					style={{ width: 40, height: 40 }}
					disabled={sending}
					className="send"
				>
					<SendIcon style={{ color: '#42a5f5' }} />
				</IconButton>
			</InputWrapper>
		</form>
	);
};
