const imageViewerSlice = set => ({
	currentStudyModality: null, // To store current study modality extracted from image series in IV

	// ==================== Setters ====================
	setCurrentStudyModality: studyInfo =>
		set(() => ({
			currentStudyModality: studyInfo,
		})),

	// ==================== Re-setters ====================
	resetCurrentStudyModality: () =>
		set(() => ({
			currentStudyModality: null,
		})),
});

export default imageViewerSlice;
