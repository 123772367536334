export const initializeDefaultCountryCode = (defaultCountryCode, setDefaultCountryCode, form) => {
	const service = new window.google.maps.places.PlacesService(document.createElement('div'));
	const request = {
		placeId: form.getValues().userLocation.place_id,
		fields: ['address_components'],
	};

	service.getDetails(request, (place, status) => {
		if (status === window.google.maps.places.PlacesServiceStatus.OK) {
			const countryComponent = place.address_components.find(component => component.types.includes('country'));

			if (countryComponent && !defaultCountryCode) {
				setDefaultCountryCode(countryComponent.short_name);
			}
		}
	});
};
