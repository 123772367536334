/* Generated automagically by FhirStarter - DO NOT EDIT */
import AuditEventMapping from './AuditEventMapping';
import ActivityHistoryMapping from './ActivityHistoryMapping';
import ImagingStudyMapping from './ImagingStudyMapping';
import ServiceRequestMapping from './ServiceRequestMapping';
import EncounterMapping from './EncounterMapping';
import MedicationMapping from './MedicationMapping';
import OrganizationMapping from './OrganizationMapping';
import OrganizationAffiliationMapping from './OrganizationAffiliationMapping';
import PatientMapping from './PatientMapping';
import CodeSystemMapping from './CodeSystemMapping';
import IssuerMapping from './IssuerMapping';
// import ProvenanceMapping from './ProvenanceMapping';
import TaskMapping from './TaskMapping';
import FaxMapping from './FaxMapping';
import ImagingStudyWorklistMapping, { ImagingStudyWorklistMappingSearchByUtcDate } from './ImagingStudyWorklistMapping';
import PatientActivityMapping from './PatientActivityMapping';
import ValueSetMapping from './ValueSetMapping';
import WorkListLayoutMapping from './WorkListLayoutMapping';
import TaskLogLayoutMapping from './TaskLogLayoutMapping';
import DeviceMapping from './DeviceMapping';
import DiagnosticReportMapping from './DiagnosticReportMapping';
import DocumentReferenceMapping from './DocumentReferenceMapping';
import PractitionerMapping from './PractitionerMapping';
import WorkflowAutomationMapping from './WorkflowAutomationMapping';
import PatientLedgerMapping from './PatientLedgerMapping';
import AllergyIntoleranceMapping from './AllergyIntoleranceMapping';
import _ from 'lodash';

const dict = {
	ActivityHistory: ActivityHistoryMapping,
	AuditEvent: AuditEventMapping,
	ImagingStudy: ImagingStudyMapping,
	ServiceRequest: ServiceRequestMapping,
	Encounter: EncounterMapping,
	Medication: MedicationMapping,
	Organization: OrganizationMapping,
	OrganizationAffiliation: OrganizationAffiliationMapping,
	Patient: PatientMapping,
	CodeSystem: CodeSystemMapping,
	AssigningAuthority: IssuerMapping,
	// Provenance: ProvenanceMapping,
	Task: TaskMapping,
	Fax: FaxMapping,
	ImagingStudyWorklist: ImagingStudyWorklistMapping,
	ImagingStudyWorklistSearchByUtcDate: ImagingStudyWorklistMappingSearchByUtcDate,
	PatientActivity: PatientActivityMapping,
	ValueSet: ValueSetMapping,
	WorkListLayout: WorkListLayoutMapping,
	TaskLogLayout: TaskLogLayoutMapping,
	Device: DeviceMapping,
	DiagnosticReport: DiagnosticReportMapping,
	default: ImagingStudyWorklistMapping,
	DocumentReference: DocumentReferenceMapping,
	Practitioner: PractitionerMapping,
	WorkflowAutomation: WorkflowAutomationMapping,
	PatientLedger: PatientLedgerMapping,
	AllergyIntolerance: AllergyIntoleranceMapping,
};
const capitalizeFirstLetter = string => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};
const getColumnMapping = (resource, mavenWorklistWorklistDateTimeDisplay) => {
	let resourceStrippedAndCapitalized = capitalizeFirstLetter(_.replace(resource, /\/.*/g, ''));

	if (['ImagingStudyWorklist', 'default'].includes(resourceStrippedAndCapitalized)) {
		// if feature flag is true new date format(ImagingStudyWorklistNewDateTime) else old date format(ImagingStudyWorklist)
		resourceStrippedAndCapitalized = mavenWorklistWorklistDateTimeDisplay
			? 'ImagingStudyWorklist'
			: 'ImagingStudyWorklistSearchByUtcDate';
	}
	if (dict[resourceStrippedAndCapitalized]) {
		_.forEach(dict[resourceStrippedAndCapitalized], elem => {
			if (!elem['filterType']) {
				elem['filterType'] = 'none';
			}
			if (!elem['options']) {
				elem['options'] = { filter: false, sort: false };
			}
		});
		return dict[resourceStrippedAndCapitalized];
	} else {
		return dict.default;
	}
};

export default getColumnMapping;

/**
 * Returns an object containing default
 * @param {string[]} resourceList The list of resources for which to return a list of options
 * @returns {Object} An object containing lists of selectable options, keyed by resource name
 */
export const getColumnValueSet = (columnList, resource) => {
	// TODO: this can also be used to load a valueset from an API if nothing is obtained from the mapping
	// additionally, this will probably be replaced by the API call functionality altogether at some point
	let result = {},
		resourceStripped = _.replace(resource, /\/.*/g, ''),
		resourceMapping = dict[resourceStripped];
	if (!(columnList instanceof Array)) {
		columnList = [columnList];
	}
	for (let column of columnList) {
		let list =
			!!resourceMapping && !!resourceMapping[column] && !!resourceMapping[column].valueSet
				? resourceMapping[column].filterType === 'single-select'
					? makeObjectList(resourceMapping[column].valueSet)
					: resourceMapping[column].valueSet
				: []; // where this is called will be expecting something, so we have to return at least an empty array
		result[column] = list;
	}
	return result;
};

const makeObjectList = list => {
	const labelLabel = 'label',
		valueLabel = 'value';
	const arr = _.reduce(list, (a, b) => [...a, { [labelLabel]: item, [valueLabel]: item }]);

	return { labelField: labelLabel, valueField: valueLabel, values: arr };
};
