export const APPOINTMENT_SUCCESS = 'APPOINTMENT_SUCCESS';
export const APPOINTMENT_FAILURE = 'APPOINTMENT_FAILURE';
export const APPOINTMENT_CANCEL = 'APPOINTMENT_CANCEL';
export const APPOINTMENT_RESCHEDULE_SUCCESS = 'APPOINTMENT_RESCHEDULE_SUCCESS';
export const APPOINTMENT_RESCHEDULE_FAILURE = 'APPOINTMENT_RESCHEDULE_FAILURE';
export const APPOINTMENT_CONFIRMED = 'APPOINTMENT_CONFIRMED';
export const FORM_FILLED_SUCCESS = 'FORM_FILLED_SUCCESS';
export const FORM_FILLED_FAILURE = 'FORM_FILLED_FAILURE';
export const VIEW_STUDY = 'VIEW_STUDY';
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';
export const UPLOAD_DICOM_SUCCESS = 'UPLOAD_DICOM_SUCCESS';
export const UPLOAD_DICOM_FAILURE = 'UPLOAD_DICOM_FAILURE';
export const SHARE_DOCUMENT = 'SHARE_DOCUMENT';
export const SHARE_DICOM = 'SHARE_DICOM';
export const SHARE_VIA_LINK = 'SHARE_VIA_LINK';
export const ADD_CONTACT = 'ADD_CONTACT';
export const USER_SEARCH = 'USER_SEARCH';
export const STUDY_DOWNLOAD = 'STUDY_DOWNLOAD';
export const STUDY_BURN_TO_DISC = 'STUDY_BURN_TO_DISC';
export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';
export const HELP_CENTER = 'HELP_CENTER';
export const HELP_CENTER_SEARCH = 'HELP_CENTER_SEARCH';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const BMI_UPDATE = 'BMI_UPDATE';
export const FEEDBACK_POSITIVE = 'FEEDBACK_POSITIVE';
export const FEEDBACK_NEGATIVE = 'FEEDBACK_NEGATIVE';
export const FEEDBACK_DISMISS = 'FEEDBACK_DISMISS';
export const USER_ORG_MAPPING = 'USER_ORG_MAPPING';
