import React, { useCallback, useEffect, useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import classnames from 'classnames';
import GlobalStyles from '@mui/material/GlobalStyles';
import { useUserStore } from './stores';
import useNavigatorOnLine from './hooks/useNavigatorOnline';

import {
	TabProvider,
	SelectorContextProvider,
	useAuth,
	useAppModeContext,
	ImportTabProvider,
	GlobalSearchHelpCenterPPProvider,
	useIsTablet,
	mediaTablet,
	mediaDesktopL,
	useImportTabContext,
	useConfig,
} from '@worklist-2/core';
import { HelpCenterContextProvider } from '@rs-core/context/HelpCenterContext';
import { SearchScopeProvider } from '@rs-core/context/SearchScopeContext';
import { lightTheme } from '@worklist-2/ui/src/themes';
import { SideBar, UploadProgressWindow } from '@worklist-2/ui/src/components';
import ChatCallingBubble from '@worklist-2/ui/src/components/Chat/ChatCallingBubble';
import { useChatGlobalContext } from '@worklist-2/ui/src/components/Chat/ChatGlobalContext';
import ChatCallParticipantMedia from '@worklist-2/ui/src/components/Chat/ChatCallParticipantMedia';

import LoginRoute from './routes/Login';
import ValidRoutes from './routes/ValidRoutes';
import {
	AppointmentContextProvider,
	useAppointmentContext,
} from './components/Appointment/AppointmentContext/AppointmentContext';
import { BlumeAssistantContextProvider } from './context/BlumeAssistantContext';
import { BlumeHelpCenterContextProvider } from './context/BlumeHelpCenterContext';
import { useToastMessageContext } from './context/ToastMessageContext';
import HelpPopOver from './views/HelpView/HelpPopOver';
import useStoreSync from './hooks/useStoreSync';
import BlumeHeaderV2 from './components/BlumeHeader/BlumeHeaderV2';
import AppointmentDrawer from './components/Appointment/AppointmentDrawer/AppointmentDrawer';
import BlumeToastMessage from './components/BlumeToastMessage';
import Schedule from './components/WhiteLabel/Schedule';

import appGlobalStyles from './appGlobalStyles';
import { Typography } from '@mui/material';
import sendAnalyticsEvent from './analytics';
import { LOGIN_SUCCESS } from './analytics/eventTypes';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import useGoogleTranslation from './hooks/useGoogleTranslation';
import PrivacyPolicy from './routes/Legal/PrivacyPolicy';
import TermsServices from './routes/Legal/TermsServices';

// Desktop App component

const DesktopApp = ({ children, setShowCallBubble, openChatDrawer, setOpenChatDrawer }) => {
	const { isFullscreen, isOpen, setIsOpen } = useAppointmentContext();

	useEffect(() => {
		if (!isOpen) {
			setShowCallBubble(true);
			setOpenChatDrawer(false);
		} else setShowCallBubble(false);
	}, [isOpen]);

	useEffect(() => {
		if (openChatDrawer) setIsOpen('Chat');
	}, [openChatDrawer]);

	return (
		<Box
			sx={{
				'--app-padding-top': '10px',
				'--app-drawer-border-radius': 'var(--border-radius-base)',
				'--app-drawer-offset': 'var(--app-drawer-border-radius)',
				'--app-drawer-background-color': '#fcfcfc',
				display: 'flex',
				justifyContent: 'end',
				height: isFullscreen ? '100vh' : 'unset',

				[mediaDesktopL]: {
					'--app-padding-top': '0px',
				},
			}}
		>
			<Box
				sx={{
					flex: '1',
					width: isFullscreen ? '0' : '100%',
					overflow: isFullscreen ? 'hidden' : 'unset',
					transition: '0.2s',
					paddingTop: 'none',
				}}
			>
				{children}
			</Box>

			<AppointmentDrawer />
		</Box>
	);
};

function handleGoogleTranslateLanguage(selectedLanguage) {
	if (selectedLanguage) {
		const frame = document.querySelector('.goog-te-combo');
		if (frame) {
			const languageLinks = frame.querySelectorAll('option');
			languageLinks.forEach(link => {
				if (link.value === selectedLanguage) {
					frame.value = selectedLanguage;
					const event = new Event('change');
					frame.dispatchEvent(event);
				}
			});
		}
	}
}

const I18nWrapper = ({ children }) => {
	const { blumeUserSettings } = useAuth();
	const { i18n } = useTranslation();

	useEffect(() => {
		if (blumeUserSettings?.language) {
			i18n.changeLanguage(blumeUserSettings?.language);
			handleGoogleTranslateLanguage(blumeUserSettings?.language);
		}
	}, [blumeUserSettings, i18n]);

	return children;
};

const AuthenticatedLayout = ({ children }) => {
	const { authorized, loggedInUser, setProfiles } = useAuth();
	const { toggleSideBarIsOpen } = useImportTabContext();
	const { appMode } = useAppModeContext();
	const { type } = useToastMessageContext();
	const { callData, callParticipants } = useChatGlobalContext();
	const [watermarkMessage, setWatermarkMessage] = useState('');

	const [sideBarIsOpen, setSideBarIsOpen] = useState(false);
	// this is only used to determine the class to apply
	const [sideBarState, setSideBarState] = useState('closed');
	const [showCallBubble, setShowCallBubble] = useState(true);
	const [openChatDrawer, setOpenChatDrawer] = useState(false);

	const location = useLocation();
	const isTablet = useIsTablet();

	const navigate = useNavigate();

	const { fetchUser } = useUserStore(state => ({ fetchUser: state.fetchUser }));

	const isCaller =
		loggedInUser?.email?.toLowerCase() === callData?.callNotification
			? callData?.callNotification?.caller?.email?.toLowerCase()
			: callData?.caller?.email?.toLowerCase();
	const remoteParticipants = callData?.conversation?.attributes?.participants?.filter(
		participant => participant.email.toLowerCase() !== loggedInUser?.email?.toLowerCase()
	);
	// For caller side, get remote participant name. For receiver side, get caller name
	const remoteParticipantName =
		callData?.callNotification?.caller?.name ??
		(remoteParticipants ? (remoteParticipants?.length > 1 ? 'Group' : remoteParticipants[0]?.name) : '');

	const queryParams = new URLSearchParams(location.search);
	const isStudyView = location.pathname === '/view-study';
	const __config = useConfig();

	useNavigatorOnLine();

	useEffect(() => {
		if (authorized) {
			fetchUser({ __config }).then(profiles => {
				setProfiles(profiles);
			});
		}
	}, [authorized]);

	useEffect(() => {
		setSideBarIsOpen(!(isTablet || isStudyView));
	}, [isStudyView]);

	useEffect(() => {
		if (sideBarIsOpen) {
			setSideBarState('open');
		} else {
			setSideBarState('closed');
		}

		toggleSideBarIsOpen(sideBarIsOpen);
	}, [sideBarIsOpen]);

	useEffect(() => {
		if (isTablet) {
			setSideBarIsOpen(false);
		}

		const organization = queryParams.get('organization');

		if (organization) {
			sessionStorage.setItem('organization', organization.toString());
		}
	}, [isTablet, location]);

	useEffect(() => {
		if (loggedInUser?.email) {
			sendAnalyticsEvent(LOGIN_SUCCESS, {
				email: loggedInUser?.email,
				name: loggedInUser?.fullName,
				id: loggedInUser?.id,
				isBlumeSynced: loggedInUser?.isBlumeSynced,
			});
		}
		// determine if the user information from UserAuthContext differs than the email stored in the sessionStorage "login_hint" key
		const loginHint = sessionStorage.getItem('login_hint');
		if (loginHint && loggedInUser && loginHint?.toLowerCase() !== loggedInUser?.email?.toLowerCase()) {
			setWatermarkMessage(`The user in this session is being impersonated by ${loginHint}`);
		}
	}, [loggedInUser?.email]);

	const onToggleSidebarIsOpen = useCallback(() => setSideBarIsOpen(prev => !prev), []);

	const onClickLogo = () => {
		if (!sideBarIsOpen) onToggleSidebarIsOpen();

		navigate('/');
	};

	return authorized ? (
		<SelectorContextProvider>
			<BlumeHelpCenterContextProvider>
				<GlobalSearchHelpCenterPPProvider>
					<HelpCenterContextProvider PopOverComponent={HelpPopOver}>
						<SideBar
							className="sidebar"
							open={sideBarIsOpen}
							setSideBarIsOpen={setSideBarIsOpen}
							onClickLogo={onClickLogo}
							onClickOpenClose={onToggleSidebarIsOpen}
						/>
						<Box className={classnames('main-window', `main-window-sidebar-${sideBarState}-${appMode}`)}>
							<AppointmentContextProvider>
								<BlumeAssistantContextProvider>
									<BlumeHeaderV2 toggleSidebar={() => setSideBarIsOpen(prev => !prev)} />
									{isTablet ? (
										children
									) : (
										<DesktopApp
											openChatDrawer={openChatDrawer}
											setOpenChatDrawer={setOpenChatDrawer}
											setShowCallBubble={setShowCallBubble}
										>
											{children}
										</DesktopApp>
									)}
									{showCallBubble &&
										(callData?.status?.calling ||
											callData?.status?.onCall ||
											callData?.status?.incoming) && (
											<Box
												sx={{
													position: 'absolute',
													right: isTablet ? '10px' : '70px',
													top: !isTablet && '125px',
													bottom: isTablet && '10px',
													zIndex: 1050,
												}}
											>
												<ChatCallingBubble
													isBlume
													isCaller={isCaller}
													remoteParticipantName={remoteParticipantName}
													onBubbleClick={() => setOpenChatDrawer(true)}
												/>
											</Box>
										)}
									{callData?.status?.onCall &&
										callParticipants?.map(participant => (
											<ChatCallParticipantMedia
												key={participant.identity}
												participant={participant}
											/>
										))}
								</BlumeAssistantContextProvider>
							</AppointmentContextProvider>
						</Box>
						<UploadProgressWindow isDownload={type !== 'upload'} />
						{watermarkMessage && (
							<Typography
								sx={{
									position: 'fixed',
									bottom: '5%',
									left: '50%',
									transform: 'translate(-50%,0)',
									textAlign: 'center',
									border: '1px solid rgba(0, 0, 0, 0.12)',
									padding: '8px 16px',
									borderRadius: '24px',
									backgroundColor: '#FFFFFF',
									boxShadow: '0px 0px 20px rgba(77, 121, 234, 0.3)',
								}}
							>
								{watermarkMessage}
							</Typography>
						)}
						<BlumeToastMessage />
					</HelpCenterContextProvider>
				</GlobalSearchHelpCenterPPProvider>
			</BlumeHelpCenterContextProvider>
		</SelectorContextProvider>
	) : null;
};

const CustomThemeProvider = ({ children, newHomePage, external }) => (
	<ThemeProvider theme={lightTheme}>
		<GlobalStyles
			sty
			styles={{
				':root': {
					...appGlobalStyles.root,
				},

				body: {
					backgroundColor: newHomePage ? '#F9FAFB' : lightTheme.palette.primary.main,
				},

				...appGlobalStyles.chat,
				...appGlobalStyles.translate,

				scrollbarWidth: 'thin',
				scrollbarColor: '#272727 #e6e6e6',

				'.MuiInputBase-root': {
					color: 'var(--field-color) !important',
				},

				'.portalDetailWindow': {
					'--app-detail-padding-left': '100px',
					'--app-detail-padding-right': '100px',
					'--app-detail-flex-dir': 'row',
					'--app-detail-width': 'unset',
					'--app-detail-overflowY': 'hidden',
					'--app-detail-justify-content': 'space-between',
					maxWidth: 'calc(100% - var(--app-detail-padding-left))',
					'.portalDetailedInfo': {
						overflowY: 'auto',
					},
					[mediaDesktopL]: {
						'--app-detail-padding-left': '18px',
						'--app-detail-padding-right': '38px',
					},
				},
				'.leftBarOpen': {
					'--app-detail-padding-left': '94px',
					'--app-detail-padding-right': '93px',

					[mediaDesktopL]: {
						'--app-detail-padding-left': '24px',
						'--app-detail-padding-right': '38px',
					},
				},
				'.rightBarOpen': {
					'--app-detail-padding-left': '70px',
					'--app-detail-padding-right': '93px',
					[mediaDesktopL]: {
						'--app-detail-flex-dir': 'column',
						'--app-detail-padding-left': '42px',
						'--app-detail-padding-right': '21px',
						'--app-detail-width': '100%',
						'--app-detail-overflowY': 'auto',
						'--app-detail-justify-content': 'flex-start',
						maxWidth:
							'calc(100vw - 79px - 370px - var(--app-detail-padding-left) - var(--app-detail-padding-right))',

						'.portalDetailedInfo': {
							overflowY: 'unset',
							paddingTop: '24px',
							maxWidth: 'var(--app-detail-width)',
						},
						'.portalImageInfo': {
							paddingRight: 'var(--app-detail-padding-right)',
							width: 'calc(var(--app-detail-width) - var(--app-detail-padding-right))',
							minHeight: '573px',
						},
					},
				},
				'.leftRightBarOpen': {
					'--app-detail-padding-left': '90px',
					'--app-detail-padding-right': '30px',
					[mediaDesktopL]: {
						'--app-detail-flex-dir': 'column',
						'--app-detail-padding-left': '24px',
						'--app-detail-padding-right': '21px',
						'--app-detail-width': '100%',
						'--app-detail-overflowY': 'auto',
						maxWidth:
							'calc(100vw - 205px - 370px - var(--app-detail-padding-left) - var(--app-detail-padding-right))',

						'.portalDetailedInfo': {
							paddingTop: '26px',
							overflowY: 'unset',
							maxWidth: 'var(--app-detail-width)',
						},
						'.portalImageInfo': {
							paddingRight: 'var(--app-detail-padding-right)',
							width: 'calc(var(--app-detail-width) - var(--app-detail-padding-right))',
							minHeight: '573px',
						},
					},
				},
				'.main-window-sidebar-closed-patientPortal': {
					marginLeft: newHomePage ? '65px' : '79px',
					[mediaTablet]: {
						marginLeft: '0px',
					},
					'.portalMainWindow': {
						padding: '24px 5% 0px 5%',
						[mediaDesktopL]: {
							padding: '24px 2.5% 0px 2.5%',
						},
						[mediaTablet]: {
							padding: '10px 16px',
							marginTop: '58px',
						},
					},

					'.portalSharedMainWindow': {
						padding: '0px 96px 0px 96px',
						[mediaTablet]: {
							marginTop: '0 !important',
						},
					},
				},

				'.main-window-sidebar-open-patientPortal': {
					marginLeft: external ? '0' : newHomePage ? '206px' : '220px',
					[mediaTablet]: {
						marginLeft: '0px',
					},
					'.portalMainWindow': {
						padding: '24px 5% 0px 5%',
						[mediaTablet]: {
							padding: '10px 16px',
							marginTop: '58px',
						},
					},

					'.portalSharedMainWindow': {
						padding: '0px 17px 0px 53px',
						[mediaDesktopL]: {
							padding: '0px 17px 0px 25px',
						},
						[mediaTablet]: {
							marginTop: '0 !important',
						},
					},
				},

				'&.searchTabs .search-items-container': {
					overflow: 'hidden',
					'&:hover': {
						overflow: 'auto',
					},
				},
				'&:hover': {
					'::-webkit-scrollbar-thumb': {
						background: '#c4c4c4',
					},
				},

				'&::-webkit-scrollbar': {
					width: '4px',
					height: '4px',
				},
				'&::-webkit-scrollbar-track': {
					background: 'transparent',
				},
				'&::-webkit-scrollbar-thumb': {
					background: 'transparent',
					borderRadius: '2px',
					height: '40px',
				},
				'&::-webkit-scrollbar-corner': {
					background: 'transparent',
				},
				...appGlobalStyles.userProfile,
			}}
		/>
		{children}
	</ThemeProvider>
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 1, // 1 minutes
			cacheTime: 1000 * 60 * 5, // 5 minutes
			refetchOnWindowFocus: false,
			retry: 2,
		},
	},
});

const App = () => {
	const phoenixBlumeNewSchedulingFlow = useBooleanFlagValue('phoenix-blume-new-scheduling-flow');
	// Sync Shared Data for zustand stores
	useStoreSync();
	useGoogleTranslation();

	const { pathname } = useLocation();
	const external = pathname.startsWith('/externalImageViewer') || pathname.startsWith('/external-form');

	return (
		<CustomThemeProvider newHomePage external={external}>
			<QueryClientProvider client={queryClient}>
				<AuthenticatedTemplate>
					<TabProvider>
						<ImportTabProvider>
							<SearchScopeProvider>
								<Suspense fallback={null}>
									<I18nWrapper>
										<AuthenticatedLayout>
											<ValidRoutes />
										</AuthenticatedLayout>
									</I18nWrapper>
								</Suspense>
							</SearchScopeProvider>
						</ImportTabProvider>
					</TabProvider>
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<SelectorContextProvider>
						<Routes>
							{phoenixBlumeNewSchedulingFlow && <Route element={<Schedule />} path="/book-appointment" />}
							<Route element={<PrivacyPolicy />} path="/privacy-policy" />
							<Route element={<TermsServices />} path="/terms-services" />
							<Route element={<LoginRoute />} path="*" />
							<Route element={<LoginRoute />} path="login" />
						</Routes>
					</SelectorContextProvider>
				</UnauthenticatedTemplate>
				{/* <ReactQueryDevtools initialIsOpen={false} /> */}
			</QueryClientProvider>
			<div id="google_translate_element" style={{ display: 'none' }} />
		</CustomThemeProvider>
	);
};

export default App;
