import React from 'react';
import Box from '@mui/material/Box';
import MobileSharedWithSection from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetailSections/MobileSharedWithSection';
import MobilePhysicianInformationSection from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetailSections/MobilePhysicianInformationSection';
import MobileStudyInformationSection from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetailSections/MobileStudyInformationSection';
import MobileAppointmentLocationSection from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetailSections/MobileAppointmentLocationSection';
import MobileMapSection from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileStudyDetail/MobileStudyDetailSections/MobileMapSection';
import { useIsMobile } from '@rs-core/utils/responsiveUtils';
import FormsView from '@worklist-2/patientPortal/src/views/StudyInfoView/StudyDetailedView/FormsView';

const MobileStudyDetailScheduled = ({ study, studyAdditionalInfo, onShareClick, appointmentDate }) => {
	const isMobile = useIsMobile();
	return (
		<Box data-testid="scheduledDetailMobile">
			{!isMobile && <MobileMapSection appointmentDate={appointmentDate} study={study} />}

			<Box sx={{ padding: '16px' }}>
				<MobileStudyInformationSection study={study} />
			</Box>

			{isMobile && <MobileMapSection study={study} />}

			<Box sx={{ padding: '16px' }}>
				<MobilePhysicianInformationSection study={study} />

				<MobileAppointmentLocationSection study={study} />
				{study?.patientId && <FormsView patientId={study.patientId} studyId={study.id} />}
				<MobileSharedWithSection
					study={study}
					studyAdditionalInfo={studyAdditionalInfo}
					onShareClick={onShareClick}
				/>
			</Box>
		</Box>
	);
};

export default MobileStudyDetailScheduled;
