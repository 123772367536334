import * as yup from 'yup';

const validationSchemaV2 = yup.object().shape(
	{
		location: yup.string().required(),
		startTime: yup.date().required(),
		endTime: yup.date().required(),
		healthcareServiceId: yup.number().required(),
		email: yup.string().email('Email is not valid').required('Email is required'),
		countryCode: yup.string().optional(),
		birthDate: yup.string().required(),
		phone: yup.string().optional(),
		referringPhysician: yup
			.object()
			.shape({
				name: yup.string().optional(),
				id: yup.string().optional(),
			})
			.nullable()
			.optional(),
		firstName: yup.string().required('First name is required'),
		lastName: yup.string().required('Last name is required'),
		pin: yup.number().optional(),
		signupConsent: yup.boolean().optional(),
		blumedescription: yup.string().optional(),
		description: yup.string().required(),
		orderSetId: yup.string().required(),
	},
	[['phone', 'phone']]
);

export default validationSchemaV2;
