// core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useConfig } from '@rs-core/hooks';

//mui
import { Box, Stack, Button, Avatar, Typography, Tooltip } from '@mui/material';

// contexts
import { useUserStore } from '../../../../../stores';

// api
import patchUser from '../../../../../api/patchUser';

// utils
import sendAnalyticsEvent from '@worklist-2/patientPortal/src/analytics';
import { LOGOUT } from '../../../../../analytics/eventTypes';

export const handleBlumeLogout = async (__config, resetAndLogOut = Function.prototype) => {
	try {
		const data = [{ operation: 'ADD', path: '/isOnline', value: false }];
		await patchUser({ __config, data });
		sendAnalyticsEvent(LOGOUT, {});
		sessionStorage.removeItem('organization');
	} catch (e) {
		console.error('Error while updating online status', e);
	} finally {
		resetAndLogOut(__config.auth.omegaai.msal.patient_portal_logout_redirect_uri);
	}
};

const Logout = () => {
	const { firstName, lastName, logo } = useUserStore(state => ({
		firstName: state.primaryUser?.firstName,
		lastName: state.primaryUser?.lastName,
		logo: state.primaryUser?.profilePictureUrl,
	}));
	const { resetAndLogOut } = useAuth();
	const __config = useConfig();
	const { t } = useTranslation('profile');

	const getName = () => `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`;

	const onLogoutClick = () => handleBlumeLogout(__config, resetAndLogOut);

	return (
		<Box>
			<Stack
				spacing={2}
				sx={{
					background: '#FFFFFF99',
					padding: '26px 36px',
					borderRadius: '20px',
					boxShadow: '4px 10px 40px 0px #0000000A',
				}}
			>
				<Box>
					<Stack direction="row" spacing={2}>
						<Tooltip title={`${firstName} ${lastName}`}>
							<Avatar
								alt={`${firstName} ${lastName}`}
								src={logo}
								sx={{ borderRadius: '20px', height: '80px', width: '80px', background: '#568AF333' }}
							>
								{getName()}
							</Avatar>
						</Tooltip>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Typography sx={{ fontWeight: '500' }}>{`${firstName} ${lastName}`}</Typography>
						</Box>
					</Stack>
				</Box>
				<Button color="rsPrimary" sx={{ padding: '10px' }} variant="outlined" onClick={onLogoutClick}>
					{t('Log Out')}
				</Button>
			</Stack>
		</Box>
	);
};
export default Logout;
